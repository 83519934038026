import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import AvatarBoy from "../../../../../../media/avatars/001-boy.svg";
import AvatarGirl from "../../../../../../media/avatars/002-girl.svg";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../../Library/AlertNotif";

export default function CardProfilePCF({ studentid }) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [stdProfile, setStdProfile] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const GET_STUDENT_DATA_M = (value) => {
    setStdProfile({ ...stdProfile, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: value,
      },
      target_data: "GET_STUDENT_DATA_M",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdProfile({
          ...stdProfile,
          loading: false,
          data: [],
          message: response.error,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdProfile({
          ...stdProfile,
          loading: false,
          data: [],
          message: msg,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setStdProfile({
            ...stdProfile,
            loading: false,
            data: results,
            message: "",
          });
        } else {
          setStdProfile({
            ...stdProfile,
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  const [stdStatus, setStdStatus] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const GET_STUDENT_STATUS = (value) => {
    setStdStatus({ ...stdStatus, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: value,
      },
      target_data: "GET_STUDENT_STATUS",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdStatus({
          ...stdStatus,
          loading: false,
          data: [],
          message: response.error,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdStatus({ ...stdStatus, loading: false, data: [], message: msg });
      } else {
        var results = response.response.data;
        if (results) {
          setStdStatus({
            ...stdStatus,
            loading: false,
            data: results,
            message: "",
          });
        } else {
          setStdStatus({
            ...stdStatus,
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_STUDENT_DATA_M(studentid);
    GET_STUDENT_STATUS(studentid);
  }, []);

  let statusStudent = "";

  if (Object.values(stdStatus.data).length > 0) {
    let actArr = stdStatus.data.status.id.split(".");
    statusStudent = actArr[actArr.length - 1];
  }

  const RenderTypeEnrollment = (value) => {
    let typeEnrollment = "";
    let typeArr = value.split(".");
    typeEnrollment = typeArr[typeArr.length - 1];
    return typeEnrollment;
  };

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-body">
        {stdProfile.loading || stdStatus.loading ? (
          <LoadingBtn />
        ) : stdProfile.message || stdStatus.message ? (
          <AlertNotif
            message={stdProfile.message || stdStatus.message}
            color={"danger"}
          />
        ) : Object.values(stdProfile.data).length > 0 &&
          Object.values(stdStatus.data).length > 0 ? (
          <div className="student-informasi">
            <div className="d-flex">
              <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div className="symbol symbol-130 symbol-primary">
                  <img
                    src={
                      stdProfile.data.gender === "p" ? AvatarBoy : AvatarGirl
                    }
                    alt={stdProfile.data.fullname}
                    className="bg-light-primary"
                  />
                </div>
              </div>

              <div className="flex-grow-1">
                {/* TITLE */}
                <div className="d-flex justify-content-between flex-wrap mt-1">
                  <div className="d-flex mr-3">
                    <p className="text-dark-75 font-size-h1 font-weight-bold mr-3 mb-0">
                      {stdProfile.data.fullname}
                    </p>
                    <span className="">
                      <i
                        className={
                          "font-size-h5 " +
                          (Object.values(stdStatus.data).length > 0 && (
                            stdStatus.data.status.id === "asc.student.status.resign" ? "flaticon2-cancel text-danger" : "flaticon2-correct  text-success"
                          ))}
                      ></i>
                    </span>
                  </div>
                  <div className="my-lg-0 my-3">
                    <div className="btn-group">
                      <button
                        className={
                          "btn btn-sm font-weight-bolder text-uppercase btn-" +
                          (stdStatus.data.status.id ===
                          "asc.student.status.active"
                            ? "light-success"
                            : "danger")
                        }
                      >
                        {stdStatus.data.status.name }
                      </button>
                    </div>   
                  </div>
                </div>
                {/* CONTENT */}
                <div className="mt-1">
                  <div className="d-flex flex-wrap mb-2">
                    <span className="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <i className="flaticon2-new-email mr-2 font-size-lg"></i>{" "}
                      {stdProfile.data.sgumail}
                    </span>
                    <span className="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <i className="flaticon2-calendar-3 mr-2 font-size-lg"></i>{" "}
                      {stdStatus.data.studentid}
                    </span>
                    <span className="text-dark-50 font-weight-bold">
                      <i className="socicon-whatsapp mr-2 font-size-lg"></i>{" "}
                      {stdProfile.data.cellular
                        ? stdProfile.data.cellular
                        : "-"}
                    </span>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between mt-1">
                    <div className="d-flex flex-column flex-grow-1 pr-8">
                      {Object.values(stdStatus.data).length > 0 ? (
                        <div className="prodi">
                          <p className="font-weight-bold text-dark-50 mb-0 font-size-lg">
                            Program: {stdStatus.data.program.name}
                          </p>
                          <p className="font-weight-bold text-dark-50 mb-0 font-size-lg">
                            Department: {" "}
                            {stdStatus.data.clu ? stdStatus.data.clu.name : "-"}
                          </p>
                          {stdStatus.data.clu_spec ? (
                            <p className="font-weight-bold text-dark-50 mb-0 font-size-lg">
                              Specialization: {" "}
                              {stdStatus.data.clu_spec
                                ? stdStatus.data.clu_spec.name
                                : "-"}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          "No record found"
        )}
      </div>
    </div>
  );
}
