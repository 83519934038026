import React from 'react'
import packageJson from "../../../../../../package.json";
import PDFIcon from "../../../../../media/files/pdf.svg";
import File1 from "../../../../../media/documents/Langkah Pembayaran ke BCA Virtual Account.pdf";
import File2 from "../../../../../media/documents/Study Fee Installment Alternative.pdf";

export default function Regulations() {
    const listPayment = [{title:"Payment Step by VA BCA", desc:"Bank BCA",link:File1},
                         {title:"Payment Alternative",desc:"Non Bank BCA",link:File2}]
    return (
        <div id="regulation-payment">
            <div className="card card-custom card-stretch gutter-b">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Payment Instructions</span>
                    </h3>
                </div>
            </div>

            <div className="row">
            {listPayment.map((v,index)=>(
                <div className="col-6" key={index}>
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-body">
                            <div className="d-flex flex-column align-items-center pt-5">
                                <img src={PDFIcon} alt="PDF Icon" className='max-h-65px' />
                                <a href={v.link} 
                                className="text-dark-75 font-weight-bold mt-5 font-size-lg">{v.title}</a>
                                <span className="text-muted">{v.desc}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </div>
            

        </div>
    )
}
