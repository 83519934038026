import React, { useState, useEffect, useMemo } from 'react'
import jwt_decode from "jwt-decode"
import Moment from "moment";
import { openToast } from '../../../../../../Library/ToastPopUp';
import AxiosLibWithHeader2 from '../../../../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../../../../Widgets/LoadingBtn';
import { openModal } from '../../../../../../Library/ModalPopUp';

export default function FormStudentAtd(props) {
    const atd_lec_id = (props.atd_lec_id) ? props.atd_lec_id : 0;
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    const [stdList, setStdList] = useState({
        loading: false,
        data: [],
        message: ""
    });
    const [stdPost, setStdPost] = useState([]);
    const [tickAll, setTickAll] = useState(false);
    const GET_ATD_LECTURER_BY_ATDID = () => {
        setStdList({ ...stdList, loading: true });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": { "atdid": atd_lec_id },
            "target_data": "GET_ATD_LECTURER_BY_ATDID"
        };
        const postData = {
            keys: keys,
            body: body
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdList({
                    ...stdList,
                    message: response.error,
                    loading: false,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setStdList({ ...stdList, message: msg, loading: false });
            } else {
                var results = response.response.data;
                if (results) {
                    if (Object.values(results.atd_student).length > 0) {
                        results.atd_student.sort((a, b) => (a.studentstatusdata.fullname > b.studentstatusdata.fullname) ? 1 : -1);

                        const studentLst = results.atd_student.map((v) => {
                            var obj = {};
                            obj.remark = (v.remark) ? v.remark : "";
                            obj.flag_sts = v.flag_sts;
                            obj.status = v.status;
                            obj.studentid = v.studentid;
                            obj.stundentname = (v.studentstatusdata) ? v.studentstatusdata.fullname : "-";
                            return obj;
                        })
                        // studentLst.sort(function (a, b) {
                        //     if (a.stundentname < b.stundentname) { return -1; }
                        //     return 0;
                        // })
                        setStdPost(studentLst);
                        setStdList({ ...stdList, data: results.atd_student, loading: false });
                    }

                } else {
                    setStdList({
                        ...stdList,
                        message: "No record found",
                        loading: false,
                        data: [],
                    });
                }
            }
        });
    }

    useEffect(() => {
        if (props.atd_lec_id) {
            GET_ATD_LECTURER_BY_ATDID();
        }
    }, [props])

    const ResultMemo = useMemo(() => {
        let computedData = stdList.data;
        if (tickAll) {
            computedData = stdList.data.map(item => ({
                ...item,
                status: "P",
            }));
            return computedData;
        }
        return computedData;
    }, [stdList.data, stdPost, tickAll]);

    const handlerAttd = (index, value) => {
        setStdPost((prev) => {
            const newData = [...prev]
            newData[index].status = value
            return newData
        })
    }
    const handlerRemark = (index, value) => {
        if (value) {
            setStdPost((prev) => {
                const newData = [...prev]
                newData[index].remark = value
                return newData
            })
        }
    }

    const LocationCoordinate = ({ values }) => {
        let coordinate = values.coordinate;
        let coordinateType = "";
        if (coordinate) {
            let coordinateArr = coordinate.split(",");
            coordinateType = coordinateArr[0] + "," + coordinateArr[1];
        }

        return (
            <div>
                {(coordinateType !== "0,0") ? (
                    <a href={"https://www.google.com/maps/search/?api=1&query=" + coordinateType} target="_blank" title='Open map'>
                        View location
                    </a>
                ) : (
                    <div>
                        <span className="fw-bolder me-1">Location:</span>
                        <span className="text-danger">{coordinateType}</span>
                    </div>
                )}
            </div>
        )
    }

    const ConfirmAtd = ({formid}) => {
        return (
            <div className="text-center">
                <h4>Saving this attendance list will <span className="text-danger">disable</span> the QR scan. This is to prevent unauthorized changes on the attendance data that has been finalized by the lecturers. After saving, lecturers can still edit the attendance data manually. Do you want to continue ?</h4>
                <div className="btn-groups mt-10">
                    <button className="btn btn-lg" type='button' onClick={() => openModal({ open: false })}>No</button>
                    <button className="btn btn-lg btn-primary" type='button' onClick={() => UPDATE_STD_ATD_BY_LEC(formid)}>Yes</button>
                </div>
            </div>
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (AuthDecode.remotedBy) {
            openToast({ header: "Error", message: "You cannot access this request" });
        } else {
            var formid = e.target.id;
            openModal({ header: "Confirmation", message: <ConfirmAtd formid={formid} /> });
        }
    }

    const UPDATE_STD_ATD_BY_LEC = (formid) => {
        openModal({open:false});
        var target = document.getElementById(formid);
        var myButton = target.getElementsByClassName("btn-submit")[0];
        var classSpinner = ['spinner', 'spinner-white', 'spinner-left'];
        var messageReturn = ""; var result = false;

        myButton.classList.add(...classSpinner);
        myButton.textContent = "processing";
        myButton.disabled = true;
        var stdPostNew = stdPost.map((v) => {
            var obj = {};
            obj.remark = (v.remark) ? v.remark : "";
            obj.status = (v.status) ? v.status : "";
            obj.studentid = (v.studentid) ? v.studentid : "";
            return obj;
        })

        var paramPost = {
            "atd_lec_id": atd_lec_id,
            "student": stdPostNew
        }

        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": paramPost,
            "target_data": "UPDATE_STD_ATD_BY_LEC"
        };

        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        messageReturn = response.error;
                    } else if ("message" in response.response) {
                        messageReturn += "\nFailed save attendance " + response.response.message.internal;
                    } else if ("responseCode" in response) {
                        if (response.responseCode === 201) {
                            //messageReturn = "Please make sure all your students have done their attendance before submitting attendance.";    
                            messageReturn = "Success update attendance student."; result = true;
                        } else {
                            messageReturn = "Failed update attendance student.";
                        }
                    }

                    openToast({
                        message: messageReturn,
                        header: "Info"
                    });

                    myButton.classList.remove(...classSpinner);
                    myButton.disabled = true;
                    myButton.innerHTML = 'Save Changes';

                }
            );
    }

    return (
        <div id='student-list-atd' className='card card-custom gutter-b'>
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">Form Student Attendance</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">Please fill up the form with correctly</span>
                </h3>
                <div className="card-toolbar">
                    <div className="btn-group">
                        <button type='button' className="btn btn-light-primary btn-sm">
                            <i className="far fa-user-circle"></i>
                            Total Student {Object.values(stdList.data).length}
                        </button>
                        <button className="btn btn-primary btn-sm" type='button' title='Click here to refresh the table' onClick={() => GET_ATD_LECTURER_BY_ATDID()}>
                            <i className="fa fa-sync"></i> Click here to refresh
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <form method="post" id="student-form-attd" autoComplete='off' onSubmit={(e) => handleSubmit(e)}>
                    <div className="table-responsive">
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-start">
                            <thead>
                                <tr>
                                    <th width="5%">No</th>
                                    <th>Student</th>
                                    <th>Attendance
                                        {/* <div className="d-flex justify-content-start align-items-middle">
                                            <span className='mr-2'>Attendance | </span>
                                            <label><input type="checkbox" name="tick" value={"P"} onChange={(e)=>HandlerTickAll(e)} /> Tick all present</label> 
                                        </div> */}
                                    </th>
                                    <th>Remark</th>
                                    <th>Tap Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(stdList.loading) ? (
                                    <tr>
                                        <td colSpan={7}><LoadingBtn /></td>
                                    </tr>
                                ) : (
                                    (stdList.message) ? (
                                        <tr>
                                            <td colSpan={7}><span className="text-danger">{stdList.message}</span></td>
                                        </tr>
                                    ) : (
                                        (Object.values(stdList.data).length > 0) ? (
                                            stdList.data.map((v, index) => (
                                                <tr key={index} className={(v.flag_sts === 4 || v.flag_sts === 5) ? "bg-light-danger" : ""}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <span className="d-block">{(v.studentstatusdata) ? v.studentstatusdata.fullname : ""}</span>
                                                        <span>{v.studentid}</span>
                                                    </td>
                                                    <td>
                                                        {(v.flag_sts === 1 || v.flag_sts === 4 || v.flag_sts === 5) ? (
                                                            <>
                                                                <label className='mr-3'>
                                                                    <input type="radio" name={"atd-" + v.studentid} value={"P"} defaultChecked={(v.status == "P") ? true : false} onChange={(e) => handlerAttd(index, e.target.value)} /> Present
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name={"atd-" + v.studentid} value={"A"} defaultChecked={(v.status == "A") ? true : false} onChange={(e) => handlerAttd(index, e.target.value)} /> Absent
                                                                </label>
                                                            </>
                                                        ) : (
                                                            <span className="text-danger">{v.remark}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <input type="text" name="remarks" className='form-control' defaultValue={v.remark} onChange={(e) => handlerRemark(index, e.target.value)} />
                                                    </td>
                                                    <td>
                                                        {(v.tap_time) ? (
                                                            <>
                                                                <div className='text-left'>
                                                                    <span className='d-block'>{Moment(v.tap_time).format('D MMM YYYY')}</span>
                                                                    <span className='d-block'>{Moment(v.tap_time).format('H:mm')}</span>
                                                                </div>
                                                                <LocationCoordinate values={v} />
                                                            </>
                                                        ) : '-'}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8">No record found</td>
                                            </tr>
                                        )
                                    )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="8" className="text-right py-10">
                                        {(!atd_lec_id) ? (
                                            <div className="alert alert-danger">
                                                <div className="alert-title text-left">
                                                    <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                                                    You cannot submit this Session.<br />Because there's some key has been missing. Please report this to IST Dept.<br />Thank you.
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="btn-group">
                                                <button className="btn btn-secondary font-weight-bolder text-uppercase px-9 py-4" type='button' onClick={() => { props.setSelectedSession({}); props.changeStatus(1) }} >Cancel</button>
                                                <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit" type="submit">Save Changes</button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </form>
            </div>
        </div>
    )
}
