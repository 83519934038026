import React, { useEffect, useMemo, useState } from 'react'
import jwt_decode from "jwt-decode"
import parse from 'html-react-parser'
import { openModal } from '../../../../Library/ModalPopUp';
import { GenerateToken } from '../GenerateToken';
import AxiosLib from '../../../../Library/AxiosLib';
import LoadingBtn from '../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../Library/AlertNotif';
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import { openToast } from '../../../../Library/ToastPopUp';
import { StudentLink } from '../../../../Const/StudentCard';

export default function PreviewScoreStd(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const MyProfile = props.profile;
    const paramToken = {
        user: MyProfile.fullname,
        mail: MyProfile.sgumail
    }

    const prevScoreObj = props.prevscore.param;
    //console.log(prevScoreObj);
    const prevScoreObjData = props.prevscore.data;
    const [listData, setListData] = useState(prevScoreObj.student);
    //console.log(listData);
    const [listData2, setListData2] = useState(prevScoreObjData);
    const [insToken, setInsToken] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [finScore, setFinScore] = useState({
        loading: false,
        data: [],
        message: ""
    });
    const [MyToken, setMyToken] = useState({ tokens: {}, result: false, message: "", load: false });

    const ResultData = useMemo(() => {
        let computedData = listData;
        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toString().toLowerCase().includes(search)
                        //console.log(key,listData[key])
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedData;
    }, [listData, search, sorting]);

    const requestToken = () => {
        if (AuthDecode.remotedBy) {
            openToast({ header: "Error", message: "You cannot access this request" });
        } else {
            setMyToken({ ...MyToken, load: true });
            var Token = GenerateToken(5, 'a');
            paramToken.token = Token;
            var param = paramToken;
            AxiosLib(param, 'mc/api/send-token-mail')
                .then(
                    response => {
                        if (response.error) {
                            setMyToken({ ...MyToken, result: false, load: false, message: ((response.message !== '') ? parse(response.message) : "Failed generate token") });
                            openToast({ header: "Error", message: ((response.message !== '') ? parse(response.message) : 'Failed generate token') });
                        } else if (response.hasOwnProperty("message")) {
                            setMyToken({ ...MyToken, result: true, tokens: response.tokens, load: false, message: ((response.message !== '') ? parse(response.message) : '-') });
                            openToast({ header: "Info", message: ((response.message !== '') ? parse(response.message) : '-') });
                        }
                    }
                );
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (AuthDecode.remotedBy) {
            openToast({ header: "Error", message: "You cannont access this request" });
        } else {
            openModal({header:"Confirmation", message:<ConfirmForm />});
        }
    }

    const SubmitOk = () => {
        openModal({open:false});
        setFinScore({ ...finScore, loading: true, message: "", data: [] });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };

        var std_post = prevScoreObj.student.map((v)=>{
            var obj = {};
            obj.atp_id_term = v.atp_id_term;
            obj.course_id = v.course_id;
            obj.quiz_1 = v.quiz_1;
            obj.quiz_2 = v.quiz_2;
            obj.quiz_3 = v.quiz_3;
            obj.remark = v.remark;
            obj.studentid = v.studentid;
            return obj;
        });

        const paramPostSct = {
            item_type: "mid",
            preview: false,
            section_id: prevScoreObj.section_id,
            total_quiz: prevScoreObj.total_quiz,
            student: std_post
        }

        var body = {
            "parameters": paramPostSct,
            "target_data": "SUBMIT_SCR_STD_BY_LEC"
        };

        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setFinScore({
                    ...finScore,
                    message: response.error,
                    loading: false,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.internal;
                setFinScore({ ...finScore, message: msg, loading: false });
            } else {
                var results = response.response.data;
                if (results) {
                    setFinScore({
                        ...finScore,
                        message: "",
                        loading: false,
                        data: results,
                    });
                    openToast({ header: "Congratulations", message: "Your changes have been successfully saved!" });
                    //props.setPrevScore({ ...props.prevScore, data: [] });
                } else {
                    setFinScore({
                        ...finScore,
                        message: "No record found",
                        loading: false,
                        data: [],
                    });
                }
            }
        });
    }

    const ConfirmForm = () =>{
        return(
            <div className="confirm-window text-center">
                <h3 className="text-dark mb-10">Are you sure wants to submit ?</h3>
                <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit" type='button' onClick={()=>SubmitOk()}>
                    YES
                </button>
            </div>
        )
    }

    const CheckMyToken = () => {
        setMyToken({ ...MyToken, load: true });
        AxiosLib(paramToken, 'mc/api/check-token')
            .then(
                response => {
                    setMyToken({ ...MyToken, tokens: response, load: false });
                }
            );
    }

    useEffect(() => {
        CheckMyToken();
    }, []);

    const fetchGrade = (studentid) => {
        let data = prevScoreObjData.filter(el => el.studentdata.studentid === studentid);
        if (Object.values(data).length > 0) {
            return data[0].grade;
        }
        return "";
    }
    const fetchGradePoint = (studentid) => {
        let data = prevScoreObjData.filter(el => el.studentdata.studentid === studentid);
        if (Object.values(data).length > 0) {
            return data[0].grade_point;
        }
        return "";
    }

    const HandlerBack = () => {
        const ConfirmMsg = () =>{
            return (
                <div className='text-center'>
                    <p className='mb-10 h4'>Are you sure you want to discard all changes?</p>
                    <button className="btn btn-clear btn-lg font-weight-bolder text-uppercase mr-8" type='button' onClick={()=>openModal({open:false})}>No</button>
                    <button className="btn btn-primary btn-lg font-weight-bolder text-uppercase" type='button' 
                        onClick={()=>{
                            props.setPrevScore({ ...props.prevScore, data: [] })
                            openModal({open:false});
                            window.scrollTo(0, 0);
                        } }
                    >
                        Yes</button>
                </div>
            )
        }
        openModal({header:"Confirmation", message:<ConfirmMsg />})        
    }

    return (
        <div id='preview-score'>
            <h1 className='text-center mb-5'>Preview Mid Score</h1>
            <form onSubmit={(e) => handleSubmit(e)} id="form-scoring" method="post" autoComplete="off">
                <table className="table table-head-custom table-borderless table-vertical-center">
                    <thead>
                        <tr className='bg-light-primary'>
                            <th width="5%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">No</span></th>
                            <th width="20%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Student</span></th>
                            <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Quiz 1</span></th>
                            <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Quiz 2</span></th>
                            <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Quiz 3</span></th>
                            <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Grade</span></th>
                            <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Grade Point</span></th>
                            <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Absent</span></th>
                            <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Remark</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(Object.values(ResultData).length > 0) ? (
                            ResultData.map((v, index) => (
                                <tr key={index} className={((v.atd > 25) ? "bg-light-danger" : "")}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <StudentLink data={v} />
                                    </td>
                                    <td>
                                        {v.quiz_1}
                                    </td>
                                    <td>
                                        {v.quiz_2}
                                    </td>
                                    <td>
                                        {v.quiz_3}
                                    </td>
                                    <td>
                                        {fetchGrade(v.studentid)}
                                    </td>
                                    <td>
                                        {fetchGradePoint(v.studentid)}
                                    </td>
                                    <td>
                                        {v.atd}%
                                    </td>
                                    <td>{v.remark}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9">No record found</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="notes d-flex justify-content-between align-items-start mb-5 px-8">
                    <div className="note">
                        <small className="d-block">Note:</small>
                        <span className="bg-danger px-3 mr-2"></span>
                        <span className="text-danger">Total of Attendance more than 25%</span>
                    </div>
                    <div className="t_quiz">
                        <div className="form-group mb-0">
                            <label className='font-weight-bolder'>Total Quiz</label>
                            <input type="text" name="token" className='form-control form-control-sm' disabled defaultValue={prevScoreObj.total_quiz} />
                        </div>
                    </div>
                </div>

                <div className="mb-5 px-8">
                    {(AuthDecode.remotedBy) ? (
                        <div className="alert alert-danger">
                            <div className="alert-title text-left">
                                <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                                You cannot submit this Session.<br />This session belongs to <b>Mr/Mrs. {AuthDecode.currentData.given_name + " " + AuthDecode.currentData.family_name}</b>
                            </div>
                        </div>
                    ) : ""}
                </div>

                {(finScore.message) ? (
                    <div className="my-5 d-flex justify-content-end px-8">
                        <AlertNotif message={finScore.message + ". Please try again later"} color={"danger"} title={"Error"} />
                    </div>
                ) : ""}

                <div className="mb-5 d-flex justify-content-end px-8">
                    <div className="btn-group">
                        <button className="btn btn-secondary font-weight-bolder text-uppercase px-9 py-4" type='button' onClick={() => HandlerBack()} >Back</button>
                        <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit" type='submit' disabled={(finScore.loading) ? true : ((Object.values(finScore.data).length > 0) ? true : false)}>{(finScore.loading) ? "Processing..." : "Submit and Finish"}</button>
                    </div>
                </div>
                
            </form>
        </div>
    )
}
