import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import LeaveApproval from './LeaveApproval';
import PCF from './PCF/PCF';
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import ResignApproval from './ResignApproval';
import LoadingBtn from '../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../Library/AlertNotif';

export function DetailActivity() {
    const { stdid } = useParams();
    const { token } = useParams();
    const data = jwt_decode(token);

    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const [stdProfile, setStdProfile] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const GET_STUDENT_DATA_M = () => {
        setStdProfile({ ...stdProfile, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: stdid,
            },
            target_data: "GET_STUDENT_DATA_M",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdProfile({
                    ...stdProfile,
                    loading: false,
                    data: [],
                    message: response.error,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setStdProfile({
                    ...stdProfile,
                    loading: false,
                    data: [],
                    message: msg,
                });
            } else {
                var results = response.response.data;
                if (results) {
                    setStdProfile({
                        ...stdProfile,
                        loading: false,
                        data: results,
                        message: "",
                    });
                } else {
                    setStdProfile({
                        ...stdProfile,
                        loading: false,
                        data: [],
                        message: "No record found",
                    });
                }
            }
        });
    };

    const [stdStatus, setStdStatus] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const GET_STUDENT_STATUS = () => {
        setStdStatus({ ...stdStatus, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: stdid,
            },
            target_data: "GET_STUDENT_STATUS",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdStatus({
                    ...stdStatus,
                    loading: false,
                    data: [],
                    message: response.error,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setStdStatus({ ...stdStatus, loading: false, data: [], message: msg });
            } else {
                var results = response.response.data;
                if (results) {
                    setStdStatus({
                        ...stdStatus,
                        loading: false,
                        data: results,
                        message: "",
                    });
                } else {
                    setStdStatus({
                        ...stdStatus,
                        loading: false,
                        data: [],
                        message: "No record found",
                    });
                }
            }
        });
    };

    useEffect(() => {
        GET_STUDENT_DATA_M();
        GET_STUDENT_STATUS();
    }, [])

    let selectedActivity = [];
    let selectedActType = "";
    let selectedActValue = [];
    if (data) {
        selectedActivity = data;
        selectedActType = selectedActivity.type;
        selectedActValue = selectedActivity.value;
    }

    const [actType, setActType] = useState(selectedActType);

    if(stdStatus.loading || stdProfile.loading){
        return <LoadingBtn />
    }else if(stdStatus.message || stdStatus.message){
        return <AlertNotif message={stdStatus.message || stdStatus.message} color={"danger"} />
    }else if(Object.values(stdStatus.data).length > 0 && Object.values(stdProfile.data).length > 0){
        if (actType === "Leave") {
            return (
                <LeaveApproval
                    data={
                        Object.values(selectedActValue).length > 0 ? selectedActValue : []
                    }
                    setActType={setActType}
                />
            );
        } else if (actType === "PCF" || actType === "PCF Revision" || stdStatus.data) {
            return <PCF data={Object.values(selectedActValue).length > 0 ? selectedActValue : []} setActType={setActType} students={stdProfile} stdStatus={stdStatus.data} />;
        } else if (actType === "Resign") {
            return <ResignApproval data={Object.values(selectedActValue).length > 0 ? selectedActValue : []} />;
        }
    }else{
        return "";
    }
}
