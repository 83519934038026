import React, { useState } from 'react'
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from '../../../../../../Library/AxiosLibWithHeader2';
import { openToast } from '../../../../../../Library/ToastPopUp';

export default function FormTeachingTopic(props) {
    const currSection = (props.data) ? props.data : null;

    const [postTT, setPostTT] = useState((props.data) ? props.data.teaching_topic : '');
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    const submitTeachingTopic = (e, value) => {
        e.preventDefault();
        if (AuthDecode.remotedBy) {
            openToast({ header: "Error", message: "You cannot access this request" });
        } else {
            var formid = e.target.id;
            var target = document.getElementById(formid);
            var myButton = target.getElementsByClassName("btn-submit")[0];
            var classSpinner = ['spinner', 'spinner-white', 'spinner-left'];
            var messageReturn = "";

            myButton.classList.add(...classSpinner);
            myButton.textContent = "processing";
            myButton.disabled = true;

            var dataPostTeachingTopic = {
                "activity_type": currSection.activity_type,
                "atdid": currSection.id,
                "end_dt": currSection.end_dt,
                "lec_tap_time": currSection.lec_tap_time,
                "remark": "",
                "room_id": (currSection.room_id) ? currSection.room_id : 0,
                "start_dt": currSection.start_dt,
                "teaching_topic": value
              }

            var keys = {
                "AppId": AuthDecode.AppID,
                "AppEnv": AuthDecode.AppEnv,
                "Secretkey": AuthDecode.secretkey
            };
            var body = {
                "parameters": dataPostTeachingTopic,
                "target_data": "UPDATE_ATD_LECTURER"
            };
            const postData = {
                keys: keys,
                body: body
            };

            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        //console.log(response);
                        if ("message" in response.response) {
                            messageReturn = "Failed save Teaching Topic. Error " + response.response.message.user;
                        } else if ("responseCode" in response) {
                            var result = response.response.data;
                            if (response.responseCode === 201) {
                                messageReturn = "Success update teaching topic.";
                                props.setAtdLecId(result.atd_lec_id);
                                props.setQRCode(result.qr);
                            } else {
                                messageReturn = "Failed update teaching topic.";
                            }
                        } else {
                            messageReturn = "Unknow.";
                        }

                        openToast({
                            message: messageReturn,
                            header: "Info"
                        });

                        myButton.classList.remove(...classSpinner);
                        myButton.disabled = true;
                        myButton.innerHTML = 'Save Changes';

                    }
                );
        }
    }
    return (
        <div id='form-teaching-topic' className='card card-custom card-stretch gutter-b'>
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">Form Teaching Topic</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">Please fill up the form with correctly</span>
                </h3>
            </div>
            <div className="card-body">
                <form method="post" autoComplete='off' id="form-teaching-topic" onSubmit={(e) => submitTeachingTopic(e, postTT)}>
                    <div className="form-group">
                        <label htmlFor="teaching-topic">Teaching Topic*</label>
                        <input type="text" name='teaching-topic' required className="form-control required" value={(postTT) ? decodeURIComponent(postTT) : ''} onChange={(e) => setPostTT(encodeURIComponent(e.target.value))} placeholder="Please fill the teaching topic for this class" />
                        <span className="text-muted">Please use latin alphabet and write in clear.</span>
                    </div>
                    <div className="text-right">
                        <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit" type='submit'>Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
