import React, { useState, useMemo } from "react";
import { Pagination, Search, TableHeader } from "../../../Library/DataTables";
import LoadingBtn from "../../Widgets/LoadingBtn";

export default function TableData(props) {
  let listData = props.studentAct.data;
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [totalApprove, setTotalApprove] = useState(0);
  const [totalSubmit, setTotalSubmit] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const ITEMS_PER_PAGE = 20;

  const headers = [
    { name: "Students", field: "studentid", sortable: true },
    { name: "Program Study", field: "clu_id", sortable: false },
    { name: "Activity", field: "status_activity", sortable: false },
    { name: "Time Request", field: "submit_date_formatted", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = listData;
    if(filters){
      computedData = computedData.filter(item => {
        return item.status_activity === filters
      });
    }
    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key].toLowerCase().includes(search.toLowerCase())
        );
      });
    }
    setTotalItems(computedData.length);

    const computeAppv = computedData.filter(item => item.status_activity === 'APPROVED');
    const totalAppv = computeAppv.length;
    setTotalApprove(totalAppv);

    const computeSubmit = computedData.filter(item => item.status_activity === 'SUBMITTED');
    const totalSubmit = computeSubmit.length;
    setTotalSubmit(totalSubmit);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [listData, currentPage, search, sorting, filters]);
  /*==END DATATABELS CONFIG==*/
  
  
  return (
    <div className="table-responsive">
      <div className="d-flex justify-content-center mb-8">
        <span
          className="btn btn-light text-primary font-weight-bold mr-3"
        >
          <i className="fas fa-poll text-primary"></i> Total Student{" "}
          {totalItems > 0 ? totalItems : 0}
        </span>
        <button type="button" onClick={(e)=>setFilters("SUBMITTED")}
          className="btn btn-light-primary font-weight-bold mr-3"
        >
          <i className="fas fa-poll"></i> Total Unapprove{" "}
          {totalSubmit > 0 ? totalSubmit : 0}
        </button>
        <button type="button" onClick={(e)=>setFilters("APPROVED")}
          className="btn btn-light-primary font-weight-bold"
        >
          <i className="fas fa-poll"></i> Total Approved{" "}
          {totalApprove > 0 ? totalApprove : 0}
        </button>
      </div>

      <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
        <TableHeader
          headers={headers}
          onSorting={(field, order) => setSorting({ field, order })}
        />
        <tbody>
          {props.studentAct.loading ? <LoadingBtn /> : ""}
          {ResultData.length > 0 ? (
            ResultData.map((listData, index) => (
              <tr key={index} className={(listData.studentdata) ? "" : "bg-light-danger"}>
                <td width={"35%"}>
                  <span className="d-block text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                    {listData.studentdata
                      ? listData.studentdata.fullname
                      : "-"}
                  </span>
                  <span className="d-block text-muted font-weight-bold d-block">
                    {listData.studentid}
                  </span>
                </td>
                <td width={"30%"}>
                  <span className="d-block text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                    {Object.values(listData.clu).length > 0
                      ? listData.clu.name
                      : "-"}
                  </span>
                  <span className="d-block text-muted font-weight-bold d-block">
                    Semester {listData.semester}
                  </span>
                </td>
                <td>
                  <span className="d-block text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                    {Object.values(listData.student_activity).length > 0
                      ? listData.student_activity.name
                      : "-"}
                  </span>
                  <span className="text-muted font-weight-bold d-block">
                    {(listData.status_activity) ? ("Status: " + listData.status_activity) : ""}
                  </span>
                </td>

                <td>
                  <span className="d-block text-success  font-weight-bolder mb-1 font-size-lg">
                    <span>Submited: </span>
                    <span className="d-block">
                      {listData.submit_date_formatted
                        ? listData.submit_date_formatted
                        : "-"}
                    </span>
                  </span>
                  {(listData.approve_date_formatted) ? (
                    <>
                      <div className="separator separator-dashed"></div>
                      <span className="text-primary  font-weight-bolder d-block mb-1 font-size-lg">
                        <span>Approved:</span>
                        <span className="d-block">{listData.approve_date_formatted}</span>
                      </span>
                    </>
                  ) : ''}

                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">Data not founded</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          {totalItems > 0 ? (
            <>
              <tr>
                <td colSpan={4}>
                  <button
                    className="btn btn-clean font-weight-bold"
                    type="button"
                  >
                    <i className="fas fa-poll"></i> Total item{" "}
                    {totalItems > 0 ? totalItems : 0}
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </td>
              </tr>
            </>
          ) : (
            ""
          )}
        </tfoot>
      </table>
    </div>
  );
}
