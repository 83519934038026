import React from 'react'

export function BannedNotif(props) {
    const banned = props.BannedUser;
    return (
        <div id="BannedUser">
            <div className="card card-custom">
                <div className="card-body">
                    <h1>{banned.Reason}</h1>
                </div>
            </div>
        </div>
    )
}

export default BannedNotif