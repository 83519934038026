import React from "react";
import jwt_decode from "jwt-decode";
import { FormatRupiah } from "../../../../../Library/GlobalHelper";
import { openModal } from "../../../../../Library/ModalPopUp";
import { useState } from "react";
import { openToast } from "../../../../../Library/ToastPopUp";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";

export default function PreviewPCF({ mypcf, data, setSubmitPCFOri, param, totalsks }) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const ModalCancel = () => {
        return (
            <div className="confirm-close">
                <h3>Are you sure want to cancel the PCF ?</h3>
                <p className="font-weight-bolder">You will permanently cancel this payment.</p>

                <div className="text-right pt-10">
                    <button className="btn btn-lg btn-clear px-6 font-weight-bolder" type="button" onClick={() => openModal({ open: false })}>
                        Not Now
                    </button>
                    <button className="btn btn-lg btn-danger px-6 font-weight-bolder" type="button" onClick={() => { setSubmitPCFOri({ loading: false, data: [], message: "" }); openModal({ open: false }); }}>
                        Cancel Registration
                    </button>
                </div>
            </div>
        )
    }

    const [submitPCF, setSubmitPCF] = useState({ loading: false, data: [], message: "" });
    const SUBMIT_REREG_PCF = (param) => {
        setSubmitPCF({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };

        var body = {
            parameters: param,
            target_data: "SUBMIT_REREG_PCF",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if ("message" in response.response) {
                setSubmitPCF({ loading: false, data: [], message: response.response.message.user })
                openToast({
                    message: "Error " + response.response.message.user,
                    header: "Error",
                });
            } else {
                var result = response.response.result;
                if (result === "success") {
                    setSubmitPCF({ loading: false, data: response.response.data, message: "Successfully submit PCF" })
                    openToast({
                        message: "Successfully submit PCF",
                        header: "Info",
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    setSubmitPCF({ loading: false, data: [], message: response.response.msg })
                    openToast({
                        message: response.response.msg,
                        header: "Error",
                    });
                }
            }
        });
    }

    const SubmitPreviewPCF = () => {
        const cloneData = { ...data };
        let paramSubmit = {};
        paramSubmit.cellular = param.cellular;
        paramSubmit.courses = cloneData.courses;
        paramSubmit.studentid = param.studentid.toString();
        SUBMIT_REREG_PCF(paramSubmit);
    }

    return (
        <div className="card card-custom">
            <div className="card-body p-0">
                <div className="header-info">
                    <div className="row justify-content-center py-8">
                        <div className="col-md-10">
                            <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div className="d-flex flex-column px-0 order-2 order-md-1 align-items-center align-items-md-start">
                                    <span className="h1 font-weight-boldest">{AuthDecode.currentData.given_name + " " + AuthDecode.currentData.family_name}</span>
                                    <span className="d-flex flex-column font-size-h5 text-muted align-items-center align-items-md-start">
                                        <span>{param.studentid}</span>
                                    </span>
                                </div>
                                <div className="order-1 order-md-2 mb-5 mb-md-0 text-right">
                                    <span className="d-block text-uppercase h1 font-weight-boldest">Draft Invoice</span>
                                    <span className="text-muted h5">(Before Scholarships and Adjustments)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body-info">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr className="font-weight-boldest h-65px" style={{ backgroundColor: "#474bcf" }}>
                                    <th className="align-middle font-size-h4 text-white border-0" width={"5%"}>No</th>
                                    <th className="align-middle font-size-h4 text-white border-0">Course</th>
                                    <th className="align-middle font-size-h4 text-white border-0">Type/Credit</th>
                                    <th className="align-middle font-size-h4 text-white border-0">Section ID</th>
                                    <th className="align-middle font-size-h4 text-white border-0">Lecturers</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(mypcf).length > 0 ? (
                                    mypcf.map((v, index) => (
                                        <tr key={index} className="font-size-lg font-weight-bolder h-65px">
                                            <td>{index + 1}</td>
                                            <td>
                                                <span className="d-block font-weight-bolder">
                                                    {v.detail.coursetitle ? v.detail.coursetitle : ""}
                                                </span>
                                                <span className="text-muted font-weight-bolder">
                                                    {v.detail.course_code ? v.detail.course_code : "-"}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="d-block text-capitalize font-weight-bolder">
                                                    Credit {v.detail.course_credit ? v.detail.course_credit : ""} SKS
                                                </span>
                                                <span className="text-muted text-capitalize font-weight-bolder">
                                                    {v.detail.course_type ? v.detail.course_type : ""}
                                                </span>
                                            </td>
                                            <td>
                                                <span className="font-weight-bolder">
                                                    {v.detail.section_id ? v.detail.section_id : ""}
                                                </span>
                                            </td>
                                            <td>
                                                {Object.values(v.detail.lecturer).length > 0 ? (
                                                    v.detail.lecturer.map((l, index) => (
                                                        <span className="d-block text-dark" key={index}>
                                                            {l.emp ? l.emp.fullname : "-"}
                                                        </span>
                                                    ))
                                                ) : ""}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5}>No record found</td>
                                    </tr>
                                )}
                            </tbody>
                            {totalsks > 0 ? (
                                <tfoot>
                                    <tr>
                                        <td colSpan={2} className="font-weight-bolder text-right">Total Credit:</td>
                                        <td className="font-weight-bolder">{totalsks} SKS</td>
                                        <td colSpan={2}></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} className="font-weight-bolder text-right">Limit Credit:</td>
                                        <td className="font-weight-bolder">{data.max_sks} SKS</td>
                                        <td colSpan={2}></td>
                                    </tr>
                                </tfoot>
                            ) : ""}
                        </table>
                    </div>
                </div>

                <div className="tuition-fee">
                    {Object.values(data.itemDetail).length > 0 ? (
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr className="font-weight-boldest h-65px" style={{ backgroundColor: "#474bcf" }}>
                                        <th className="align-middle font-size-h4 text-white border-0">Name</th>
                                        <th className="align-middle font-size-h4 text-white border-0">QTY</th>
                                        <th className="align-middle font-size-h4 text-white border-0">Price</th>
                                        <th className="align-middle font-size-h4 text-white border-0">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.itemDetail.map((v, index) => (
                                        <tr key={index} className="font-size-lg font-weight-bolder">
                                            <td className="font-size-h4">{v.itemName}</td>
                                            <td className="font-size-h4">{v.itemQty}</td>
                                            <td className="font-size-h4">IDR {FormatRupiah(v.itemPrice)}</td>
                                            <td className="font-size-h4">IDR {FormatRupiah(v.itemQty * v.itemPrice)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                {data.totalAmount ? (
                                    <tfoot>
                                        <tr>
                                            <td colSpan={5} className="text-center py-5">
                                                <span className="font-weight-bolder font-size-h1">
                                                    Total Payment: IDR {FormatRupiah(data.totalAmount)}
                                                </span>
                                            </td>
                                        </tr>
                                    </tfoot>
                                ) : ""}
                            </table>
                        </div>
                    ) : ""}
                </div>
                <div className="text-center my-10">
                    <button className="btn btn-lg btn-light mr-5 font-weight-bold text-uppercase px-7" type="button" onClick={() => openModal({ header: "", message: <ModalCancel /> })}>Cancel</button>
                    <button className="btn btn-lg btn-primary font-weight-bold text-uppercase px-7" type="button" onClick={() => SubmitPreviewPCF()} disabled={submitPCF.loading}>{submitPCF.loading ? "Processing..." : "Submit and Finish"}</button>
                </div>
            </div>
        </div>
    );


}
