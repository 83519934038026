import React, { useRef } from 'react'
import Moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { openModal } from '../../../../Library/ModalPopUp';
import DetailSchedule from './DetailSchedule';

export default function FullCalendarSchd(props) {
    const calendarRef = useRef();

    const eventRender = (data) => {
        let item = data.event;
        const groupData = JSON.parse(item.groupId);
        console.log(groupData)
        return (
            <div className={"event-item w-100 cursor-pointer " + (groupData.is_cancel ? "bg-danger border-danger" : "")} onClick={() => detailEvent(item)}>
                <p className={"fs-1 font-weight-bolder mb-0 text-wrap "+(groupData.is_cancel ? "text-white":"")}>{item.title}</p>
                <p className={"fs-1 font-weight-bolder mb-0 "+(groupData.is_cancel ? "text-white":"")}>
                    {Moment(item.start).format("hh:mm a")} -{" "}
                    {Moment(item.end).format("hh:mm a")}
                </p>
                <p className={"font-weight-bolder mb-0 "+(groupData.is_cancel ? "text-white":"")}>
                    Room: {" "}
                    {(groupData.event_room) ? (
                        groupData.event_room.map((v, index) => (
                            <span key={index}>
                                {v.room ? v.room.g_resourceName +"("+v.room.g_capacity+")": "-"}
                                {(Object.values(groupData.event_room).length - 1) > index ? ", ":""} 
                            </span>
                        ))
                    ) : ""}
                </p>
            </div>
        );
    };

    const detailEvent = (data) => {
        openModal({
            message: <DetailSchedule data={data} GET_SCHD_CLASS_LEC_NEXT={props.GET_SCHD_CLASS_LEC_NEXT} paramDate={props.paramDate} actionDetail={props.actionDetail} />,
            header: "Selected Schedule",
        });
    };

    return (
        <div>
            <FullCalendar
                ref={calendarRef}
                defaultView="dayGridMonth"
                headerToolbar={{ start: '', center: '', end: '' }}
                plugins={[dayGridPlugin]}
                initialDate={props.initialDate}
                validRange={{ start: props.paramDate.tgl, end:props.paramDate.tgl_end }}
                events={Object.values(props.data).length > 0 ? props.data : []}
                eventContent={(info) => eventRender(info)}
                eventColor='#000'
                eventTextColor='yellow'
                eventDidMount={(info) => {
                    if (info.event.extendedProps.background) {
                      info.el.style.background = info.event.extendedProps.background;
                    }
                    if (info.event.extendedProps.border) {
                        info.el.style.borderColor = info.event.extendedProps.border;
                    }
                }}
            />
        </div>
    )
}
