import React from "react";
import { openModal } from "../../../Library/ModalPopUp"

const NotifAnnouncement = ({ data }) => {
    console.log(data);
    const BodyMsg = ({ data }) => {

        return (
            <div>
                {data.map((v, index) => (
                    <div className="" key={index}>
                        <h3>{v.Title}</h3>
                        <div style={{ overflow: "auto", minWidth: "200px" }}>
                            <div dangerouslySetInnerHTML={{ __html: v.Message }} />
                        </div>
                    </div>
                ))}
            </div>
        )
    }
    openModal({ header: "Information", message: <BodyMsg data={data} />, size: "lg" })

    return "";
}

export { NotifAnnouncement }