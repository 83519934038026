import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode"
import Moment from "moment"
import SGU from "../../../../media/logos/logo-sgu.png";
import AxiosLibWithHeader2 from '../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../Library/AlertNotif';

export default function ProfileStaff() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    const [myProfile, setMyProfile] = useState({
        loading: false,
        message: "",
        data: []
    });

    const GET_EMP_BIO_BY_IDENID = (parameters) => {
        setMyProfile({ loading: true, data: [], message: "" });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": parameters,
            "target_data": "GET_EMP_BIO_BY_IDENID"
        };
        const postData = {
            keys: keys,
            body: body
        };
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setMyProfile({
                            data: [],
                            message: response.error,
                            loading: false,
                        });
                    } else if ("message" in response.response) {
                        var msg = response.response.message.user;
                        setMyProfile({
                            data: [],
                            message: msg,
                            loading: false,
                        });
                    } else {
                        var results = response.response.data;
                        if (results) {
                            setMyProfile({
                                data: results,
                                message: "",
                                loading: false,
                            });
                        } else {
                            setMyProfile({
                                data: [],
                                message: "No record found",
                                loading: false,
                            });
                        }
                    }
                }
            );
    }

    useEffect(() => {
        GET_EMP_BIO_BY_IDENID({ empid: AuthDecode.currentData.entityid });
    }, [])

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="w-50">
                <div className='card card-custom card-stretch'>
                    <div className="card-body pt-4">
                        <div className="pic-user d-flex justify-content-center align-items-center">
                            <div className="w-100px h-100px rounded-circle bg-light border border-2 border-white text-center d-flex justify-content-center align-items-center">
                                <img src={SGU} alt={AuthDecode.currentData.family_name} className="w-100 p-5" />
                            </div>
                        </div>
                        <div className="text-center">
                            <p className="font-weight-bolder text-primary mb-0 h5">{AuthDecode.currentData.given_name} {AuthDecode.currentData.family_name}</p>
                            <p className="font-weight-bolder text-muted">{AuthDecode.currentData.empid}</p>
                        </div>
                        {myProfile.loading ? <LoadingBtn /> : myProfile.message ? <AlertNotif color={"danger"} message={myProfile.message} title={"Information"} /> : (
                            Object.values(myProfile.data).length > 0 ? (
                                <>
                                    <div className="border-top border-bottom py-3">
                                        <p className="mb-0 font-weight-bolder row">
                                            <span className="text-muted col-4">Fullname</span>
                                            <span className="text-dark col-8">: {myProfile.data.fullname ? myProfile.data.fullname : "-"}</span>
                                        </p>
                                        <p className="mb-0 font-weight-bolder row">
                                            <span className="text-muted col-4">Employee ID</span>
                                            <span className="text-dark col-8">: {myProfile.data.employee_id ? myProfile.data.employee_id : "-"}</span>
                                        </p>
                                        <p className="mb-0 font-weight-bolder row">
                                            <span className="text-muted col-4">Email</span>
                                            <span className="text-dark col-8">: {myProfile.data.sgumail ? myProfile.data.sgumail : "-"}</span>
                                        </p>
                                        <p className="mb-0 font-weight-bolder row">
                                            <span className="text-muted col-4">Status</span>
                                            <span className="text-dark col-8">: {myProfile.data.is_active ? "Active" : "Not Active"}</span>
                                        </p>
                                        <p className="mb-0 font-weight-bolder row">
                                            <span className="text-muted col-4">Type</span>
                                            <span className="text-dark col-8">: {myProfile.data.emp_type ? (myProfile.data.emp_type.name ? myProfile.data.emp_type.name : "-") : "-"}</span>
                                        </p>
                                        <p className="mb-0 font-weight-bolder row">
                                            <span className="text-muted col-4">Education</span>
                                            <span className="text-dark col-8">: {myProfile.data.rate ? myProfile.data.rate : "-"}</span>
                                        </p>
                                        <p className="mb-0 font-weight-bolder row">
                                            <span className="text-muted col-4">ID</span>
                                            <span className="text-dark col-8">: {myProfile.data.id ? myProfile.data.id : "-"}</span>
                                        </p>
                                    </div>

                                    <div className="border border-warning bg-light-warning p-3 mt-8 rounded">
                                        <p className="mb-0">
                                            <span className="mr-1">
                                                <i className="fa fa-info-circle text-warning"></i>
                                            </span>
                                            <span className="text-warning">
                                                If you want to change the information please contact HR
                                            </span>
                                        </p>
                                    </div>
                                </>
                            ) : ""
                        )}

                    </div>
                </div>
            </div>
            
        </div>

    )
}
