import React from "react";
import { FormatRupiah } from "../../../../../../Library/GlobalHelper";
import { openModal } from "../../../../../../Library/ModalPopUp";

const TableSubmitPCF = ({ mypcf, totalsks, invoice }) => {
  mypcf.sort((a, b) => (a.detail.coursetitle > b.detail.coursetitle ? 1 : -1));
  return (
    <div className="table-responsive">
      <div className="d-flex justify-content-between align-items-center font-weight-bolder">
        <p className="mb-0">
          Total Credit <span className="text-danger">{totalsks} SKS</span>
        </p>
        <p className="mb-0">
          Limit Credit{" "}
          <span className="text-danger">{invoice.max_sks} SKS</span>
        </p>
      </div>
      <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
        <thead className="thead-dark">
          <tr>
            <th>Course</th>
            <th width="10%">Credit</th>
          </tr>
        </thead>
        <tbody>
          {mypcf.map((f, index) => (
            <tr
              key={index}
              className={
                f.action_type === "remove"
                  ? "bg-light-danger"
                  : !f.origin || f.origin === "redo"
                    ? "bg-light-success"
                    : ""
              }
            >
              <td>
                <span className="d-block font-weight-bolder">
                  {f.detail.coursetitle}
                </span>
                <span className="d-block text-muted">
                  {f.detail.course_code ? f.detail.course_code : f.detail.code}
                </span>
                <span className="text-muted">{f.detail.section_id}</span>
              </td>
              <td>
                <span className="d-block">{f.detail.course_credit} SKS</span>
                <span className="text-capitalize">{f.detail.course_type}</span>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="border-top">
          <tr>
            <td colSpan={3}>
              <div className="d-flex justify-content-between align-items-center font-weight-bolder">
                <p className="mb-0">
                  Total Credit <span className="text-danger">{totalsks} SKS</span>
                </p>
                <p className="mb-0">
                  Limit Credit{" "}
                  <span className="text-danger">{invoice.max_sks} SKS</span>
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <small className="font-weight-bolder">Color info:</small>
              <div className="notes d-flex">
                <div className="note-item mr-3">
                  <div className="bg-light-danger rounded p-1">
                    <small className="font-weight-bolder text-danger">Removed course</small>
                  </div>
                </div>
                <div className="note-item mx-1 d-none">
                  <div className="bg-light rounded p-1">
                    <small className="font-weight-bolder text-primary">Added by student</small>
                  </div>
                </div>
                <div className="note-item">
                  <div className="bg-light-success rounded p-1">
                    <small className="font-weight-bolder text-success">Added by counselor</small>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const TableSubmitPCFRevisi = ({ mypcf, totalsks, invoice }) => {
  mypcf.sort((a, b) => (a.detail.coursetitle > b.detail.coursetitle ? 1 : -1));
  return (
    <div className="table-responsive">
      <div className="d-flex justify-content-between align-items-center font-weight-bolder">
        <p className="mb-0">
          Total Credit <span className="text-danger">{totalsks} SKS</span>
        </p>
        <p className="mb-0">
          Limit Credit{" "}
          <span className="text-danger">{invoice.max_sks} SKS</span>
        </p>
      </div>
      <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
        <thead className="thead-dark">
          <tr>
            <th>Course</th>
            <th width="10%">Credit</th>
          </tr>
        </thead>
        <tbody>
          {mypcf.map((f, index) => (
            <tr
              key={index}
              className={
                f.action_type === "remove"
                  ? "bg-light-danger"
                  : !f.origin || f.origin === "redo"
                    ? "bg-light-success"
                    : ""
              }
            >
              <td>
                <span className="d-block font-weight-bolder">
                  {f.detail.coursetitle}
                </span>
                <span className="d-block text-muted">
                  {f.detail.course_code ? f.detail.course_code : f.detail.code}
                </span>
                <span className="text-muted">{f.detail.section_id}</span>
              </td>
              <td>
                <span className="d-block">{f.detail.course_credit} SKS</span>
                <span className="text-capitalize">{f.detail.course_type}</span>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="border-top">
          <tr>
            <td colSpan={3}>
              <div className="d-flex justify-content-between align-items-center font-weight-bolder">
                <p className="mb-0">
                  Total Credit <span className="text-danger">{totalsks} SKS</span>
                </p>
                <p className="mb-0">
                  Limit Credit{" "}
                  <span className="text-danger">{invoice.max_sks} SKS</span>
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <small className="font-weight-bolder">Color info:</small>
              <div className="notes d-flex justify-content-between">
                <div className="note-item">
                  <div className="bg-light-danger rounded p-1">
                    <small className="font-weight-bolder text-danger">Removed course</small>
                  </div>
                </div>
                <div className="note-item">
                  <div className="bg-light-success rounded p-1">
                    <small className="font-weight-bolder text-success">Added by counselor</small>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const TablePCFOri = ({ pcffirst, totalFPCF }) => {
  pcffirst.sort((a, b) =>
    a.detail.coursetitle > b.detail.coursetitle ? 1 : -1
  );
  return (
    <div className="table-responsive">
      <p className="mb-0 font-weight-bolder">
        Total Credit <span className="text-danger">{totalFPCF} SKS</span>
      </p>
      <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
        <thead className="thead-dark">
          <tr>
            <th>Course</th>
            <th width="10%">Credit</th>
          </tr>
        </thead>
        <tbody>
          {pcffirst.map((f, index) => (
            <tr key={index} className={f.action_type === "remove" ? "bg-light-danger" : ""}>
              <td>
                <span className="d-block font-weight-bolder">
                  {f.detail.coursetitle}
                </span>
                <span className="d-block text-muted">{f.detail.code}</span>
                <span className="text-muted">{f.detail.section_id}</span>
              </td>
              <td>
                <span className="d-block">{f.detail.credit} SKS</span>
                <span className="text-capitalize">{f.detail.course_type}</span>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="border-top">
          <tr>
            <td colSpan={3} className="text-center">
              <p className="mb-0 font-weight-bolder">
                Total Credit{" "}
                <span className="text-danger">{totalFPCF} SKS</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <small className="font-weight-bolder">Color info:</small>
              <div className="notes d-flex justify-content-between">
                <div className="note-item">
                  <div className="bg-light-danger rounded p-1">
                    <small className="font-weight-bolder text-danger">Removed course by student</small>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const TableInvoicePCF = ({
  invoice,
  approvePCF,
  updatePCF,
  HandlerApprove,
}) => {
  return (
    <div className="invoice">
      <div className="border rounded p-2">
        <h3>Draft Invoice</h3>
        <p className="font-size-7 text-muted">
          Before scholarships and adjustments
        </p>
        <div className="table-responsive">
          {invoice.itemDetail.map((v, index) => (
            <div key={index} className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <span className="mr-2">{v.itemName} </span>
                <span className="font-weight-bolder text-end">
                  {v.itemQty} x {FormatRupiah(v.itemPrice)}
                </span>
              </div>
            </div>
          ))}
          {invoice.totalAmount ? (
            <div className="border-top mt-3 pt-3 d-flex justify-content-between align-items-center">
              <span className="font-weight-bolder font-size-3">
                Total Payment
              </span>
              <span className="font-weight-bolder font-size-3">
                IDR {FormatRupiah(invoice.totalAmount)}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="mt-8">
        <button
          className="btn btn-block btn-sm btn-primary text-uppercase p-3 font-weight-bold"
          type="button"
          onClick={() => HandlerApprove()}
          disabled={approvePCF.loading}
        >
          {approvePCF.loading || updatePCF.loading
            ? "Processing..."
            : "Approve PCF"}
        </button>
        <button
          className="btn btn-block btn-sm btn-light text-uppercase p-3 font-weight-bold"
          type="button"
          onClick={() => openModal({ open: false })}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export { TableSubmitPCF, TableSubmitPCFRevisi, TablePCFOri, TableInvoicePCF };
