import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'
import { AlertNotif } from '../../../Library/AlertNotif'
import MyAttendance from './MyAttendance'

export class AcademicSummary extends Component {
    constructor(props) {
        super(props);
        this.getStudentStatus = this.getStudentStatus.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode: AuthDecode,
            StudentStatus: { loading: false, data: [], message: "" },
            SemesterStatus: [],
            CoursePlan: { loading: false, data: [], message: "" },
        };
    }

    getStudentStatus() {
        const reqModule2 = () => {
            this.setState({ StudentStatus: { loading: true, data: [], message: "" } })
            var keys = {
                "AppId": this.state.AuthDecode.AppID,
                "AppEnv": this.state.AuthDecode.AppEnv,
                "Secretkey": this.state.AuthDecode.secretkey
            };
            var body = {
                "parameters": {
                    "studentid": this.state.AuthDecode.currentData.entityid
                },
                "target_data": "GET_STUDENT_STATUS"
            };
            const postData = {
                keys: keys,
                body: body
            };

            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        if (response.error) {
                            this.setState({ StudentStatus: { loading: false, data: [], message: response.error } })
                        } else if ("message" in response.response) {
                            this.setState({ StudentStatus: { loading: false, data: [], message: response.response.message } })
                        } else {
                            var results = response.response.data;
                            if (results) {
                                var values = results.semester_status;
                                if (Object.keys(values).length > 0) {
                                    //values.sort((a, b) => (b.semester - a.semester));
                                    this.setState({
                                        StudentStatus: { loading: false, data: results, message: "" },
                                        SemesterStatus: values
                                    })
                                    //this.getCoursePlan(values[0]);
                                } else {
                                    this.setState({ StudentStatus: { loading: false, data: [], message: "Semester status is empty, please contact your FAO about this" } })
                                }
                            } else {
                                this.setState({ StudentStatus: { loading: false, data: [], message: "No record found" } })
                            }
                        }
                    }
                );
        }
        reqModule2();
    }

    componentDidMount() {
        this.getStudentStatus();
    }

    render() {

        return (
            <div id="academic-summary">
                {this.state.StudentStatus.loading ? (
                    <div className="text-center">
                        <LoadingBtn />
                    </div>
                ) : this.state.StudentStatus.message ? (
                    <AlertNotif message={this.state.StudentStatus.message} color={"danger"} title={"Information"} />
                ) : (
                    Object.values(this.state.StudentStatus.data).length > 0 ? (
                        <MyAttendance semester_status={this.state.SemesterStatus} studentid={this.state.StudentStatus.data.studentid} />
                    ) : ""
                )}
            </div>
        )
    }
}

export default AcademicSummary;