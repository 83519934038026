import React, { useState, useRef, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { approvalSubmition } from "../../../../Lecturer/Thesis/Activity/components/helper";
import ResendSuccessDialog from "./ResendSuccessDialog";

const ModalNewActivity = ({ closeModal, optionsAdvisor, datas, getLog }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [advisorChoosen, setAdvisorChoosen] = useState("");
  const [description, setDescription] = useState("");
  const [maxWords, setMaxWords] = useState(0);
  const [loadingSubmit, setLoadingSubmit] = useState({
    loading: false,
    res: "",
  });
  const [checkbox, setCheckbox] = useState(false);

  // uploaad file
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    // const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);
  };

  // handle submit activity
  const handleSubmitActivity = async () => {
    try {
      setLoadingSubmit({ loading: true, res: "" });
      const body = {
        parameters: {
          type: "act.type.thesis.activity",
          subject: "Test",
          descr: description,
          target_user_id: advisorChoosen.email,
          user_id: datas.data.studentdata.sgumail,
        },
        target_data: "CREATE_SUBMIT_ACTIVITY",
      };
      const submit = await approvalSubmition(body);
      // console.log("submit ", submit);
      if (submit.result === "success") {
        setLoadingSubmit({ loading: false, res: submit.result });
        getLog(datas.data);
      } else {
        setLoadingSubmit({ loading: false, res: submit.result });
      }
    } catch (error) {
      setLoadingSubmit({ loading: false, res: error.message });
    }
  };

  const handleTextAreaChange = (e) => {
    if (maxWords < 50) {
      setDescription(e.target.value);
    } else {
      if (e.nativeEvent.data === null) {
        setDescription(e.target.value);
      }
    }
  };

  useEffect(() => {
    setMaxWords((description.match(/ /g) || []).length);
  }, [description]);


  return (
    <>
      <div
        className="modal fade show d-block"
        data-backdrop="static"
        style={{ backgroundColor: "rgba(63, 66, 84, 0.5)" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content p-10 position-relative"
            style={{ width: 450 }}
          >
            <a
              title="close"
              className="btn btn-xs btn-icon btn-light btn-hover-danger position-absolute"
              style={{ top: 10, right: 10 }}
              onClick={() => closeModal(false)}
            >
              <i className="ki ki-close icon-sm text-muted"></i>
            </a>
            {loadingSubmit.res !== "" ? (
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  {loadingSubmit.res === "success" ? (
                    <ResendSuccessDialog status={loadingSubmit.res} />
                  ) : (
                    <ResendSuccessDialog
                      status={loadingSubmit.res}
                      retry={handleSubmitActivity}
                    />
                  )}
                </div>
              </div>
            ) : (
              <>
                <h4 className="modal-title" id="exampleModalLabel">
                  Add New Advisory Log
                </h4>
                <div className="my-10">
                  <div className="w-100">
                    <div className="d-flex flex-column">
                      <div className="d-flex mb-5">
                        <div className="d-flex flex-column mr-5 w-100">
                          <p>Date</p>
                          <div className="d-flex align-items-center position-relative w-100">
                            <label className="position-relative w-100">
                              <ReactDatePicker
                                selected={startDate}
                                // minDate={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="MMMM d, yyyy"
                                className="w-100 rounded border-1 border-light-primary p-2"
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: 8,
                                  right: 10,
                                }}
                              >
                                <i className="icon-md far fa-calendar-alt"></i>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-column gap-2 w-100">
                          <p>
                            Advisor<span className="text-danger">*</span>
                          </p>
                          <div>
                            <Select
                              options={optionsAdvisor}
                              onChange={(e) => setAdvisorChoosen(e.value)}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: "#f3f4f6",
                                  outline: "none",
                                  boxShadow: "none",
                                  "&:hover": {
                                    borderColor: "#f3f4f6",
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column w-100">
                        <p>
                          Description<span className="text-danger">*</span>
                        </p>
                        <textarea
                          rows={4}
                          name="description"
                          placeholder="Describe your work"
                          className="border rounded p-2"
                          style={{ outline: "none" }}
                          value={description}
                          onChange={(e) => handleTextAreaChange(e)}
                          onPaste={(e) => e.preventDefault()}
                        />
                        <p
                          className={`p-0 m-0 align-self-end ${
                            maxWords >= 40 && maxWords < 50 && "text-warning"
                          } ${maxWords === 50 && "text-danger"}`}
                        >
                          {maxWords}/50
                        </p>
                      </div>
                      {/* attachment */}
                      {/* <div
                      className="d-flex w-50 mt-5 cursor-pointer"
                      onClick={handleClick}
                    >
                      <i className="icon-lg la la-paperclip mr-1"></i>
                      <p className="text-muted">Add Attachment</p>
                      <input
                        type="file"
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        disabled
                        className="position-absolute"
                        style={{ display: "none" }}
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
                <label class="checkbox d-flex align-items-start mb-10">
                  <input
                    type="checkbox"
                    checked={checkbox}
                    onChange={() => setCheckbox(!checkbox)}
                  />
                  <span></span>
                  <p className="p-0 m-0 pl-1">
                    I acknowledge that this record cannot be edited after
                    submission.
                  </p>
                </label>
                <button
                  type="button"
                  className={`btn ${
                    loadingSubmit.loading ? "btn-light-primary" : "btn-primary"
                  }`}
                  disabled={
                    description === "" || advisorChoosen === "" || !checkbox
                  }
                  onClick={() =>
                    !loadingSubmit.loading && handleSubmitActivity()
                  }
                >
                  {loadingSubmit.loading ? "Loading..." : "Submit"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalNewActivity;
