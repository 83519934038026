import React, { useEffect, useMemo, useState } from "react";
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    Title,
} from "chart.js";
import Chart from 'chart.js/auto';
import { Line, } from "react-chartjs-2";

ChartJS.register(LineElement, PointElement, LinearScale, Title);

export default function ChartGPA({ studentStatus }) {
    const listdata = studentStatus.data ? studentStatus.data.semester_status : [];
    const [dataBar, setDataBar] = useState();

    const ResultData = useMemo(() => {
        var computeData = [];
        if (listdata) {
            computeData = listdata.sort((a, b) => (a.semester > b.semester ? 1 : -1));
            var arrLabel = []; var arrGPA = []; var arrGPAMid = [];
            computeData.map((v) => {
                arrLabel.push("Term " + v.semester);
                arrGPA.push((v.cgpa) ? v.cgpa : 0);
                arrGPAMid.push((v.gpa) ? v.gpa : 0);
            })
            var labels = arrLabel;
            const data = {
                labels,
                datasets: [
                    {
                        label: 'CGPA',
                        data: arrGPA,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                    {
                        label: 'GPA',
                        data: arrGPAMid,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    }
                ],
            };

            setDataBar(data);
        }

        return computeData;
    }, [listdata])

    return (
        <div>
            {dataBar ? (
                <div className="fs-7 py-5">
                    <ChartLine data={dataBar} />
                </div>
            ) : ""}
            {Object.values(ResultData).length > 0 ? (
                <div className="table-responsive px-8">
                    <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                        <thead className="text-uppercase text-primary">
                            <tr>
                                <th>Term</th>
                                <th>CGPA</th>
                                <th>GPA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ResultData.map((v, index) => (
                                <tr key={index}>
                                    <td>
                                        <span className="text-gray-600 fw-bold fs-6">Semester {v.semester}</span></td>
                                    <td>
                                        <span className="text-gray-800 fw-bold fs-6 me-1">{v.cgpa ? v.cgpa : "-"}</span>
                                    </td>
                                    <td>
                                        <span className="fw-bold fs-6 text-danger">{v.gpa ? v.gpa : "-"}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : ""}
        </div>
    )
}

const ChartLine = ({ data }) => {
    const sum = data
        ? data.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)
        : 0;
    return (
        <div className="chart-pie">
            <Line data={data ? data : null} />
        </div>
    );
};
