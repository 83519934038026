import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import Moment from "moment"
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";

export function ThesisScoreIndex() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    const [stdThesis, setStdSThesis] = useState({
        loading: false,
        message: "",
        data: [],
    });


    const GET_THS_BY_STUDENT = () => {
        setStdSThesis({ ...stdThesis, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
            },
            target_data: "GET_THS_BY_STUDENT",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdSThesis({
                    data: [],
                    message: response.error,
                    loading: false,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setStdSThesis({
                    data: [],
                    message: msg,
                    loading: false,
                });
            } else {
                var results = response.response.data;
                console.log(results);
                if (results) {
                    setStdSThesis({
                        data: results,
                        message: "",
                        loading: false,
                    });
                } else {
                    setStdSThesis({
                        data: [],
                        message: "No record found",
                        loading: false,
                    });
                }
            }
        });
    };


    useEffect(() => {
        GET_THS_BY_STUDENT();
    }, []);


    const examiner = (examine) => {
        return (
            (Object.values(examine).length > 0) ? (
                examine.map((v, index) => (
                    <div className="row mb-2">
                        <label className="col-lg-4 font-weight-bold h6">{(index===0)? "Examiner" : ""}</label>
                        <div className="col-lg-8">
                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{v.emp.fullname}</span>
                            <span className="font-weight-bold text-gray-800 h6 text-capitalize"></span>
                        </div>
                    </div>
                ))

            ) : ("")
        )
    }
    return (
        <div id="view-score-std">
            <div
                className="alert alert-custom alert-white alert-shadow fade show gutter-b"
                role="alert"
            >
                <div className="alert-icon">
                    <span className="svg-icon svg-icon-primary svg-icon-xl">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                    d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                ></path>
                                <path
                                    d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                ></path>
                            </g>
                        </svg>
                    </span>
                </div>
                <div className="alert-text h4">Thesis Score</div>
            </div>

            {stdThesis.loading ? <LoadingBtn /> : stdThesis.message ? <AlertNotif message={stdThesis.message} color={"danger"} title={"Information"} /> : (
                Object.values(stdThesis.data).length > 0 ? (
                    <div className="card card-custom gutter-b border-light">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-lg-4 border rounded mr-10 bg-light ">
                                    <h3 className="mb-8">Personal</h3>
                                    <hr />
                                    <div className="row mb-6 ">
                                        <label className="col-lg-4 font-weight-bold h6">Full Name</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.studentdata.fullname}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-6 ">
                                        <label className="col-lg-4 font-weight-bold h6">Student ID</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.studentid}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-6">
                                        <label className="col-lg-4 font-weight-bold h6">Department</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.studentdata.clu.name}</span>

                                        </div>
                                    </div>




                                </div>
                                <div className="col-12 col-lg-7 border rounded bg-light  ">
                                    <h3 className="mb-8">Thesis Data</h3>
                                    <hr />
                                    <div className="row mb-6">
                                        <label className="col-lg-4 font-weight-bold h6">Thesis Title</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.final_title}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-6">
                                        <label className="col-lg-4 font-weight-bold h6">Defense Date</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.defensedate ? Moment(stdThesis.data.defensedate).format("DD MMM YYYY") : ""}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-6">
                                        <label className="col-lg-4 font-weight-bold h6">Term</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.course_detail[stdThesis.data.course_detail.length - 1].atp_term.name}</span>
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.course_detail.score}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-6">
                                        <label className="col-lg-4 font-weight-bold h6">Semester</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.course_detail[stdThesis.data.course_detail.length - 1].semester}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-6">
                                        <label className="col-lg-4 font-weight-bold h6">Score / Grade</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.course_detail[stdThesis.data.course_detail.length - 1].score} / </span>
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.course_detail[stdThesis.data.course_detail.length - 1].grade_fin} </span>
                                        </div>
                                    </div>
                                    <div className="row mb-6">
                                        <label className="col-lg-4 font-weight-bold h6">Status</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.course_detail[stdThesis.data.course_detail.length - 1].course_status}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-lg-4 font-weight-bold h6">Advisor</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.advisor.fullname}  </span>
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize"></span>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-lg-4 font-weight-bold h6">Co Advisor</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdThesis.data.coadvisor.fullname}  </span>
                                            <span className="font-weight-bold text-gray-800 h6 text-capitalize"></span>
                                        </div>
                                    </div>



                

                                    {examiner(stdThesis.data.ths_main_ex)}

                                </div>
                            </div>
                        </div>
                    </div>
                ) : ""
            )}


        </div>
    );
}
