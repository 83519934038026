import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import CourseOvering from "./FormOvering/CourseOvering";
import CourseCatalog from "./FormOvering/CourseCatalog";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import { openToast } from "../../../../../Library/ToastPopUp";
import LoadingBtn from "../../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../../Library/AlertNotif";
import { openModal } from "../../../../../Library/ModalPopUp";
import ApprovedPCF from "./ApprovedPCF";
import MyPCF from "./FormOvering/MyPCF";
import MyPCFRevisions from "./FormOvering/MyPCFRevisions";

export default function PCF({ data, setActType, students, stdStatus }) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [totalsks, setTotalSKS] = useState(0);
  const [fPCF, setFPCF] = useState([]);
  const [cpl, setCPL] = useState({ loading: false, data: [], message: "" });

  const GET_COUNSELING_COURSE_STUDENT = () => {
    setCPL({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: data.studentid,
      },
      target_data: "GET_COUNSELING_COURSE_STUDENT",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setCPL({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setCPL({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          
          var fpcf = results.filter((prev) => prev.in_semester === 1);
          if (fpcf) {
            var firstPCF = fpcf.map((f) => {
              var obj = {};
              obj.action_type = "original";
              obj.course_id = f.course_id;
              obj.origin = true;
              obj.section_id = f.section_id;
              obj.detail = f;
              obj.detail.course_credit = f.credit;
              let typeArr = f.course_type.split(".");
              let type = typeArr[typeArr.length - 1];
              obj.detail.course_type = type;
              return obj;
            });
            setFPCF(firstPCF);
          }

          var lastCourse = results.filter((prev) => prev.in_semester !== 1);
          setCPL({ loading: false, data: lastCourse, message: "" });
        } else {
          setCPL({ loading: false, data: [], message: "No record found" });
        }
      }
    });
  };

  const [courses, setCourses] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [originpcf, setOriginPCF] = useState([]);
  const [mypcf, setMypcf] = useState([]);

  const GET_COUNSELING_PCF = () => {
    setCourses({ message:"", loading: true, data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        eventid: data.eventid,
      },
      target_data: "GET_COUNSELING_PCF",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setCourses({ data:[], loading: false, message: response.error });
      } else if ("message" in response.response) {
        setCourses({
          data:[],
          loading: false,
          message: response.response.message.internal,
        });
      } else {
        var param = response.response.data;
        if (Object.values(param).length > 0) {
          var FPCF = param.filter((prev) => prev.action_type);
          FPCF.sort((a, b) => a.semester - b.semester);
          setCourses({ ...courses, loading: false, data: param });
          if (Object.values(FPCF).length > 0) {
            let resObjOri = GeneratePCFList(FPCF);
            setOriginPCF(resObjOri);
            let resObj = GeneratePCFList(FPCF);
            setMypcf(resObj);
          }
        } else {
          setCourses({
            data:[],
            loading: false,
            message: "No record found",
          });
        }
      }
    });
  };

  const GeneratePCFList = (arrData) => {
    var results = [];
    results = arrData.map((v) => {
      var obj = {};
      obj.action_type =
        v.action_type === "asc.course.action.remove" ? "remove" : "add";
      obj.origin = true;
      obj.course_id = v.course_id;
      obj.section_id = v.section_id;
      v.course_code = v.code;
      v.course_credit = v.credit;
      let typeArr = v.course_type.split(".");
      let type = typeArr[typeArr.length - 1];
      v.course_type = type;
      obj.detail = v;
      return obj;
    });
    return results;
  };

  const [selectedAcad, setSelectedAcad] = useState(0);
  const [acadActv, setAcadActv] = useState([]);

  const GET_ACTIVE_ACAD = () => {
    setAcadActv({ message:"", loading: true, data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      target_data: "GET_ACTIVE_ACAD",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setAcadActv({ data:[], loading: false, message: response.error });
      } else if ("message" in response.response) {
        setAcadActv({
          data:[],
          loading: false,
          message: response.response.message.internal,
        });
      } else {
        var param = response.response.data;
        if (Object.values(param).length > 0) {
          var std_group = stdStatus.atp_group_type;
          var selected = param.find(
            (prev) => prev.atp_group_type.id === std_group
          );
          if (selected) {
            setSelectedAcad(selected.reff_atp_id_term);
            setAcadActv({ message:"", loading: false, data: param });
          }
        } else {
          setAcadActv({
            data:[],
            loading: false,
            message: "No record found",
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_COUNSELING_COURSE_STUDENT();
    GET_COUNSELING_PCF();
    GET_ACTIVE_ACAD();
  }, []);

  const handlerActionCourse = (values) => {
    if (values.action_type === "add") {
      //check existing course
      var isSame = mypcf.filter((prev) => (prev.section_id === values.section_id || prev.detail.course_code === values.detail.course_code));
      
      if (Object.values(isSame).length > 0) {
        const hasDifferentActionType = mypcf.some(item => 
            (item.course_id === values.course_id || item.section_id === values.section_id) 
            && item.action_type !== "add"
        );
        if(hasDifferentActionType){
          const clonepcf = [...mypcf];
          clonepcf.push(values);
          setMypcf(clonepcf);
        }else{
          openToast({
            header: "Information",
            message: "This course has already been added to the 'PCF' (please check the Trash)",
          });
        }
      } else {
        const clonepcf = [...mypcf];
        clonepcf.push(values);
        setMypcf(clonepcf);
      }
    } else if (values.action_type === "remove") {
      var clone = [...mypcf];
      clone.splice(values.index, 1);
      setMypcf(clone);
    } else {
      alert("Failed execute no action type");
    }
  };

  const StudentEventStatus = ({ data }) => {
    return (
      <div className="submited-info mt-5 ">
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-7">
                    <h2 className="text-dark mb-4">
                      {data.student_activity.name}
                    </h2>
                    <p className="text-dark-50 line-height-lg">
                      Activity Selected
                    </p>
                  </div>
                  <div className="col-sm-5 d-flex align-items-center justify-content-sm-end">
                    <button
                      className={
                        "btn font-weight-bolder text-uppercase font-size-lg py-3 px-6 btn-" +
                        (data.event_status === "PROCESSED"
                          ? "success"
                          : "warning")
                      }
                      type="button"
                    >
                      <span className="text-capitalize">
                        {data.event_status === "PROCESSED"
                          ? "Approved"
                          : "PENDING APPROVAL"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h3 className="text-dark mb-4">
                      {data.submit_date_formatted}
                    </h3>
                    <p className="text-dark-50 line-height-lg">Submited Date</p>
                  </div>
                  {data.approve_date_formatted ? (
                    <div className="col">
                      <h3 className="text-dark mb-4">
                        {data.approve_date_formatted}
                      </h3>
                      <p className="text-dark-50 line-height-lg">
                        Approved Date
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col">
                    <h3 className="text-dark mb-4">{data.atp.name}</h3>
                    <p className="text-dark-50 line-height-lg">Academic Term</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (data.event_status === "PROCESSED") {
    return (
      <div className="card card-custom gutter-b mb-8">
        <div className="card-body">
          <div className="d-flex justify-content-start align-items-center">
            <Link
              className="btn btn-clear btn-sm text-hover-warning"
              to={"/counseling/student/" + data.studentid + "/activity"}
            >
              <i className="fa fa-arrow-left"></i>
            </Link>
            <div className="title border-left pl-5">
              <h3 className="mb-0">Form PCF Approval</h3>
            </div>
          </div>

          <StudentEventStatus data={data} />

          <div className="preview-pcf mt-8">
            <ApprovedPCF
              eventid={data.eventid}
              atp={data.atp}
              studentid={data.studentid}
            />
          </div>
        </div>
      </div>
    );
  }

  const compareArrays = (arrayA, arrayB) => {
    const outputArray = [];

    // Iterate through arrayA
    arrayA.forEach((itemA) => {
      // Check if itemA exists in arrayB
      const matchingItemB = arrayB.find(
        (itemB) => itemA.section_id === itemB.section_id
      );

      if (matchingItemB) {
        // If itemA exists in arrayB, compare the 'status' property
        if (itemA.action_type !== matchingItemB.action_type) {
          // If 'status' is different, add it to outputArray
          outputArray.push({
            ...itemA,
            action_type: matchingItemB.action_type,
          });
        }
      } else {
        // If itemA does not exist in arrayB, add it to outputArray
        outputArray.push({ ...itemA, action_type: "add" });
      }
    });

    // Iterate through arrayB to find items not in arrayA
    arrayB.forEach((itemB) => {
      const matchingItemA = arrayA.find(
        (itemA) => itemB.section_id === itemA.section_id
      );

      if (!matchingItemA) {
        // If itemB does not exist in arrayA, add it to outputArray
        let type = "";
        if (itemB.detail.action_type) {
          let typeArr = itemB.detail.action_type.split(".");
          type = typeArr[typeArr.length - 1];
        } else {
          type = "add";
        }
        outputArray.push({ ...itemB, action_type: type });
      }
    });

    return outputArray;
  };

  var FinalPCFList = [];
  if (Object.values(fPCF).length > 0 && Object.values(mypcf).length > 0) {
    FinalPCFList = compareArrays(fPCF, mypcf);
  } else {
    FinalPCFList = mypcf;
  }

  return (
    <div id="approval-pcf">
      <div className="card card-custom gutter-b mb-8">
        <div className="card-body">
          <div className="d-flex justify-content-start align-items-center">
            <Link
              className="btn btn-clear btn-sm text-hover-warning"
              to={{
                pathname: "/counseling/student/" + data.studentid + "/activity",
              }}
            >
              <i className="fa fa-arrow-left"></i>
            </Link>
            <div className="title border-left pl-5">
              <h3 className="mb-0">Form PCF Approval</h3>
            </div>
          </div>

          <StudentEventStatus data={data} />
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          {cpl.loading ? (
            <LoadingBtn />
          ) : cpl.message ? (
            <AlertNotif message={cpl.message} color={"danger"} />
          ) : (
            <CourseOvering
              data={cpl.data}
              handlerActionCourse={handlerActionCourse}
              student={students.data}
              studentid={data.studentid}
              current={data}
            />
          )}
        </div>
        <div className="col-4">
          {selectedAcad ? (
            <CourseCatalog
              handlerActionCourse={handlerActionCourse}
              student={students.data}
              selectedAcad={selectedAcad}
            />
          ) : (
            ""
          )}
        </div>
        <div className="col-4">
          {courses.loading ? (
            <LoadingBtn />
          ) : courses.message ? (
            <AlertNotif message={courses.message} color={courses.message} />
          ) : Object.values(courses.data).length > 0 &&
            Object.values(mypcf).length > 0 ? (
            Object.values(fPCF).length > 0 ? (
              <MyPCFRevisions
                fPCF={fPCF}
                originPCFRev={originpcf}
                mypcf={mypcf}
                setMypcf={setMypcf}
                eventid={data.eventid}
                student={students.data}
                studentid={data.studentid}
                acad={data.atp}
                totalsks={totalsks}
                setTotalSKS={setTotalSKS}
                selectedAcad={selectedAcad}
              />
            ) : (
              <MyPCF
                fPCF={fPCF}
                mypcf={mypcf}
                setMypcf={setMypcf}
                eventid={data.eventid}
                student={students.data}
                studentid={data.studentid}
                acad={data.atp}
                totalsks={totalsks}
                setTotalSKS={setTotalSKS}
                selectedAcad={selectedAcad}
              />
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
