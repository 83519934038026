import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import Moment from "moment";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import { Link } from "react-router-dom";
import Shape1 from "../../../../../media/shapes/abstract-9.svg";
import Shape2 from "../../../../../media/shapes/abstract-7.svg";
import LoadingBtn from "../../../Widgets/LoadingBtn";

export class Invoice extends Component {
  constructor(props) {
    super(props);
    this.getInvoiceActive = this.getInvoiceActive.bind(this);
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    this.state = {
      AuthDecode: AuthDecode,
      Invoice: { loading: false, data: [], message: "" },
    };
  }

  getInvoiceActive() {
    this.setState({ Invoice: { loading: true, data: [], message: "" } });
    var keys = {
      AppId: this.state.AuthDecode.AppID,
      AppEnv: this.state.AuthDecode.AppEnv,
      Secretkey: this.state.AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: this.state.AuthDecode.currentData.entityid,
      },
      target_data: "GET_STUDENT_OUTSTANDING",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      //console.log('invoice',response);
      if (response.error) {
        this.setState({ Invoice: { loading: false, data: [], message: response.error } });
      } else if ("message" in response.response) {
        this.setState({ Invoice: { loading: false, data: [], message: response.response.message.user } });
      } else {
        var results = response.response.data;
        if(results){
            this.setState({ Invoice: { loading: false, data: results, message: "" } });
        }else{
            this.setState({ Invoice: { loading: false, data: results, message: "No record found" } });
        }
      }
    });
  }

  componentDidMount() {
    this.getInvoiceActive();
  }

  render() {
    return (
      <div id="invoice">
        <div className="card card-custom gutter-b">
          <div className="card-body">
            <div className="text-center">
                {this.state.Invoice.loading ? <LoadingBtn /> : this.state.Invoice.message ? (
                    <div className="bg-light-danger p-3 rounded text-danger ">
                        {this.state.Invoice.message}
                    </div>
                ) : (Object.values(this.state.Invoice.data).length > 0) ? (
                    <div className="invoice pt-30 pt-md-25 pb-5 px-5 text-center">
                        <div className="d-flex flex-center position-relative mb-25">
                            <span className="svg svg-fill-primary opacity-4 position-absolute">
                                <svg width="175" height="200">
                                    <polyline points="87,0 174,50 174,150 87,200 0,150 0,50 87,0"></polyline>
                                </svg>
                            </span>
                            <span className="svg-icon svg-icon-5x svg-icon-primary">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                        <path d="M1.4152146,4.84010415 C11.1782334,10.3362599 14.7076452,16.4493804 12.0034499,23.1794656 C5.02500006,22.0396582 1.4955883,15.9265377 1.4152146,4.84010415 Z" fill="#000000" opacity="0.3"></path>
                                        <path d="M22.5950046,4.84010415 C12.8319858,10.3362599 9.30257403,16.4493804 12.0067693,23.1794656 C18.9852192,22.0396582 22.5146309,15.9265377 22.5950046,4.84010415 Z" fill="#000000" opacity="0.3"></path>
                                        <path d="M12.0002081,2 C6.29326368,11.6413199 6.29326368,18.7001435 12.0002081,23.1764706 C17.4738192,18.7001435 17.4738192,11.6413199 12.0002081,2 Z" fill="#000000" opacity="0.3"></path>
                                    </g>
                                </svg>
                            </span>
                        </div>
                        <span className="font-size-h1 d-block d-block font-weight-boldest text-dark-75 py-2">{parseInt(this.state.Invoice.data.amount) > 0 ? this.state.Invoice.data.formatted : 0 }</span>
                        <h4 className="font-size-h6 d-block d-block font-weight-bold mb-7 text-dark-50">Outstanding Amount</h4>
                        <p className="font-italic">Note : please complete the payment to prevent suspension, if you have made payment please confirm it to Finance department.</p>
                    </div>
                ) : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Invoice;
