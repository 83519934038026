import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../../Widgets/LoadingBtn';
import CardSessionList from './widgets/sessions/CardSessionList';

export class DetailClassList extends Component {
    constructor(props) {
        super(props);
        this.GET_SECTION = this.GET_SECTION.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        var param = this.props.match.params.token;
        var decodeCourse = (param) ? jwt_decode(param) : [];
        this.state = {
            AuthDecode: AuthDecode,
            SectionID: decodeCourse.section_id,
            SelectedStatus: decodeCourse.status,
            IsLoad: false,
            SelectedSection: { load: false, data: [] },
            IsError: { message: '', return: false }
        }
    }

    GET_SECTION() {
        this.setState({ SelectedSection: { load: true, data: [] } });
        var keys = {
            "AppId": this.state.AuthDecode.AppID,
            "AppEnv": this.state.AuthDecode.AppEnv,
            "Secretkey": this.state.AuthDecode.secretkey
        };
        var parameters = {
            "section_id": this.state.SectionID,
        }
        var body = {
            "parameters": parameters,
            "target_data": "GET_SECTION"
        };
        const postData = {
            keys: keys,
            body: body
        };
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    this.setState({ IsLoad: false });
                    if (response.error) {
                        this.setState({
                            IsError: {
                                message: response.error,
                                return: true
                            },
                            SelectedSection: {
                                load: false,
                                data: []
                            }
                        });
                    } else if ("message" in response.response) {
                        var msg = response.response.message.user;
                        this.setState({
                            IsError: {
                                message: msg,
                                return: true
                            },
                            SelectedSection: {
                                load: false,
                                data: []
                            }
                        });
                    } else {
                        var results = response.response.data;
                        if (Object.keys(results).length > 0) {
                            this.setState({ SelectedSection: { load: false, data: results[0] } });
                        } else {
                            this.setState({
                                IsError: {
                                    message: "No record found.",
                                    return: true
                                },
                                SelectedSection: {
                                    load: false,
                                    data: []
                                }
                            });
                        }
                    }
                }
            );
    }

    componentDidMount() {
        this.GET_SECTION();
    }

    render() {
        return (
            <div id='detail-section'>
                {(this.state.IsError.return) ? (
                    <div className="alert alert-danger">
                        {this.state.SectionID || this.state.SelectedStatus ? "Missing parameters" : this.state.IsError.message}
                    </div>
                ) : (
                    <div className="d-flex flex-column flex-grow-1">
                        <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-primary svg-icon-xl">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"></rect>
                                            <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                            <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero"></path>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                            <div className="alert-text"><h3>Attendance Class</h3></div>
                        </div>

                        <div className="atd-section">
                            {(this.state.SelectedSection.load) ? (
                                <LoadingBtn />
                            ) : ''}
                            <div className={(this.state.SelectedSection.load) ? "d-none" : "d-block"}>
                                {((Object.values(this.state.SelectedSection.data).length > 0) ? (
                                    <CardSessionList section={this.state.SelectedSection.data} status={this.state.SelectedStatus} />
                                ) : '')}
                            </div>
                        </div>
                        
                    </div>
                )}
            </div>
        )
    }
}
