import React from 'react'
import { useState } from 'react'
import CalendarDeptStd from './CalendarDeptStd';
import { StudentRepresentative } from '../../../../Const/StudentRepresentative';

export default function CalendarDeptIndex() {

    const arrData = StudentRepresentative;

    const FetchCallDept = () => {
        return <CalendarDeptStd studentid={selectedDept} />
    }

    const [selectedSemes, setSelectedSemes] = useState('');
    const [selectedDept, setSelectedDept] = useState('');

    const handleSemesChange = (event) => {
        const value = event.target.value;
        setSelectedSemes(value);
        setSelectedDept('');
    };

    const deptOptions = selectedSemes
    ? arrData.find(item => item.semes.toString() === selectedSemes)?.dept || []
    : [];

    return (
        <div className='card card-custom gutter-b'>
            <div className="card-header align-items-center border-0 mt-4">
                <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">Study Program Timetable (Generalized)</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">Please choose department by semester</span>
                </h3>
            </div>
            <div className="card-body pt-0 px-0">
                <div className="bg-light p-5 d-flex justify-content-center align-items-end">
                    <div className="mb-3 mr-3 w-50">
                        <label className="font-weight-bold">Semester</label>
                        <select name="semes" className="form-control" required value={selectedSemes} onChange={handleSemesChange}>
                            <option value="">Choose one</option>
                            {arrData.map((s, index) => (
                                <option key={index} value={s.semes}>Semester {s.semes}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3 mr-3 w-50">
                        <label className="font-weight-bold">Department</label>
                        <select name="semes" className="form-control" value={selectedDept} required onChange={(e) => setSelectedDept(e.target.value)}>
                            <option value="">Choose one</option>
                            {Object.values(deptOptions).length > 0 ? (
                                deptOptions.map((d, index) => (
                                    <option value={d.stdid} key={index}>{d.code}</option>
                                ))
                            ) : ""}
                        </select>
                    </div>

                </div>

                <div className="selected-calendar">
                    {(selectedSemes && selectedDept) ? <FetchCallDept /> : ""}
                </div>
            </div>
        </div >
    )
}
