import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from '../../../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../../../Widgets/LoadingBtn';
import HistPCF from './HistPCF';
import { openModal } from '../../../../../Library/ModalPopUp';
import FormFirstPCF from './FormFirstPCF';
import Notifications from '../../../Dashboard/Widget/Notifications';
import AxiosLib from '../../../../../Library/AxiosLib';
import moment from 'moment';
import { openToast } from '../../../../../Library/ToastPopUp';
import FinalPCF from './FinalPCF';

export function CurrPCF() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const [stdStatus, setStdStatus] = useState({ loading: false, data: [], message: "" });
    const GET_STUDENT_STATUS = () => {
        setStdStatus({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
            },
            target_data: "GET_STUDENT_STATUS",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdStatus({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setStdStatus({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    setStdStatus({ loading: false, data: results, message: "" });
                    GET_ACTIVE_ACAD(results.atp_group_type);
                } else {
                    setStdStatus({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    };

    const [stdAcad, setStdAcad] = useState({ loading: false, data: [], message: "" });
    const GET_ACTIVE_ACAD = (acadType) => {
        setStdAcad({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            target_data: "GET_ACTIVE_ACAD",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdAcad({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setStdAcad({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    var selectIndex = results.findIndex(
                        (v) => v.atp_group_type.id === acadType
                    );
                    setStdAcad({ loading: false, data: results[selectIndex], message: "" });
                } else {
                    setStdAcad({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    }

    const fetchingAnnouncements = (param) => {
        //setAnnouncement({ loading: true, message: "", data: [] })
        AxiosLib(param, "mc/api/notifications/fetch").then(
            (response) => {
                if (response.error) {
                    openToast({ header: "Error", message: response.error })
                } else {
                    if (response.data) {
                        openModal({ header: "Information", message: <BodyNotif data={response.data} />, size: "lg" })
                    }
                }
            }
        );
    }

    const TabsMenu = [{ id: 1, name: "History PCF", component: <HistPCF /> },
                      { id: 2, name: "PCF Enrollment", component: <FormFirstPCF acad={stdAcad.data} /> },
                      { id: 3, name: "Download Final PCF", component: <div className="bg-white border border-top-0"><FinalPCF acad={stdAcad.data} students={stdStatus.data} /></div> }];
    const [tabsActive, setTabActive] = useState(0);

    const BodyNotif = ({ data }) => {
        return (
            <div>
                {data.map((v, index) => (
                    <div className="" key={index}>
                        <h3>{v.Title}</h3>
                        <div style={{ overflow: "auto", minWidth: "200px" }}>
                            <div dangerouslySetInnerHTML={{ __html: v.Message }} />
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const [pcf, setPCF] = useState({ loading: false, data: [], message: "" });
    const GET_REREG_STUDENT = () => {
        setPCF({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
            },
            target_data: "GET_REREG_STUDENT",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setPCF({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setPCF({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    setTabActive(1);
                    setPCF({ loading: false, data: results, message: "" });
                } else {
                    setPCF({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    }

    useEffect(() => {
        GET_STUDENT_STATUS();
        GET_REREG_STUDENT();
        fetchingAnnouncements({ "RoleID": AuthDecode.currentData.roleid, "MenuID": 5, "CurrentDate": moment().format("YYYY-MM-DD") })
    }, [])

    return (
        <div id='current-pcf'>
            <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                <div className="alert-icon">
                    <span className="svg-icon svg-icon-primary svg-icon-xl">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero"></path>
                            </g>
                        </svg>
                    </span>
                </div>
                <div className="alert-text"><h3>Plan Course Form Term {(Object.values(stdAcad.data).length > 0) ? ((Object.values(stdAcad.data.reff_atp_term).length > 0) ? stdAcad.data.reff_atp_term.name : "-") : "-"}</h3></div>
            </div>

            <div className="tabulasi-pcf">
                {stdStatus.loading || stdAcad.loading ? <LoadingBtn /> : stdStatus.message || stdAcad.message ? (
                    <div className="bg-white p-5">
                        <div className="border rounded p-5 text-center bg-light">
                            <span className="svg-icon svg-icon-primary svg-icon-2x">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z" fill="#000000" opacity="0.3" />
                                        <polygon fill="#000000" points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912" />
                                    </g>
                                </svg>
                            </span>
                            <p className="text-primary mb-0">No record found</p>
                        </div>
                    </div>
                ) : (
                    Object.values(stdStatus.data).length > 0 || Object.values(stdAcad.data).length > 0 ? (
                        <>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="tab-header d-flex justify-content-start align-items-center">
                                    {TabsMenu.map((item, index) => (
                                        <div key={item.id} className={'border border-bottom-0 rounded-top p-5 ' + ((index === tabsActive) ? "bg-white" : "bg-light cursor-pointer")} onClick={() => setTabActive(index)}>
                                            <span className={(index === tabsActive) ? "font-weight-bolder" : ""}>
                                                {item.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    {tabsActive !== 1 ? (
                                        <Link to={{ pathname: "/registration/revision-plan-course-form" }} className="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-2 px-6">
                                            <span>PCF Revision </span>
                                            <span className="svg-icon svg-icon-2x">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                        <rect fill="#000000" opacity="0.3" transform="translate(14.000000, 12.000000) rotate(-90.000000) translate(-14.000000, -12.000000) " x="13" y="5" width="2" height="14" rx="1" />
                                                        <rect fill="#000000" opacity="0.3" x="3" y="3" width="2" height="18" rx="1" />
                                                        <path d="M11.7071032,15.7071045 C11.3165789,16.0976288 10.6834139,16.0976288 10.2928896,15.7071045 C9.90236532,15.3165802 9.90236532,14.6834152 10.2928896,14.2928909 L16.2928896,8.29289093 C16.6714686,7.914312 17.281055,7.90106637 17.675721,8.26284357 L23.675721,13.7628436 C24.08284,14.136036 24.1103429,14.7686034 23.7371505,15.1757223 C23.3639581,15.5828413 22.7313908,15.6103443 22.3242718,15.2371519 L17.0300721,10.3841355 L11.7071032,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(16.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-16.999999, -11.999997) " />
                                                    </g>
                                                </svg>
                                            </span>
                                        </Link>
                                    ) : ""}
                                </div>
                            </div>
                            <div className="tab-body">
                                {TabsMenu[tabsActive].component}
                            </div>
                            <div className="mt-8">
                                <Notifications stdstatus={stdStatus.data} exept={1} ispopup={false} />
                            </div>
                        </>
                    ) : ""
                )}

            </div>

        </div>
    )
}
