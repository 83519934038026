import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2";
import { Academic, BannedNotif } from "./Widget";
import Invoice from "../Billings/Widgets/Invoice";
import ImportantDates from "./Widget/ImportantDates";
import { Link } from "react-router-dom";
import CalendarSchedule from "./Widget/Calendars/CalendarSchedule";
import Notifications from "./Widget/Notifications";
import CardAppStore from "./Widget/CardAppStore";
import { NotifAnnouncement } from "./NotifAnnouncements";
import AxiosLib from "../../../Library/AxiosLib";
import moment from "moment";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.fetchingAnnouncements = this.fetchingAnnouncements.bind(this);
    this.GET_ACTIVE_ACAD = this.GET_ACTIVE_ACAD.bind(this);
    this.getIsPCFOpen = this.getIsPCFOpen.bind(this);

    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    this.state = {
      AuthDecode: AuthDecode,
      StudentStatus: [],
      Acad: [],
      SemesterStatus: [],
      Expired: [],
      LoadingPage: false,
      BannedUser: [],
      IsError: false,
      ErrorMsg: "",
      ISPCF: false,
      announcements: {loading:false, message:"", data:[]}
    };
  }

  fetchingAnnouncements(param) {
    this.setState({ announcements: { loading: true, message: "", data: [] } })
    AxiosLib(param, "mc/api/notifications/fetch").then(
      (response) => {
        if (response.error) {
          this.setState({ announcements: { loading: false, message: response.error, data: [] } })
        } else {
          if (response.data) {
            this.setState({ announcements: { loading: false, message: "", data: response.data } })
          } else {
            this.setState({ announcements: { loading: false, message: "No record found", data: [] } })
          }
        }
      }
    );
  }

  getStudentStatus() {
    this.setState({ LoadingPage: true });
    const reqModule2 = () => {
      var keys = {
        AppId: this.state.AuthDecode.AppID,
        AppEnv: this.state.AuthDecode.AppEnv,
        Secretkey: this.state.AuthDecode.secretkey,
      };
      var body = {
        parameters: {
          studentid: this.state.AuthDecode.currentData.entityid,
        },
        target_data: "GET_STUDENT_STATUS",
      };
      const postData = {
        keys: keys,
        body: body,
      };

      AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
        //console.log(response);
        if (response.error) {
          this.setState({
            IsError: true,
            ErrorMsg: response.error,
            LoadingPage: false,
          });
        } else if ("message" in response.response) {
          this.setState({
            Expired: response.response.message,
            LoadingPage: false,
          });
        } else {
          var param = response.response.data.semester_status;
          param.sort((a, b) => a - b).reverse();
          this.setState({
            SemesterStatus: param,
            StudentStatus: response.response.data,
            LoadingPage: false,
          });
        }
      });
    };
    reqModule2();
  }

  getIsPCFOpen() {
    this.setState({ LoadingPage: true });
    var keys = {
      AppId: this.state.AuthDecode.AppID,
      AppEnv: this.state.AuthDecode.AppEnv,
      Secretkey: this.state.AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: this.state.AuthDecode.currentData.entityid,
      },
      target_data: "GET_REREG_STUDENT",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        this.setState({ IsError: true, LoadingPage: false });
      } else if ("message" in response.response) {
        this.setState({
          MessageRereg: response.response.message,
          LoadingPage: false,
        });
      } else {
        if (response.response.data) {
          this.setState({
            ISPCF: true,
          });
        } else {
          this.setState({
            LoadingPage: false,
          });
        }
      }
    });
  }

  GET_ACTIVE_ACAD() {
    var keys = {
      AppId: this.state.AuthDecode.AppID,
      AppEnv: this.state.AuthDecode.AppEnv,
      Secretkey: this.state.AuthDecode.secretkey,
    };
    var body = {
      target_data: "GET_ACTIVE_ACAD",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        this.setState({
          IsError: true,
          ErrorMsg: response.error,
          LoadingPage: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        this.setState({
          IsError: true,
          ErrorMsg: msg,
          LoadingPage: false,
        });
      } else {
        var results = response.response.data;
        if (results) {
          this.setState({ Acad: results, LoadingPage: false });
        } else {
          this.setState({
            IsError: true,
            ErrorMsg: "No record found for Academic Calendar",
            LoadingPage: false,
          });
        }
      }
    });
  }

  componentDidMount() {
    this.getStudentStatus();
    //this.checkBanned();
    this.getIsPCFOpen();
    //this.infoRereg();
    this.GET_ACTIVE_ACAD();
    this.fetchingAnnouncements({"RoleID":this.state.AuthDecode.currentData.roleid, "CurrentDate":moment().format("YYYY-MM-DD")});
  }

  render() {
    return (
      <div id="dashboard-std">
        {Object.keys(this.state.Expired).length > 0 ? (
          <div className="row">
            <div className="col-lg-12 col-xxl-12">
              <div className="alert alert-danger">
                <p className="m-0">
                  <h1>You've been signed out</h1>
                  <p>{this.state.Expired.user}</p>
                  <p className="text-center">
                    <Link to={{ pathname: "/sign-out" }} className="btn btn-light">Re-Sign in here</Link>
                  </p>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              {(Object.values(this.state.StudentStatus).length > 0) && (this.state.ISPCF) ? (
                <Notifications stdstatus={this.state.StudentStatus} exept={3}/>
              ) : ""}

              {Object.values(this.state.announcements.data).length > 0 ? (
                <NotifAnnouncement data={this.state.announcements.data} />
              ) : ""}
            </div>
            <div className="col-lg-4 col-xxl-4">
              {Object.values(this.state.StudentStatus).length > 0 ? (
                <Academic data={this.state.StudentStatus} />
              ) : ""}
              <Invoice />
            </div>
            <div className="col-lg-8 col-xxl-8">

              <CalendarSchedule />

              <div className="row">
                <div className="col-lg-8 col-xxl-8">
                  {Object.keys(this.state.StudentStatus).length >
                    0 && Object.keys(this.state.Acad).length > 0 ? (
                    <ImportantDates
                      StudentStatus={this.state.StudentStatus}
                      Acad={this.state.Acad}
                    />
                  ) : ""}
                </div>
                <div className="col-lg-4 col-xxl-4">
                  <CardAppStore />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Dashboard;
