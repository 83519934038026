import React, { Component } from 'react'
import Detail from './detail/Detail'

export class ThesisActivityLCTDetail extends Component {
  render() {
    return (
      <Detail />
    )
  }
}
