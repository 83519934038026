import React from 'react'

export default function TableData() {
    return (
        <div id="table-data">
            <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-vertical-center table-bordered">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Academic Calendar</th>
                            <th>Term Duration</th>
                            <th>Leave on Semester</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="5">No record founded</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
