import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { dataFetcher } from "./components/helper";
import "./progress.css";
import NoData from "./components/NoData";
import Loading from "./components/Loading";

const Meeting = ({ total }) => {
  return (
    <div className="d-flex align-items-center ">
      <progress value={total / 8} className="mr-3 progressBar" />
      <p className="text-muted font-weight-bold p-0 m-0">{total} of 8</p>
    </div>
  );
};

const ApprovalList = () => {
  const navigation = useHistory();
  const [datas, setDatas] = useState([]);
  const [datasTable, setDatasTable] = useState([]);
  const [searchProps, setSearchProps] = useState("");

  // get advisor
  const GET_THS_BY_ADVISOR = async () => {
    setDatas({ loading: true, data: [], message: "" });

    const body = {
      target_data: "GET_THS_BY_ADVISOR",
    };
    const response = await dataFetcher(body);
    console.log(response)
    setDatas(response);
    if (response.message === "") {
      const results = response.data;
      let newDatas = [];
      results.map((dta) => {
        newDatas.push({
          studentid: dta.studentid,
          name: dta.studentdata.fullname,
          department: dta.studentdata.clu.name,
          title: dta.final_title,
          meet: dta.approved_act,
        });
      });
      setDatasTable(newDatas);
    }
  };

  // filter
  const handleSeacrhFilter = (x) => {
    if (searchProps.length > 2) {
      return x.filter((item) =>
        ["studentid", "name", "department", "title"].some((key) =>
          String(item[key])?.toLowerCase().includes(searchProps)
        )
      );
    } else {
      return x;
    }
  };

  useEffect(() => {
    GET_THS_BY_ADVISOR();
  }, []);

  return (
    <div className=" mb-10 bg-white mx-1 p-10 rounded">
      <h1>Thesis Activity Approval</h1>
      <p className="font-weight-bold text-muted my-5">
        Student’s Thesis Activity Approval
      </p>

      {datas.loading ? (
        <Loading />
      ) : datas.message === "" ? (
        <>
          <div className="position-relative d-flex align-items-center mb-5 mt-10">
            <i
              className="icon-md text-muted flaticon2-search-1 position-absolute"
              style={{ top: 5, left: 7 }}
            ></i>
            <input
              type="text"
              placeholder="Search"
              value={searchProps}
              onChange={(e) => setSearchProps(e.target.value)}
              className="py-2 pl-10 border rounded "
              style={{ outline: "none", border: 0 }}
            />
            {searchProps !== "" && (
              <a
                title="clear search"
                className="btn btn-xs btn-icon btn-light ml-2"
                onClick={() => setSearchProps("")}
              >
                <i class="ki ki-close icon-sm text-muted"></i>
              </a>
            )}
          </div>

          {/* table */}
          {handleSeacrhFilter(datasTable).length > 0 ? (
            <div className="border-bottom border-b-2">
              <table className="table table-row-bordered table-row-gray-300 gy-7">
                <thead>
                  <tr className="fw-bolder fs-6">
                    <th className="text-muted ps-5">No</th>
                    <th className="text-muted">ID</th>
                    <th className="text-muted">Student’s Name</th>
                    <th className="text-muted">Department</th>
                    <th className="text-muted">Thesis Title</th>
                    <th className="text-muted">Advisory Meetings</th>
                    <th className="text-muted">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {handleSeacrhFilter(datasTable)?.map((data, index) => (
                    <tr key={index}>
                      <td className="ps-5">{index + 1}</td>
                      <td>{data.studentid}</td>
                      <td>{data.name}</td>
                      <td>{data.department}</td>
                      <td style={{ maxWidth: 300 }}>{data.title}</td>
                      <td>
                        <Meeting total={data.meet} />
                      </td>
                      <td className="pl-8">
                        <i
                          title="Detail"
                          class="text-primary fas fa-arrow-right cursor-pointer"
                          onClick={() =>
                            navigation.push("/thesis/activity-detail", {
                              state: data.studentid,
                            })
                          }
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <NoData msg="No data found for" sbj={searchProps} />
          )}
        </>
      ) : (
        <NoData msg={datas.message} sbj="" />
      )}
    </div>
  );
};

export default ApprovalList;
