import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import CardProfile from '../Counseling-new/CardProfile';
import CalendarSchedule from '../../Student/Dashboard/Widget/Calendars/CalendarSchedule';
import AcadInfo from '../Counseling-new/Acad/AcadInfo';
import LoadingBtn from '../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../Library/AlertNotif';
import AxiosLibWithHeader2 from '../../../Library/AxiosLibWithHeader2';
import ChartGPA from '../Counseling-new/Acad/ChartGPA';
import MyAttendance from '../../Student/AcademicSummary/MyAttendance';
import { CCPStd } from '../Counseling-new';

export class StudentProfile extends Component {
    constructor(props) {
        super(props);
        this.GET_STUDENT_STATUS = this.GET_STUDENT_STATUS.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        var studentid = this.props.match.params.stdid;
        this.state = {
            AuthDecode: AuthDecode,
            studentid: studentid,
            studentStatus: { load: false, data: [], message: "" },
            semesStatus: []
        }
    }

    GET_STUDENT_STATUS() {
        this.setState({ studentStatus: { load: true, data: [], message: "" } });
        var keys = {
            "AppId": this.state.AuthDecode.AppID,
            "AppEnv": this.state.AuthDecode.AppEnv,
            "Secretkey": this.state.AuthDecode.secretkey
        };
        var parameters = {
            "studentid": this.state.studentid,
        }
        var body = {
            "parameters": parameters,
            "target_data": "GET_STUDENT_STATUS"
        };
        const postData = {
            keys: keys,
            body: body
        };
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    this.setState({ IsLoad: false });
                    if (response.error) {
                        this.setState({ studentStatus: { load: false, data: [], message: response.error } });
                    } else if ("message" in response.response) {
                        var msg = response.response.message.user;
                        this.setState({ studentStatus: { load: false, data: [], message: msg } });
                    } else {
                        var results = response.response.data;
                        if (Object.keys(results).length > 0) {
                            var values = results.semester_status;
                            if (Object.keys(values).length > 0) {
                                values.sort((a, b) => (b.semester - a.semester));
                                this.setState({
                                    studentStatus: { load: false, data: results, message: "" },
                                    semesStatus: values
                                })
                                //this.getCoursePlan(values[0]);
                            } else {
                                this.setState({ studentStatus: { load: false, data: results, message: "" } });
                            }
                        } else {
                            this.setState({ studentStatus: { load: false, data: [], message: "No record found." } });
                        }
                    }
                }
            );
    }

    componentDidMount() {
        this.GET_STUDENT_STATUS();
    }

    render() {
        return (
            <div className="student-profile">
                <div className='card card-custom gutter-b'>
                    <div className="card-body pb-0">
                        <CardProfile
                            studentid={this.state.studentid}
                        />
                        
                        <ul className="nav nav-tabs nav-bold nav-tabs-line">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#kt_tab_pane_1_1">
                                    <span className="nav-icon">
                                        <i className="flaticon2-calendar-9"></i>
                                    </span>
                                    <span className="nav-text">Timetables</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#kt_tab_pane_1_2">
                                    <span className="nav-icon">
                                        <i className="flaticon2-time"></i>
                                    </span>
                                    <span className="nav-text">Attendance</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#kt_tab_pane_1_3">
                                    <span className="nav-icon">
                                        <i className="flaticon2-document"></i>
                                    </span>
                                    <span className="nav-text">Transcript</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="tab-content">
                    <div className="tab-pane fade show active" id="kt_tab_pane_1_1" role="tabpanel" aria-labelledby="kt_tab_pane_1_1">
                        <div className="row">
                            <div className="col-12 col-lg-4 col-xxl-4">
                                {this.state.studentStatus.load ? <LoadingBtn /> : this.state.studentStatus.message ? <AlertNotif message={this.state.studentStatus.message} color={"danger"} /> : (
                                    Object.values(this.state.studentStatus.data).length > 0 ? (
                                        <>
                                            <AcadInfo studentStatus={this.state.studentStatus.data} />

                                            <div className="card card-custom gutter-b">
                                                <div className="card-body pt-0 px-0">
                                                    <ChartGPA studentStatus={this.state.studentStatus} />
                                                </div>
                                            </div>
                                        </>
                                    ) : ""
                                )}
                            </div>
                            <div className="col-12 col-lg-8 col-xxl-8">
                                <CalendarSchedule stdid={this.state.studentid} />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_1_2" role="tabpanel" aria-labelledby="kt_tab_pane_1_2">
                        <div className="attd">
                            {this.state.studentStatus.load ? <LoadingBtn /> : this.state.studentStatus.message ? <AlertNotif message={this.state.studentStatus.message} color={"danger"} /> : (
                                Object.values(this.state.studentStatus.data).length > 0 ? (
                                    <MyAttendance semester_status={this.state.semesStatus} studentid={this.state.studentid} />
                                ) : ""
                            )}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_tab_pane_1_3" role="tabpanel" aria-labelledby="kt_tab_pane_1_3">
                        <CCPStd />
                    </div>
                </div>
            </div>
        )
    }
}

