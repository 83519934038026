import React from "react";
import Tooltip from "../../../../Lecturer/Thesis/Activity/components/Tooltip";

const Attachment = () => {
  return (
    <Tooltip title="See Attachment">
      <i className="icon-lg text-primary ml-7 flaticon2-paper cursor-pointer"></i>
    </Tooltip>
  );
};

export default Attachment;
