import sign from 'jwt-encode';
import packageJson from "../../../../package.json";

const AuthStoreData = value =>{
    if(value){        
        value.currentData = {
            entityid:value.identity.entityid, 
            given_name:value.identity.given_name, 
            family_name: value.identity.family_name, 
            empid: value.identity.empid,
            role:value.identity.role,
            roleid:value.identity.roleid,
        };

        const token = sign(value, packageJson.system_param.PublicKey);
        localStorage.setItem("AuthToken",token);
    }
}

export default AuthStoreData;