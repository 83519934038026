import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../../Library/AlertNotif";

export default function ApprovedPCF({ eventid, atp, studentid }) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    const [tcreditAct, setTcreditAct] = useState(0);
    const [courses, setCourses] = useState({
        loading: false,
        data: [],
        message: ""
    });
    const GET_COUNSELING_PCF = () => {
        setCourses({ message: "", loading: true, data: [] });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "eventid": eventid
            },
            "target_data": "GET_COUNSELING_PCF"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setCourses({ data: [], loading: false, message: response.error });
                    } else if ("message" in response.response) {
                        setCourses({ data: [], loading: false, message: response.response.message.internal });
                    } else {
                        var result = response.response.data;
                        if (Object.values(result).length > 0) {
                            var FPCF = result.filter(prev => (prev.action_type));
                            FPCF.sort((a, b) => a.semester - b.semester);
                            if (Object.values(FPCF).length > 0) {
                                let resObj = GeneratePCFList(result);
                                const totalCredit = resObj.reduce((a, v) => a = a + ((v.action_type === 'add' && v.detail.course_type !== "optional") ? v.detail.credit : 0), 0);
                                setTcreditAct(totalCredit);
                                setCourses({ message: "", loading: false, data: resObj });
                            }
                        } else {
                            setCourses({ data: [], loading: false, message: "No record found" });
                        }
                    }
                }
            );
    }

    const GeneratePCFList = (arrData) => {
        var results = [];
        results = arrData.map((v) => {
            var obj = {};
            obj.action_type = (v.action_type === "asc.course.action.add") ? "add" : "remove";
            obj.origin = true;
            obj.course_id = v.course_id;
            v.course_code = v.code;
            v.course_credit = v.credit;
            let typeArr = v.course_type.split(".");
            let type = typeArr[typeArr.length - 1];
            v.course_type = type;
            obj.detail = v;
            return obj;
        })
        return results;
    }

    const objParam = {
        studentid: studentid,
        atpid: atp.id
    }
    const [fpcf, setFpcf] = useState({ loading: false, data: [], message: "" });
    const [sumall, setSumall] = useState(0);
    const GET_STUDENT_COURSE = () => {
        setFpcf({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: objParam,
            target_data: "GET_STUDENT_COURSE",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setFpcf({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setFpcf({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    var total = results.reduce((a, v) => a = a + ((v.status === 'ACTIVE' && v.course.course_type !== "asc.course.type.optional") ? v.credit : 0), 0);
                    setSumall(total);
                    setFpcf({ loading: false, data: results, message: "" });
                } else {
                    setFpcf({ loading: false, data: results, message: "No record found" });
                }
            }
        });
    }

    useEffect(() => {
        GET_COUNSELING_PCF();
        GET_STUDENT_COURSE();
    }, [])

    return (
        <div id="activity-student">
            <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="mb-5 text-uppercase">
                                Final PCF
                            </h3>
                            {fpcf.loading ? <LoadingBtn /> : (
                                fpcf.message ? <AlertNotif title={"Information"} message={fpcf.message} color={"danger"} /> :
                                    Object.values(fpcf.data).length > 0 ? (
                                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                            <thead>
                                                <tr className="text-left text-uppercase">
                                                    <th width="5%">No</th>
                                                    <th>Course Code</th>
                                                    <th>Course Title</th>
                                                    <th>Type</th>
                                                    <th>Credit</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fpcf.data.map((v, index) => {
                                                    let type = "";
                                                    if (v.course) {
                                                        const course_type = v.course ? v.course.course_type : "";
                                                        let typeArr = course_type.split(".");
                                                        type = typeArr[typeArr.length - 1];
                                                    }

                                                    return (
                                                        <tr className={v.status === "CANCEL" ? "bg-light-danger" : ""}>
                                                            <td>{index + 1}</td>
                                                            <td>{v.course.code}</td>
                                                            <td>{v.course.coursetitle}</td>
                                                            <td className="text-capitalize">{type}</td>
                                                            <td>{v.credit}</td>
                                                            <td>{v.status}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={5} className="font-weight-bolder text-center">Total Credit {sumall} SKS</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    ) : ""
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="mb-5 text-uppercase">
                                History Activity PCF
                            </h3>
                            {courses.loading ? <LoadingBtn /> : (
                                courses.message ? <AlertNotif title={"Information"} message={courses.message} color={"danger"} /> : (
                                    Object.values(courses.data).length > 0 ? (
                                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                            <thead>
                                                <tr className="text-left text-uppercase">
                                                    <th width="5%">No</th>
                                                    <th>Course</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {courses.data.map((v, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <span className="d-block font-weight-bold">{v.detail.coursetitle} / {v.detail.course_code}</span>
                                                            <span className="d-block">Credit {v.detail.course_credit}</span>
                                                            <span className="d-block text-capitalize">Type {v.detail.course_type}</span>
                                                        </td>
                                                        <td>
                                                            <span className={"badge badge-" + (v.action_type === "add" ? "success" : "danger")}>{v.action_type}</span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2} className="text-center">
                                                        <span className="font-weight-bolder">Total Credit {tcreditAct} SKS</span>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    ) : ""))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
