import React from 'react';
import { Link } from 'react-router-dom';

const StudentLink = ({data}) => {
    return (
        <Link to={{pathname:"/student/"+data.studentid}} className="text-dark-75 text-hover-primary" >
            <span className="font-weight-bolder mb-1 font-size-lg">{data.studentname}</span>
            <span className="text-muted font-weight-bold d-block">{data.studentid}</span>
        </Link>
    )
}

export {StudentLink}