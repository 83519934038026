import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../Library/AlertNotif';
import FormScoreStd from './FormScoreStd';

export class DetailMid extends Component {
    constructor(props) {
        super(props);
        this.getStudentList = this.getStudentList.bind(this);
        this.getCurrentClass = this.getCurrentClass.bind(this);
        this.GET_EMP_BIO_BY_IDENID = this.GET_EMP_BIO_BY_IDENID.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        var param = this.props.match.params.token;
        var decodeSection = (param) ? jwt_decode(param) : [];
        this.state = {
            AuthDecode: AuthDecode,
            CurrentSection: decodeSection,
            StudentList: { loading: false, data: [], message: "" },
            CurrentClass: { loading: false, data: [], message: "" },
            Profile: { loading: false, data: [], message: "" },
        }
    }

    getStudentList() {
        this.setState(prevState => ({
            StudentList: {
                ...prevState.StudentList,
                loading: true
            }
        }));
        const reqModule = () => {
            var keys = {
                "AppId": this.state.AuthDecode.AppID,
                "AppEnv": this.state.AuthDecode.AppEnv,
                "Secretkey": this.state.AuthDecode.secretkey
            };
            var body = {
                "parameters": {
                    "section_id": this.state.CurrentSection.section_id
                },
                "target_data": "GET_SCR_LECTURER_STDLIST"
            };
            const postData = {
                keys: keys,
                body: body
            };
            //console.log("studentlist",postData);
            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        //console.log("GET_SCR_LECTURER_STDLIST_M",response);
                        if (response.error) {
                            this.setState(prevState => ({
                                StudentList: {
                                    ...prevState.StudentList,
                                    loading: false,
                                    message: response.error
                                }
                            }));
                        } else if ("message" in response.response) {
                            this.setState(prevState => ({
                                StudentList: {
                                    ...prevState.StudentList,
                                    loading: false,
                                    message: response.response.message.user
                                }
                            }));
                        } else {
                            var results = response.response.data;
                            if (results) {
                                results.sort(function (a, b) {
                                    if (a.studentdata.fullname < b.studentdata.fullname) { return -1; }
                                    if (a.studentdata.fullname > b.studentdata.fullname) { return 1; }
                                    return 0;
                                })

                                this.setState(prevState => ({
                                    StudentList: {
                                        ...prevState.StudentList,
                                        loading: false,
                                        data: results
                                    }
                                }));
                            } else {
                                this.setState(prevState => ({
                                    StudentList: {
                                        ...prevState.StudentList,
                                        loading: false,
                                        message: "Have no students. Please contact your FAO"
                                    }
                                }));
                            }
                        }
                    }
                );
        }
        reqModule();
    }

    getCurrentClass() {
        this.setState(prevState => ({
            CurrentClass: {
                ...prevState.CurrentClass,
                loading: true
            }
        }));
        const reqModule = () => {
            var keys = {
                "AppId": this.state.AuthDecode.AppID,
                "AppEnv": this.state.AuthDecode.AppEnv,
                "Secretkey": this.state.AuthDecode.secretkey
            };

            //NEW
            var body = {
                "parameters": {
                    "section_id": this.state.CurrentSection.section_id
                },
                "target_data": "GET_SECTION"
            };
            //End New

            const postData = {
                keys: keys,
                body: body
            };
            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        if (response.error) {
                            this.setState(prevState => ({
                                CurrentClass: {
                                    ...prevState.CurrentClass,
                                    loading: false,
                                    message: response.error
                                }
                            }));
                        } else if ("message" in response.response) {
                            this.setState(prevState => ({
                                CurrentClass: {
                                    ...prevState.CurrentClass,
                                    loading: false,
                                    message: response.response.message.user
                                }
                            }));
                        } else {
                            var results = response.response.data;
                            if (results) {
                                this.setState(prevState => ({
                                    CurrentClass: {
                                        ...prevState.CurrentClass,
                                        loading: false,
                                        message: "",
                                        data: results[0]
                                    }
                                }));
                            } else {
                                this.setState(prevState => ({
                                    CurrentClass: {
                                        ...prevState.CurrentClass,
                                        loading: false,
                                        message: "No record found for this section"
                                    }
                                }));
                            }

                        }
                    }
                );
        }
        reqModule();
    }

    GET_EMP_BIO_BY_IDENID() {
        this.setState(prevState => ({
            Profile: {
                ...prevState.Profile,
                loading: true,
                message: "",
                data: []
            }
        }));
        var keys = {
            "AppId": this.state.AuthDecode.AppID,
            "AppEnv": this.state.AuthDecode.AppEnv,
            "Secretkey": this.state.AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                empid: this.state.AuthDecode.currentData.entityid
            },
            "target_data": "GET_EMP_BIO_BY_IDENID"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                this.setState(prevState => ({
                    Profile: {
                        ...prevState.Profile,
                        message: response.error,
                        loading: false,
                    }
                }));
            } else if ("message" in response.response) {
                var msg = response.response.message.internal;
                this.setState(prevState => ({
                    Profile: {
                        ...prevState.Profile,
                        message: msg,
                        loading: false,
                    }
                }));
            } else {
                var results = response.response.data;
                if (results) {
                    this.setState(prevState => ({
                        Profile: {
                            ...prevState.Profile,
                            data: results,
                            loading: false,
                        }
                    }));
                } else {
                    this.setState(prevState => ({
                        Profile: {
                            ...prevState.Profile,
                            message: "No record found",
                            loading: false,
                        }
                    }));
                }
            }
        });
    }

    componentDidMount() {
        this.getStudentList();
        this.getCurrentClass();
        this.GET_EMP_BIO_BY_IDENID();
    }

    render() {
        return (
            <div id='mid-score'>
                <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                    <div className="alert-icon">
                        <span className="svg-icon svg-icon-primary svg-icon-xl">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                    <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div className="alert-text"><h3>Mid Score System</h3></div>
                </div>

                <div className="row">
                    <div className="col-12 ">
                        {(this.state.StudentList.loading) ? <LoadingBtn /> : ""}
                        <div className={(this.state.StudentList.loading) ? "d-none" : ""}>
                            {(this.state.StudentList.message) ? (
                                <AlertNotif message={this.state.StudentList.message} color={"danger"} />
                            ) : (
                                (Object.values(this.state.StudentList.data).length > 0 && Object.values(this.state.Profile.data).length > 0 && Object.values(this.state.CurrentClass.data).length > 0) ? (
                                    <FormScoreStd profile={this.state.Profile.data} data={this.state.StudentList.data} section_id={this.state.CurrentSection.section_id} sections={this.state.CurrentClass.data} />
                                ) : (
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="border rounded p-5 text-center bg-light">
                                                <span className="svg-icon svg-icon-primary svg-icon-2x">
                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z" fill="#000000" opacity="0.3" />
                                                            <polygon fill="#000000" points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <p className="text-primary mb-0">List of student is empty, please ask your FAO to assign the student for this course.</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
