import React from 'react'

export default function CardCounselor({counselor}) {
  return (
    <div className="card card-custom card-stretch gutter-b">
        <div className="card-body">
            <h3 className='text-primary font-size-h3 font-weight-bold mb-3'>Counselor Information</h3>
            {Object.values(counselor).length > 0 ? (
                counselor.map((v,index)=>(
                    (v.emp) ? (
                        <div key={index}>
                            <p className="text-dark">
                                <span className='d-block'>Fullname</span>
                                <span className='font-weight-bold font-size-h4'>{v.emp.fullname}</span>
                            </p>
                            <p className="text-dark">
                                <span className='d-block'>Email:</span>
                                <span className='font-weight-bold font-size-h4'>{v.emp.email}</span>
                            </p>
                        </div>
                    ) : ""
                ))
            ) :"No record found"}
        </div>
    </div>
  )
}
