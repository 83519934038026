import React, { useState } from 'react'
import Moment from "moment"
import jwt_decode from "jwt-decode"
import FormTeachingTopic from './FormTeachingTopic';
import FormStudentAtd from './FormStudentAtd';
import ShowQrCode from './ShowQrCode';
import CardCurrentSectionHorizontal from '../CardCurrentSectionHorizontal';

export default function DetailAtdClass(props) {
    const currentSession = (props.data) ? props.data : null;
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const [atd_lec_id, setAtdLecId] = useState((currentSession.id) ? currentSession.id : 0);
    const [qrCode, setQRCode] = useState((currentSession.qr) ? currentSession.qr : "");

    return (
        <div id='detail-class-session'>
            <div className="card card-custom gutter-b">
                <div className="card-header bg-light">
                    <CardCurrentSectionHorizontal sections={props.sections} />
                </div>
                <div className="card-body pt-5">
                    <div className="row">
                        <div className="col-lg-4 col-xxl-4">
                            <div className="d-flex align-items-center mb-10 bg-light-warning rounded p-5 text-cursor" onClick={(e) => { props.setSelectedSession({}); props.changeStatus(5); }} style={{ cursor: "pointer" }}>
                                <span className="symbol symbol-40 symbol-light-warning mr-5">
                                    <span className="symbol-label">
                                        <span className="svg-icon svg-icon-xl svg-icon-warning">
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                    <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1" />
                                                    <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) " />
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                </span>
                                <div className="d-flex flex-column font-weight-bold">
                                    <span className="text-dark mb-1 h4">Back</span>
                                    <span className="text-muted">go back to session list</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xxl-4">
                            <div className="d-flex align-items-center mb-10 bg-light-success rounded p-5">
                                <span className="symbol symbol-40 symbol-light-success mr-5">
                                    <span className="symbol-label">
                                        <span className="svg-icon svg-icon-xl svg-icon-success">
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
                                                    <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                </span>
                                <div className="d-flex flex-column font-weight-bold">
                                    <span className="text-dark mb-1 h4">{Moment(currentSession.start_dt).format('D MMM YYYY')}</span>
                                    <span className="text-dark">
                                        {Moment(currentSession.start_dt).format('hh:mm a')} - {Moment(currentSession.end_dt).format('hh:mm a')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xxl-4">
                            <div className="d-flex align-items-center mb-10 bg-light-success rounded p-5">
                                <span className="symbol symbol-40 symbol-light-success mr-5">
                                    <span className="symbol-label">
                                        <span className="svg-icon svg-icon-xl svg-icon-success">
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"></path>
                                                    <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                </span>
                                <div className="d-flex flex-column font-weight-bold">
                                    <span className="text-dark mb-1 h4">{(currentSession.status) ? currentSession.status : "DRAFT"}</span>
                                    <span className="text-dark">
                                        {(currentSession.lec_tap_time) ? Moment(currentSession.lec_tap_time).format('D MMM YYYY, hh:mm a') : ''}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {(AuthDecode.remotedBy) ? (
                            <div className="col-12 mb-3">
                                <div className="alert alert-danger">
                                    <div className="alert-title text-left">
                                        <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                                        You cannot submit this Session.<br />This session belongs to <b>Mr/Mrs. {AuthDecode.currentData.given_name + " " + AuthDecode.currentData.family_name}</b>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                    </div>

                    <div className="row">
                        <div className={"col-md-12 col-lg-" + ((atd_lec_id) ? 8 : 12)}>
                            <FormTeachingTopic data={currentSession} atd_lec_id={atd_lec_id} setAtdLecId={setAtdLecId} setQRCode={setQRCode} />
                        </div>
                        {(atd_lec_id) ? (
                            <div className="col-md-12 col-lg-4 col-xxl-4">
                                <ShowQrCode />
                            </div>
                        ) : ""}
                    </div>
                </div>
            </div>



            {(atd_lec_id) ? (
                <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                        <FormStudentAtd data={currentSession} atd_lec_id={atd_lec_id} setSelectedSession={props.setSelectedSession} changeStatus={props.changeStatus} />
                    </div>
                </div>
            ) : ""}

        </div>
    )
}
