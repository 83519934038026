import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { GET_SECTION } from './Apis';
import LoadingBtn from '../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../Library/AlertNotif';
import { TableCurrentClass } from '../Widget/WCoursesDetail';
import FormScoreComp from "./FormScoreComp";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export function SchemaScore() {
    const params = useParams();
    const token = params.token;
    const param = jwt_decode(token);
    const section_id = param.section_id;

    const [section, setSection] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const ReloadData = () =>{
        const paramSection = { section_id: section_id };
        GET_SECTION(paramSection, setSection);
    }

    useEffect(() => {
        ReloadData();
    }, []);

    const HandlerBack = () =>{
        ReloadData();
    }


    return (
        <div id='submit-score-schema'>
            <div className="mb-5">
                {section.loading ? (
                    <LoadingBtn />
                ) : section.message ? (
                    <AlertNotif message={section.message} color={"danger"} />
                ) : Object.values(section.data).length ? (
                    <TableCurrentClass data={section.data} />
                ) : (
                    "-"
                )}
            </div>

            <div className="my-5">
                {section.loading ? (
                    <LoadingBtn />
                ) : section.message ? (
                    <AlertNotif message={section.message} color={"danger"} />
                ) : Object.values(section.data).length ? (
                    <FormScoreComp section_id={section_id} sectionInfo={section.data} />
                ) : (
                    "-"
                )}
            </div>
        </div>
    )
}