import React from 'react'
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { AlertNotif } from "../../Library/AlertNotif";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

const CardUnregis = () => {
    return (
        <div className="m-auto">
            <AlertNotif title="Your account is unregistered" message="Please contact IST to sync your account into MY SGU Portal" color="info" />
            <p className="text-center">
                <Link to={{ pathname: "/sign-out" }} className="btn btn-lg btn-primary">
                    <i className="fs-1 fas fa-sign-out-alt"></i>
                    <span className="font-weight-bolder">
                        Re-Sign in here
                    </span>
                </Link>
            </p>
        </div>
    )
}

const CardSessionTimeout = () => {
    return (
        <div>
            <AlertNotif title="You've been signed out" message="Please sign in again to continue using MY SGU Portal" color="danger" />
            <p className="text-center">
                <Link to={{ pathname: "/sign-out" }} className="btn btn-lg btn-primary">
                    <i className="fs-1 fas fa-sign-out-alt"></i>
                    <span className="font-weight-bolder">
                        Re-Sign in here
                    </span>
                </Link>
            </p>
        </div>
    )
}

const CardLais = ({url}) => {
    return (
        <div className="alert bg-light-info border border-info">
            <p class="text-center font-weight-bold h3 text-info">
                Hi,{AuthDecode.currentData.given_name}{" "}
                you will be redirected to the survey filling page.
                <br />
                Please click below if you are not redirected in 2
                seconds
                <br />
                <br />
                <br />
                <a
                    href={url}
                    className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                >
                    Go to Survey
                </a>
            </p>
        </div>
    )
}

export { CardUnregis, CardSessionTimeout, CardLais }