import React from 'react'

export default function CardAppStore() {
    return (
        <div className="card card-custom card-stretch  gutter-b">
            <div className="card-header align-items-center border-0">
                <h3 className="card-title align-items-start">
                    <span className="font-weight-bolder text-dark">
                        Download your SGU Mobile
                    </span>
                </h3>
            </div>
            <div className="card-body pt-0">
                <div className="text-center d-flex justify-content-center align-items-center">
                    <img
                        className="w-120px rounded"
                        src="https://play-lh.googleusercontent.com/nmPK3i8zwvFkr6SmA076PEX2QcgH3SaNAFaY0VzRe0C3ksqWYPzVNeyvfZLPTs4QAb0=w2560-h1440-rw"
                        alt="sgu-mobile"
                    />
                    <div>
                        <div>
                            <p className="text-dark mb-0">Available on</p>
                            <a href="https://play.google.com/store/apps/details?id=id.ac.sgu.mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                <img
                                    alt="Get it on Google Play"
                                    className="w-100"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                />
                            </a>

                            <a href="https://apps.apple.com/id/app/sgu-mobile-app/id6448629664?itsct=apps_box_badge&amp;itscg=30200" >
                                <img
                                    alt="Download on App Store"
                                    className="w-100 p-3"
                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1683158400" alt="Download on the App Store" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
