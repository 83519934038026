import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import { AlertNotif } from '../../Library/AlertNotif';
import ProfileStaff from './Staff/ProfileStaff';
import ProfileStudent from './Students';

export class Profiles extends Component {

    constructor(props) {
        super(props);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode: AuthDecode,
        };
    }

    render() {
        return (
            <div id="profile">
                {this.state.AuthDecode.currentData.entityid ? (
                    (this.state.AuthDecode.currentData.roleid === 1) ? (
                        <ProfileStudent />
                    ) : <ProfileStaff />
                ) : <AlertNotif message={"You don't have an access to this portal, please contact IST Dept to solve this problem"} color={"danger"} title={"Information"}  />}
            </div>
        )
    }
}

export default Profiles;