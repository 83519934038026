import React, { Component } from 'react'
import { Redirect } from "react-router-dom"

export class MADashboard extends Component {
    render() {
        return (
            <Redirect to="/my-attendance/class-list" />
        )
    }
}

export default MADashboard