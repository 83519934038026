import React, { useState } from 'react'
import jwt_decode from "jwt-decode"
import Moment from "moment";
import { openModal } from '../../../../Library/ModalPopUp';
import { LoopWeekDay } from '../../../../Library/GlobalHelper';
//import DetailSchedule from './DetailSchedule';

export default function CalendarWeekStd({data}) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    var now = Moment();
    var moday = now.clone().weekday(1).format("YYYY-MM-DD");
    var saturday = now.clone().weekday(6).format("YYYY-MM-DD");

    const [startWeek, setStartWeek] = useState(moday);
    const [selectedDate, setSelectedDate] = useState("");
    const myweek = LoopWeekDay(now);
    const today = Moment().format("dddd, DD MMMM YYYY");

    const handlerRenderEvent = (date) => {
        setSelectedDate(date);
        const paramSchd = {
            empid: AuthDecode.currentData.entityid,
            tgl: date,
            tglend: date,
        };
    }

    return (
        <div className='cal-dept-week'>
            <div className="my-8 d-flex justify-content-between align-items-center px-5">
                <div className="time-selected">
                    <h4 className='mb-0'>
                        <span className="day-name d-block">
                            {Moment((selectedDate ? selectedDate : today)).format("ddd")},
                        </span>
                        <span className="date">
                            {Moment((selectedDate ? selectedDate : today)).format("DD MMM YYYY")}
                        </span>
                    </h4>
                </div>
                <div className="action">
                    <div className="btn-group">
                        <button className="btn btn-light btn-sm" type='button'>
                            <i className="fas fa-chevron-left"></i>
                        </button>
                        <button className="btn btn-light btn-sm text-dark" type='button'>
                            <span className="h4">Today</span>
                        </button>
                        <button className="btn btn-light btn-sm" type='button'>
                            <i className="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div className='border p-5 px-8'>
                <div className="d-flex justify-content-between align-items-center">
                    {myweek.map((v, index) => (
                        <div key={index} className={"text-center  p-3 rounded cursor-pointer " + (((Moment(now).format("YYYY-MM-DD")) === v.date) ? "bg-danger text-white " : (selectedDate === v.date ? "bg-white" : "text-primary"))} onClick={() => handlerRenderEvent(v.date)}>
                            <span className="day d-block font-weight-bolder">{v.name.charAt(0)}</span>
                            <span className="date font-weight-bolder">{Moment(v.date).format("DD")}</span>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}
