import React from "react";
import LoadingBtn from "../../../Page/Widgets/LoadingBtn";
import { AlertNotif } from "../../../Library/AlertNotif";

export default function MFormAtd({ stdList }) {
  return (
    <div className="atd-std">
      <div className="bg-light-info border border-info rounded p-2 mb-5">
        <p className="mb-0">
          <span className="mr-2">
            <i className="fa fa-info-circle text-info"></i>
          </span>
          If you want to change the attendance, please use desktop browser.
        </p>
      </div>
      {stdList.loading ? <LoadingBtn /> : stdList.message ? <AlertNotif message={stdList.message} color={"danger"} title={"Information"} /> : (
        Object.values(stdList.data).length > 0 ? (
          <div className="list-atd">
            {stdList.data.map((v, index) => (
              <div className={"d-flex align-items-center mb-10 px-2 " + ((v.flag_sts === 3 || v.flag_sts === 4 || v.flag_sts === 5) ? "bg-light-danger rounded" : "")} key={index}>
                <div className="symbol symbol-40 symbol-light mr-5">
                  <span className="symbol-label">{index + 1}</span>
                </div>
                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                  <span className="text-dark mb-1 font-size-lg">{v.stundentname}</span>
                  <span className="text-muted">{v.studentid}</span>
                  {v.remark ? (
                    <span className="text-danger">
                      <i className="bi bi-bookmark"></i>
                      {v.remark}
                    </span>
                  ) : ""}
                </div>
                <div className={"symbol symbol-30 symbol-"+((v.status === "P" ? "success" : "danger"))}>
                  <span className="symbol-label">{v.status}</span>
                </div>
              </div>
            ))}
          </div>
        ) : ""
      )}
    </div>
  );
}
