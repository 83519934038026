import React, { useState } from "react";
import { openModal } from "../../../../../../Library/ModalPopUp";

const ConfirmLeaveSubmitScore = ({setSelectedColumn, scrStudent, HandlerSubmit, wg_com_id}) =>{
    const [loading, setLoading] = useState(false);
    const handleClose = () =>{
        setLoading(false);
        //setSelectedColumn(null);
        //openModal({open:false});
        //ReloadData();
        HandlerSubmit();
    }
    return (
        <div className="text-center">
            <h3>All unsaved information will be lost. Do you want to save your changes?</h3>
            <div className="btn-group mt-5">
                <button className="btn btn-lg btn-light" type="button" onClick={()=>openModal({open:false})}>No</button>
                <button className="btn btn-lg btn-primary" type="button" onClick={()=>handleClose()} disabled={loading}>
                    {loading ? "Processing...":"Yes"}
                </button>
            </div>
        </div>
    )
}

const MessageAlertScore = ({message}) =>{
    return (
        <div className="text-center">
            <h3>{message}</h3>
        </div>
    )
}
const MessageAlertLeaveScore = ({message, setSelectedColumn, ReloadData}) =>{
    const handlerLeave = () => {
        setSelectedColumn(null);
        ReloadData();
        openModal({open: false});
    }
    return (
        <div className="text-center">
            <p>{message}</p>
            <div className="btn-group">
                <button className="btn btn-lg btn-primary" type="button" onClick={()=>openModal({open:false})}>Cancel</button>
                <button className="btn btn-lg btn-light" type="button" onClick={()=>handlerLeave()}>Leave Page</button>
            </div>
        </div>
    )
}
const MessageSuccessScore = ({message}) =>{
    return (
        <div className="text-center">
            <div className="icon-svg">
                <i className="la la-check-circle icon-10x text-primary"></i>
            </div>
            <h3>{message}</h3>
        </div>
    )
}
const MessageFailedScore = ({message}) =>{
    return (
        <div className="text-center">
            <div className="icon-svg">
                <i className="la la-times-circle icon-10x text-danger"></i>
            </div>
            <h3>{message}</h3>
        </div>
    )
}

export {ConfirmLeaveSubmitScore, MessageAlertScore, MessageSuccessScore, MessageFailedScore, MessageAlertLeaveScore};