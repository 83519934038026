import React, { useState } from "react";
import { useMemo } from "react";
import { openModal } from "../../../../../Library/ModalPopUp";


export default function MYPCF(props) {
  const listData = props.mypcf ? props.mypcf : [];
  const acad = props.acad ? props.acad : [];

  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [totalRemove, setTotalRemove] = useState(0);

  const ResultData = useMemo(() => {
    let computedData = listData;
    computedData.sort((a, b) =>
      a.detail.coursetitle > b.detail.coursetitle ? 1 : -1
    );
    var totalRemove = computedData.filter(prev => prev.action_type === "remove");
    setTotalRemove(Object.values(totalRemove).length);
    var totalSKS = computedData.reduce(
      (a, v) =>
      (a =
        a +
        ((v.detail.course_type !== "optional" && (!v.action_type || v.action_type === "add")) ? v.detail.course_credit : 0)),
      0
    );
    var objSubjectUniq = computedData.reduce((accumulator, currentValue) => {
      if (currentValue.detail.course_type !== "optional") {
        if (currentValue.action_type) {
          if (currentValue.action_type === "add") {
            const { course_id, detail: { course_credit } } = currentValue;
            if (!accumulator.hasOwnProperty(course_id)) {
              accumulator[course_id] = course_credit;
            } else if (accumulator[course_id] !== course_credit) {
              accumulator[course_id] += course_credit;
            }
          }
        } else {
          const { course_id, detail: { course_credit } } = currentValue;
          if (!accumulator.hasOwnProperty(course_id)) {
            accumulator[course_id] = course_credit;
          } else if (accumulator[course_id] !== course_credit) {
            accumulator[course_id] += course_credit;
          }
        }
      }
      return accumulator;
    }, {});
    var tsks = 0;
    if (objSubjectUniq) {
      Object.keys(objSubjectUniq).map((k) => {
        tsks = tsks + objSubjectUniq[k];
      });
    }
    //setTotalItems(computedData.length);
    props.setTotalSKS(tsks);
    return computedData;
  }, [listData, currentPage, search, sorting]);
  /*==END DATATABELS CONFIG==*/

  const handlerChangePCF = (values) => {
    var objclone = [...listData];
    if (values.origin) {
      var xx = objclone.map((item) => {
        if (item.course_id === values.course_id && item.section_id === values.section_id) {
          item.action_type = "remove";
          //item.detail.action_type = "asc.course.action.remove";
        }
        return item;
      });
      props.setMypcf(xx);
    } else {
      var xx = objclone.filter(prev => prev.course_id !== values.course_id);
      props.setMypcf(xx);

    }
  }

  const handlerRedoPCF = (values) => {
    var objclone = [...listData];
    var xx = objclone.map((item) => {
      if (item.section_id === values.section_id) {
        item.action_type = (item.detail.action_type) ? ((item.detail.action_type === "asc.course.action.add") ? "add":"remove") :"add";
        item.detail.action_type = (item.detail.action_type) ? item.detail.action_type :  "asc.course.action.add";
      }
      return item;
    });
    props.setMypcf(xx);
    openModal({ open: false });
  }

  const ListRemovePCF = () => {
    var removedCourse = [...listData];
    var objremove = removedCourse.filter(prev => prev.action_type === "remove");
    return (
      <div className="hist-pcf">
        {Object.values(objremove).length > 0 ? (
          objremove.map((v, index) => (
            <div className="border p-3 rounded mb-8 shadow" key={index}>
              <div className="d-flex justify-content-start align-items-start">
                <div className="my-course w-100 ">
                  <span className="font-weight-bolder mb-1">{v.detail.coursetitle}</span>
                  <div>
                    <span className="text-muted font-weight-bold">{v.detail.course_code}</span>
                  </div>
                </div>
                <div className='info-course mx-2 mx-xxl-5'>
                  <span className="font-weight-bolder d-block">
                    Credit
                    <span className="ml-2 text-dark font-weight-bold">{v.detail.course_credit}</span>
                  </span>
                  <span className="text-muted text-capitalize">{v.detail.course_type}</span>
                </div>
                <button
                  className="btn btn-sm btn-icon btn-warning p-5" type="button" title={"Redo this subject"} onClick={() => handlerRedoPCF(v, index)} >
                  <i className="fas fa-redo"></i>
                </button>
              </div>
              <div className="cost_owner mt-5">
                <span className="font-weight-bolder d-block">{(v.detail.section_id) ? v.detail.section_id : "-"}</span>
                <span className="font-weight-bolder text-muted">Section ID</span>
              </div>
            </div>
          ))
        ) : <p className="text-danger">No record found</p>}
      </div>
    )
  }

  const openHistPCF = () => {
    openModal({ header: "List of removed course from your PCF Revision", message: <ListRemovePCF /> });
  }

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">My PCF Revision</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Please fill in the list of courses you will take
          </span>
        </h3>
        <div className="card-toolbar">
          <span className="font-weight-bolder">
            Total item {ResultData.length}
          </span>
        </div>
      </div>

      <div
        className="card-body pt-0"
        style={{ maxHeight: "600px", overflowY: "auto" }}
      >
        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
          <thead>
            <tr>
              <th><span className="text-dark">COURSE</span></th>
            </tr>
          </thead>
          <tbody>
            {ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                (!v.action_type || v.action_type === "add") ? (
                  <tr key={index}>
                    <td>
                      <div className={"border p-3 rounded " + ((v.action_type) ? "bg-light" : "")}>
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="my-course w-100 ">
                            <span className="font-weight-bolder mb-1">{v.detail.coursetitle}</span>
                            <div>
                              <span className="text-muted font-weight-bold">{v.detail.course_code}</span>
                            </div>
                          </div>
                          <div className='info-course mr-8'>
                            <span className="font-weight-bolder d-block">
                              Credit
                              <span className="ml-2 text-dark font-weight-bold">{v.detail.course_credit}</span>
                            </span>
                            <span className="text-muted text-capitalize">{v.detail.course_type}</span>
                          </div>
                          <button
                            className="btn btn-sm btn-icon btn-danger p-5" type="button" title="Remove to your PCF" onClick={() => handlerChangePCF(v)} >
                            <i className="fas fa-times"></i>
                          </button>
                        </div>
                        <div className="section mt-5">
                          <span className="font-weight-bolder d-block">{v.detail.section_id}</span>
                          <span className="font-weight-bolder text-muted">Section ID</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : ""
              ))
            ) : (
              <tr>
                <td colSpan={2}>No record found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="card-footer">
        <div className="d-flex justify-content-between align-items-center">
          <span className="font-weight-bolder">
            Total Credit {props.totalsks} SKS
          </span>

          <div className="btn-group">
            <button className="btn btn-sm btn-icon btn-warning" type="button">
              <i className="fa fa-trash text-dark"></i>
            </button>
            <button
              className="btn btn-sm btn-light-warning btn-hover-light-warning"
              type="button"
              onClick={() => openHistPCF()}
            >
              <span className="font-weight-bolder text-dark text-uppercase">
                {totalRemove} course removed
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
