import React, { Component } from 'react'
import { Redirect } from "react-router-dom"

export class SDashboard extends Component {
    render() {
        return (
            <Redirect to="/score-grade/view-score" />
        )
    }
}

export default SDashboard