import React from "react";

export default function AcadInfo({ studentStatus }) {
    let enrollment = "";
    if (studentStatus.enrollment_type_id) {
        let enrollmentType = studentStatus.enrollment_type_id.split(".");
        enrollment = enrollmentType[enrollmentType.length - 1];
    }
    const currentSemester = studentStatus.semester_status.find(item => item.atp_term.id === studentStatus.atp_term.id);

    return (
        <div className='card card-custom gutter-b'>
            <div className="card-body">
                <h3 className='mb-8'>Academic Overview</h3>
                <div className="w-100">
                    <div className="d-flex mb-3 justify-content-between align-items-center">
                        <div className="font-weight-bolder">Student ID</div>
                        <div className='text-capitalize'>{studentStatus.studentid}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className="font-weight-bolder">Cummulative Earned Credits</div>
                        <div className='text-capitalize'>{studentStatus.ccredit_pass ? studentStatus.ccredit_pass : 0} SKS</div>
                    </div>
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className="font-weight-bolder">Cummulative GPA</div>
                        <div className='text-capitalize'>{studentStatus.cgpa ? studentStatus.cgpa : 0} ({studentStatus.avg_grade ? studentStatus.avg_grade : "-"})</div>
                    </div>

                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className="font-weight-bolder">Current Semester</div>
                        <div className='text-capitalize'>{currentSemester ? currentSemester.semester : studentStatus.semester}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className="font-weight-bolder">Current Semester Credit</div>
                        <div className='text-capitalize'>{currentSemester ? currentSemester.credit_all : 0} SKS</div>
                    </div>

                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className="font-weight-bolder">Status</div>
                        <div className='text-capitalize'>{studentStatus.status ? (studentStatus.status.name ? studentStatus.status.name : "-") : "-"}</div>
                    </div>
                    {studentStatus.corporate ? (
                        <div className="d-flex justify-content-between mb-3 align-items-center">
                            <div className="font-weight-bolder">Corporate</div>
                            <div className='text-capitalize'>{studentStatus.corporate.name ? studentStatus.corporate.name : "-"}</div>
                        </div>
                    ) : ""}
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className="font-weight-bolder">Term Intake</div>
                        <div className='text-capitalize'>{studentStatus.term_intake ? studentStatus.term_intake.name : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <div className="font-weight-bolder">Enrollment Type</div>
                        <div className='text-capitalize'>{enrollment}</div>
                    </div>
                    {Object.values(studentStatus.scholarship_hist).length > 0 && (
                        <div className="d-flex justify-content-between mb-3 align-items-center">
                            <div className="font-weight-bolder">Scholarship Term</div>
                            <div className='text-capitalize'>{studentStatus.scholarship_hist[Object.values(studentStatus.scholarship_hist).length - 1].atp_term.name}</div>
                        </div>
                    )}
                    <div className="d-flex flex-column mb-3 align-items-start">
                        <div className="font-weight-bolder">Department</div>
                        <div className='text-capitalize'>{studentStatus.clu ? studentStatus.clu.name : "-"}</div>
                        <div className='text-capitalize'>{studentStatus.clu ? studentStatus.clu.faculty.name : "-"}</div>
                    </div>
                    {studentStatus.clu_spec ? (
                        <div className="d-flex flex-column mb-3 align-items-start">
                            <div className="font-weight-bolder">Specialization</div>
                            <div className='text-capitalize'>{studentStatus.clu_spec ? studentStatus.clu_spec.name : "-"}</div>
                        </div>
                    ) : ""}
                    {studentStatus.yudisium_date ? (
                        <div className="d-flex justify-content-between mb-3 align-items-center">
                            <div className="font-weight-bolder">Yudisium Date</div>
                            <div className='text-capitalize'>{studentStatus.yudisium_date}</div>
                        </div>
                    ) : ""}
                </div>
            </div>
        </div>
    )
}


// Info credit
// 1. completed credit => ccredit_pass
// 2. credit cgpa => ccredit_gpa
// 3. credit for gpa =>
// 4. current semester credit => credit_all