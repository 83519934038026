import React, { useEffect, useState } from "react";
import Moment from "moment"
import jwt_decode from "jwt-decode";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";

export default function ImportantDates({ StudentStatus, Acad }) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    const [selectedATPID, setSelectedATPID] = useState({});
    const currYear = new Date().getFullYear();
    const [selectedEventYear, setSelectedEventYear] = useState(currYear);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const groupByYearMonthDate = (myArray) => {
        let res = {};
        let fn = (year, month, o = res, array = myArray) => {
            o[year][month] = array.filter(
                ({ start_dt: d }) => `${year}-${month}` === d.slice(0, 7)
            );
        };

        for (let { start_dt } of myArray) {
            let [year, month] = start_dt.match(/\d+/g);
            if (!res[year]) res[year] = {};
            fn(year, month);
        }
        return res;
    };

    const [mstone, setMstone] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const GET_ACAD_MSTONE_BY_ACAD = (atpid) => {
        setMstone({ ...mstone, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                atpid: atpid,
            },
            target_data: "GET_ACAD_MSTONE_BY_ACAD",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setMstone({ ...mstone, loading: false, message: response.error });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setMstone({ ...mstone, loading: false, message: msg });
            } else {
                var results = response.response.data;
                if (results) {
                    let eventItem = groupByYearMonthDate(results);
                    setMstone({ ...mstone, loading: false, data: eventItem });
                } else {
                    setMstone({
                        ...mstone,
                        loading: false,
                        message: "No record found for active academic calendar",
                    });
                }
            }
        });
    };

    useEffect(() => {
        // let stdType = StudentStatus.atp_group_type;
        // console.log();
        // var selectIndex = Acad
        //     ? Acad.findIndex((v) => v.atp_group_type.id === stdType)
        //     : 0;
        // if (selectIndex !== "-1") {
        //     setSelectedATPID(Acad[selectIndex]);
        //     let currAtpID = Acad[selectIndex].atp_term.id;
        //     GET_ACAD_MSTONE_BY_ACAD(currAtpID);
        // }
        const std_acad_id= StudentStatus.atp_term.id;
        GET_ACAD_MSTONE_BY_ACAD(std_acad_id);
    }, []);

    const EventItem = ({ data }) => {
        const values = Object.keys(data).sort().reduce(
            (obj, key) => {
                obj[key] = data[key];
                return obj;
            },
            {}
        );

        const colorType = (type) => {
            var color = "muted";
            if (type === "asc.atp.milestone.ReRegistrationPeriodRevision" || type === "asc.atp.milestone.ReRegistrationPeriod" || type === "asc.atp.milestone.FinanceDueDatePayment") {
                color = "danger"
            } else if (type === "asc.atp.milestone.BlockScoreInput") {
                color = "danger";
            }
            return color;
        }

        return (
            <div className="timeline timeline-6 mt-3">
                {(Object.keys(values).map((key, index) => (
                    <div className="timelines" key={index}>
                        <div className="timeline timeline-6 mt-3" key={index}>
                            {(Object.values(values[key]).length > 0) ? (
                                values[key].map((v, index) => (
                                    <div className="timeline-item align-items-start" key={index}>
                                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                                            <span className="d-block">{Moment(v.start_dt).format('MMMM Do')}</span>
                                            <span className="">{Moment(v.start_dt).format('LT')}</span>
                                        </div>
                                        <div className="timeline-badge">
                                            <i className={"fa fa-genderless text-" + (colorType(v.mstone_type_id)) + " icon-xl"}></i>
                                        </div>
                                        <div className="timeline-content">
                                            <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg d-block">
                                                {v.name}
                                            </span>
                                            {(v.end_dt) ? (
                                            <span className="font-weight-bolder text-muted pl-3 font-size-lg">
                                                <span className="mr-2">until</span>
                                                {Moment(v.end_dt).format('MMMM Do at LT')}
                                            </span>
                                            ) : ""}
                                        </div>
                                    </div>
                                ))
                            ) : ""}
                        </div>
                    </div>
                )))}
            </div>
        )
    }

    return (
        <div className="card card-custom card-stretch gutter-b">
            <div className="card-header align-items-center border-0 mt-4">
                <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">Important Dates</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">Save the date</span>
                </h3>

                <div className="card-toolbar">
                    <select
                        name="event_year"
                        className="form-control form-control-solid"
                        value={selectedEventYear}
                        onChange={(e) => setSelectedEventYear(e.target.value)}
                    >
                        {Object.values(mstone.data).length > 0 ? (
                            Object.keys(mstone.data).map((k) => (
                                <option value={k}>Year {k}</option>
                            ))
                        ) : (
                            <option value={currYear}>Year {currYear}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="card-body pt-4">
                {(mstone.loading) ? <LoadingBtn /> : ""}
                <div className={(mstone.loading) ? "d-none" : ""}>
                    {(Object.values(mstone.data).length > 0) ? (
                        (mstone.data[selectedEventYear]) ? <EventItem data={mstone.data[selectedEventYear]} /> : ""
                    ) : <p className="text-dark">No record found</p>}
                </div>

                <div className="color-noted d-flex justify-content-center mt-10 flex-wrap">
                    <div className="item d-flex  align-items-center mr-5">
                        <i className="fa fa-genderless text-danger icon-xl mr-2"></i>
                        <span className="text-dark-75">Important dates</span>
                    </div>

                    <div className="item d-flex  align-items-center mr-5">
                        <i className="fa fa-genderless text-success icon-xl mr-2"></i>
                        <span className="text-dark-75">Holiday</span>
                    </div>

                    <div className="item d-flex  align-items-center mr-5">
                        <i className="fa fa-genderless text-primary icon-xl mr-2"></i>
                        <span className="text-dark-75">Examination</span>
                    </div>

                    <div className="item d-flex  align-items-center mr-5">
                        <i className="fa fa-genderless text-warning icon-xl mr-2"></i>
                        <span className="text-dark-75">Graduation</span>
                    </div>

                    <div className="item d-flex  align-items-center mr-5">
                        <i className="fa fa-genderless text-info icon-xl mr-2"></i>
                        <span className="text-dark-75">Orientasion</span>
                    </div>

                </div>
            </div>
        </div>
    )
}
