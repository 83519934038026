import React from 'react'

export default function CategoriesFile(props) {
    const FileTypes = (props.FileTypes) ? props.FileTypes : [];
    
    let objIsactive = [1];
    let Categories = {};
    if(FileTypes){
        Categories.Type = FileTypes.filter(o => objIsactive.find(x => (x !== parseInt(o.SubType)))).map(o=>o);
        Categories.SubType = FileTypes.filter(o => objIsactive.find(x => (x === parseInt(o.SubType)))).map(o=>o);
    }
    return (
        <div id="CategoriesFile">
            <div className="container">
                <form method="post" autoComplete='off'>
                    <div className="form-group">
                        <label>Type</label>
                        <select name="type" className="form-control">
                            <option value="">Choose One</option>
                            {(Object.keys(Categories).length > 0) ? (
                                Categories.Type.map((v)=>(
                                    <option value={v.ID}>{v.Name}</option>
                                ))
                            ) : ''}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Sub Type</label>
                        <select name="type" className="form-control">
                            <option value="">Choose One</option>
                            {(Object.keys(Categories).length > 0) ? (
                                Categories.SubType.map((v)=>(
                                    <option value={v.ID}>{v.Name}</option>
                                ))
                            ) : ''}
                        </select>
                    </div>
                    <div className="btn-group float-right">
                        <button className="btn btn-secondary font-weight-bolder text-uppercase px-9 py-4" type='button' onClick={(e) => props.handlerBackSubmit()}>Back</button>
                        <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" type='submit'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
