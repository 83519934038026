import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import LoadingBtn from '../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../Library/AlertNotif';
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';

export default function TableCoursplan({ studentid }) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    const [courseplan, setCourseplan] = useState({ loading: false, data: [], message: "" });
    const GET_STD_CPL = () => {
        setCourseplan({ loading: true, data: [], message: "" });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "studentid": studentid
            },
            "target_data": "GET_STD_CPL"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if ("message" in response.response) {
                        setCourseplan({ loading: false, data: [], message: response.response.message.user });
                    } else {
                        var param = response.response.data;
                        if (Object.values(param).length > 0) {
                            param.sort((a, b) => a.semester - b.semester);
                            setCourseplan({ loading: false, data: param, message: "" });
                        } else {
                            setCourseplan({ loading: false, data: [], message: "No record found" });
                        }
                    }
                }
            );
    }
    useEffect(() => {
        GET_STD_CPL();
    }, []);

    const openCollapse = (num) => {
        num = parseInt(num);
        var target = document.getElementById("table-collapse-native");
        var rowItem = target.getElementsByClassName("collapse-item");
        for (let index = 0; index < rowItem.length; index++) {
            rowItem[index].classList.remove('show');
        }
        var rowselected = target.getElementsByClassName("semes-" + num);
        for (let index = 0; index < rowselected.length; index++) {
            rowselected[index].classList.add('show');
        }
    }

    var cSemester = 1;
    const appendTR = (v) => {
        var trappend = [];
        if (v) {
            let typeArr = v.course_type.split(".");
            let type = typeArr[typeArr.length - 1];
            trappend.push(
                <tr className={((v.grade_fin) ? ((v.grade_fin === 'F') ? "bg-danger text-white" : "bg-white") : 'bg-white') + " collapse-item semes-" + v.semester + ' ' + ((1 === v.semester) ? 'show' : '')} >
                    <td>{v.coursetitle}</td>
                    <td>{v.code}</td>
                    <td>{v.credit}</td>
                    <td>{type}</td>
                    <td>{(v.score) ? v.score : 0}</td>
                    <td>{(v.grade_fin) ? v.grade_fin : '-'}</td>
                    <td>{(v.ects) ? v.ects : 0}</td>
                    <td>{(v.atd) ? v.atd : 0}%</td>
                    <td>{(v.grade_fin) ? ((v.grade_fin === 'F') ? "Fail" : "") : ''}</td>
                </tr>
            );
        }
        return trappend;
    }

    return (
        <div>
            {courseplan.loading ? <LoadingBtn /> : (
                courseplan.message ? <AlertNotif title={"Information"} message={courseplan.message} color={"danger"} /> : (
                    Object.values(courseplan.data).length > 0 ? (
                        <div className="table responsive">
                            <table className="table table-head-custom table-vertical-center table-bordered table-primary" id="table-collapse-native">
                                <thead>
                                    <tr className='bg-light-primary'>
                                        <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Course Title</span></th>
                                        <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Course Code</span></th>
                                        <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Credit</span></th>
                                        <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Type</span></th>
                                        <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Score</span></th>
                                        <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Grade</span></th>
                                        <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">ECTS</span></th>
                                        <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Atd</span></th>
                                        <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Status</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='bg-light-primary collapse-header' onClick={(e) => openCollapse(1)} ><th colSpan={10}><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Semester 1</span>
                                        {(courseplan.data[0]) ? (
                                            courseplan.data[0].majorname ? (
                                                <span className="label label-inline label-warning float-right">
                                                    {courseplan.data[0].majorname}
                                                </span>
                                            ) : ""
                                        ) : ''}
                                    </th></tr>
                                    {courseplan.data.map((v, index) => {
                                        if (v.semester !== cSemester) {
                                            cSemester = v.semester;
                                            return (
                                                <>
                                                    <tr key={index} className='bg-light-primary collapse-header' onClick={(e) => openCollapse(v.semester)}  >
                                                        <th colSpan={10}>
                                                            <span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Semester {v.semester} </span>
                                                            {v.majorname ? (
                                                            <span className="label label-inline label-warning float-right">
                                                                {v.majorname} {(v.specname) ? '/' + v.specname : ''}
                                                            </span>
                                                            ) : ""}
                                                        </th>
                                                    </tr>
                                                    {appendTR(v)}
                                                </>);
                                        } else {
                                            return appendTR(v);
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : ""
                )
            )}

        </div>
    )
}
