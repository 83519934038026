import React from "react";

const Loading = () => {
  return (
    <button
      type="button"
      className="btn spinner spinner-darker-primary spinner-right"
    ></button>
  );
};

export default Loading;
