import React from 'react'
import Moment from "moment"

export function Overview(props) {
    const profile = (props.profile) ? props.profile : {};
    return (
        <div className="overview mb-10">
            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Full Name</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark">{profile.fullname}</span>
                </div>
            </div>

            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Gender</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark">{(profile.gender === 'p') ? 'Male':'Female'}</span>
                </div>
            </div>

            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Place and Birtdate</label>
                <div className="col-lg-8">
                    <span className="font-weight-bold font-size-lg text-dark">{((profile.birthplace) ? (profile.birthplace +', ') : '') + ((profile.birthdate) ? Moment(profile.birthdate).format('D MMMM YYYY') : '')}</span>
                </div>
            </div>

            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Address</label>
                <div className="col-lg-8">
                    {(Object.values(profile.reff_address).length > 0) ? (
                        profile.reff_address.map((v,index)=>(
                            <span key={index} className="d-block mb-3">
                                <span className="d-block font-weight-bold font-size-lg text-dark"> {v.address}</span>
                                <span className="d-block">
                                {(v.city) ? (
                                    <span className="font-weight-bold font-size-lg text-dark mr-3"> {v.city.name},</span>
                                ) : ''}
                                {(v.country) ? (
                                    <span className="font-weight-bold font-size-lg text-dark mr-3">{v.country.name},</span>
                                ) : ''}
                                {(v.postalcode) ? (
                                    <span className="font-weight-bold font-size-lg text-dark">Postal Code {v.postalcode}</span>
                                ) : ''}
                                </span>
                            </span>
                        ))
                    ) : ''}
                    
                </div>
            </div>

            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Contact</label>
                <div className="col-lg-8">
                    <span className="d-block font-weight-bold font-size-lg text-dark"> 
                        <i className="la la-mobile-phone"></i> {(profile.cellular) ? profile.cellular : '-'}
                    </span>
                    {(Object.values(profile.reff_address).length > 0) ? (
                        profile.reff_address.map((v,index)=>(
                            (v.email) ? (
                                <span className="font-weight-bold font-size-lg text-dark mr-3" key={index}> 
                                    <i className="la la-envelope-o"></i> {v.email}
                                </span>
                            ) : ''
                        ))
                    ) : ''}
                    
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <h3 className="mb-3">
                        <span className="card-label font-weight-bolder text-dark">{profile.firstname}'s Family</span>
                    </h3>
                    <div className="table-responsive">
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                            <thead>
                                <tr>
                                    <th>Family Name</th>
                                    <th>Profession</th>
                                    <th>Contact</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(profile.reff_family && Object.values(profile.reff_family).length > 0) ? (
                                    profile.reff_family.map((v,index)=>(
                                    <tr key={index}>
                                        <td>
                                            <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{v.fullname}</span>
                                            <span className="text-muted font-weight-bold d-block">{v.type}</span>
                                        </td>
                                        <td>{(v.profession) ? v.profession.name : '-'}</td>
                                        <td>-</td>
                                    </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colspan="4">No record found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Overview;