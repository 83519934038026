import React from 'react'

const GetLinkDoc = (cluid,cluspecid) => {
    var linkfile = "https://drive.google.com/drive/folders/1HhBMGmtzDySxTC3Nx0ZAcqSS7ixm8sfs?usp=sharing";
    if(cluid == 9){ //ACC
      linkfile = "https://drive.google.com/drive/folders/1R36IuewwdT-w6fu1DM1_l8axQ3AquP_z?usp=sharing";
    }else if (cluid === 10) { //PR
      linkfile = "https://drive.google.com/drive/folders/1gRXwxA5vTSqd4wg9MBIAFOayzYXhxYbE?usp=sharing";
    }else if (cluid === 3 && cluspecid === 33) { //HTM
      linkfile = "https://drive.google.com/drive/folders/1dGySark549C0lMOILlQngXb93Rq4QsRF?usp=sharing";
    }else if (cluid === 8) { //BM
      linkfile = "https://drive.google.com/drive/folders/1cZzyrKZxOWJp-4R39_LPK4mC7YrOI7K9?usp=sharing";
    }else if(cluid === 7){ //IE
      linkfile = "https://drive.google.com/drive/folders/1MQ9t1YdKvNfVkAh-An8PYxWVczpSKHZM?usp=sharing";
    }else if(cluid === 2){ //IT
      linkfile = "https://drive.google.com/drive/folders/1tN046NID8wwAooeClul_-87CIBGzxrrp?usp=sharing";
    }else if(cluid === 1){ //ME
      linkfile = "https://drive.google.com/drive/folders/1Q7kxRIDVew_dySTPnj_hTNjeAQ4oSqzK?usp=sharing";
    }else if(cluid === 6){ //BE
      linkfile = "https://drive.google.com/drive/folders/1MtEbE1I99yPXOE33CtmO5VmVfQgp7mqW?usp=sharing";
    }else if(cluid === 5){ //FT
      linkfile = "https://drive.google.com/drive/folders/18xJILGV2_0ieA8YmLzsZSDuTn8Im_IFH?usp=sharing";
    }else if(cluid === 4){ //CE - PCR
      linkfile = "https://drive.google.com/drive/folders/1Ksco4r67zqhH7Bm3_jN97GvU0BbiOjUH?usp=sharing";
    }else if(cluid === 18){ //CE - SEE
      linkfile = "https://drive.google.com/drive/folders/1Ksco4r67zqhH7Bm3_jN97GvU0BbiOjUH?usp=sharing";
    }
    return linkfile;
  }

  export {GetLinkDoc};
