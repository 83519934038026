import React, { useState, useEffect } from 'react'
import Moment from "moment";
import jwt_decode from "jwt-decode"
import LoadingBtn from '../../../../../Widgets/LoadingBtn';
import AxiosLibWithHeader2 from '../../../../../../Library/AxiosLibWithHeader2';
import TableData from './TableData';
import DetailAtdClass from '../submit-atd/DetailAtdClass';
import CardCurrentSectionHorizontal from '../CardCurrentSectionHorizontal';

export default function CardSessionList({ section, status }) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const datasection = Object.values(section).length > 0 ? section : null;
    const [actvSession, setActvSession] = useState((status) ? status : 2);

    const changeStatus = value => {
        var parameters = {
            "empid": AuthDecode.currentData.entityid,
            "section_id": datasection.section_id,
            "status": value
        }
        GET_ATD_LECTURER_BY_SECTION(parameters);
        setActvSession(value);
    }

    const [lstSession, setLstSession] = useState({
        loading: false,
        message: "",
        data: [],
        result: false
    });

    const GET_ATD_LECTURER_BY_SECTION = (parameters) => {
        setLstSession({ ...lstSession, loading: true });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": parameters,
            "target_data": "GET_ATD_LECTURER_BY_SECTION"
        };
        const postData = {
            keys: keys,
            body: body
        };
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setLstSession({
                            ...lstSession,
                            result: false,
                            message: response.error,
                            loading: false,
                        });
                    } else if ("message" in response.response) {
                        var msg = response.response.message.user;
                        setLstSession({
                            ...lstSession,
                            result: false,
                            message: msg,
                            loading: false,
                        });
                    } else {
                        var results = response.response.data;
                        if (results) {
                            results.sort((a, b) => Moment(b.start_dt) - Moment(a.start_dt));
                            console.log(results);
                            setLstSession({
                                ...lstSession,
                                result: true,
                                data: results,
                                message: "",
                                loading: false,
                            });
                        } else {
                            setLstSession({
                                ...lstSession,
                                result: true,
                                data: [],
                                message: "No record found",
                                loading: false,
                            });
                        }

                    }
                }
            );
    }

    const [selectedSession, setSelectedSession] = useState({});


    useEffect(() => {
        var parameters = {
            "empid": AuthDecode.currentData.entityid,
            "section_id": datasection.section_id,
            "status": actvSession
        }
        GET_ATD_LECTURER_BY_SECTION(parameters);
    }, [])

    return (
        (Object.values(selectedSession).length > 0) ? (
            <DetailAtdClass data={selectedSession} setSelectedSession={setSelectedSession} changeStatus={changeStatus} sections={datasection} />
        ) : (
            <div id="card-session-list" className='card card-custom gutter-b'>
                <div className="card-header bg-light">
                    <CardCurrentSectionHorizontal sections={datasection} />
                </div>
                <div className="card-body pt-5">
                    <div className="title-atd d-flex justify-content-between align-items-center mb-5">
                        <h3 className="d-flex align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                Session Class Attendance
                            </span>
                            <span className="text-muted mt-3 font-weight-bold font-size-sm">
                                List of your session
                            </span>
                        </h3>
                        <div className="form-group mb-0">
                            <label>Choose Status</label>
                            <select name="FStatus" className="form-control form-control-sm" onChange={(e) => changeStatus(e.target.value)} defaultValue={actvSession} >
                                <option value="">Choose Status Session</option>
                                <option value="3">Submitted</option>
                                <option value="4">Approved</option>
                                <option value="5">Submitted/Approved</option>
                            </select>
                        </div>
                    </div>

                    {(lstSession.loading) ? <LoadingBtn /> : ''}
                    <div className={((lstSession.loading) ? "d-none" : "d-block") + ""} id="card-bar" >
                        {(Object.values(lstSession.data).length > 0) ? (
                            <TableData data={lstSession.data} setSelectedSession={setSelectedSession} />
                        ) : (
                            <div className="border rounded p-5 text-center bg-light">
                                <span className="svg-icon svg-icon-primary svg-icon-2x">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z" fill="#000000" opacity="0.3" />
                                            <polygon fill="#000000" points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912" />
                                        </g>
                                    </svg>
                                </span>
                                <p className="text-primary mb-0">No record found</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    )
}

