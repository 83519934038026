import React, { useState } from "react";

const TableCurrentClass = ({ data }) => {
  const [open, setOpen] = useState(true);
  return (
    <div className="card card-custom card-fit card-border">
      <div className="card-header">
        <div className="card-title">
          <span className="svg-icon svg-icon-primary svg-icon-xl bg-light rounded-circle p-2 mr-2">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <defs />
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                />
                <path
                  d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                />
              </g>
            </svg>
          </span>
          <h3 className="card-label">Scoring Sheet</h3>
        </div>
        <div className="card-toolbar">
          <div
            className="d-flex justify-content-center align-items-center"
            onClick={() => setOpen(open ? false : true)}
          >
            <h4 className="mx-2 cursor-pointer">
              <span className="d-block">{data.course.coursetitle}</span>
              <span className="text-muted font-weight-bold font-size-sm mr-2">
                Credit {data.course.credit} SKS
              </span>
              <span className="text-muted font-weight-bold font-size-sm">
                · {data.course.code} · {data.section_id}
              </span>
            </h4>
            <button
              className="btn btn-sm btn-icon btn-light ml-3"
              type="button"
            >
              <i
                className={"la icon-2x la-angle-" + (open ? "up" : "down")}
              ></i>
            </button>
          </div>
        </div>
      </div>
      {open ? (
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-align-center">
              <tbody>
                <tr className="bg-light">
                  <th width={"20%"}>Section ID</th>
                  <th width={"20%"}>Course Code</th>
                  <th width={"20%"}>Credit</th>
                  <th width={"20%"}>Total Session</th>
                  <th width={"20%"}>Max Seat</th>
                  <th width={"20%"}>Corporate</th>
                </tr>
                <tr>
                  <td>{data.section_id}</td>
                  <td>{data.course.code}</td>
                  <td>{data.course.credit} SKS</td>
                  <td>{data.section_session}</td>
                  <td>{data.max_seat}</td>
                  <td>
                    {data.corporate
                      ? data.corporate.name
                        ? data.corporate.name
                        : "SGU"
                      : "SGU"}
                  </td>
                </tr>
                <tr className="bg-light">
                  <th colSpan={2}>Course Title</th>
                  <th colSpan={2}>Lecturer</th>
                  <th colSpan={2}>Student Department</th>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {data.course.coursetitle}
                  </td>
                  <td colSpan={2}>
                    <LectLabel data={data.lecturer} />
                  </td>
                  <td colSpan={2}>
                    <DeptLabel data={data.prereq} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const CardCurrentClass = ({ data }) => {
  return (
    <div className="row">
      <div className="col-12 col-lg-4">
        <div className="border bg-white">
        <BarProgress total={100} name={"Session"} />
        </div>
      </div>
      <div className="col-12 col-lg-4"></div>
      <div className="col-12 col-lg-4"></div>
    </div>
  );
};

const LectLabel = ({ data }) => {
  if (Object.values(data).length > 0) {
    return data.map((v, index) => (
      <span key={index} className="badge badge-light me-1">
        {v.emp ? (v.emp.fullname ? v.emp.fullname : "-") : "-"}(
        {v.emp_submitted}/{v.emp_session})
      </span>
    ));
  } else {
    return "-";
  }
};

const DeptLabel = ({ data }) => {
  const ItemLabel = ({ item }) => {
    return (
      <span>
        <span className="dept-name">
          <span className="name">{item.clu ? item.clu.name : ""}</span>
          {item.clu_spec ? (
            <span className="spec_name">({item.clu_spec.code})</span>
          ) : (
            ""
          )}
          <span className="semester ml-1">(Semester {item.semester})</span>
        </span>
      </span>
    );
  };
  if (Object.values(data).length > 1) {
    return (
      <ul className="pl-5">
        {data.map((v, index) => (
          <li key={index}>
            <ItemLabel item={v} />
          </li>
        ))}
      </ul>
    );
  } else if (Object.values(data).length === 1) {
    return data.map((v, index) => <ItemLabel item={v} key={index} />);
  } else {
    return "-";
  }
};

const BarProgress = ({total, name}) => {
  return (
    <svg
      id="SvgjsSvg1933"
      width="306"
      height="176.25973510742188"
      version="1.1"
      className="apexcharts-svg"
      transform="translate(0, 0)"
      style={{ background: "transparent" }}
    >
      <g
        id="SvgjsG1935"
        className="apexcharts-inner apexcharts-graphical"
        transform="translate(41, 0)"
      >
        <defs id="SvgjsDefs1934">
          <clipPath id="gridRectMaskqmv3lxjd">
            <rect
              id="SvgjsRect1937"
              width="232"
              height="250"
              x="-3"
              y="-1"
              rx="0"
              ry="0"
              opacity="1"
              stroke-width="0"
              stroke="none"
              stroke-dasharray="0"
              fill="#fff"
            ></rect>
          </clipPath>
          <clipPath id="gridRectMarkerMaskqmv3lxjd">
            <rect
              id="SvgjsRect1938"
              width="230"
              height="252"
              x="-2"
              y="-2"
              rx="0"
              ry="0"
              opacity="1"
              stroke-width="0"
              stroke="none"
              stroke-dasharray="0"
              fill="#fff"
            ></rect>
          </clipPath>
          <filter
            id="SvgjsFilter1952"
            filterUnits="userSpaceOnUse"
            width="200%"
            height="200%"
            x="-50%"
            y="-50%"
          >
            <feComponentTransfer
              id="SvgjsFeComponentTransfer1953"
              result="SvgjsFeComponentTransfer1953Out"
              in="SourceGraphic"
            >
              <feFuncR
                id="SvgjsFeFuncR1954"
                type="linear"
                slope="0.5"
              ></feFuncR>
              <feFuncG
                id="SvgjsFeFuncG1955"
                type="linear"
                slope="0.5"
              ></feFuncG>
              <feFuncB
                id="SvgjsFeFuncB1956"
                type="linear"
                slope="0.5"
              ></feFuncB>
              <feFuncA id="SvgjsFeFuncA1957" type="identity"></feFuncA>
            </feComponentTransfer>
          </filter>
        </defs>
        <g id="SvgjsG1939" className="apexcharts-radialbar">
          <g id="SvgjsG1940">
            <g id="SvgjsG1941" className="apexcharts-tracks">
              <g
                id="SvgjsG1942"
                className="apexcharts-radialbar-track apexcharts-track"
                rel="1"
              >
                <path
                  id="apexcharts-radialbarTrack-0"
                  d="M 29.71097560975609 112.99999999999999 A 83.28902439024391 83.28902439024391 0 0 1 196.28902439024392 113"
                  fill="none"
                  fill-opacity="1"
                  stroke="rgba(225,240,255,0.85)"
                  stroke-opacity="1"
                  stroke-linecap="round"
                  stroke-width="10.636585365853659"
                  stroke-dasharray="0"
                  className="apexcharts-radialbar-area"
                ></path>
              </g>
            </g>
            <g id="SvgjsG1944">
              <g
                id="SvgjsG1949"
                className="apexcharts-series apexcharts-radial-series"
                seriesName={name}
                rel="1"
              >
                <path
                  id="SvgjsPath1950"
                  d="M 29.71097560975609 112.99999999999999 A 83.28902439024391 83.28902439024391 0 0 1 169.80297804535178 52.08626370794575"
                  fill="none"
                  fill-opacity="0.85"
                  stroke="rgba(54,153,255,0.85)"
                  stroke-opacity="1"
                  stroke-linecap="round"
                  stroke-width="10.636585365853659"
                  stroke-dasharray="0"
                  className="apexcharts-radialbar-area apexcharts-radialbar-slice-0"
                  selected="true"
                  index="0"
                  j="0"
                ></path>
              </g>
              <circle
                id="SvgjsCircle1945"
                r="77.97073170731709"
                cx="113"
                cy="113"
                className="apexcharts-radialbar-hollow"
                fill="transparent"
              ></circle>
              <g
                id="SvgjsG1946"
                className="apexcharts-datalabels-group"
                transform="translate(0, 0) scale(1)"
                style={{ opacity: 1 }}
              >
                <text
                  id="SvgjsText1947"
                  font-family="Helvetica, Arial, sans-serif"
                  x="113"
                  y="108"
                  text-anchor="middle"
                  dominant-baseline="auto"
                  font-size="13px"
                  font-weight="700"
                  fill="#b5b5c3"
                  className="apexcharts-text apexcharts-datalabel-label"
                  style={{ fontFamily: "Helvetica, Arial, sans-serif" }}
                >
                  {name}
                </text>
                <text
                  id="SvgjsText1948"
                  font-family="Helvetica, Arial, sans-serif"
                  x="113"
                  y="89"
                  text-anchor="middle"
                  dominant-baseline="auto"
                  font-size="30px"
                  font-weight="700"
                  fill="#5e6278"
                  className="apexcharts-text apexcharts-datalabel-value"
                  style={{ fontFamily: "Helvetica, Arial, sans-serif" }}
                >
                  {total}%
                </text>
              </g>
            </g>
          </g>
        </g>
        <line
          id="SvgjsLine1958"
          x1="0"
          y1="0"
          x2="226"
          y2="0"
          stroke="#b6b6b6"
          stroke-dasharray="0"
          stroke-width="1"
          className="apexcharts-ycrosshairs"
        ></line>
        <line
          id="SvgjsLine1959"
          x1="0"
          y1="0"
          x2="226"
          y2="0"
          stroke-dasharray="0"
          stroke-width="0"
          className="apexcharts-ycrosshairs-hidden"
        ></line>
      </g>
      <g id="SvgjsG1936" className="apexcharts-annotations"></g>
    </svg>
  );
};

export { TableCurrentClass, CardCurrentClass };
