const staging_menu_lect = [
    {
        "ID": "69",
        "Name": "Thesis Information",
        "Description": "",
        "PathURI": "/thesis",
        "ParentID": "0",
        "Position": "9",
        "IsActive": "1",
        "Icon": "<svg width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" version=\"1.1\">\r\n    <g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\r\n        <rect x=\"0\" y=\"0\" width=\"24\" height=\"24\"/>\r\n        <path d=\"M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z\" fill=\"#000000\"/>\r\n        <path d=\"M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z\" fill=\"#000000\" opacity=\"0.3\"/>\r\n    </g>\r\n</svg>",
        "Type": null,
        "BgColor": null,
        "RoleID": "1",
        "Slug": null,
        "URI": null,
        "IsOpen": null,
        "Child": [
            {
                "ID": "71",
                "Name": "Thesis Defense",
                "Description": "",
                "PathURI": "/thesis/defense",
                "ParentID": "69",
                "Position": "1",
                "IsActive": "1",
                "Icon": "",
                "Type": "local",
                "BgColor": null,
                "RoleID": "1",
                "Slug": "ThesisScore",
                "URI": "/defense"
            },
            {
                "ID": "70",
                "Name": "Thesis Activity",
                "Description": "",
                "PathURI": "/thesis/activity",
                "ParentID": "69",
                "Position": "2",
                "IsActive": "1",
                "Icon": "",
                "Type": "local",
                "BgColor": null,
                "RoleID": "1",
                "Slug": "ThesisActivityLCT",
                "URI": "/activity"
            }
        ]
    }
];

const staging_menu_std = [
    {
        "ID": "69",
        "Name": "Thesis Information",
        "Description": "",
        "PathURI": "/thesis",
        "ParentID": "0",
        "Position": "9",
        "IsActive": "1",
        "Icon": "<svg width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" version=\"1.1\">\r\n    <g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\r\n        <rect x=\"0\" y=\"0\" width=\"24\" height=\"24\"/>\r\n        <path d=\"M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z\" fill=\"#000000\"/>\r\n        <path d=\"M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z\" fill=\"#000000\" opacity=\"0.3\"/>\r\n    </g>\r\n</svg>",
        "Type": null,
        "BgColor": null,
        "RoleID": "1",
        "Slug": null,
        "URI": null,
        "IsOpen": null,
        "Child": [
            {
                "ID": "71",
                "Name": "Thesis Information",
                "Description": "",
                "PathURI": "/thesis/information",
                "ParentID": "69",
                "Position": "1",
                "IsActive": "1",
                "Icon": "",
                "Type": "local",
                "BgColor": null,
                "RoleID": "1",
                "Slug": "ThesisInfoSTD",
                "URI": "/information"
            },
            {
                "ID": "70",
                "Name": "Thesis Activity",
                "Description": "",
                "PathURI": "/thesis/activity",
                "ParentID": "69",
                "Position": "2",
                "IsActive": "1",
                "Icon": "",
                "Type": "local",
                "BgColor": null,
                "RoleID": "1",
                "Slug": "ThesisActivitySTD",
                "URI": "/activity"
            }
        ]
    }
];

export { staging_menu_std, staging_menu_lect }
