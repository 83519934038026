import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode"
import { Link } from 'react-router-dom'
import UserPicBoy from '../../../media/avatars/001-boy.svg'
import AxiosLibWithHeader2 from '../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../Page/Widgets/LoadingBtn';

export function Header() {
    const token = localStorage.getItem("AuthToken");
    const AuthDecode = jwt_decode(token);
    //const param = props.data;
    // const acadAct = (param) ? param.Acad : {};
    // const [acad, setAcad] = useState();

    // useEffect(() => {
    //     if (param.RoleID === 1) {
    //         const acadType = (param) ? param.AcadType : '';
    //         var selectIndex = (acadAct) ? (acadAct.findIndex(v => v.atp_group_type.id === acadType)) : 0;
    //         if (selectIndex !== '-1') {
    //             setAcad(acadAct[selectIndex]);
    //         }
    //     }
    // })
    const [stdStatus, setStdStatus] = useState({ loading: false, data: [], message: "" });
    const GET_STUDENT_STATUS = () => {
        setStdStatus({ loading: true, data: [], message: "" })
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
            },
            target_data: "GET_STUDENT_STATUS",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdStatus({ loading: false, data: [], message: response.error })
            } else if ("message" in response.response) {
                setStdStatus({ loading: false, data: [], message: response.response.message.user })
            } else {
                setStdStatus({ loading: false, data: response.response.data, message: "" })
            }
        });
    }

    const [acad, setAcad] = useState({ loading: false, data: [], message: "" });
    const GET_ACTIVE_ACAD = () => {
        setAcad({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            target_data: "GET_ACTIVE_ACAD",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setAcad({ loading: false, data: [], message: response.error })
                //window.location.replace('/sign-out');
            } else if ("message" in response.response) {
                setAcad({ loading: false, data: [], message: response.response.message.user });
                //window.location.replace('/sign-out');
            } else {
                if (response.response.data) {
                    var result = response.response.data;
                    var value = result.filter(prev => prev.atp_id_term !== 116);
                    setAcad({ loading: false, data: value, message: "" })
                }
            }
        });
    }

    useEffect(() => {
        GET_ACTIVE_ACAD();
        if (AuthDecode.currentData.roleid === 1) {
            GET_STUDENT_STATUS();
        }
    }, []);



    return (
        <div id="kt_header" className="header header-fixed">
            <div className="container-fluid d-flex align-items-stretch justify-content-between">
                <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                    <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                        <ul className="menu-nav">
                            {(AuthDecode.currentData.roleid === 1) ? (
                                stdStatus.loading ? (
                                    <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                                        <LoadingBtn />
                                    </li>
                                ) : (
                                    stdStatus.message ? (
                                        <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                                            <div className="alert alert-custom alert-light-danger py-1 px-5" role="alert">
                                                <div className="alert-text">
                                                    <div className="d-flex align-items-center">
                                                        <i className="flaticon-warning text-danger font-weight-bold"></i> 
                                                        <span className="mx-2 font-weight-bold">{stdStatus.message}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ) : (
                                        Object.keys(stdStatus.data).length > 0 ? (
                                            <>
                                                <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                                                    <span className="menu-link bg-light-primary text-primary">
                                                        <span className="menu-text text-primary"><i className="fas fa-tag text-primary mr-2"></i> Program  {(stdStatus.data.program) ? stdStatus.data.program.name : "-"}</span>
                                                    </span>
                                                </li>
                                                <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                                                    <span className="menu-link bg-light-primary text-primary">
                                                        <span className="menu-text text-primary"><i className="fas fa-tag text-primary mr-2"></i> Academic Calendar: {(stdStatus.data.atp_term) ? stdStatus.data.atp_term.name : "-"} </span>
                                                    </span>
                                                </li>
                                            </>
                                        ) : ""
                                    )
                                )

                            ) : (
                                acad.loading ? (
                                    <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                                        <LoadingBtn />
                                    </li>
                                ) : (
                                    acad.message ? (
                                        <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                                            <div className="alert alert-custom alert-light-danger py-1 px-5" role="alert">
                                                <div className="alert-text">
                                                    <div className="d-flex align-items-center">
                                                        <i className="flaticon-warning text-danger font-weight-bold"></i> 
                                                        <span className="mx-2 font-weight-bold">{acad.message}</span>
                                                        <span className="badge badge-danger cursor-pointer" onClick={()=>GET_ACTIVE_ACAD()}>
                                                            <i className="ki ki-refresh text-white"></i>
                                                            <span className="ml-1">Try again</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ) : (
                                        Object.values(acad.data).length ? (
                                            <>
                                                <li className="menu-item menu-item-submenu menu-item-rel menu-item-active">
                                                    <span className="menu-link bg-light-primary text-primary">
                                                        <span className="menu-text text-primary"><i className="fas fa-tag text-primary mr-2"></i> Academic Calendar: </span>
                                                    </span>
                                                </li>
                                                {acad.data.map((v, index) => (
                                                    <li className="menu-item menu-item-submenu menu-item-rel menu-item-active" key={index}>
                                                        <span className="menu-link bg-light-primary">
                                                            <span className="menu-text">{v.atp_term ? (v.atp_term.name ? v.atp_term.name : "-") : ""}</span>
                                                        </span>
                                                    </li>
                                                ))}
                                            </>
                                        ) : ""
                                    )
                                )
                            )}
                        </ul>
                    </div>
                </div>

                <div className="topbar">
                    <div className="dropdown">
                        <div className="topbar-item" data-toggle="dropdown" data-offset="0px,0px">
                            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2">
                                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{AuthDecode.identity.given_name}</span>
                                <span className="symbol symbol-lg-35 symbol-25 symbol-light-success">
                                    <span className="symbol-label font-size-h5 font-weight-bold">{AuthDecode.identity.given_name.charAt(0).toUpperCase()}</span>
                                </span>
                            </div>
                        </div>
                        <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
                            <div className="d-flex align-items-center p-8 rounded-top">
                                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                                    <img src={UserPicBoy} alt={"pic-" + AuthDecode.identity.given_name} className="p-1" />
                                </div>
                                <div className="d-flex flex-column">
                                    <Link to="/profile" title="View profile" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary text-capitalize">{AuthDecode.identity.given_name + " " + AuthDecode.identity.family_name}</Link>
                                    <div className="text-muted mt-1 text-capitalize">{AuthDecode.identity.role}</div>
                                </div>
                            </div>
                            <div className="separator separator-solid"></div>
                            <div className="navi navi-spacer-x-0 pt-0">
                                <div className="navi-footer">
                                    <Link to="/sign-out" className="btn btn-light-primary font-weight-bold">Sign Out</Link>
                                    <a href="https://wa.me/message/NNSYT7PGEZM5E1" target="_blank" className="btn btn-clean font-weight-bold">Change Password</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
