import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import LoadingBtn from '../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../Library/AlertNotif';
import TableActivity from './TableActivity';
import AxiosLibWithHeader2 from '../../../Library/AxiosLibWithHeader2';

export class CounselingLect extends Component {
    constructor(props) {
        super(props);
        this.getCounseling = this.getCounseling.bind(this);
        this.splitCounselingByStatus = this.splitCounselingByStatus.bind(this);
        this.GroupByKey = this.GroupByKey.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            Acad: [],
            AuthDecode: AuthDecode,
            Loading: false,
            CounselingList: [],
            ResultCompare: [],
            Error: "",
            SelectedTab: "PendingApproved",
            NavTab: [{ id: 1, name: "All", key: "All" }, { id: 2, name: "Pending Approval", key: "PendingApproved" },
            { id: 3, name: "Approved", key: "Approved" }, { id: 4, name: "Cancelled", key: "Cancelled" },
            { id: 5, name: "Not Active", key: "NotActive" }, { id: 6, name: "Finish", key: "Finished" }]
        };
    }

    getCounseling() {
        this.setState({ Loading: true, CounselingList: [], Error: "" });
        const reqModule = () => {
            var keys = {
                "AppId": this.state.AuthDecode.AppID,
                "AppEnv": this.state.AuthDecode.AppEnv,
                "Secretkey": this.state.AuthDecode.secretkey
            };
            var body = {
                "parameters": {
                    empid: this.state.AuthDecode.currentData.entityid
                },
                "target_data": "GET_COUNSELING_BY_LECTURER"
            };
            const postData = {
                keys: keys,
                body: body
            };

            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        if (response.error) {
                            this.setState({ Error: response.error, CounselingList: [], ResultCompare: [], Loading: false });
                        } else if ("message" in response.response) {
                            this.setState({ Error: response.response.message.user, CounselingList: [], ResultCompare: [], Loading: false });
                        } else {
                            var param = response.response.data;
                            let result = {}; let leaveArr = [];
                            if (param) {
                                param.sort((a, b) => (a.studentid > b.studentid ? 1 : -1));
                                var objCounseling = [...param];
                                // var objCounselingLeave = [...param];
                                // //leave 
                                // leaveArr = objCounselingLeave.filter((prev) => {
                                //     if (prev.student_activity) {
                                //         if(prev.student_activity.id === "asc.student.activity.leave"){
                                //             return prev;
                                //         }
                                //     }
                                // });
                                // var splitLeave = this.splitCounselingByStatus(leaveArr);
                                // console.log(splitLeave);
                                //pcf
                                result = this.splitCounselingByStatus(objCounseling);
                                this.setState({ CounselingList: param, Loading: false, ResultCompare: result, Error: "" });
                            }
                        }
                    }
                );
        }
        reqModule();
    }

    GroupByKey(list, key) {
        var result = list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})
        return result;
    }

    splitCounselingByStatus(objCounseling) {
        let objIsactive = [1];
        let param = {};

        if (objCounseling) {
            //console.log(objCounseling);
            objCounseling = objCounseling.filter(o => o.studentstatusdata !== null).map(o => o);
            //console.log(parsing);
            objCounseling.sort(function (a, b) {
                if (a.studentstatusdata.fullname < b.studentstatusdata.fullname) { return -1; }
                if (a.studentstatusdata.fullname > b.studentstatusdata.fullname) { return 1; }
                return 0;
            })

            //grouped by studentid
            var objA = objCounseling.filter(o => objIsactive.find(x => x === o.studentstatusdata.is_active)).map(o => o);
            param.All = this.GroupByKey(objA, 'studentid');

            var objP = objCounseling.filter(o => objIsactive.find(x => (x === o.studentstatusdata.is_active && o.event_status === "SUBMITTED"))).map(o => o);
            param.PendingApproved = this.GroupByKey(objP, 'studentid');

            var objAP = objCounseling.filter(o => objIsactive.find(x => (x === o.studentstatusdata.is_active && o.event_status === "PROCESSED"))).map(o => o);
            param.Approved = this.GroupByKey(objAP, 'studentid');

            var objC = objCounseling.filter(o => objIsactive.find(x => (x === o.studentstatusdata.is_active && o.event_status === "CANCELLED"))).map(o => o);
            param.Cancelled = this.GroupByKey(objC, 'studentid');

            var objNA = objCounseling.filter(o => objIsactive.find(x => (x === o.studentstatusdata.is_active && !o.eventid))).map(o => o);
            param.NotActive = this.GroupByKey(objNA, 'studentid');

            var objF = objCounseling.filter(o => !objIsactive.find(x => (x === o.studentstatusdata.is_active))).map(o => o);
            param.Finished = this.GroupByKey(objF, 'studentid');
            //end grouped
        }

        return param;

    }

    componentDidMount() {
        this.getCounseling();
    }
    render() {
        return (
            <div id='conseling'>
                <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                    <div className="alert-icon">
                        <span className="svg-icon svg-icon-primary svg-icon-xl">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                    <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div className="d-flex align-items-center mr-2 py-2">
                        <h3 className="font-weight-bold mb-0 mr-10">Counseling Status</h3>
                        <div className="d-flex mr-3">
                            <div className="navi navi-hover navi-active navi-link-rounded navi-bold d-flex flex-row">
                                {this.state.NavTab.map((n, index) => (
                                    <div className="navi-item mr-2" key={index} onClick={()=>this.setState({SelectedTab:n.key})}>
                                        <span className={"navi-link "+(n.key === this.state.SelectedTab ? "active":"cursor-pointer")}>{n.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.Loading ? <LoadingBtn /> : (
                    this.state.Error ? <AlertNotif message={this.state.Error} color={"danger"} /> : (
                        Object.values(this.state.ResultCompare).length > 0 ? (
                            // Object.keys(this.state.ResultCompare[this.state.SelectedTab]).map((v, index) => (
                            //     <p key={index}>{v}</p>
                            // ))
                            <TableActivity data={this.state.ResultCompare[this.state.SelectedTab]} />
                        ) : ""
                    ))}

            </div>
        )
    }
}
