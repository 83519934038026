import React from 'react'
import LogoComp from "../../../../media/logos/logo-sgu.png"
import LoadingBtn from '../../../Page/Widgets/LoadingBtn'

const LoadingMobile = () => {
  return (
    <div className='text-center'>
        <img src={LogoComp} alt="sgu" width={100} />
      <LoadingBtn />
    </div>
  )
}

export {LoadingMobile}