import React, { useEffect, useMemo, useState } from "react";
import jwt_decode from "jwt-decode";
import LoadingBtn from "../../../../Widgets/LoadingBtn";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import { BtnAddNewComponents, RowCompNew, RowCompOri } from "./ScoreActions";
import { AlertNotif } from "../../../../../Library/AlertNotif";
import { openModal } from "../../../../../Library/ModalPopUp";
import { MessageFailedScore } from "../ScoringComponents";
import { CREATE_APPEND_SECTION_COM, DELETE_SECTION } from "../Apis";
import { useHistory } from "react-router-dom";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function UpdateComp({
  section_id,
  HandlerBack,
  HandlerProgressBar,
}) {
  const [section, setSection] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [scoreComp, setScoreComp] = useState([]);
  const [totalWeight, setTotalWeight] = useState([]);
  const [totalSummative, setTotalSummative] = useState(0);
  const [totalFormative, setTotalFormative] = useState(0);

  const ReloadData = () => {
    GET_SECTION(setSection, setScoreComp, section_id);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const ResultData = useMemo(() => {
    let componentAdd = scoreComp.filter(item => item.action_type === "add")
    let computedData = componentAdd.map((v) => {
      var obj = {};
      obj.id = v.id;
      obj.weight = v.weight;
      obj.wg_com_id = v.wg_com_id;
      obj.wg_com_name = v.name || "";
      obj.wg_com_type = v.wg_com ? v.wg_com.type || "" : "";
      obj.ori = "ori" in v ? v.ori : true;
      obj.action_type = v.action_type || "";
      return obj;
    });

    const tWeight = computedData.reduce(
      (acc, obj) => acc + obj.weight * 100,
      0
    );
    setTotalWeight(tWeight);

    //sorting
    computedData.sort((a, b) => (a.wg_com_id > b.wg_com_id ? 1 : -1));

    //calculate total type component
    const tSummative = computedData.filter(
      (item) => item.wg_com_type === "Summative"
    );
    setTotalSummative(Object.values(tSummative).length);
    const tFormative = computedData.filter(
      (item) => item.wg_com_type === "Formative"
    );
    setTotalFormative(Object.values(tFormative).length);

    return computedData;
  }, [scoreComp, totalSummative, totalFormative]);

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const [delCom, setDelCom] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const HandlerSubmit = () => {
    const newData = [...scoreComp];
    const dataOnlyAdd = newData.filter((item) => item.action_type === "add");
    setSubmit({loading:true, data:[], message:""});
    AppendComponent(dataOnlyAdd);
  };

  const AppendComponent = (newData) => {
    const findEmptyName = newData.filter(
      (item) => item.name === "" || item.wg_com.type === ""
    );
    const findEmptyWeight = newData.filter(
      (item) => item.weight === 0 || item.wg_com.type === ""
    );
    if (Object.values(findEmptyName).length > 0) {
      openModal({
        header: "Error",
        message: (
          <MessageFailedScore message={"Name of component cannot be empty"} />
        ),
      });
      setSubmit({loading:false, data:[], message:""});
    } else if (Object.values(findEmptyWeight).length > 0) {
      openModal({
        header: "Error",
        message: (
          <MessageFailedScore message={"Component weight cannot be 0"} />
        ),
      });
      setSubmit({loading:false, data:[], message:""});
    } else {
      const arrComponent = newData.map((v) => {
        var obj = {};
        obj.weight = v.weight * 100;
        obj.name = encodeURIComponent(v.name);
        obj.type = v.wg_com.type;
        obj.wg_com_id = v.wg_com_id;
        return obj;
      });

      const paramObj = { component: arrComponent, section_id: section_id };
      CREATE_APPEND_SECTION_COM(paramObj, setSubmit, HandlerProgressBar);
    }
  };

  const RemoveComponent = (data) => {
    const removeList = data.map((item) => {
      const paramdelete = {
        deletetype: "com",
        reff_id: item.id,
        section_id: section_id,
      };
      DELETE_SECTION(paramdelete, setDelCom, ReloadData);
    });
  };

  const ConfirmLessWeight = () => {
    openModal({
      header: "",
      message: (
        <MessageFailedScore
          message={
            "The total weight is less than 100%, please adjust and save it before leaving this page."
          }
        />
      ),
    });
  };

  const history = useHistory();

  useEffect(() => {
    const handlePopState = () => {
      if (totalWeight === 100) {
        window.history.go(-1);
        return false;
      } else {
        ConfirmLessWeight();
      }
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [history, totalWeight]);

  return (
    <div className="card">
      <div className="card-body p-0">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <div
              className="nav-link py-5 text-muted text-hover-primary cursor-pointer border-0"
              title="Back"
              onClick={() =>
                totalWeight === 100 ? HandlerBack() : ConfirmLessWeight()
              }
            >
              <i className="fas fa-angle-left"></i>
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-link active">
              <i className="flaticon-cogwheel-2"></i>
              <span className="font-weight-bolder ml-2">
                Grading Scheme Setting
              </span>
            </div>
          </li>
        </ul>
        <div className="w-50 m-auto pt-10">
          <div className="">
            {delCom.message ? (
              <div className="mt-5">
                <AlertNotif color={"danger"} message={delCom.message} />
              </div>
            ) : (
              ""
            )}

            <div className="table-responsive">
              <table className="table table-head-bg table-bordered table-vertical-center">
                <thead>
                  <tr className="text-left text-dark text-uppercase">
                    <th width="45%">Name</th>
                    <th width="25%">Type</th>
                    <th>Weight</th>
                  </tr>
                </thead>
                <tbody>
                  {section.loading ? (
                    <tr>
                      <td colSpan={4}>
                        <LoadingBtn />
                      </td>
                    </tr>
                  ) : section.message ? (
                    <tr>
                      <td colSpan={4}>{section.message}</td>
                    </tr>
                  ) : ResultData.length > 0 ? (
                    ResultData.map(
                      (v) =>
                        v.action_type === "add" &&
                        (v.ori === true ? (
                          <RowCompOri
                            data={v}
                            comp={scoreComp}
                            setScoreComp={setScoreComp}
                            section_id={section_id}
                            setDelCom={setDelCom}
                            ReloadData={ReloadData}
                          />
                        ) : (
                          <RowCompNew
                            data={v}
                            scoreComp={scoreComp}
                            setScoreComp={setScoreComp}
                          />
                        ))
                    )
                  ) : (
                    ""
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="border-0" colSpan={2}>
                      <BtnAddNewComponents
                        scoreComp={scoreComp}
                        setScoreComp={setScoreComp}
                      />
                    </td>
                    <td className="border-0" colSpan={2}>
                      <span className="font-weight-bolder mr-2">
                        Total Weight:
                      </span>
                      <span
                        className={
                          "font-weight-bolder " +
                          (totalWeight !== 100 ? "text-danger" : "")
                        }
                      >
                        {totalWeight}%
                      </span>
                      <span className="ml-1">
                        <i
                          className={
                            "icon-md ml-2 " +
                            (totalWeight !== 100
                              ? "fas fa-times-circle text-danger "
                              : "flaticon2-correct text-success ")
                          }
                        ></i>
                      </span>
                    </td>
                  </tr>
                  {submit.message ? (
                    <tr>
                      <td colSpan={4} className="border-0">
                        <AlertNotif message={submit.message} color={"danger"} />
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {totalSummative > 14 || totalFormative > 14 ? (
                    <tr>
                      <td colSpan={4} className="border-0">
                        <AlertNotif
                          message={
                            "Total component Summative/Formative have exceeded the limit"
                          }
                          color={"danger"}
                        />
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td colSpan={4} className="text-right border-0">
                      <div className="btn btn-group">
                        <button
                          className="btn btn-lg btn-light font-weight-bolder"
                          type="button"
                          disabled={totalWeight === 100 ? false : true}
                          onClick={() => HandlerBack()}
                        >
                          Cancel
                        </button>
                        {totalSummative > 14 || totalFormative > 14 || (
                          <button
                            className="btn btn-lg btn-primary font-weight-bolder"
                            type="button"
                            onClick={() => HandlerSubmit()}
                            disabled={
                              totalWeight === 100
                                ? submit.loading
                                  ? true
                                  : false
                                : true
                            }
                          >
                            {submit.loading ? "Processing..." : "Save Changes"}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const GET_SECTION = (setSection, setListData, section_id) => {
  setSection({ loading: true, message: "", data: [] });
  setListData([]);
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };
  var body = {
    parameters: {
      section_id: section_id,
    },
    target_data: "GET_SECTION",
  };
  const postData = {
    keys: keys,
    body: body,
  };
  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    if (response.error) {
      setSection({
        data: [],
        message: response.error,
        loading: false,
      });
      setListData([]);
    } else if ("message" in response.response) {
      var msg = response.response.message.internal;
      setSection({ data: [], message: msg, loading: false });
      setListData([]);
    } else {
      var results = response.response.data;
      if (results) {
        if (results[0].section_com) {
          const scr_comp = results[0].section_com.map((v) => {
            v.action_type = "add";
            return v;
          });
          setListData(scr_comp);
        }
        setSection({ data: results, message: "", loading: false });
      } else {
        setSection({
          message: "No record found",
          loading: false,
          data: [],
        });
        setListData([]);
      }
    }
  });
};
