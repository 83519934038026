import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import "./TableStudentScore.css";
import { AlertNotif } from "../../../../Library/AlertNotif";
import { openModal } from "../../../../Library/ModalPopUp";
import {
  ConfirmLeaveSubmitScore,
  MessageAlertScore,
} from "./ScoringComponents";
import { SCR_SET_INCOMPLETE_BY_LEC, SUBMIT_SCR_STD_BY_LEC_COM } from "./Apis";
import { FormSingleComponentUpdate } from "./template-score/ScoreActions";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function TableStudentScore(props) {
  const {
    data,
    components,
    scrStudent,
    setScrStudent,
    ReloadData,
    sectionInfo,
    section_id,
    HandlerProgressBar,
  } = props;
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const sortedData = components.sort((a, b) => {
    if (a.wg_com.type < b.wg_com.type) return -1;
    if (a.wg_com.type > b.wg_com.type) return 1;
  
    return a.wg_com.id - b.wg_com.id;
  });

  const totalWeight = components.reduce((total, component) => {
    return total + (component.weight * 100);
  }, 0);

  const HandlerSubmit = () => {
    const com_id = selectedColumn;
    const myempid = AuthDecode.currentData.entityid;
    const findMeOnThisSection = sectionInfo.lecturer.filter(
      (item) => item.emp_id == myempid
    );
    if (Object.values(findMeOnThisSection).length > 0) {
      if (com_id) {
        const cloneScore = [...scrStudent];
        var objScr = [];
        cloneScore.map((v) => {
          //console.log(v.studentid,v.atd);
          if (v.atd < 25 || v.atd === 25) {
            console.log(v.studentid,v.atd);
            if (Object.values(v.student_course_com).length > 0) {
              const getScore = v.student_course_com.find(
                (prev) => prev.wg_com.id === com_id
              );
              objScr.push({
                studentid: v.studentid.toString(),
                score: typeof 0 === getScore.score ? 0 : getScore.score,
              });
            }
          }
        });
        if (Object.values(objScr).length > 0) {
          let objParam = {
            section_id: section_id,
            wg_com_id: com_id,
            student: objScr,
          };

          // console.log(objParam);
          const isScoreNull = objParam.student.find((item) => item.score === "");
          if (isScoreNull) {
            openModal({
              header: "Error",
              message: (
                <MessageAlertScore
                  message={
                    "Score cannot be empty. All fields must be filled before saving."
                  }
                />
              ),
            });
          } else {
            // check if numeric or letter
            SUBMIT_SCR_STD_BY_LEC_COM(objParam, setSubmit, ReloadData);
          }
        } else {
          openModal({
            header: "Error",
            message: <MessageAlertScore message={"No data post"} />,
          });
        }
      } else {
        openModal({
          header: "Error",
          message: <MessageAlertScore message={"Component id is not defined"} />,
        });
      }
    } else {
      openModal({ header: "Attention", message: <MessageAlertScore message={"You are not allowed to submit this score"} /> });
    }
  };

  const HandlerInputScore = (studentId, id, compId, value) => {
    if (value > 100) {
      openModal({
        header: "Value too high",
        message: (
          <MessageAlertScore
            message={"The score cannot exceed 100. Please input a value between 0 and 100."}
          />
        ),
      });
      return;
    }
    if (value || Number(value) === 0) {
      const updatedScores = scrStudent.map((student) => {
        if (student.studentid === studentId) {
          const scoreIndex = student.student_course_com.findIndex(
            (scc) => scc.wg_com.id === compId && scc.id === id
          );
          if (scoreIndex > -1) {
            student.student_course_com[scoreIndex].score = Number(value) || 0;
          } else {
            alert("Ooopss ");
          }
        }
        return student;
      });
      setScrStudent(updatedScores);
    }
  };

  const handleColumnClick = (wg_com_id, studentid) => {
    if ((totalWeight * 100).toFixed(0) === 100) {
      openModal({
        header: "Information",
        message: (
          <p>
            Total weight ({(totalWeight * 100).toFixed(0)}%) is{" "}
            {totalWeight < 1 ? "less than" : "over"} 100%, please update the
            weights of components
          </p>
        ),
      });
    } else {
      if (wg_com_id) {
        if (selectedColumn && selectedColumn !== wg_com_id) {
          var objScr = [];
          const findScoreCom = scrStudent.map((v) => {
            if (v.atd < 25) {
              if (Object.values(v.student_course_com).length > 0) {
                const getScore = v.student_course_com.find(
                  (prev) => prev.wg_com.id === selectedColumn
                );
                objScr.push({
                  studentid: v.studentid.toString(),
                  score: typeof 0 === getScore.score ? 0 : getScore.score,
                });
              }
            }
          });

          const FindNullScore = objScr.every((item) => item.score === "");
          //console.log(FindNullScore);
          const IsHalfFillScore = objScr.some((item) => item.score !== "");
          //console.log(IsHalfFillScore);
          if (FindNullScore) {
            setSelectedColumn(wg_com_id);

            setTimeout(() => {
              const inputToFocus = document.querySelector(
                `input[data-comp-id="${wg_com_id}"][data-student-id="${studentid}"]`
              );
              if (inputToFocus) {
                inputToFocus.focus();
              }
            }, 0);

          } else if (IsHalfFillScore) {
            openModal({
              header: "Confirmation",
              message: (
                <ConfirmLeaveSubmitScore
                  setSelectedColumn={setSelectedColumn}
                  ReloadData={ReloadData}
                  HandlerSubmit={HandlerSubmit}
                  scrStudent={scrStudent}
                  wg_com_id={wg_com_id}
                />
              ),
            });
            return;
          } else {
            alert("unknown")
          }

        } else {
          setSelectedColumn(wg_com_id);

          setTimeout(() => {
            const inputToFocus = document.querySelector(
              `input[data-comp-id="${wg_com_id}"][data-student-id="${studentid}"]`
            );
            if (inputToFocus) {
              inputToFocus.focus();
            }
          }, 0);
        }
      }
    }
  };

  const handlerUpdate = (data) => {
    openModal({
      header: "",
      message: (
        <FormSingleComponentUpdate
          data={data}
          section_id={section_id}
          HandlerProgressBar={HandlerProgressBar}
        />
      ),
    });
  };

  const MessageBody = ({ student, id }) => {
    const [updateStatus, setUpdateStatus] = useState({
      loading: false,
      data: [],
      message: "",
    });

    const HandlerIncomplete = (id) => {
      const param = {
        scr_id: parseInt(id),
      };
      SCR_SET_INCOMPLETE_BY_LEC(param, setUpdateStatus, ReloadData);
    };
    return (
      <div className="text-center">
        {updateStatus.message ? (
          <AlertNotif color={"danger"} message={updateStatus.message} />
        ) : (
          ""
        )}
        <h3 className="mb-5">
          This action will exclude the course from the student's CGPA, GPA, and cumulative earned credits calculation.
          Are you sure you want to change the Final Result status for {student.fullname} to 'Incomplete'?
        </h3>
        <div className="btn-group">
          <button
            className="btn btn-lg btn-light"
            type="button"
            onClick={() => openModal({ open: false })}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-danger"
            type="button"
            disabled={updateStatus.loading}
            onClick={() => HandlerIncomplete(id)}
          >
            {updateStatus.loading ? "Processing..." : "Set to Incomplete"}
          </button>
        </div>
      </div>
    );
  };

  const handlerStatusInComplete = (student, value) => {
    if (value) {
      openModal({
        header: "Confirmation",
        message: <MessageBody student={student} id={value} />,
      });
      return;
    }
  };

  const scrollbarHolderRef = useRef(null);
  const tableWrapperRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const scrollbarHolder = scrollbarHolderRef.current;
    const tableWrapper = tableWrapperRef.current;
    const table = tableRef.current;

    if (table) {
      const handleScroll = () => {
        if (tableWrapper) {
          tableWrapper.scrollLeft = scrollbarHolder.scrollLeft;
        }
      };

      const updateDummyWidth = () => {
        if (scrollbarHolder.firstChild) {
          scrollbarHolder.firstChild.style.width = `${table.offsetWidth}px`;
        }
      };

      // Membuat dan menambahkan dummy div jika belum ada
      if (!scrollbarHolder.firstChild) {
        const dummyDiv = document.createElement('div');
        scrollbarHolder.appendChild(dummyDiv);
      }

      // Awalnya sesuaikan lebar dummyDiv
      updateDummyWidth();

      // Event listeners
      scrollbarHolder.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', updateDummyWidth);

      return () => {
        scrollbarHolder.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', updateDummyWidth);
      };
    }
  }, []);


  return (
    <div className="table-responsive" style={{ width: '100%', overflow: 'hidden' }}>
      {totalWeight !== 100 ? (
        <div className="my-5">
          <div className="alert alert-danger">
            <div className="d-flex align-items-center">
              <i className="la la-info-circle icon-3x text-white"></i>
              <span className="h3 font-weight-bolder text-white">
                Total weight ({(totalWeight).toFixed(0)}%) is{" "}
                {totalWeight < 100 ? "less than" : "over"} 100%, please update the
                weights of components
              </span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="scrollbar-holder" ref={scrollbarHolderRef} style={{ overflowX: 'auto', overflowY: 'hidden', height: '20px' }}></div>
      <div className="table-wrapper" ref={tableWrapperRef} style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <table
          className="table table-head-bg table-borderless table-vertical-center"
          id="table-component-score" style={{ minWidth: '600px', width: 'auto' }} ref={tableRef}
        >
          <thead>
            <tr
              className="text-left text-dark text-uppercase"
              style={{ fontSize: "11px" }}
            >
              <th className="freeze-column w-250px">Student</th>
              {sortedData.map((c, index) => (
                <th
                  key={index}
                  className={
                    "text-center " +
                    (selectedColumn === c.wg_com_id ? "active" : "")
                  }
                >
                  <div className="min-w-100px">
                    <div className="text-wrap">
                      {decodeURIComponent(c.name)}
                      <span className="d-block">{(c.weight * 100).toFixed(0)}%</span>
                    </div>
                  </div>
                </th>
              ))}
              <th>Score</th>
              <th>Grade</th>
              <th>Absent</th>
              <th className="min-w-120px">Final Result</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((v, index) => (
                <tr
                  key={index}
                  className={v.atd > 25 ? "bg-light-danger" : ""}
                  data-student-id={v.studentid}
                >
                  <td className={"freeze-column " + (v.atd > 25 ? "bg-light-danger" : "")}>
                    <div className="w-250px">
                      <Link to={"/student/" + v.studentid} target="_blank">
                        <span className="d-block font-weight-bolder h5 text-dark text-hover-primary">
                          {v.studentdata
                            ? v.studentdata.fullname
                              ? v.studentdata.fullname
                              : "-"
                            : "-"}
                        </span>
                        <span className="text-muted">{v.studentid}</span>
                      </Link>
                    </div>
                  </td>
                  {sortedData.map((comp) => {
                    const compScore = v.student_course_com.find(
                      (sc) => sc.wg_com.id === comp.wg_com.id
                    );
                    return (
                      <td
                        key={comp.wg_com.id}
                        className={
                          (selectedColumn === comp.wg_com_id ? "active" : "") +
                          " " +
                          (v.atd > 25 ? "bg-light-danger" : "")
                        }
                        onClick={() =>
                          handleColumnClick(comp.wg_com_id, v.studentid)
                        }
                      >
                        <div className="d-flex justify-content-center cursor-pointer">
                          {selectedColumn === comp.wg_com_id ? (
                            <input
                              type="text"
                              data-comp-id={comp.wg_com_id}
                              data-student-id={v.studentid}
                              readOnly={v.atd > 25 ? true : false}
                              className="form-control text-center w-60px"
                              value={compScore ? compScore.score.toString() : "x"}
                              tabIndex={index + 1}
                              onChange={(e) =>
                                HandlerInputScore(
                                  v.studentid,
                                  compScore.id,
                                  compScore.wg_com.id,
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <span
                              className="text-dark form-control w-60px text-center cursor-pointer"
                              title={
                                compScore.submitted
                                  ? "Last updated by " +
                                  compScore.submittedby.fullname +
                                  " at " +
                                  moment(compScore.submitted).format(
                                    "DD MMM YYYY hh:mm a"
                                  )
                                  : "Click to open form"
                              }
                            >
                              {compScore ? compScore.score.toString() : ""}
                            </span>
                          )}
                        </div>
                      </td>
                    );
                  })}
                  <td className={v.atd > 25 ? "bg-light-danger" : ""}>{v.score}</td>
                  <td className={v.atd > 25 ? "bg-light-danger" : ""}>{v.grade_fin ? v.grade_fin : ""}</td>
                  <td className={v.atd > 25 ? "bg-light-danger" : ""}>{v.atd}%</td>
                  <td className={v.atd > 25 ? "bg-light-danger" : ""}>
                    {v.course_status !== "INCOMPLETE" ? (
                      <select
                        name="status_std"
                        className="form-control"
                        value={"0"}
                        onChange={(e) =>
                          handlerStatusInComplete(v.studentdata, e.target.value)
                        }
                      >
                        <option value="0">
                          {v.course_status ? v.course_status : ""}
                        </option>
                        <option value={v.id}>INCOMPLETE</option>
                      </select>
                    ) : (
                      v.course_status
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={Object.values(components).length + 7}>
                  No record found
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td className="freeze-column"></td>
              {components.map((v, index) => (
                <td
                  key={index}
                  className={selectedColumn === v.wg_com_id && "active"}
                >
                  {selectedColumn === v.wg_com_id && (
                    <>
                      <button
                        className="save-button btn btn-primary btn-block"
                        type="button"
                        disabled={submit.loading}
                        onClick={() => HandlerSubmit()}
                      >
                        {" "}
                        {submit.loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : (
                          "Save"
                        )}{" "}
                      </button>
                      <button
                        className="btn btn-light btn-block"
                        type="button"
                        onClick={() => {
                          setSelectedColumn(null);
                          ReloadData();
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </td>
              ))}
              <td colSpan={5}></td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="note mb-10">
        <small className="d-block">Note:</small>
        <span className="bg-light-danger px-3 mr-2"></span>
        <span className="text-danger">Total of Attendance more than 25%</span>
      </div>

      {submit.message ? (
        <div className="mt-5">
          <AlertNotif color={"danger"} message={submit.message} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
