import React from 'react'
import { MyCalendarWeek } from './MyCalendarWeek';

export function MDashboard() {
    return (
        <div className='dashboard-mobile px-10'>
            <MyCalendarWeek />
        </div>
    )
}
