import React, { Component } from 'react'
import ApprovalList from './ApprovalList'

export class ThesisActivityLCT extends Component {
  render() {
    return (
      <ApprovalList />
    )
  }
}
