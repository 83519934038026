import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import ChartGPA from "./ChartGPA";
import AcadInfo from "./AcadInfo";
import CalendarSchedule from "../../../Student/Dashboard/Widget/Calendars/CalendarSchedule";
import { CardCreditTaken } from "./CardCourseCredit";

export function CAcadStd() {
    const { stdid } = useParams();

    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const [stdStatus, setStdStatus] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const GET_STUDENT_STATUS = () => {
        setStdStatus({ ...stdStatus, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: stdid,
            },
            target_data: "GET_STUDENT_STATUS",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdStatus({
                    ...stdStatus,
                    loading: false,
                    data: [],
                    message: response.error,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setStdStatus({ ...stdStatus, loading: false, data: [], message: msg });
            } else {
                var results = response.response.data;
                if (results) {
                    setStdStatus({
                        ...stdStatus,
                        loading: false,
                        data: results,
                        message: "",
                    });
                } else {
                    setStdStatus({
                        ...stdStatus,
                        loading: false,
                        data: [],
                        message: "No record found",
                    });
                }
            }
        });
    };


    useEffect(() => {
        GET_STUDENT_STATUS();
    }, []);

    return (
        stdStatus.loading ? (
            <LoadingBtn />
        ) : stdStatus.message ? (
            <AlertNotif
                message={stdStatus.message}
                color={"danger"}
            />
        ) : Object.values(stdStatus.data).length > 0 ? (
            <div className="details">
                <div className="row">
                    <div className="col-12 col-lg-4 col-xxl-4">
                        <div className="mb-4">
                            <AcadInfo studentStatus={stdStatus.data} />
                        </div>
                        <div className="card card-custom gutter-b">
                            <div className="card-body pt-0 px-0">
                                <ChartGPA studentStatus={stdStatus} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 col-xxl-8">
                        <CalendarSchedule stdid={stdid} />
                    </div>
                </div>
            </div>
        ) : ""
    )
}
