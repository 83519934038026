import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'
import TableData from "./TableData"
import Invoice from "./Widgets/Invoice"
import Regulations from "./Widgets/Regulations"
import Detail from "./Detail";

export class Billings extends Component {
    constructor(props){
        super(props);
        this.getInvoiceHist = this.getInvoiceHist.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode:AuthDecode,
            Invoice:[],
            Expired:[],
            Detail:[],
            TotalItem:0,
            LoadingPage:false,
            IsError:false
        };
    }

    getInvoiceHist(){
        this.setState({LoadingPage:true});
        const reqModule2 = () => {
            
            var keys={
                "AppId":this.state.AuthDecode.AppID,
                "AppEnv":this.state.AuthDecode.AppEnv,
                "Secretkey":this.state.AuthDecode.secretkey
            };
            var body={     
                "parameters" :{
                    "studentid":this.state.AuthDecode.currentData.entityid
                },              
                "target_data": "GET_INVOICE_HIST"
            };
            const postData = {
                keys:keys,
                body:body
            };
            
            AxiosLibWithHeader2(postData,'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice',response);
                    if(response.error){
                        this.setState({IsError:true});
                    }else if("message" in response.response){
                        this.setState({Expired:response.response.message,LoadingPage:false});
                    }else{
                        this.setState({Invoice:response.response.data,MsgInvoice:response.response.msg,LoadingPage:false});
                    }   
                }
            );  
        }
        reqModule2();
    }

    componentDidMount(){
        this.getInvoiceHist();            
    }


    render() {

        const detailInvoice = param =>{
            this.setState({Detail:param,LoadingPage:false});
        }

        const goback = param =>{
            this.setState({Detail:[]});
        }


        return (
            <div id="billing">
            {(Object.keys(this.state.Detail).length > 0) ? (
                <Detail data={this.state.Detail} goback={goback} />
            ) : (
                (this.state.IsError) ? (
                    <button className="btn btn-outline-success" type='button' onClick={(e) => this.getInvoiceHist()}>
                        <i className="flaticon2-reload"></i> Failed fetching, click to retry fetching.
                    </button>
                ) : (
                <div className="row">                    
                    {(Object.keys(this.state.Invoice).length > 0) ? (
                    <div className="col-sm-12 col-lg-4 col-xxl-4">
                        <Invoice />
                    </div>
                    ) : ''}
                           
                    <div className="col-sm-12 col-lg-8 col-xxl-8">
                        <div className="card card-custom card-stretch gutter-b">
                            <div className="card-header border-0 py-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label font-weight-bolder text-dark">Your Billings</span>
                                </h3>
                                <div className="card-toolbar">
                                    <button className="btn btn-clean font-weight-bold" type="button"><i className="fas fa-poll"></i> Total item {Object.keys(this.state.Invoice).length}</button>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                            {(Object.keys(this.state.Expired).length > 0) ? (
                                <div className="col-xl-12">
                                    <div className="alert alert-custom alert-notice alert-light-primary fade show" role="alert">
                                        <div className="alert-icon"><i className="flaticon-warning"></i></div>
                                        <div className="alert-text">{this.state.Expired.user}</div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                {(this.state.LoadingPage) ? (
                                    <LoadingBtn />
                                ) : ''}
                                <div style={{display:((this.state.LoadingPage) ? "none":"block")}}>
                                    <TableData data={this.state.Invoice} detailInvoice={detailInvoice} />
                                </div>
                                </>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
                )                
            )}

            </div>
        )
    }
}

export default Billings;