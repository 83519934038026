import React, { useEffect, useState } from "react";
import "./TableStudentScore.css";
import { Link } from 'react-router-dom'
import { openModal } from "../../../../Library/ModalPopUp";
import { AlertNotif } from "../../../../Library/AlertNotif";
import { SUBMIT_SCR_STD_BY_LEC_COM } from "./Apis";

export default function TableStudentScoreLetter(props) {
  const { data, section_id, ReloadData, HandlerLeavePage } = props;
  const [scores, setScores] = useState({});

  useEffect(() => {
    const initialScores = data.map((student) => ({
      atd: student.atd || 0,
      remark: student.remark || "",
      studentid: student.studentid,
      fullname: student.studentdata
        ? student.studentdata.fullname
          ? student.studentdata.fullname
          : "-"
        : "-",
      status_score: student.score_letter || "",
      course_status:student.course_status || ""
    }));
    setScores(initialScores);
  }, [data]);

  const handleScoreChange = (studentId, status) => {
    setScores((prevScores) =>
      prevScores.map((student) =>
        student.studentid === studentId
          ? { ...student, status_score: status }
          : student
      )
    );
  };

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const HandlerSubmit = (score) => {
    const allStatusFilled = score.every(
      (student) => student.status_score !== ""
    );
    if (!allStatusFilled) {
      openModal({
        header: "Error",
        message: "Score cannot be empty. All fields must be filled before saving.",
      });
    } else {
      var objStd = score.map((v) => {
        var obj = {};
        obj.studentid = v.studentid;
        obj.score_letter = v.status_score;
        return obj;
      });
      var objParam = {
        section_id: section_id,
        student: objStd,
      };

      SUBMIT_SCR_STD_BY_LEC_COM(objParam, setSubmit, ReloadData);
    }
  };

  return (
    <div className="table-responsive">
      <table
        className="table table-head-bg table-borderless table-vertical-center"
        id="table-component-score"
      >
        <thead>
          <tr className="text-left text-dark text-uppercase">
            <th className="freeze-column">Student</th>
            <th colSpan={2}>Score</th>
            <th>Absent</th>
            <th>Remark</th>
            <th>Final Result</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(scores).length > 0 ? (
            scores.map((v, index) => (
              <tr key={index} className={v.atd > 25 ? "bg-light-danger" : ""}>
                <td className="freeze-column">
                  <Link to={"/student/" + v.studentid} target="_blank">
                    <span className="d-block font-weight-bolder h5 text-dark text-hover-primary">
                      {v.fullname}
                    </span>
                    <span className="text-muted">{v.studentid}</span>
                  </Link>
                </td>

                <td width={"10%"}>
                  <label>
                    <input
                      type="radio"
                      name={"score_" + v.studentid}
                      value={"PASS"}
                      checked={v.status_score === "PASS"}
                      onChange={() => handleScoreChange(v.studentid, "PASS")}
                    />{" "}
                    PASS
                  </label>
                </td>
                <td width={"10%"}>
                  <label>
                    <input
                      type="radio"
                      name={"score_" + v.studentid}
                      value={"FAIL"}
                      checked={v.status_score === "FAIL"}
                      onChange={() => handleScoreChange(v.studentid, "FAIL")}
                    />{" "}
                    FAIL
                  </label>
                </td>
                <td>{v.atd}%</td>
                <td>{v.remark ? v.remark : ""}</td>
                <td>{v.course_status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>No record found</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6} className="text-right">
              <div className="btn-group">
                <button className="btn btn-lg btn-light" type="button" onClick={()=>HandlerLeavePage()}>
                  Cancel
                </button>
                <button
                  className="btn btn-lg btn-primary font-weight-bold"
                  type="button"
                  disabled={submit.loading}
                  onClick={() => HandlerSubmit(scores)}
                >
                  {submit.loading ? "Processing...":"Save Changes"}
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      {submit.message ? (
        <div className="mt-5">
          <AlertNotif color={"danger"} message={submit.message} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
