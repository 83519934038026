import React, { useRef } from 'react'
import Moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { openModal } from '../../../../Library/ModalPopUp';
import DetailSchedule from '../../../../Page/Student/Dashboard/Widget/Calendars/DetailSchedule';

export default function CalendarMonth(props) {
    const calendarRef = useRef();
    const eventRender = (data) => {
        let item = data.event;
        return (
            <div className={"event-item w-100 cursor-pointer"} onClick={()=>detailEvent(item)}>
                <span className='text-wrap'>{item.title}</span>
            </div>
        );
    };

    const detailEvent = (data) => {
        openModal({
            message: <DetailSchedule data={data}/>,
            header: "Selected Schedule",
        });
    };

    return (
        <div>
            <FullCalendar
                ref={calendarRef}
                defaultView="dayGridMonth"
                headerToolbar={{ start: '', center: '', end: '' }}
                plugins={[dayGridPlugin]}
                initialDate={props.paramDate.tgl}
                validRange={{ start: props.paramDate.tgl, end: props.paramDate.tgl_end }}
                events={Object.values(props.data).length > 0 ? props.data : []}
                eventContent={(info) => eventRender(info)}
                eventDidMount={(info) => {
                    if (info.event.extendedProps.background) {
                        info.el.style.background = info.event.extendedProps.background;
                    }
                    if (info.event.extendedProps.border) {
                        info.el.style.borderColor = info.event.extendedProps.border;
                    }
                }}
            />
        </div>
    )
}
