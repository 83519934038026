import React, { useEffect, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from '../../../Library/AxiosLibWithHeader2';
import Moment from 'moment';
import LoadingBtn from '../../../Page/Widgets/LoadingBtn';
import { AlertNotif } from '../../../Library/AlertNotif';
import MFormAtd from './MFormAtd';

export function MSessionClass() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    const { token } = useParams();
    const decoded = jwt_decode(token);
    const section_id = decoded.section_id;
    const status = decoded.status;

    var parameters = {
        "empid": AuthDecode.currentData.entityid,
        "section_id": section_id,
        "status": status
    }

    const [selectedSession, setSession] = useState(0);
    const [selectedSessionData, setSessionData] = useState({});
    const [lstSession, setLstSession] = useState({
        loading: false,
        message: "",
        data: [],
        result: false
    });

    const GET_ATD_LECTURER_BY_SECTION = (parameters) => {
        setLstSession({ ...lstSession, loading: true });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": parameters,
            "target_data": "GET_ATD_LECTURER_BY_SECTION"
        };
        const postData = {
            keys: keys,
            body: body
        };
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setLstSession({
                            ...lstSession,
                            result: false,
                            message: response.error,
                            loading: false,
                        });
                    } else if ("message" in response.response) {
                        var msg = response.response.message.user;
                        setLstSession({
                            ...lstSession,
                            result: false,
                            message: msg,
                            loading: false,
                        });
                    } else {
                        var results = response.response.data;
                        if (results) {
                            results.sort((a, b) => Moment(b.start_dt) - Moment(a.start_dt));
                            setLstSession({
                                ...lstSession,
                                result: true,
                                data: results,
                                message: "",
                                loading: false,
                            });
                            if (results[0]) {
                                setSession(results[0].id);
                                setSessionData(results[0]);
                                GET_ATD_LECTURER_BY_ATDID(results[0].id);
                            }

                        } else {
                            setLstSession({
                                ...lstSession,
                                result: true,
                                data: [],
                                message: "No record found",
                                loading: false,
                            });
                        }

                    }
                }
            );
    }

    const [mySection, setMySection] = useState({
        loading: false,
        message: "",
        data: [],
        result: false
    });

    const GET_SECTION = (parameters) => {
        setMySection({ ...mySection, loading: true });
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": parameters,
            "target_data": "GET_SECTION"
        };
        const postData = {
            keys: keys,
            body: body
        };
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setMySection({
                            ...mySection,
                            result: false,
                            message: response.error,
                            loading: false,
                        });
                    } else if ("message" in response.response) {
                        var msg = response.response.message.user;
                        setMySection({
                            ...mySection,
                            result: false,
                            message: msg,
                            loading: false,
                        });
                    } else {
                        var results = response.response.data;
                        if (results) {
                            setMySection({
                                ...mySection,
                                result: true,
                                data: results[0],
                                message: "",
                                loading: false,
                            });
                        } else {
                            setMySection({
                                ...mySection,
                                result: true,
                                data: [],
                                message: "No record found",
                                loading: false,
                            });
                        }

                    }
                }
            );
    }

    const [stdList, setStdList] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const GET_ATD_LECTURER_BY_ATDID = (atd_lec_id) => {
        setStdList({ ...stdList, loading: true });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                atdid: atd_lec_id
            },
            target_data: "GET_ATD_LECTURER_BY_ATDID",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdList({
                    ...stdList,
                    message: response.error,
                    loading: false,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setStdList({ ...stdList, message: msg, loading: false });
            } else {
                var results = response.response.data;
                if (results) {
                    if (Object.values(results.atd_student).length > 0) {
                        results.atd_student.sort((a, b) =>
                            a.studentstatusdata.fullname > b.studentstatusdata.fullname
                                ? 1
                                : -1
                        );

                        const studentLst = results.atd_student.map((v) => {
                            var obj = {};
                            obj.remark = v.remark ? v.remark : "";
                            obj.flag_sts = v.flag_sts;
                            obj.status = v.status;
                            obj.studentid = v.studentid;
                            obj.stundentname = v.studentstatusdata
                                ? v.studentstatusdata.fullname
                                : "-";
                            return obj;
                        });
                        setStdList({
                            ...stdList,
                            data: studentLst,
                            loading: false,
                        });
                    }
                } else {
                    setStdList({
                        ...stdList,
                        message: "No record found",
                        loading: false,
                        data: [],
                    });
                }
            }
        });
    };

    useEffect(() => {
        GET_ATD_LECTURER_BY_SECTION(parameters);
        GET_SECTION({ "section_id": section_id });
    }, [])

    const HandlerChangeSession = (id) => {
        setSession(id);
        var sessionData = FetchCurrentSession(id);
        setSessionData(sessionData);
        GET_ATD_LECTURER_BY_ATDID(id);
    }

    const FetchCurrentSession = (id) => {
        var results = lstSession.data.find(item => item.id === parseInt(id));
        return results;
    }

    return (
        <div className='pt-10 px-10'>
            {mySection.loading ? <LoadingBtn /> : mySection.message ? <AlertNotif message={mySection.message} title={"Error"} color={"danger"} /> : (
                mySection.data ? (
                    <>
                        <h3 className='text-white text-uppercase'>{mySection.data.course ? mySection.data.course.coursetitle : "-"}</h3>
                        <p className="mb-0 text-white">
                            {mySection.data.course ? mySection.data.course.code : "-"}
                            <span className="mx-2">&middot;</span>
                            Credit {mySection.data.course ? mySection.data.course.credit : "-"}
                            <span className="mx-2">&middot;</span>
                            {mySection.data.section_id}
                        </p>
                    </>
                ) : ""
            )}

            {lstSession.loading ? <LoadingBtn /> : lstSession.message ? <AlertNotif message={lstSession.message} color={"danger"} title={"Information"} /> : (
                Object.values(lstSession.data).length > 0 ? (
                    <div className="card mt-5">
                        <div className="card-body p-5">
                            <div className="frm-row">
                                <label>Attendance:</label>
                                <select name="session_id" className="form-control form-control-sm" value={selectedSession ? selectedSession : ""} onChange={(e) => HandlerChangeSession(e.target.value)}>
                                    <option value="">Choose Session</option>
                                    {lstSession.data.map((v, index) => (
                                        <option value={v.id} key={index}>Session {v.start_dt_formatted}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                ) : ""
            )}

            {Object.keys(selectedSessionData).length > 0 ? (
                <>
                    <div className="card mt-5">
                        <div className="card-body p-5">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className='mb-0 font-weight-bolder'>
                                    <span className="text-dark d-block">{selectedSessionData.status}</span>
                                    <span className="">
                                        Submited at {(selectedSessionData.lec_tap_time) ? Moment(selectedSessionData.lec_tap_time).format("DD MMM YYYY, HH:mm") : ""}
                                    </span>
                                </p>
                                {selectedSessionData.status === "SUBMITTED" ? (
                                    <span className="svg-icon svg-icon-white svg-icon-2x text-danger">
                                        <svg width="16" height="16" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
                                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                        </svg>
                                    </span>
                                ) : (
                                    <span className="svg-icon svg-icon-white svg-icon-2x text-success">
                                        <svg width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <div className="card">
                            <div className="card-body p-5">
                                <p className="mb-0 font-weight-bolder">Teaching Topic</p>
                                {/* <input
                                    type="text"
                                    name="teaching_topic"
                                    className="form-control"
                                    defaultValue={selectedSessionData.teaching_topic}
                                /> */}
                                <p className="mb-0">
                                    {selectedSessionData.teaching_topic}
                                </p>

                                <div className="mt-5">
                                    <p className="font-weight-bolder">Attendance Students:</p>
                                    <div className="table-responsive">
                                        <MFormAtd stdList={stdList} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : ""}

        </div>
    )
}
