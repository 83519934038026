import React, {useState,useMemo} from 'react'
import sign from 'jwt-encode'
import { Pagination, Search, TableHeader } from "../../../Library/DataTables"

export function TableData(props) {
    const listData = props.data;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const ITEMS_PER_PAGE = 5;   

    const headers = [
        { name: "No", field: "ID", sortable: false },
        { name: "Title", field: "Name", sortable: true },
        { name: "Description", field: "Description", sortable: false },
        { name: "View File", field: "URL", sortable: true },
        { name: "Status", field: "IsActive", sortable: true },
        { name: "Action", field: "ID", sortable: false },
    ];
    
    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if(computedData.length > 0){
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }else{
            return [];
        }
    }, [listData, currentPage, search, sorting]);  
    /*==END DATATABELS CONFIG==*/

    return (
        <div className='table-responsives'>            
            <table className="table table-head-custom table-vertical-center">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody>
                { (ResultData.length > 0) ? (
                    ResultData.map((listData,index) => (
                        <>
                        <tr>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{index+1}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{listData.Name}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{listData.Description}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{listData.URL}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{(listData.IsActive == 1) ? 'YES':'NO'}</span></td>
                            <td width="15px" className="text-center">
                                <div className="btn-group">
                                    <button className="btn btn-xs btn-warning" type="button" title="Edit"  onClick={(e)=>props.openForm( listData,e )} ><i className="far fa-edit"></i></button>                                
                                    <button className="btn btn-xs btn-danger" type="button" title="Remove" onClick={(e)=>props.deleteData( sign(listData, "SGUAP*)_") )} ><i className="far fa-trash-alt"></i></button>                                
                                </div>
                                
                            </td>
                        </tr>
                        {(Object.keys(listData.Child).length > 0) ? (
                            listData.Child.map((v)=>(
                                <tr className='bg-light-info'>
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"></span></td>
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{v.Name}</span></td>
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{v.Description}</span></td>
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{v.PathURI}</span></td>
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{(v.IsActive == 1) ? 'YES':'NO'}</span></td>
                                    <td width="15px" className="text-center">
                                        <div className="btn-group">
                                            <button className="btn btn-xs btn-warning" type="button" title="Edit"  onClick={(e)=>props.openForm( v,e )} ><i className="far fa-edit"></i></button>                                
                                            <button className="btn btn-xs btn-danger" type="button" title="Remove" onClick={(e)=>props.deleteData( sign(v, "SGUAP*)_") )} ><i className="far fa-trash-alt"></i></button>                                
                                        </div>
                                        
                                    </td>
                                </tr>
                            ))
                        ): '' }
                        </>
                    ))
                ) : (
                    <tr><td colSpan="3">Data not found</td></tr>
                )}
                </tbody>
            </table>
            {(totalItems > 0) ? (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            ) : ''}
            <div className="row">
                <div className="col-xl-12 text-right">
                    <button className="btn btn-sm btn-clean font-weight-bold" type="button"><i className="fas fa-poll"></i> Total item {(totalItems > 0) ? totalItems : 0}</button>
                </div>
            </div>
        </div>
    )
}

export default TableData;