import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from "../../Widgets/LoadingBtn"
import Ava1 from "../../../../media/avatars/17-dark.png"
import { useEffect } from 'react'

export default function WCounseling() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    const [loading, setLoading] = useState(false);
    const [IsError, setIsError] = useState({ error: false, message: '' });
    const [counseling, setCounseling] = useState([]);

    const getCounseling = () => {
        setLoading(true);
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                empid: AuthDecode.currentData.entityid
            },
            "target_data": "GET_COUNSELING_BY_LECTURER"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setIsError({ ...IsError, error: true });
                        setIsError({ ...IsError, message: 'response.error' });
                        setLoading(false);
                    } else if ("message" in response.response) {
                        setIsError({ ...IsError, message: response.response.message });
                        setLoading(false);
                    } else {
                        var param = response.response.data;
                        let result = {};
                        if (param) {
                            var objCounseling = [...param];
                            result = splitCounselingByStatus(objCounseling);
                            setCounseling(result);
                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    }
                }
            );
    }

    const splitCounselingByStatus = (objCounseling) => {
        let objIsactive = [1];
        let param = {};

        if (objCounseling) {
            //console.log(objCounseling);
            objCounseling = objCounseling.filter(o => o.studentstatusdata !== null).map(o => o);
            //console.log(parsing);
            objCounseling.sort(function (a, b) {
                if (a.studentstatusdata.fullname < b.studentstatusdata.fullname) { return -1; }
                if (a.studentstatusdata.fullname > b.studentstatusdata.fullname) { return 1; }
                return 0;
            })

            param.All = objCounseling.filter(o => objIsactive.find(x => x === o.studentstatusdata.is_active)).map(o => o);
            param.PendingApproved = objCounseling.filter(o => objIsactive.find(x => (x === o.studentstatusdata.is_active && o.event_status === "SUBMITTED"))).map(o => o);
            param.Approved = objCounseling.filter(o => objIsactive.find(x => (x === o.studentstatusdata.is_active && o.event_status === "PROCESSED"))).map(o => o);
            param.Cancelled = objCounseling.filter(o => objIsactive.find(x => (x === o.studentstatusdata.is_active && o.event_status === "CANCELLED"))).map(o => o);
            param.NotActive = objCounseling.filter(o => objIsactive.find(x => (x === o.studentstatusdata.is_active && !o.eventid))).map(o => o);
            param.Finished = objCounseling.filter(o => !objIsactive.find(x => (x === o.studentstatusdata.is_active))).map(o => o);
            if (param.Finished) {
                param.Finished.sort(function (a, b) {
                    if (a.studentid < b.studentid) { return -1; }
                    if (a.studentid > b.studentid) { return 1; }
                    return 0;
                })
            }
        }
        return param;
    }

    useEffect(() => {
        getCounseling();
    }, [])

    return (
        <>
            {(loading) ? <LoadingBtn /> : ''}

            <div style={{ display: ((loading) ? "none" : "block") }}>
                {(Object.values(counseling).length > 0) ? (
                    (Object.values(counseling.PendingApproved).length) ? (
                        <div className="card card-custom gutter-b" style={{ backgroundColor: '#1C325E' }}>
                            <div className="card-body d-flex ps-xl-15">
                                <div className="m-0">
                                    <div className="position-relative h2 z-index-2 font-weight-bolder text-white mb-7">
                                        <p>You have <span className="text-danger">{Object.values(counseling.PendingApproved).length} approval request</span> for student PCF</p>
                                    </div>
                                    <div className="mb-3">
                                        <Link to="/counseling" className="btn btn-danger fw-bold me-2">Click here to access it</Link>
                                    </div>
                                </div>
                                <img src={Ava1} alt="icon" className="position-absolute mr-0 bottom-0 right-0 h-150px" />

                            </div>
                        </div>
                    ) : ''
                ) : ''}
            </div>
        </>
    )
}

