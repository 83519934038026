
const StudentRepresentativeOdd = [{
    semes: 1, dept: [{ id: 1, code: "Mechanical Engineering", stdid: "12301012" }, { id: 2, code: "Industrial Engineering", stdid: "12307014" }, { id: 3, code: "Information Technology", stdid: "12302060" }, { id: 4, code: "Business Administration", stdid: "12308020" }, { id: 5, code: "HTM", stdid: "12303012" }, { id: 6, code: "Accounting", stdid: "12309005" },
    { id: 7, code: "Global Strategic Communications", stdid: "12310017" }, { id: 8, code: "Chemical Engineering - PE", stdid: "12304025" }, { id: 9, code: "Chemical Engineering - SEE", stdid: "12318001" }, { id: 10, code: "Food Technology", stdid: "12305010" }, { id: 11, code: "Biomedical Engineering", stdid: "12306012" }, 
    { id: 12, code: "Master of Mechanical Engineering", stdid: "22352020" }, { id: 13, code: "Master of Information Technology", stdid: "22351016" }, { id: 14, code: "Master of Business Administration", stdid: "22353011" }]
},
{
    semes:2, dept:[{ id: 1, code: "Master of Mechanical Engineering", stdid: "22352004" }, { id: 2, code: "Master of Information Technology", stdid: "22351012" }, { id: 3, code: "Master of Business Administration", stdid: "22353009" }]
},
{
    semes: 3, dept: [{ id: 1, code: "Mechanical Engineering", stdid: "12201002" }, { id: 2, code: "Industrial Engineering", stdid: "12207001" }, { id: 3, code: "IT-Data Science & AI", stdid: "12202034" }, { id: 4, code: "IT - Technopreneurship", stdid: "12202031" },
    { id: 5, code: "Business Administration", stdid: "12208008" }, { id: 6, code: "HTM", stdid: "12203005" }, { id: 7, code: "HTM-ICB", stdid: "12203006" }, { id: 8, code: "Accounting", stdid: "12209002" }, { id: 9, code: "Global Strategic Communications", stdid: "12210001" }, { id: 10, code: "Chemical Engineering - PE", stdid: "12204005" },
    { id: 11, code: "Chemical Engineering - SEE", stdid: "12218005" }, { id: 12, code: "Food Technology", stdid: "12205003" }, { id: 13, code: "Biomedical Engineering", stdid: "12206001" },
    { id: 14, code: "Master of Mechanical Engineering", stdid: "22252012" }, { id: 15, code: "Master of Information Technology", stdid: "22251027" }, { id: 16, code: "Master of Business Administration", stdid: "22253007" }]
},
{
    semes: 5, dept: [{ id: 1, code: "Mechanical Engineering", stdid: "12101002" }, { id: 2, code: "Industrial Engineering", stdid: "12107058" }, { id: 3, code: "IT-Data Science & AI", stdid: "12102013" }, { id: 4, code: "IT - Technopreneurship", stdid: "12102004" },
    { id: 5, code: "Business Administration-DM", stdid: "12108061" }, { id: 6, code: "Business Administration-FBA", stdid: "12108060" }, { id: 7, code: "Business Administration-INNE", stdid: "12108056" }, { id: 8, code: "HTM", stdid: "12103004" }, { id: 9, code: "HTM-ICB", stdid: "12103026" }, { id: 10, code: "Accounting", stdid: "12109051" },
    { id: 11, code: "Global Strategic Communications", stdid: "12110051" }, { id: 12, code: "Chemical Engineering - PE", stdid: "12104052" }, { id: 13, code: "Chemical Engineering - SEE", stdid: "12318005" }, { id: 14, code: "Food Technology", stdid: "12105054" }, { id: 15, code: "Biomedical Engineering", stdid: "12106011" }]
},
{
    semes: 7, dept: [{ id: 1, code: "Mechanical Engineering", stdid: "12001017" }, { id: 2, code: "Industrial Engineering", stdid: "12007005" }, { id: 3, code: "IT-Data Science & AI", stdid: "12002016" }, { id: 4, code: "IT - Technopreneurship", stdid: "12202031" },
    { id: 5, code: "Business Administration-DM", stdid: "12008007" }, { id: 6, code: "Business Administration-FBA", stdid: "12008005" }, { id: 7, code: "Business Administration-INNE", stdid: "12008006" }, { id: 8, code: "HTM", stdid: "12003001" }, { id: 9, code: "HTM-ICB", stdid: "12003025" }, { id: 10, code: "Accounting", stdid: "12009002" },
    { id: 11, code: "Global Strategic Communications", stdid: "12010003" }, { id: 12, code: "Chemical Engineering - PE", stdid: "12004005" }, { id: 13, code: "Chemical Engineering - SEE", stdid: "12018005" }, { id: 14, code: "Food Technology", stdid: "12005004" }, { id: 15, code: "Biomedical Engineering", stdid: "12006004" }]
}];


const StudentRepresentativeEven = [{
    semes: 2, dept: [{ id: 1, code: "Mechanical Engineering", stdid: "12301031" }, { id: 2, code: "Industrial Engineering", stdid: "12307018" }, { id: 3, code: "Information Technology", stdid: "12302060" }, { id: 4, code: "Business Administration", stdid: "12308016" }, { id: 5, code: "HTM", stdid: "12303023" }, { id: 6, code: "Accounting", stdid: "12309005" },
    { id: 7, code: "Global Strategic Communications", stdid: "12310017" }, { id: 8, code: "Chemical Engineering - PE", stdid: "12304025" }, { id: 9, code: "Chemical Engineering - SEE", stdid: "12318005" }, { id: 10, code: "Food Technology", stdid: "12305028" }, { id: 11, code: "Biomedical Engineering", stdid: "12306010" }, 
    { id: 12, code: "MME", stdid: "22352025" },{ id: 15, code: "MME-Engineering Management", stdid: "22352027" }, 
    { id: 13, code: "MIT-Digital Innovation", stdid: "22351033" }, { id: 16, code: "MIT-Data Science and Cyber Security", stdid: "22351015" }, { id: 16, code: "MIT-Data Science and Business Informatics", stdid: "22351035" }, 
    { id: 14, code: "MBA", stdid: "22353012" },
    
    ]
},
{
    semes:3, dept:[
        { id: 1, code: "MME", stdid: "22352027" }, { id: 4, code: "MME-Engineering Management", stdid: "22352008" }, { id: 4, code: "UT-MME-Engineering Management", stdid: "22352005" },
        { id: 2, code: "MIT-Digital Innovation", stdid: "22351002" }, { id: 5, code: "MIT-Data Science and Cyber Security", stdid: "22351011" }, { id: 5, code: "MIT-Data Science and Business Informatics", stdid: "22351007" }, 
        { id: 3, code: "MBA", stdid: "22353007" }]
},
{
    semes: 4, dept: [{ id: 1, code: "Mechanical Engineering", stdid: "12201002" }, { id: 2, code: "Industrial Engineering", stdid: "12207001" }, { id: 3, code: "IT-Data Science & AI", stdid: "12202034" }, { id: 4, code: "IT - Technopreneurship", stdid: "12202015" },
    { id: 5, code: "Business Administration", stdid: "12208008" }, { id: 6, code: "HTM", stdid: "12203005" }, { id: 7, code: "HTM-ICB", stdid: "12203006" }, { id: 8, code: "Accounting", stdid: "12209002" }, { id: 9, code: "Global Strategic Communications", stdid: "12210001" }, { id: 10, code: "Chemical Engineering - PE", stdid: "12204005" },
    { id: 11, code: "Chemical Engineering - SEE", stdid: "12218005" }, { id: 12, code: "Food Technology", stdid: "12205003" }, { id: 13, code: "Biomedical Engineering", stdid: "12206001" },
    { id: 14, code: "MME", stdid: "22252013" },
    { id: 15, code: "MIT-Data Science and Cyber Security", stdid: "22251024" }, { id: 18, code: "MIT-Data Science and Business Informatics", stdid: "22251023" }, 
    { id: 16, code: "MBA", stdid: "22253010" }, { id: 17, code: "UT-MBA", stdid: "22253016" }]
},
{
    semes:5, dept:[{ id: 1, code: "MIT-Data Science and Cyber Security", stdid: "22251002" }]
},
{
    semes: 6, dept: [{ id: 1, code: "Mechanical Engineering", stdid: "12101002" }, { id: 2, code: "Industrial Engineering", stdid: "12107058" }, { id: 3, code: "IT-Data Science & AI", stdid: "12102013" }, { id: 4, code: "IT - Technopreneurship", stdid: "12102004" },
    { id: 5, code: "Business Administration-DM", stdid: "12108061" }, { id: 6, code: "Business Administration-FBA", stdid: "12108060" }, { id: 7, code: "Business Administration-INNE", stdid: "12108056" }, { id: 8, code: "HTM", stdid: "12103004" }, { id: 9, code: "HTM-ICB", stdid: "12103026" }, { id: 10, code: "Accounting", stdid: "12109051" },
    { id: 11, code: "Global Strategic Communications", stdid: "12110051" }, { id: 12, code: "Chemical Engineering - PE", stdid: "12104052" }, { id: 13, code: "Chemical Engineering - SEE", stdid: "12118005" }, { id: 14, code: "Food Technology", stdid: "12105054" }, { id: 15, code: "Biomedical Engineering", stdid: "12106011" },
    { id: 16, code: "Master of Business Administration", stdid: "22153035" }]
},
{
    semes:7, dept:[{ id: 1, code: "Master of Business Administration", stdid: "22153002" }]
},
{
    semes: 8, dept: [{ id: 1, code: "Mechanical Engineering", stdid: "12001017" }, { id: 2, code: "Industrial Engineering", stdid: "12007005" }, { id: 3, code: "IT-Data Science & AI", stdid: "12002011" }, { id: 4, code: "IT - Technopreneurship", stdid: "12002001" },
    { id: 5, code: "Business Administration-DM", stdid: "12008007" }, { id: 6, code: "Business Administration-FBA", stdid: "12008005" }, { id: 7, code: "Business Administration-INNE", stdid: "12008006" }, { id: 8, code: "HTM", stdid: "12003001" }, { id: 9, code: "HTM-ICB", stdid: "12003025" }, { id: 10, code: "Accounting", stdid: "12009002" },
    { id: 11, code: "Global Strategic Communications", stdid: "12010003" }, { id: 12, code: "Chemical Engineering - PE", stdid: "12004005" }, { id: 13, code: "Chemical Engineering - SEE", stdid: "12018005" }, { id: 14, code: "Food Technology", stdid: "12005004" }, { id: 15, code: "Biomedical Engineering", stdid: "12006004" },
    { id: 16, code: "MME", stdid: "22052011" }, 
    { id: 17, code: "MIT-Data Science and Cyber Security", stdid: "22051012" }]
}];

const StudentRepresentative = StudentRepresentativeEven;


export {StudentRepresentative};