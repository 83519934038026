import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Moment from "moment";
import jwt_decode from "jwt-decode";
import sign from "jwt-encode";
import packageJson from "../../../package.json";
import { Aside, AsideMenu, Header, HeaderMobile, SubHeader } from './Headers';
import ModalPopUp, { openModal } from '../Library/ModalPopUp';
import ToastPopUp from '../Library/ToastPopUp';
import PageContent from './components/PageContent';


export default class LayoutNew extends Component {
  constructor(props) {
    super(props);

    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    this.state = {
      AuthDecode: AuthDecode,
      currYear: Moment().format("Y")
    }
  }

  componentDidMount() {
    var currDatetime = new Date();
    currDatetime = currDatetime.getTime();
    if (currDatetime >= this.state.AuthDecode.ExpiredSession) {
      openModal({
        header: "You've been signed out",
        message: (
          <div className="message">
            <p>Please wait a seccond.</p>
            <p className="text-center">
              <Link to={{ pathname: "/sign-out" }} className="btn btn-primary">Re-Sign in here</Link>
            </p>
          </div>
        )
      });
      setTimeout(() => {
        window.location.replace('/sign-out');
      }, 1000);
    }
  }

  render() {
    const signOutRemote = () => {
      const newCurrentUser = Object.assign({}, this.state.AuthDecode);
      newCurrentUser.currentData = newCurrentUser.remotedBy;
      delete newCurrentUser.remotedBy;

      localStorage.setItem(
        "AuthToken",
        sign(newCurrentUser, packageJson.system_param.PublicKey)
      );
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 1000);
    };

    return (
      <div className="offcanvas-mobiless">
        <HeaderMobile />
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <div
              className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
              id="kt_aside"
            >
              <Aside />
              <AsideMenu />
            </div>
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Header />

              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <SubHeader signOutRemote={signOutRemote} />


                <div className="d-flex flex-column-fluid" id="main-content">
                  <div className="container-fluid">
                    <PageContent />
                  </div>
                </div>
              </div>
              <div
                className="footer py-4"
                id="kt_footer"
              >
                <p className="text-center mb-0 text-muted">
                  Version {packageJson.version}
                </p>
                <div className="text-dark order-2 text-center">
                  <span className="text-dark-75 mr-2">
                    {this.state.currYear} ©
                  </span>
                  <a
                    href="https://sgu.ac.id/"
                    target="_blank"
                    className="text-dark-75 text-hover-primary"
                  >
                    Swiss German University
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="kt_scrolltop" className="scrolltop">
          <span className="svg-icon">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x="11"
                  y="10"
                  width="2"
                  height="10"
                  rx="1"
                />
                <path
                  d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z"
                  fill="#000000"
                  fillRule="nonzero"
                />
              </g>
            </svg>
          </span>
        </div>

        <ModalPopUp />
        <ToastPopUp />
      </div>
    )
  }
}
