import React, { useState, useEffect } from "react";
import packageJson from "../../../../package.json";
import CryptoJS from "crypto-js";
import "./Form.css";
import AuthStoreData from "../Action/AuthStorage";
import ModalPopUp, { openModal } from "../../Library/ModalPopUp";
import AxiosLibWithHeader from "../../Library/AxiosLibWithHeader";
import LogoSGU from "../../../media/logos/logo-sgu.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LoadingBtn from "../../Page/Widgets/LoadingBtn";

export default function Form() {
  const [details, setDetails] = useState({
    username: "",
    email: "@sgu.ac.id",
    password: "",
    typeCK: "production",
  });
  const [isRegexMain, setRegexMail] = useState("");
  const [isDone, setIsDone] = useState("");
  const [btnSignIn, setBtnSignIn] = useState({
    text: "Sign In",
    disabled: false,
  });
  const AppEnv = "sgu-my-sgu";
  const [typePass, setTypePass] = useState("password");
  const [isLocal, setIsLocal] = useState(false);
  var datetime = new Date();
  datetime.setHours(datetime.getHours() + 6);

  useEffect(() => {
    var URI = window.location.href;
    if (!URI.includes("my.sgu")) {
      setIsLocal(true);
    }
  }, []);

  const createAppID = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      details &&
      details.username &&
      details.password &&
      isRegexMain.length === 0
    ) {
      setBtnSignIn({ ...btnSignIn, text: "Processing...", disabled: true });
      var CryptoJSAesJson = {
        encrypt: function (value, password) {
          return CryptoJS.AES.encrypt(JSON.stringify(value), password, {
            format: CryptoJSAesJson,
          }).toString();
        },

        decrypt: function (jsonStr, password) {
          return JSON.parse(
            CryptoJS.AES.decrypt(jsonStr, password, {
              format: CryptoJSAesJson,
            }).toString(CryptoJS.enc.Utf8)
          );
        },

        stringify: function (cipherParams) {
          var j = {
            ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
          };
          if (cipherParams.iv) j.iv = cipherParams.iv.toString();
          if (cipherParams.salt) j.s = cipherParams.salt.toString();
          return JSON.stringify(j).replace(/\s/g, "");
        },

        parse: function (jsonStr) {
          var j = JSON.parse(jsonStr);
          var cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(j.ct),
          });
          if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
          if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
          return cipherParams;
        },
      };
      var encrypted_password = JSON.parse(
        CryptoJSAesJson.encrypt(
          details.password,
          packageJson.system_param.PublicKey
        )
      );

      var AppID = createAppID(36);
      const postData = {
        header: {
          "App-Id": AppID,
          "App-Env": AppEnv,
        },
        body: {
          username: details.username,
          password: encrypted_password,
          typeCK: details.typeCK,
        },
      };

      const postAuth = () => {
        AxiosLibWithHeader(postData, "app/api/auth").then((response) => {
          if (response.error) {
            openModal({
              message: response.error,
              header: "Error Connection Refuse",
            });
            setBtnSignIn({ ...btnSignIn, text: "Sign In", disabled: false });
          } else if (response.result === "success") {
            response.AppID = AppID;
            response.AppEnv = AppEnv;
            response.TypeCK = details.typeCK;
            response.ExpiredSession = datetime.getTime();
            AuthStoreData(response);
            setBtnSignIn({
              ...btnSignIn,
              text: "Success Sign In",
              disabled: true,
            });
            setIsDone(
              "Wait for a seccond, we will redirect you to the main portal"
            );
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            var messageError = response.message.user;

            openModal({
              message: messageError,
              header: "Error",
            });

            setBtnSignIn({ ...btnSignIn, text: "Sign In", disabled: false });
          }
        });
      };
      postAuth();

    } else {
      openModal({
        open: false,
        message: "Details do not match! Please fill up the form.",
        header: "Error",
      });
    }
  };

  const [isLoad, setLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 1000);
  });

  const isValidEmail = (val) => {
    if (val.length > 3) {
      let regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(val)) {
        setRegexMail("Invalid Email Address");
      } else {
        setRegexMail("");
      }
    }

    setDetails({ ...details, username: val });
  };

  const openInfo = () => {
    openModal({
      header: "Info account",
      message: (
        <div>
          <p className="text-dark">Type of email that can be use:</p>
          <ol>
            <li className="h4">
              <span className="font-weight-bolder">Student:</span>{" "}
              <span className="text-primary d-block">
                firstname.lastname<span className="text-danger">@student</span>
                .sgu.ac.id
              </span>
            </li>
            <li className="h4">
              <span className="font-weight-bolder">
                Lecturer (Full time/Part time):
              </span>{" "}
              <span className="text-primary d-block">
                firstname.lastname<span className="text-danger">@lecturer</span>
                .sgu.ac.id
              </span>
            </li>
            <li className="h4">
              <span className="font-weight-bolder">Staff (Full time):</span>{" "}
              <span className="text-primary d-block">
                firstname.lastname<span className="text-danger">@sgu</span>
                .ac.id
              </span>
            </li>
          </ol>
        </div>
      ),
    });
  };

  return (
    <div className="d-flex flex-column flex-root bg-dark">
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div
          className="d-flex flex-center flex-column flex-column-fluid p-10"
          id="portal-sgu"
        >
          {!isLoad ? <LoadingBtn /> : ""}
          <div className={"w-100 w-lg-500px " + (!isLoad ? "d-none" : "")}>
            <div className="card card-custom card-stretch gutter-b">
              <div className="card-body">
                <form
                  onSubmit={submitHandler}
                  className="offcanvas-mobile-block form fv-plugins-bootstrap fv-plugins-framework"
                  noValidate="novalidate"
                  id="kt_login_signin_form"
                  autoComplete="off"
                >
                  <div className="text-center mb-10">
                    <h1
                      className="text-dark mb-3"
                      title="Swiss German University"
                    >
                      <img
                        src={LogoSGU}
                        alt="logo"
                        width={"58px"}
                        className="mr-2"
                      />
                      <span className="font-weight-boldest">
                        Academic Portal
                      </span>
                    </h1>
                    <div className="text-gray-400 font-weight-bold">
                      Please login using the same username and password as your{" "}
                      <br />
                      <span className="text-primary font-weight-bold">
                        {" "}
                        SGU Google account
                      </span>
                    </div>
                  </div>
                  {isLocal ? (
                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">
                        Type of Data
                      </label>
                      <select
                        name="typeCK"
                        id="typeCK"
                        onChange={(e) =>
                          setDetails({ ...details, typeCK: e.target.value })
                        }
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      >
                        <option value="">Choose consumer key</option>
                        <option value="production">Production</option>
                        <option value="development">Development</option>
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      SGU Email{" "}
                      <sup onClick={openInfo}>
                        <i className="fa fa-info-circle"></i>{" "}
                      </sup>
                    </label>
                    <div
                      className="input-group mb-5 fv-plugins-icon-container rounded"
                      style={{ backgroundColor: "#F3F6F9" }}
                    >
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="text"
                        name="username"
                        id="username"
                        onChange={(e) => isValidEmail(e.target.value)}
                        value={details.username}
                        placeholder="enter your full sgu mail"
                      />
                    </div>
                    <div className="text-danger">{isRegexMain}</div>
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-between mt-n5">
                      <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                        Password
                      </label>
                      <a
                        href="https://wa.me/message/NNSYT7PGEZM5E1"
                        className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                      >
                        Forgot Password ?
                      </a>
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 "
                        type={typePass}
                        name="password"
                        id="password"
                        placeholder="Password"
                        onChange={(e) =>
                          setDetails({ ...details, password: e.target.value })
                        }
                        value={details.password}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-light-primary"
                          type="button"
                          onClick={(e) =>
                            typePass == "password"
                              ? setTypePass("text")
                              : setTypePass("password")
                          }
                        >
                          <i
                            className={
                              "fa " +
                              (typePass == "password"
                                ? "fa-eye"
                                : "fa-eye-slash")
                            }
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="text-center pt-2">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      className="btn btn-primary font-weight-bolder btn-block font-size-h6 px-8 py-4"
                      disabled={btnSignIn.disabled}
                    >
                      {btnSignIn.text}
                    </button>
                  </div>
                  <p className="text-center mt-5 text-info">{isDone}</p>
                </form>

                <div className="offcanvas-mobile-block">
                  {/* <div className="d-flex flex-column justify-content-center text-center pt-5"  >
                                            <p className="font-weight-bolder font-size-sm text-muted">Visit our other portal</p>
                                            <div>
                                                <PortalCard />
                                            </div>
                                        </div> */}

                  <div className="mt-8">
                    <p className="font-weight-bolder font-size-sm text-center">
                      Mobile version is available on
                    </p>
                    <div className="d-flex justify-content-center">
                      <div className="w-50 px-2">
                        <a href="https://play.google.com/store/apps/details?id=id.ac.sgu.mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                          <img
                            alt="Get it on Google Play"
                            className="w-100"
                            src={require("../../../media/logos/app-play-store.png")}
                          />
                        </a>
                      </div>
                      <div className="w-50 px-2">
                        <a href="https://apps.apple.com/id/app/sgu-mobile-app/id6448629664?itsct=apps_box_badge&amp;itscg=30200">
                          <img
                            alt="Download on App Store"
                            className="w-100"
                            src={require("../../../media/logos/app-store.png")}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-10">
          <p className="text-center text-muted">
            <span className="d-block">Version {packageJson.version}</span>
            <a
              href="https://sgu.ac.id/"
              className="text-muted text-hover-primary px-2"
            >
              SGU &copy; 2022{" "}
            </a>
          </p>
        </div>
      </div>

      <ModalPopUp />
    </div>
  );
}
