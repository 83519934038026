import React from 'react'

export default function UploadFile(props) {
    return (
        <div id='UploadFile'>
            <div className="container">
                <form method="post" autoComplete='off' onSubmit={(e)=>props.handlerSubmitFile()}>
                    <div className="row">
                        <div className="col border-right">
                            <div className="form-group">
                                <label>Title</label>
                                <input type="text" name="title" className='form-control required' required />
                                <small className="text-danger text-message"></small>
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea name="desc" className="form-control required" required></textarea>
                                <small className="text-danger text-message"></small>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label>File PDF</label><br />
                                <input type="file" name="files" className='required' required accept="application/pdf" />
                                <small className="text-danger text-message"></small>
                            </div>
                            <p>Rule of file:</p>
                            <ol>
                                <li>File must PDF only</li>
                                <li>Maximum size is 5 MB</li>
                            </ol>
                        </div>
                    </div>
                    <div className="text-right">
                        <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" type='submit'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
