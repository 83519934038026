import React, { useState, useEffect } from 'react'
import sign from 'jwt-encode';
import { Link, useParams } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import packageJson from "../../../../../../package.json"
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../Library/AlertNotif';

export function CActivityStd() {
  const { stdid } = useParams();
  const { token } = useParams();

  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [stdAct, setStdAct] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const GET_STUDENT_ACTIVITY = () => {
    setStdAct({ ...stdAct, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: stdid,
      },
      target_data: "GET_STUDENT_ACTIVITY",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdAct({
          ...stdAct,
          loading: false,
          data: [],
          message: response.error,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdAct({
          ...stdAct,
          loading: false,
          data: [],
          message: msg,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setStdAct({
            ...stdAct,
            loading: false,
            data: results,
            message: "",
          });
        } else {
          setStdAct({
            ...stdAct,
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_STUDENT_ACTIVITY();
  }, [])

  return (
    stdAct.loading ? <LoadingBtn /> : stdAct.message ? <AlertNotif title={"Information"} message={stdAct.message} color={"danger"} /> : (
      Object.values(stdAct.data).length > 0 ? (
        <div className='card card-custom gutter-b'>
          <div className="card-header">
            <h3 className="card-title">
              Activity History
            </h3>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th width="10%">No</th>
                    <th>Activity</th>
                    <th>Academic Term</th>
                    <th>Status</th>
                    <th>Request Date</th>
                    <th>Detail</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(stdAct.data).length > 0 ? (
                    stdAct.data.map((v, index) => {
                      var paramObj = {};
                      var atpObj = { id: v.atp_id_term, name: v.atp_term.name, atp_type: v.atp_term.name };
                      paramObj.atp = atpObj;
                      paramObj.event_status = v.status;
                      paramObj.eventid = v.id;
                      paramObj.student_activity = v.student_activity;
                      paramObj.studentid = v.studentid;
                      paramObj.submit_date_formatted = v.submit_date_formatted;
                      paramObj.approve_date_formatted = v.approve_date_formatted;
                      return (
                        <tr key={index}>
                          <td>{(index + 1)}</td>
                          <td>
                            <span className="font-weight-bold d-block">{(v.student_activity) ? v.student_activity.name : "-"}</span>
                            <span>Semester {v.semester}</span>
                          </td>
                          <td>{(v.atp_term) ? v.atp_term.name : "-"}</td>
                          <td>
                            {v.status ? (
                              <span className={"font-size-lg badge badge-" + (v.status === "PROCESSED" ? "primary" : "warning")}>{(v.status === "PROCESSED") ? "APPROVED" : "PENDING APPROVAL"}</span>
                            ) : ""}
                          </td>
                          <td>{v.submit_date_formatted}</td>
                          <td>
                            {(v.student_activity) ? ((v.student_activity.id !== "asc.student.activity.resign" && v.student_activity.id !== "asc.student.activity.newcoming") ? (
                              v.status ? (
                                <Link to={{ pathname: "/counseling/student/" + v.studentid + "/activity/" + (sign({ type: v.student_activity.name, value: paramObj }, packageJson.system_param.PublicKey)) }} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                                  <i className="fa fa-arrow-right"></i>
                                </Link>
                              ) : ""
                            ) : "") : ""}
                          </td>
                        </tr>
                      )
                    }
                    )
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : ""
    )
  )
}
