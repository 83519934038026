import React from 'react'
import jwt_decode from 'jwt-decode';
import ShapeBG from "../../../../../media/bg/patterns/top-green.png"

export function Academic(props) {
    const academic = props.data;
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    let actArr = academic.status.id.split(".");
    let act = actArr[actArr.length - 1];

    return (
        <div id="widget-academic">
            <div className="card card-custom bg-gray-100 gutter-b   ">
                <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px" style={{ backgroundImage: 'url(' + ShapeBG + ')' }}>
                    <h3 className="card-title align-items-start flex-column text-white pt-15">
                        <span className="font-weight-bold font-size-h1 mb-3">Hello, {AuthDecode.currentData.given_name}</span>
                        <span className="d-block text-white ">
                            Academic Overview 
                        </span>
                        <span className="font-weight-bolder">{(academic.atp_term) ? academic.atp_term.name :""}</span>
                    </h3>
                </div>
                <div className="card-body mt-n20 px-0">
                    <div className="card-spacer mt-n20">
                        <div className="row mb-7">
                            <div className="col-sm-6 col-lg-6 col-xxl-6">
                                <div className="bg-light-primary px-6 py-8 rounded-xl mb-3">
                                    <span className="font-weight-boldest font-size-h1 d-block my-2">
                                        {(academic.cgpa) ? academic.cgpa : '-'}
                                    </span>
                                    <span className="text-dark-50 font-weight-bold font-size-h1">CGPA</span>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-6 col-xxl-6">
                                <div className="bg-light-primary px-6 py-8 rounded-xl">
                                    <span className="font-weight-boldest font-size-h1 d-block my-2">
                                        {(academic.ccredit_pass) ? academic.ccredit_pass : '0'}
                                    </span>
                                    <span className="text-dark-50 font-weight-bold font-size-h7 mt-2">Cumulative Earned Credits</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-lg-6 col-xxl-6">
                                <div className="bg-light-primary px-6 py-8 rounded-xl mb-3">
                                    <span className="font-weight-boldest font-size-h1 d-block my-2">
                                        {academic.semester}
                                    </span>
                                    <span className="text-dark-50 font-weight-bold font-size-h6 mt-2">Semester</span>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-6 col-xxl-6">
                                <div className="bg-light-primary px-6 py-8 rounded-xl">
                                    <span className="font-weight-boldest font-size-h1 d-block my-2 text-capitalize">
                                        {act}
                                    </span>
                                    <span className="text-dark-50 font-weight-bold font-size-h6 mt-2">Status</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Academic