import React, { Suspense } from 'react';
import { Route, Switch } from "react-router-dom";
import { MDashboard, MProfile, MTimetables, MMyClass, MSessionClass } from './pages/index';


export function RouteMobileLect() {
    const COMPONENT_MAP = {
        'MDashboard': MDashboard,
        'MProfile':MProfile,
        'MTimetables':MTimetables,
        'MMyClass':MMyClass,
        'MSessionClass':MSessionClass
    }
    
    return (
        <Suspense fallback={<h3>Loading..</h3>}>
            <Route path="/" component={COMPONENT_MAP["MDashboard"]} exact />
            <Route path="/dashboard" component={COMPONENT_MAP["MDashboard"]} />
            <Route path="/timetables" component={COMPONENT_MAP["MTimetables"]} />
            <Route path="/profile" component={COMPONENT_MAP["MProfile"]} />
            {/* <Route path="/my-class" component={COMPONENT_MAP["MMyClass"]} /> */}
            <Route path="/atd-class/:token" component={COMPONENT_MAP["MSessionClass"]} />

            <Route
                path="/my-class"
                render={({ match: { url } }) => (
                <>
                    <Route path={`${url}/`} component={COMPONENT_MAP["MMyClass"]} exact />
                    <Route path={`${url}/attendance/:token`} component={COMPONENT_MAP["MSessionClass"]} />
                    <Route path={`${url}/score`} component={COMPONENT_MAP["MSessionClass"]} />
                </>
                )}
            />
        </Suspense>
    )
}

export default RouteMobileLect;