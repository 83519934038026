import React, { useState, useMemo } from "react";
import Moment from "moment";
import jwt_decode from "jwt-decode";
import { TableHeader } from "../../../../../Library/DataTables";

export default function TableData(props) {
    //const listData = props.data;
    const listData = props.listdata ? props.listdata : null;
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    /*==DATATABELS CONFIG==*/
    const [ITEMS_PER_PAGE, setTotalShow] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const headers = [
        { name: "No", field: "session", sortable: false },
        { name: "Section ID", field: "start_dt", sortable: false },
        { name: "Student", field: "student_name", sortable: true },
        { name: "Department", field: "dept", sortable: true },
        { name: "Defense Date", field: "defensedate", sortable: true },
        { name: "Advisor", field: "status", sortable: false },
        { name: "Co Advisor", field: "status", sortable: false },
        { name: "Action", field: "section_id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData;
        if (listData) {
            computedData = listData.map((c) => {
                var obj = {};
                obj.advisor = c.advisor ? c.advisor : null;
                obj.advisor_id = c.advisor_id ? c.advisor_id : null;
                obj.atp_id_term = c.atp_id_term ? c.atp_id_term : 0;
                obj.coadvisor = c.coadvisor ? c.coadvisor : null;
                obj.coadvisor_id = c.coadvisor_id ? c.coadvisor_id : null;
                obj.defensedate = c.defensedate ? c.defensedate : "";
                obj.defensetime = c.defensetime ? c.defensetime : '';
                obj.duedate = c.duedate ? c.duedate : "";
                //obj.thesis_title = c.final_title ? c.final_title : "-";
                obj.id = c.id ? c.id : 0;
                obj.room_id = c.room ? c.room.id : 0;
                obj.room = c.room ? c.room : null;
                obj.section_com = c.section_com ? c.section_com : [];
                obj.section_id = c.section_id ? c.section_id : 0;
                obj.studentdata = c.studentdata ? c.studentdata : null;
                obj.studentid = c.studentid ? c.studentid : "-";
                obj.ths_main_ex = c.ths_main_ex ? c.ths_main_ex : [];
                obj.student_name = c.studentdata.fullname ? c.studentdata.fullname : "-";
                obj.student_name = c.studentdata.fullname ? c.studentdata.fullname : "-";
                obj.dept = c.studentdata.clu ? c.studentdata.clu.name : "-";
                return obj;
            });
        }

        //computedData.sort((a, b) => (a.defensedate < b.defensedate ? 1 : -1));

        if (computedData.length > 0) {
            computedData = computedData.map((v, index) => {
                var obj = {};
                obj = v;
                obj.num = index + 1;
                return obj;
            })
        }

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.values(listData).some((value) => {
                    if (value === null || value === undefined) {
                        return false;
                    }
                    if (typeof value === 'object') {
                        return Object.values(value).some(subValue =>
                            String(subValue).toLowerCase().includes(search.toLowerCase())
                        );
                    }
                    return String(value).toLowerCase().includes(search.toLowerCase());
                });
            });
        }



        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "desc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        // if (computedData.length > 0) {
        //   return computedData.slice(
        //     (currentPage - 1) * ITEMS_PER_PAGE,
        //     (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        //   );
        // } else {
        //   return [];
        // }

        return computedData;
    }, [listData, currentPage, search, sorting]);
    /*==END DATATABELS CONFIG==*/

    const momentTime = (time) => {
        const [hours, minutes, seconds] = time.split(':');
        const timeMoment = Moment({ hours, minutes, seconds });
        const formattedTime = timeMoment.format("h:mm a");
        return (
            formattedTime
        );
    };
    let currDate = Moment(new Date()).add(15, "minute");

    return (
        <div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div className="filter d-flex align-items-center">
                    <div className="w-250px d-flex align-items-center mb-3 mb-lg-0 bg-white border rounded">
                        <input
                            type="text"
                            className="form-control form-control-sm form-control-flush"
                            placeholder="Type here to find it"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="currentColor"
                                ></rect>
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                    {ResultData.length > 0 ? (
                        ResultData.map((v, index) => (
                            <tr key={index}>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                                        {v.num}
                                    </span>
                                </td>
                                <td>{v.section_id}</td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{v.student_name}</span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        {v.studentid}
                                    </span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        {v.studentdata.sgumail}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{v.dept}</span>

                                </td>
                                <td> <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{(v.defensedate) ? Moment(v.defensedate).format("DD MMM YYYY") : "-"}</span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        Time : {(v.defensetime) ? momentTime(v.defensetime) : "-"}
                                    </span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        Room : {(v.room) ? v.room.name : "-"}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{(v.advisor) ? v.advisor.fullname : "-"}</span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        {(v.advisor) ? v.advisor.email : "-"}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg d-block">{(v.coadvisor) ? v.coadvisor.fullname : "-"}</span>
                                    <span className="text-dark-50 font-weight-bolder mb-1 font-size-sm d-block">
                                        {(v.coadvisor) ? v.coadvisor.email : "-"}
                                    </span>
                                </td>

                                <td width="15px" className="text-center">
                                    <div className="btn-group">
                                        <button
                                            className="btn btn-icon btn-light btn-sm"
                                            type="button"
                                            title="Detail"
                                            onClick={() => props.setSelectedSession(v)}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                <svg
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    version="1.1"
                                                >
                                                    <g
                                                        stroke="none"
                                                        strokeWidth="1"
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    >
                                                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                        <rect
                                                            fill="#000000"
                                                            opacity="0.3"
                                                            transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                                            x="11"
                                                            y="5"
                                                            width="2"
                                                            height="14"
                                                            rx="1"
                                                        ></rect>
                                                        <path
                                                            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                            fill="#000000"
                                                            fillRule="nonzero"
                                                            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="10">No record found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
