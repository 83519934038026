import React, { useState } from 'react'
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import { openToast } from '../../../../Library/ToastPopUp';
import { AlertNotif } from '../../../../Library/AlertNotif';

export default function ResignApproval({ data }) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const objParam = { eventid: data.eventid };
    const [result, setResult] = useState({ loading: false, data: [], message: "" });
    const HandlerSubmit = (e) => {
        e.preventDefault();
        setResult({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: objParam,
            target_data: "PROCESS_RESIGN_STUDENT",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setResult({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setResult({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    setResult({ loading: false, data: results, message: "" });
                    openToast({ header: "Information", message: "Your request has been approved" });
                } else {
                    setResult({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    }
    return (
        <div className='card'>
            <div className="card-body">
                <div className="d-flex justify-content-start align-items-center">
                    <Link className="btn btn-clear btn-sm text-hover-warning" to={{ pathname: "/counseling/student/" + data.studentid + "/activity" }}>
                        <i className="fa fa-arrow-left"></i>
                    </Link>
                    <div className="title border-left pl-5">
                        <h3 className='mb-0'>Form Resign Approval</h3>
                    </div>
                </div>
                <div className="approval mt-5 w-50 border rounded p-5">
                    <form autoComplete='off' method="post" onSubmit={(e) => HandlerSubmit(e)}>
                        <div className="form-group">
                            <label className='font-weight-bold'>Resign on term</label>
                            <input type="text" name="term" className='form-control' disabled value={data.atp ? data.atp.name : ""} />
                        </div>
                        <div className="form-group">
                            <label className='font-weight-bold'>Reason</label>
                            <input type="text" name="term" className='form-control' />
                        </div>
                        <div className="text-right mb-5">
                            <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit" type='submit' disabled={result.loading}>
                                {result.loading ? "Processing..." : "Approve"}
                            </button>
                        </div>

                        {result.message ? <AlertNotif message={result.message} color={"danger"} /> : ""}
                    </form>
                </div>
            </div>
        </div>
    )
}
