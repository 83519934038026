import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import Ava1 from "../../../../../media/avatars/17-dark.png";
import { GetLinkDoc } from "./GetLinkDoc";
import ThesisScoreIndex from "./ThesisScoreIndex";
import Moment from "moment";
import ThesisRevMulti from "./ThesisRevMulti";

export class ThesisInfoSTD extends Component {
  constructor(props) {
    super(props);
    this.getStudentStatus = this.getStudentStatus.bind(this);
    this.getThesisRevision = this.getThesisRevision.bind(this);
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    this.excld = [
      { email: "agung.margiyanto@sgu.ac.id" },
      { email: "siti.lestari@sgu.ac.id" },
      { email: "sema.titiasopha@sgu.ac.id" },
      { email: "tety.rachmawati@sgu.ac.id" },
      { email: "usan@sgu.ac.id" },
      { email: "eveline.eirene@sgu.ac.id" },
      { email: "nurita.kresnawati@sgu.ac.id" },
      { email: "tasya.syifa@sgu.ac.id" },
    ];
    this.state = {
      AuthDecode: AuthDecode,
      LoadingPage: false,
      StudentStatus: [],
      IsError: false,
      ThesisRev: {},
      SelectedExaminer: 0,
      defenseDate: Moment().format("YYYY-MM-DD"),
      dateNow: Moment().format("YYYY-MM-DD"),
      DownloadRev: false,
    };

    this.updateDefenseDate = this.updateDefenseDate.bind(this);
    this.downloadRevision = this.downloadRevision.bind(this);
  }


  filterExaminers = () => {
    if (this.state.ThesisRev.ths_main_ex) {
      const filteredData = this.state.ThesisRev.ths_main_ex.filter(
        (item) =>
          !this.excld.some(
            (excludeItem) => excludeItem.email === item.emp.email
          )
      );
      this.setState((prevState) => ({
        ThesisRev: {
          ...prevState.ThesisRev,
          ths_main_ex: filteredData,
        },
      }));
    }
  };

  updateDefenseDate(defenseDate) {
    this.setState((prevState) => ({ ...prevState, defenseDate }));
    // console.log(this.state);
  }

  getStudentStatus() {
    this.setState({ LoadingPage: true });
    const reqModule2 = () => {
      var keys = {
        AppId: this.state.AuthDecode.AppID,
        AppEnv: this.state.AuthDecode.AppEnv,
        Secretkey: this.state.AuthDecode.secretkey,
      };
      var body = {
        parameters: {
          studentid: this.state.AuthDecode.currentData.entityid,
        },
        target_data: "GET_STUDENT_STATUS",
      };
      const postData = {
        keys: keys,
        body: body,
      };

      AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
        //console.log(response);
        if (response.error) {
          this.setState({
            IsError: true,
            ErrorMsg: response.error,
            LoadingPage: false,
          });
        } else if ("message" in response.response) {
          this.setState({
            Expired: response.response.message,
            LoadingPage: false,
          });
        } else {
          if (response.response.data) {
            this.setState({
              StudentStatus: response.response.data,
              LoadingPage: false,
            });
          }
        }
      });
    };
    reqModule2();
  }

  getThesisRevision() {
    this.setState({ LoadingPage: true });
    const reqModule = () => {
      var keys = {
        AppId: this.state.AuthDecode.AppID,
        AppEnv: this.state.AuthDecode.AppEnv,
        Secretkey: this.state.AuthDecode.secretkey,
      };
      var body = {
        parameters: {
          studentid: this.state.AuthDecode.currentData.entityid,
        },
        target_data: "GET_THS_BY_STUDENT",
      };
      const postData = {
        keys: keys,
        body: body,
      };

      AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
        // console.log(response);
        if (response.error) {
          this.setState({
            IsError: true,
            ErrorMsg: response.error,
            LoadingPage: false,
          });
        } else if ("message" in response.response) {
          this.setState({
            Expired: response.response.message,
            LoadingPage: false,
          });
        } else {
          console.log(response.response.data);
          if (response.response.data) {
            this.setState({
              ThesisRev: response.response.data,
              LoadingPage: false,
            });
            this.filterExaminers();
          } else {
            this.setState({ LoadingPage: false });
          }
        }
      });
    };
    reqModule();
  }

  downloadRevision(v) {
    this.setState({ DownloadRev: v });
  }

  componentDidMount() {
    this.getStudentStatus();
    this.getThesisRevision();
  }

  render() {
    const Boxinfo = ({ title, message }) => {
      return (
        <div className="card card-custom">
          <div className="card-body">
            <h3>{title}</h3>
            <div className="message">{message}</div>
          </div>
        </div>
      );
    };

    const BoxNotif = ({ title, message, link }) => {
      return (
        <div
          className="card card-custom gutter-b"
          style={{ backgroundColor: "#1C325E", minHeight: "170px" }}
          title={title}
        >
          <div className="card-body d-flex ps-xl-15">
            <div className="m-0">
              <div className="position-relative h2 z-index-2 font-weight-bolder text-white mb-7">
                {message}
              </div>
              <div className="mb-3">
                <a
                  href={link}
                  className="btn btn-danger fw-bold me-2"
                  target={"_blank"}
                >
                  Download here
                </a>
              </div>
            </div>
            <img
              src={Ava1}
              alt="icon"
              className="position-absolute mr-0 bottom-0 right-0 h-150px"
            />
          </div>
        </div>
      );
    };

    return (
      <div id="thesis">
        <div
          className="alert alert-custom alert-white alert-shadow fade show gutter-b"
          role="alert"
        >
          <div className="alert-icon">
            <span className="svg-icon svg-icon-primary svg-icon-xl">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                    fill="#000000"
                    opacity="0.3"
                  ></path>
                  <path
                    d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
          <div className="alert-text">
            <h3>Thesis Information</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <ThesisScoreIndex
              defenseData={this.state}
              setDt={this.updateDefenseDate}
            />
          </div>
          <div className="col-lg-4">
            <div className="info-thesis">
              <BoxNotif
                title={"Thesis Templates"}
                message={
                  "Thesis Proposal Template, Guidelines and CD Label Thesis"
                }
                link={"https://thesis.sgu.ac.id/index.php/"}
              />

              {Object.values(this.state.StudentStatus).length > 0 ? (
                <BoxNotif
                  title={"Approval Thesis Proposal Bachelor's Degree"}
                  message={"Approval Thesis Proposal Bachelor's Degree"}
                  link={GetLinkDoc(
                    this.state.StudentStatus.clu.id,
                    this.state.StudentStatus.clu_spec
                      ? this.state.StudentStatus.clu_spec.id
                      : 0
                  )}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8"></div>
          {Object.values(this.state.ThesisRev).length > 0 &&
          Moment(this.state.dateNow).format("YYYY-MM-DD") >
            Moment(this.state.defenseDate).format("YYYY-MM-DD") ? (
            <div className="m-auto">
              <div className="card card-custom gutter-b">
                <div className="card-body">
                  <button
                    onClick={() => this.downloadRevision(true)}
                    className="btn btn-primary btn-lg btn-block font-weight-bold h2"
                    disabled={this.state.DownloadRev}
                  >
                    {this.state.DownloadRev
                      ? "Generating..."
                      : "Download Revision"}
                  </button>
                </div>
              </div>
              <div style={{ width: "790px" }} className="m-auto">
                <ThesisRevMulti
                  data={this.state.ThesisRev}
                  selectedExaminer={this.state.SelectedExaminer}
                  downloadRev={this.state.DownloadRev}
                  setDownloadRev={this.downloadRevision}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default ThesisInfoSTD;
