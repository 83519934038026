import React, { useState } from "react";
import SuccessFailedDialog from "./SuccessFailedDialog";
import { approvalSubmition } from "./helper";

const ApprovalModal = ({ id, closeState, refetchLog }) => {
  const [note, setNote] = useState("");
  const [autoClose, setAutoCLose] = useState(3);
  const [loadingApproval, setLoadingApproval] = useState({
    state: false,
    flag: 0,
    response: "",
  });

  // handle approval
  const handleApproval = async (flag) => {
    setLoadingApproval({ state: true, flag: flag, response: "" });
    const body = {
      parameters: {
        act_id: id,
        flag: flag, // 1:approved, 2 rejected
        remark: note,
      },
      target_data: "APPROVAL_ACTIVITY",
    };
    const approve = await approvalSubmition(body);
    if (approve.result === "success") {
      setLoadingApproval({
        state: approve.loading,
        flag: flag,
        response: approve.result,
      });
      setTimeout(() => {
        closeState({ state: false, id: -1 });
        refetchLog();
      }, 3000);
    } else {
      setLoadingApproval({
        state: approve.loading,
        flag: 0,
        response: approve.result,
      });
    }
  };

  return (
    <div
      className="modal fade show d-block"
      data-backdrop="static"
      style={{ backgroundColor: "rgba(63, 66, 84, 0.5)" }}
      tabIndex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div
          class="modal-content p-10 position-relative"
          style={{ width: 400 }}
        >
          {loadingApproval.response === "" ? (
            <a
              title="close"
              className="btn btn-xs btn-icon btn-light btn-hover-danger position-absolute"
              style={{ top: 10, right: 10 }}
              onClick={() => closeState({ state: false, id: -1 })}
            >
              <i class="ki ki-close icon-sm text-muted"></i>
            </a>
          ) : (
            <div className="position-absolute" style={{ top: 5, right: 10 }}>
              <p className="text-muted m-0 p-0">Auto</p>
              <p className="text-muted p-0" style={{ marginTop: -5 }}>
                close
              </p>
            </div>
          )}
          {loadingApproval.response !== "" ? (
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <SuccessFailedDialog
                  status={loadingApproval.flag}
                  res={loadingApproval.response}
                />
              </div>
            </div>
          ) : (
            <>
              <div class="">
                <h4 class="modal-title" id="exampleModalLabel">
                  Approve or Reject Log
                </h4>
              </div>
              <div className="my-10">
                <div className="w-100">
                  <p>Leave Note</p>
                  <textarea
                    placeholder="Leave any note here"
                    rows={3}
                    onChange={(e) => setNote(e.target.value)}
                    className="w-100 p-2 rounded"
                    style={{ borderColor: "#E4E6EF", outlineColor: "#7E8299" }}
                  />
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{ gap: 15 }}
              >
                <button
                  type="button"
                  className="btn btn-light-danger font-weight-bold w-100"
                  disabled={loadingApproval.flag === 1}
                  onClick={() => !loadingApproval.state && handleApproval(2)}
                >
                  {loadingApproval.flag === 2
                    ? loadingApproval.state
                      ? "Loading..."
                      : ""
                    : "Reject"}
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold w-100"
                  disabled={loadingApproval.flag === 2}
                  onClick={() => !loadingApproval.state && handleApproval(1)}
                >
                  {loadingApproval.flag === 1
                    ? loadingApproval.state
                      ? "Loading..."
                      : ""
                    : "Approve"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalModal;
