import React, { useState } from 'react'
import TTDepartment from './Departments/TTDepartment';

export function MTimetables() {
  const [selected, setSelected] = useState(1);
  return (
    <div className='pt-10 px-10'>
        <h3 className='text-white text-center'>Timetables</h3>
        <div className="d-flex justify-content-center mb-5">
          <div className="btn-group">
            <button className={"btn btn-sm "+((selected === 1) ? "btn-primary":"btn-light")} type='button' onClick={()=>setSelected(1)}>
              <i className="fa fa-calendar"></i>
              Department
            </button>
            <button className={"btn btn-sm "+((selected === 2) ? "btn-primary":"btn-light")} type='button' onClick={()=>setSelected(2)}>
              <i className="fa fa-calendar"></i>
              Lecturer
            </button>
          </div>
        </div>
        {selected === 1 ? <TTDepartment /> : (
          <div className="p-5 border bg-white rounded">
            <p className='text-center mb-0'>Comming soon</p>
          </div>
        )}
        
    </div>
  )
}
