import React, { Component } from 'react'
import Moment from "moment"
import jwt_decode from "jwt-decode"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Link } from 'react-router-dom'
import sign from 'jwt-encode'
import packageJson from "../../../../../../package.json"
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2"
import "./CurrentSchedule.css"
import LoadingBtn from '../../../Widgets/LoadingBtn'

export class CurrentSchedule extends Component {
    constructor(props) {
        super(props);
        this.getSchedule = this.getSchedule.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        const today = new Date(),
            month = today.getMonth() + 1,
            currentDate = today.getFullYear() + "-" + (month < 10 ? ('0' + month) : month) + "-" + today.getDate(),
            currentTime = ((today.getHours() > 10) ? '0' + today.getHours() : today.getHours())
                + ":" + ((today.getMinutes() > 10) ? "0" + today.getMinutes() : today.getMinutes())
                + ":" + ((today.getSeconds() > 10) ? "0" + today.getSeconds() : today.getSeconds());
        this.state = {
            AuthDecode: AuthDecode,
            LoadingPage: false,
            Expired: [],
            SelectedDay: today,
            CurrentDate: currentDate,
            CurrentTime: currentDate + " " + currentTime,
            Schedule: [],
            Page: 1,
            Limit: 5,
            OpenFilter: false,
            count: 0,
            FStartDate: today,
            FEndDate: today,
            Filters: { StartDate: "", EndDate: "" },
            IsError: false,
        };
    }

    getSchedule(page, limit) {
        const reqModule = () => {
            this.setState({ LoadingPage: true });
            var keys = {
                "AppId": this.state.AuthDecode.AppID,
                "AppEnv": this.state.AuthDecode.AppEnv,
                "Secretkey": this.state.AuthDecode.secretkey
            };
            var body = {
                "parameters": {
                    "studentid": this.state.AuthDecode.currentData.entityid,
                    "tgl": this.state.Filters.StartDate,
                    "tgl_end": this.state.Filters.EndDate,
                    "offset": page,
                    "limit": limit,
                    "grouped": "True"
                },
                "target_data": "GET_SCHD_CLASS_STD_NEXT"
            };
            const postData = {
                keys: keys,
                body: body
            };

            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        if (response.error) {
                            this.setState({ IsError: true, LoadingPage: false })
                        } else if ("message" in response.response) {
                            this.setState({ Expired: response.response.message, LoadingPage: false });
                        } else {
                            var param = response.response.data;
                            param.sort((a, b) => new Date(a.start_dt) < new Date(b.start_dt) ? 1 : -1).reverse();
                            this.setState({ Schedule: param, LoadingPage: false });
                        }
                    }
                );
        }
        reqModule();
    }

    componentDidMount() {
        this.getSchedule(this.state.Page, this.state.Limit);
    }

    render() {

        const schedulePage = (currentPage) => {
            if (currentPage !== 0) {
                this.setState({ Page: currentPage });
                this.getSchedule(currentPage, this.state.Limit);
            }
        }

        const openFilter = () => {
            var hit = this.state.count + 1;
            var open = ((hit % 2) == 0) ? false : true;
            this.setState(prev => ({ count: hit, OpenFilter: open }));
        }

        const StartDate = () => (
            <DatePicker selected={this.state.FStartDate}
                onChange={date => {
                    this.setState({ FStartDate: date, Filters: { StartDate: formatDate(date), EndDate: formatDate(this.state.FEndDate) } })
                }} className="form-control" dateFormat="yyyy-MM-dd" placeholderText="Start Date" />
        )

        const EndDate = () => (
            <DatePicker selected={this.state.FEndDate}
                onChange={date => {
                    this.setState({ FEndDate: date, Filters: { StartDate: formatDate(this.state.FStartDate), EndDate: formatDate(date) } })
                }} className="form-control" dateFormat="yyyy-MM-dd" placeholderText="End Date" />
        )

        const formatDate = value => {
            var d = new Date(value),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }


        const searchSchedule = () => {
            this.getSchedule(this.state.Page, this.state.Limit);
        }

        return (
            <div id="time-table-schd">
                <div className="card card-custom card-stretch gutter-b">
                    <div className="card-header border-0">
                        <h3 className="card-title">Timetable for today</h3>
                        <div className="card-toolbar">
                            <div className="btn-group">
                                <button className="btn btn-primary btn-sm font-weight-bolder">{Moment(this.state.CurrentDate).format('D MMMM YYYY')}</button>
                                <button className="btn btn-outline-primary btn-hover-light-primary btn-sm" onClick={(e) => openFilter()}  >
                                    <i className={"ki icon-nm ki-arrow-" + ((this.state.OpenFilter) ? "up" : "down")}></i>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="card-body pt-0">

                        <div id="filter-form" className="filter-schd p-5 border border-secondary bg-light-primary rounded gutter-b" style={{ display: ((this.state.OpenFilter) ? "block" : "none") }}>
                            <div className="form-group mb-2">
                                <label>Find schedule by range date</label>
                                <div className="row">
                                    <div className="col-lg-3 col-xxl-4">
                                        <StartDate />
                                    </div>
                                    <div className="col-lg-2 col-xxl-1">
                                        <small>until</small>
                                    </div>
                                    <div className="col-lg-3 col-xxl-4">
                                        <EndDate />
                                    </div>
                                    <div className="col-lg-3 col-xxl-3">
                                        <button className="btn btn-primary btn-sm ml-3" type="button" onClick={(e) => searchSchedule()} ><i className="fa fa-search"></i> Search</button>
                                    </div>
                                </div>                                
                            </div>
                        </div>


                        {(Object.keys(this.state.Expired).length > 0) ? (
                            <div className="col-xl-12">
                                <div className="alert alert-custom alert-notice alert-light-primary fade show" role="alert">
                                    <div className="alert-icon"><i className="flaticon-warning"></i></div>
                                    <div className="alert-text">{this.state.Expired.user}</div>
                                </div>
                            </div>
                        ) : (
                            (this.state.IsError) ? (
                                <button className="btn btn-outline-success" type='button' onClick={(e) => this.getSchedule(this.state.Page, this.state.Limit)}>
                                    <i className="flaticon2-reload"></i> Retry
                                </button>
                            ) : (
                                <>
                                    {(this.state.LoadingPage) ? (
                                        <LoadingBtn />
                                    ) : ''}
                                    <div style={{ display: ((this.state.LoadingPage) ? "none" : "block") }}>
                                        {(Object.keys(this.state.Schedule).length > 0) ? (
                                            <>
                                                <div className="timeline timeline-6 mt-3">
                                                    <div className="timeline-bar"></div>
                                                    {this.state.Schedule.map((v, index) => (
                                                        <div className="timeline-item align-items-start" key={index}>
                                                            <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                                                                <span className={"label label-inline d-block label-" + ((v.group_class == "A") ? "warning" : ((v.group_class == "B") ? "primary" : "danger"))}>
                                                                    {Moment(v.start_dt).format('D MMMM YYYY')}
                                                                </span>
                                                                {Moment(v.start_dt).format('H:mm')} - {Moment(v.end_dt).format('H:mm')}<br />
                                                                <span className={"ml-2 text-" + ((v.group_class == "A") ? "warning" : ((v.group_class == "B") ? "primary" : "danger"))}>Group {v.group_class}</span>
                                                            </div>
                                                            <div className="timeline-badge">
                                                                <i className={"fa fa-genderless icon-xl text-" + ((v.group_class == "A") ? "warning" : ((v.group_class == "B") ? "primary" : "danger"))}></i>
                                                            </div>
                                                            {(Object.keys(v.course).length > 0) ? (
                                                                <div className="font-weight-mormal font-size-lg timeline-content pl-3" style={{ backgroundColor: "#F3F6F9", borderRadius: "0.85rem", position: "relative", padding: "0.75rem 1.5rem" }}>
                                                                    <h4 className="text-primary">{v.course.coursetitle}</h4>
                                                                    <span className="d-block text-dark-75">{v.course.code}</span>
                                                                    <span className="d-block text-dark-75">Credit {v.course.credit}</span>
                                                                </div>
                                                            ) : (
                                                                <div className="font-weight-mormal font-size-lg timeline-content pl-3">
                                                                    <h4 className="text-danger">Unavailable Course </h4>
                                                                </div>
                                                            )}

                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="btn-group float-right pt-5">
                                                    {(this.state.Page != 1) ? (
                                                        <button className="btn btn-light-primary" type="button" onClick={(e) => schedulePage((this.state.Page - 1))}>Prev</button>
                                                    ) : ''}
                                                    {(this.state.Page > 1) ? (
                                                        <button className="btn btn-light-primary" type="button" onClick={(e) => schedulePage((this.state.Page + 1))} >Next</button>
                                                    ) : ''}
                                                </div>
                                            </>
                                        ) : (
                                            <h4>There is no schedule for today</h4>
                                        )}
                                    </div>
                                </>
                            )
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CurrentSchedule
