import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import SemesterScore from "./SemesterScore";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";

export function ScoreViewIndex() {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
  const [stdStatus, setStdStatus] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const [stdStatusData, setStdStatusData]=useState({});
  const [currentSemes, setCurrentSemes] = useState();

  const GET_STUDENT_STATUS = () => {
    setStdStatus({ ...stdStatus, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: AuthDecode.currentData.entityid,
      },
      target_data: "GET_STUDENT_STATUS",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdStatus({
          data: [],
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdStatus({
          data:[],
          message: msg,
          loading: false,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setStdStatusData(results);
          var stdSemes = results.semester_status;
          const currentAtpid = results.atp_term ? results.atp_term.id : 0;
          if(Object.values(stdSemes).length > 0){
              stdSemes.sort((a, b) => (a.semester < b.semester ? 1 : -1));
              setStdStatus({
                  data: stdSemes,
                  message: "",
                  loading: false,
              });

              const getCurrentSemes = stdSemes.find(item => item.atp_term.id === currentAtpid);
              setCurrentSemes(getCurrentSemes);

              const objParam = {
                  studentid: AuthDecode.currentData.entityid,
                  atpid: results.atp_term.id
              }
              GET_STUDENT_COURSE(objParam);
          }else{
            setStdStatus({
              data:[],
              message: "Semester status is empty, please contact your FAO about this",
              loading: false,
            });
          }
        } else {
          setStdStatus({
            data: [],
            message: "No record found",
            loading: false,
          });
        }
      }
    });
  };

  const [fpcf, setFpcf] = useState({ loading: false, data: [], message: "" });
  const [sumSKS, setSumSKS] = useState(0);
  const GET_STUDENT_COURSE = (param) => {
      setFpcf({ loading: true, data: [], message: "" });
      var keys = {
          AppId: AuthDecode.AppID,
          AppEnv: AuthDecode.AppEnv,
          Secretkey: AuthDecode.secretkey,
      };
      var body = {
          parameters: param,
          target_data: "GET_STUDENT_COURSE",
      };
      const postData = {
          keys: keys,
          body: body,
      };

      AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
          if (response.error) {
              setFpcf({ loading: false, data: [], message: response.error });
          } else if ("message" in response.response) {
              setFpcf({ loading: false, data: [], message: response.response.message.user });
          } else {
              var results = response.response.data;
              if (results) {
                  var total = results.reduce((a, v) => a = a + ((v.status === 'ACTIVE' && v.course.course_type !== "asc.course.type.optional") ? v.credit : 0), 0);
                  setSumSKS(total);
                  setFpcf({ loading: false, data: results, message: "" });
              } else {
                  setFpcf({ loading: false, data: results, message: "No record found" });
              }
          }
      });
  }

  useEffect(() => {
    GET_STUDENT_STATUS();
  }, []);

  const EnrollmentType = ({value}) =>{
    let results = "";
    var enrollArr = value.split(".");
    results = enrollArr[Object.values(enrollArr).length - 1];
    return results;
  }

  return (
    <div id="view-score-std">
      <div
        className="alert alert-custom alert-white alert-shadow fade show gutter-b"
        role="alert"
      >
        <div className="alert-icon">
          <span className="svg-icon svg-icon-primary svg-icon-xl">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                  fill="#000000"
                  opacity="0.3"
                ></path>
                <path
                  d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                  fill="#000000"
                  fillRule="nonzero"
                ></path>
              </g>
            </svg>
          </span>
        </div>
        <div className="alert-text h4">View Score</div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-8 col-xxl-9">
          {stdStatus.loading ? <LoadingBtn /> : (
            stdStatus.message ? <AlertNotif message={stdStatus.message} color={"danger"} title={"Information"} /> : (
              Object.values(stdStatus.data).length > 0 ? (
                <SemesterScore stdStatus={stdStatus.data} />
              ) : ""
            )
          )}
          
        </div>
        <div className="col-12 col-lg-4 col-xxl-3">
        {Object.values(stdStatus.data).length > 0 ? (
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body px-0">
              <div className="data-info">
                <p className="font-size-h4 font-weight-bolder text-center">
                  <span className="d-block">{AuthDecode.currentData.given_name+" "+AuthDecode.currentData.family_name}</span>
                  <span className="text-muted font-size-h6">{stdStatusData.studentid}</span>
                </p>
                <div className="d-flex bg-light justify-content-center align-items-center border-top border-bottom py-3">
                  <div className="p-2 text-center">
                    <span className="d-block font-weight-bolder h2">
                      {currentSemes ? (currentSemes.cgpa ? currentSemes.cgpa : 0) : 0}
                    </span>
                    <span className="text-muted font-size-sm font-weight-bolder">
                      CGPA
                    </span>
                  </div>
                  <div className="p-2 text-center mx-5">
                    <span className="d-block font-weight-bolder h2">
                      {currentSemes ? (currentSemes.semester ? currentSemes.semester : 0) : 0}
                    </span>
                    <span className="text-muted font-size-sm font-weight-bolder">
                      Semester
                    </span>
                  </div>
                  <div className="p-2 text-center">
                    <span className="d-block font-weight-bolder h2">
                      {currentSemes ? (currentSemes.credit_all ? currentSemes.credit_all : 0) : 0} SKS
                    </span>
                    <span className="text-muted font-size-sm font-weight-bolder">
                      Current Semester Credit
                    </span>
                  </div>
                </div>
              </div>

              <div className="px-8 mt-5">
                <p className="d-flex justify-content-between">
                  <span>Status:</span> 
                  <span className="font-weight-bolder">{(stdStatusData.status) ? stdStatusData.status.name : "-"}</span></p>
                <p className="d-flex justify-content-between">
                  <span>Program:</span>
                  <span className="font-weight-bolder">{(stdStatusData.program) ? stdStatusData.program.name : "-"}</span></p>
                <p className="d-flex justify-content-between">
                  <span>Department: </span>
                  <span className="font-weight-bolder text-right">{(stdStatusData.clu) ? stdStatusData.clu.name : "-"}</span></p>
                {(stdStatusData.clu_spec) ? (
                  <p className="d-flex justify-content-between">
                    <span>Specialization:</span>
                    <span className="font-weight-bolder text-right">{stdStatusData.clu_spec.name}</span></p>
                ) : ""}
                <p className="d-flex justify-content-between">
                  <span>Term Intake: </span>
                  <span className="font-weight-bolder text-right">{(stdStatusData.term_intake) ? stdStatusData.term_intake.name : "-"}</span>
                </p>
                <p className="d-flex justify-content-between">
                  <span>Cumulative Earned Credits: </span>
                  <span className="font-weight-bolder text-right">{(stdStatusData.ccredit_pass) ? stdStatusData.ccredit_pass : "0"} SKS</span>
                </p>
                <p className="d-flex justify-content-between">
                  <span>Enrollment Type: </span>
                  <span className="font-weight-bolder text-capitalize text-right">{(stdStatusData.enrollment_type_id) ? <EnrollmentType value={stdStatusData.enrollment_type_id} /> : "-"}</span>
                </p>
              </div>
            </div>
          </div>
        ) : ""}
        </div>
      </div>
    </div>
  );
}
