import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import AxiosLib from '../../../Library/AxiosLib';
import LoadingBtn from '../../Widgets/LoadingBtn';
import { openModal } from '../../../Library/ModalPopUp';
import Form from './Form';
import { DetailAnnouncement } from './DetailAnnouncement';

export class Announcements extends Component {
    constructor(props) {
        super(props);
        this.fetchingAnnouncements = this.fetchingAnnouncements.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode: AuthDecode,
            announcements: { loading: false, message: "", data: [] },
            FormOpen: false
        };
    }

    fetchingAnnouncements(param) {
        this.setState({ announcements: { loading: true, message: "", data: [] } })
        AxiosLib(param, "mc/api/notifications/fetch").then(
            (response) => {
                if (response.error) {
                    this.setState({ announcements: { loading: false, message: response.error, data: [] } })
                } else {
                    if (response.data) {
                        this.setState({ announcements: { loading: false, message: "", data: response.data } })
                    } else {
                        this.setState({ announcements: { loading: false, message: "No record found", data: [] } })
                    }
                }
            }
        );
    }

    componentDidMount() {
        this.fetchingAnnouncements({});
    }

    render() {

        const HandlerRemove = (data) => {
            if (window.confirm("Are you sure want to remove ?")) {
                var dataParam = { ID: data.ID };
                this.setState({ announcements: { loading: true, message: "", data: [] } })
                AxiosLib(dataParam, "mc/api/notifications/delete").then(
                    (response) => {
                        console.log(response);
                        if (response.error) {
                            this.setState({ announcements: { loading: false, message: response.error, data: [] } })
                        } else {
                            if (response.result) {
                                //this.setState({ announcements: { loading: false, message: "", data: dataParam } })
                                this.fetchingAnnouncements({});
                            } else {
                                this.setState({ announcements: { loading: false, message: "Failed removed", data: [] } })
                            }
                        }
                    }
                );
            }
        }

        const OpenFormAnn = () => {
            this.setState({ FormOpen: true })
        }

        return (
            <div id='announcements'>
                <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                    <div className="alert-icon">
                        <span className="svg-icon svg-icon-primary svg-icon-xl">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                    <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div className="d-flex align-items-center mr-2 py-2">
                        <h3 className="font-weight-bold mb-0 mr-10">Announcements</h3>
                    </div>
                </div>

                {this.state.FormOpen === true ? (
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Form Announcement</h3>
                        </div>
                        <div className="card-body">
                            <Form />
                        </div>
                    </div>
                ) : (
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                                <h3 className="card-label">List of all announcement</h3>
                            </div>
                            <div className="card-toolbar">
                                <button className="btn btn-primary font-weight-bolder text-uppercase" type='button' onClick={() => OpenFormAnn()}>
                                    <i className="fa fa-plus-circle"></i> Add new
                                </button>
                            </div>
                        </div>
                        <div className="card-body px-0 pt-0">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr className='bg-light font-weight-bolder'>
                                            <th>No</th>
                                            <th>Title</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Menus</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.announcements.loading ? (
                                            <tr>
                                                <td colSpan={7}><LoadingBtn /></td>
                                            </tr>
                                        ) : this.state.announcements.message ? (
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className="p-5 border bg-light rounded">
                                                        <div className="text-center">
                                                            <p className='mb-0'><i className="icon-2x flaticon2-box-1"></i></p>
                                                            <p className='mb-0 font-size-lg'>{this.state.announcements.message}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            Object.values(this.state.announcements.data).length > 0 ? (
                                                this.state.announcements.data.map((v, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{v.Title}</td>
                                                        <td>{v.StartFrom}</td>
                                                        <td>{v.EndFrom}</td>
                                                        <td>
                                                            {Object.values(v.Menus).length > 0 ? (
                                                                v.Menus.map((m, index) => (
                                                                    <span key={index} className='badge badge-info mr-1'>
                                                                        {m.MenuName ? m.MenuName : "Dashboard"}
                                                                    </span>
                                                                ))
                                                            ) : ""}</td>
                                                        <td>
                                                            <span className="d-block font-weight-bolder">{v.CreatedAt}</span>
                                                            <span className="text-muted">{v.CreatedBy}</span>
                                                        </td>
                                                        <td>
                                                            <div className="btn-group">
                                                                <button className="btn btn-primary btn-sm" type='button' title='Preview' onClick={() => openModal({ header: "Detail Announcement", message: <DetailAnnouncement data={v} /> })}>
                                                                    <i className="fa fa-eye"></i>
                                                                </button>
                                                                <button className="btn btn-danger btn-sm" type='button' title='Remove' onClick={() => HandlerRemove(v)}>
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : ""
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
