import React from 'react'
import Background from "../../media/error/bg3.jpg";
import parse from 'html-react-parser'

export default function UnderMaintenance(props) {
    return (
        (props.data) ? (
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30" style={{ backgroundImage: "url(https://sgu-s3apps.s3.ap-southeast-1.amazonaws.com/mysgu/maintenance.jpg)" }}>
                    <h1 className="font-weight-boldest text-dark-75 mt-15" style={{fontSize:'5em'}}>{props.data.Title}</h1>
                    <div className="font-size-h3 text-secondary font-weight-normal">{(props.data.Description) ? parse(props.data.Description) : ''}</div>
                </div>
            </div>
        ) : (
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row-fluid flex-column p-10 p-sm-30">
                    <h1 className="font-weight-boldest text-dark-75 mt-15 text-center" style={{ fontSize: "10rem" }}>Swiss German University</h1>
                </div>
            </div>
        )
    )
}
