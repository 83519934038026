import React from 'react'
import jwt_decode from "jwt-decode";
import ExcelJs from "exceljs";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

const DownloadFinalScore = (obj, myfilename) => {

    const wb = new ExcelJs.Workbook();
    wb.creator = "MY-SGU";
    wb.lastModifiedBy = "MY-SGU-Report-Score";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    SheetExcel(wb, obj, myfilename);

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile("Report Score " + myfilename, buffer);
    });
}

const SheetExcel = (wb, param, myfilename) => {
    const tableHeader = [
        { key: "A", title: "No", width: 10 },
        { key: "B", title: "Student ID", width: 20 },
        { key: "C", title: "Student Name", width: 40 },
        { key: "D", title: "Quiz 1", width: 15 },
        { key: "E", title: "Quiz 2", width: 15 },
        { key: "F", title: "Quiz 3", width: 15 },
        { key: "G", title: "Midterm", width: 15 },
        { key: "H", title: "Quiz 4", width: 15 },
        { key: "I", title: "Quiz 5", width: 15 },
        { key: "J", title: "Quiz 6", width: 15 },
        { key: "K", title: "Final Exam", width: 20 },
        { key: "L", title: "Score", width: 15 },
        { key: "M", title: "Grade", width: 15 },
        { key: "N", title: "Attendance", width: 15 }];

    const headers = tableHeader;
    const ws = wb.addWorksheet("Section " + myfilename);
    const cloneHeader = [...headers];
    const newHeader = cloneHeader.map((obj) => {
        //delete obj.title;
        var x = {};
        x.key = obj.key;
        x.width = obj.width;
        return x;
    });
    ws.columns = newHeader;

    ws.getRow(1).getCell("A").value = "Report Score Section Id " + myfilename;
    ws.getRow(1).getCell("A").font = {
        bold: true,
        size: 16,
    };

    var coursename = "", course_credit="",coursetype = "";
    var selectedClass = param.header;
    if(selectedClass.course){
        coursename += (selectedClass.course.coursetitle) ? selectedClass.course.coursetitle : "-";
        coursename += "/";
        coursename += (selectedClass.course.code) ? selectedClass.course.code : "-";
        course_credit += (selectedClass.course.credit) ? selectedClass.course.credit : "-";
        let courseTypeArr = selectedClass.course.course_type.split(".");
        let courseType = courseTypeArr[courseTypeArr.length - 1];
        coursetype += courseType;
    }
    ws.getRow(3).getCell("A").value = "Course";
    ws.getRow(3).getCell("B").value = ": "+coursename;

    ws.getRow(4).getCell("A").value = "Credit";
    ws.getRow(4).getCell("B").value = ": "+course_credit;

    ws.getRow(5).getCell("A").value = "Type";
    ws.getRow(5).getCell("B").value = ": "+coursetype;

    ws.getRow(6).getCell("A").value = "Lecturer";
    ws.getRow(6).getCell("B").value = ": "+AuthDecode.currentData.given_name + " "+ AuthDecode.currentData.family_name;
    

    let rowNumber = 8;
    const rowHeader = ws.getRow(rowNumber);
    tableHeader.map((v, index) => {
        var i = index + 1;
        rowHeader.getCell(i).value = v.title;
        rowHeader.getCell(i).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        }
        rowHeader.getCell(i).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "191970" },
        };
        rowHeader.getCell(i).alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        rowHeader.getCell(i).font = {
            bold: true,
            size: 11,
            color: { argb: "FFFFFF" },
        };
    })

    rowNumber = rowNumber + 1;
    if(Object.values(param.data).length > 0){
        param.data.map((v,index)=>{
            const row = ws.getRow(rowNumber);
            row.getCell(1).value = ((index+1));
            row.getCell(2).value = (v.studentid);
            row.getCell(3).value = (v.studentname);
            row.getCell(4).value = (v.quiz_1);
            row.getCell(5).value = (v.quiz_2);
            row.getCell(6).value = (v.quiz_3);
            row.getCell(7).value = (v.midterm);
            row.getCell(8).value = (v.quiz_4);
            row.getCell(9).value = (v.quiz_5);
            row.getCell(10).value = (v.quiz_6);
            row.getCell(11).value = (v.finalexam);
            row.getCell(12).value = (v.score);
            row.getCell(13).value = (v.grade_fin);
            row.getCell(14).value = (v.atd)+"%";
            rowNumber++;
        })
    }else{
        ws.getRow(9).getCell("A").value = "No record found";
    }

}

export default DownloadFinalScore;