import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Moment from "moment";
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../Library/AlertNotif";

export default function MyDetailAtd({param}) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const [atdStd, setAtdStd] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const GET_ATD_STUDENT_BY_COURSE = (param) => {
        setAtdStd({ ...atdStd, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: param,
            target_data: "GET_ATD_STUDENT_BY_COURSE",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setAtdStd({
                    ...atdStd,
                    message: response.error,
                    loading: false,
                    data: []
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setAtdStd({
                    ...atdStd,
                    message: msg,
                    loading: false,
                    data: []
                });
            } else {
                var results = response.response.data;
                if (results) {
                    setAtdStd({
                        ...atdStd,
                        data: results,
                        message: "",
                        loading: false,
                    });
                } else {
                    setAtdStd({
                        ...atdStd,
                        data: [],
                        message: "No record found",
                        loading: false,
                    });
                }
            }
        });
    };

    useEffect(() => {
        GET_ATD_STUDENT_BY_COURSE(param);
    }, []);

    return (
        <div className="table-responsive" style={{ maxHeight: "400px" }}>
            <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                <thead>
                    <tr className="text-uppercase text-primary font-weight-bolder">
                        <th>No</th>
                        <th width={150}>Schedule Date</th>
                        <th>Teaching Topic</th>
                        <th>Status</th>
                        <th width={150}>Tap Time</th>
                        <th>Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {atdStd.loading ? (
                        <tr>
                            <td colSpan={6}><LoadingBtn /></td>
                        </tr>
                    ) : atdStd.message ? (
                        <tr>
                            <td colSpan={6}><AlertNotif message={atdStd.message} color={"danger"} title={"Information"} /></td>
                        </tr>
                    ) : (Object.values(atdStd.data).length > 0 ? (
                        atdStd.data.map((v, index) => (
                            <tr key={index} className={(v.flag_sts === 3 || v.flag_sts === 4 || v.flag_sts === 5) ? "bg-light-danger" : ""}>
                                <td>{index + 1}</td>
                                <td>
                                    <span className="d-block font-weight-bolder">{v.atd_lecturer ? ((v.atd_lecturer.start_dt) ? Moment(v.atd_lecturer.start_dt).format("D MMM YYYY") : "") : ""}</span>
                                    <span className="text-dark">
                                        {v.atd_lecturer ? ((v.atd_lecturer.start_dt) ? Moment(v.atd_lecturer.start_dt).format("hh:mm a") : "") : ""} -
                                        {v.atd_lecturer ? ((v.atd_lecturer.end_dt) ? Moment(v.atd_lecturer.end_dt).format("hh:mm a") : "") : ""}
                                    </span>
                                </td>
                                <td>
                                    <span className="d-block font-weight-bolder">
                                        {v.atd_lecturer ? v.atd_lecturer.emp.fullname : ""}
                                    </span>
                                    <span className="font-size-7">
                                        {v.atd_lecturer ? v.atd_lecturer.teaching_topic : ""}
                                    </span>
                                </td>
                                <td className="text-center">
                                    <span className="font-weight-bolder">
                                        {v.status}
                                    </span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder">
                                        <span className="d-block">{v.tap_time ? Moment(v.tap_time).format("D MMM YYYY") : "-"}</span>
                                        <span>{v.tap_time ? Moment(v.tap_time).format("hh:mm a") : ""}</span>
                                    </span>
                                </td>
                                <td>
                                    <span className="font-weight-bolder">
                                        {v.remark ? v.remark : ""}
                                    </span>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6}>No record found</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
