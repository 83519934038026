import React, { Component } from 'react'
import Activity from './Activity'

export class ThesisActivitySTD extends Component {
  render() {
    return (
      <Activity />
    )
  }
}
