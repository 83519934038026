import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import IconPDF from "../../../../media/files/pdf.svg";
import packageJson from "../../../../../package.json";

export class Regulations extends Component {
    constructor(props){
        super(props);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode:AuthDecode,
            Loading:false,
            FileDoc:[{title:"Intake 2017", url:packageJson.system_param.OldMySGU+"images/file_uploads/regul/STUDY_AND_EXAMINATIONS_REGULATION_FOR_BACHELORS_DEGREE_INTAKE_2017.pdf"},
                     {title:"Intake 2016", url:packageJson.system_param.OldMySGU+"images/file_uploads/regul/Study_Examination_Regulations_Bachelors_Degree_Intake_2016_27June2016_Rev01_DiP.pdfimages/file_uploads/thesis/schedule/2020/ThesisDefenseSchedule.MT_Rev1.pdf"},
                     {title:"Intake 2015", url:packageJson.system_param.OldMySGU+"images/file_uploads/regul/Study_Exam%20Regulation%20Bachelors%20Degree%20Intake%202015%20Rev%2023July15_MiA_2.pdfimages/file_uploads/thesis/template_thesis.zip"},
                     {title:"Intake 2014", url:packageJson.system_param.OldMySGU+"images/file_uploads/regul/Academic%20Regulation%20for%20Bachelor%20Programs%20Intake%202014%20-%20v-08082014_2.pdf"},
                     {title:"Intake 2013", url:packageJson.system_param.OldMySGU+"images/file_uploads/regul/StudyGuidelinesAndRegulationisi2013.pdf"},
                     {title:"Intake 2012", url:packageJson.system_param.OldMySGU+"images/file_uploads/regul/Study%20Guidelines%20And%20Regulation%202012.pdf"},
                     {title:"Intake 2011", url:packageJson.system_param.OldMySGU+"images/file_uploads/regul/Study_Examination%20Regulation%202011.pdf"}]
        
        };
    }

    render() {
        return (
            <div id="exam-reschedule">
                <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                    <div className="alert-icon">
                        <span className="svg-icon svg-icon-primary svg-icon-xl">
                            <svg  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                    <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fill-rule="nonzero"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div className="alert-text">
                        <h3>Study & Examination Regulation</h3>
                    </div>
                </div>

                <div className="card card-custom gutter-b">
                    <div className="card-body">
                        <div className="font-size-h4">
                            <p>Swiss German University is a legally recognized, private university in Indonesia. The bachelor's study programs in Swiss German University consist of a minimum of eight semesters. Applicants have to fulfill the requirements of the respective program.</p>
                            <p>During each academic semester, there are quizzes and a final examination of each subject. Later in the study, there is an Oral Final Study Examination (OFSE) in three subjects. The study is finalized with a thesis and a thesis defense.</p>
                            <p>These Study and Examination Regulations apply to the bachelor's study programs based on each intake year. They regulate the academic parts, such as content, structure and process of study as well as administrative matters, such as discipline and behavior</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {this.state.FileDoc.map((v,index)=>(
                        <div className="col-md-3" key={index}>
                            <div className="card card-custom gutter-b">
                                <div className="card-body">
                                    <div className="d-flex flex-column align-items-center">
                                        <img alt="" className="max-h-65px" src={IconPDF} />
                                        <a href={v.url} className="text-dark-75 font-weight-bold mt-2 font-size-lg">{v.title}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}    
                </div>

                <div className="card card-custom">
                    <div className="card-header">
                        <h3 className="card-title">
                            Graduation Predicate for Bachelor's and Master's Degree Graduation Year 2017
                        </h3>
                    </div>
                    <div className="card-body">
                        <p className="font-size-h4">The Decree of Rector of Swiss German University on the Determination of Graduation Predicate for Bachelor's and Master's Degree Graduation Year 2017 is issued as below.</p>
                        <div className="d-flex flex-column align-items-center">
                            <img alt="" className="max-h-65px" src={IconPDF} />
                            <a href="https://my.sgu.ac.id/images/file_uploads/regul/RectorsDecree_Determination_of_Graduation_Predicate_for_Bachelor_Master_30Aug2017.pdf" className="text-dark-75 font-weight-bold mt-2 font-size-lg">Rector's Decree</a>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Regulations;