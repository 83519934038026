import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../Library/AlertNotif";
import { openToast } from "../../../Library/ToastPopUp";

export default function Filter(props) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [clu, setCLU] = useState({
    loading: false,
    message: "",
    data: [],
    iserror: false,
  });

  const fetchCLU = () => {
    setCLU({ ...clu, loading: true });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      target_data: "CLU_LOOK_UP",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setCLU({
          ...clu,
          iserror: true,
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setCLU({ ...clu, message: msg, loading: false });
      } else {
        var results = response.response.data;
        if (results) {
          setCLU({ ...clu, data: results, loading: false });
        } else {
          setCLU({
            ...clu,
            iserror: true,
            message: "No record found",
            loading: false,
            data: [],
          });
        }
      }
    });
  };

  const [acad, setAcad] = useState({
    loading: false,
    message: "",
    data: [],
    iserror: false,
  });

  const fetchAcad = () => {
    setAcad({ ...acad, loading: true });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        type: "asc.atp.type.term",
      },
      target_data: "GET_ACAD_LOOKUP",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setAcad({
          ...acad,
          iserror: true,
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setAcad({ ...acad, message: msg, loading: false });
      } else {
        var results = response.response.data;
        if (results) {
          setAcad({ ...acad, data: results, loading: false });
        } else {
          setAcad({
            ...acad,
            iserror: true,
            message: "No record found",
            loading: false,
            data: [],
          });
        }
      }
    });
  };

  const [actType, setActType] = useState({
    loading: false,
    message: "",
    data: [],
    iserror: false,
  });

  const fetchActType = () => {
    setActType({ ...actType, loading: true });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        grouptype: "student.activity",
      },
      target_data: "TYPE_LOOK_UP",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setActType({
          ...actType,
          iserror: true,
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setActType({ ...actType, message: msg, loading: false });
      } else {
        var results = response.response.data;
        if (results) {
          setActType({ ...actType, data: results, loading: false });
        } else {
          setActType({
            ...actType,
            iserror: true,
            message: "No record found",
            loading: false,
            data: [],
          });
        }
      }
    });
  };

  useState(() => {
    fetchCLU();
    fetchAcad();
    fetchActType();
  }, []);

  const handlerSubmit = (e) => {
    e.preventDefault();
    var formid = e.target.id;
    var target = document.getElementById(formid);
    var myButton = target.getElementsByClassName("btn-submit")[0];
    var classSpinner = ["spinner", "spinner-white", "spinner-left"];

    if(props.paramSA.activity_type /*&& props.paramSA.atpid*/ ){
        props.submitStudentAct();
    }else{
        openToast({header:"Error Filtering", message:"Please fill up the form with correctly"});
    }
    
    
  };
  return (
    <div className="card card-custom gutter-b ">
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">Form Filtering</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            please fill up this filed with correctly
          </span>
        </h3>
      </div>
      <div className="card-body pt-4">
        {clu.loading || acad.loading || actType.loading ? <LoadingBtn /> : ""}
        {clu.message ? (
          <AlertNotif title={"Error"} message={clu.message} />
        ) : (
          <form
            autoComplete="off"
            onSubmit={(e) => handlerSubmit(e)}
            id="form-filtering"
            className={
              clu.loading || acad.loading || actType.loading
                ? "d-none"
                : "d-block"
            }
          >
            <div className="row">
              <div className="col-12">
                <div className="fv-row fv-plugins-icon-container mb-8">
                  <label className="required font-weight-bold mb-2">
                    Activity Type <sup className="text-danger font-weight-bolder">*</sup>
                  </label>
                  <select
                    className="form-control"
                    value={props.paramSA.activity_type}
                    onChange={(e) =>
                      props.setParamSA({
                        ...props.paramSA,
                        activity_type: e.target.value,
                      })
                    }
                  >
                    <option value="">Choose one</option>
                    <option value="asc.student.activity.pcf">PCF</option>                               
                    <option value="asc.student.activity.pcfr">PCF Revision</option>   
                  </select>
                </div>

                <div className="fv-row fv-plugins-icon-container mb-8">
                  <label className="font-weight-bold mb-2">
                    Term Type
                  </label>
                  <select
                    className="form-control"
                    value={props.paramSA.atpid}
                    onChange={(e) =>
                      props.setParamSA({
                        ...props.paramSA,
                        atpid: parseInt(e.target.value),
                      })
                    }
                  >
                    <option value="0">Choose one</option>
                    {Object.values(acad.data).length > 0
                      ? acad.data.map((v, index) => (
                          <option value={v.id} key={index}>
                            {v.name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>

                <div className="fv-row fv-plugins-icon-container mb-8">
                  <label className="font-weight-bold mb-2">
                    Program Study
                  </label>
                  <select
                    className="form-control"
                    value={props.paramSA.clu_id}
                    onChange={(e) =>
                      props.setParamSA({
                        ...props.paramSA,
                        clu_id: parseInt(e.target.value),
                      })
                    }
                  >
                    <option value="0">Choose one</option>
                    {Object.values(clu.data).length > 0
                      ? clu.data.map((v, index) => (
                          <option value={v.id} key={index}>
                            {v.name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>

                <div className="fv-row fv-plugins-icon-container mb-8">
                  <label className="font-weight-bold mb-2">
                    Student ID
                  </label>
                  <input
                    type="text"
                    placeholder="Enter studentid"
                    className="form-control"
                    value={props.paramSA.studentid}
                    onChange={(e) =>
                      props.setParamSA({
                        ...props.paramSA,
                        studentid: e.target.value,
                      })
                    }
                  />
                </div>

                
              </div>

              <div className="col-12">
                <div className="btn-group float-right">
                  <button
                    className="btn btn-light font-weight-bolder"
                    type="reset"
                  >
                    Clear
                  </button>
                  <button
                    className="btn btn-success font-weight-bolder btn-submit"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
