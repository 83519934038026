import React from 'react'

export function DetailAnnouncement({data}) {
  return (
    <div>
      <h3>{data.Title}</h3>
      <div style={{overflow:"auto", minWidth:"200px"}}>
        <div dangerouslySetInnerHTML={{__html:data.Message}} />
      </div>
    </div>
  )
}
