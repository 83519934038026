import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { GET_SECTION } from './apis';
import LoadingBtn from '../../../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../../../Library/AlertNotif';
import { TableCurrentClass } from '../../../../ScoreandGrade/Widget/WCoursesDetail';
import UpdateComp from "./UpdateComp";



var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export function SchemaScore(props) {
    const param = props.data[props.data.length - 1];
    const section_id = param.section_id;

    const [section, setSection] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const ReloadData = () =>{
        const paramSection = { section_id: section_id };
        GET_SECTION(paramSection, setSection);
        //console.log(param)
    }

    useEffect(() => {
        ReloadData();
    }, []);

    const HandlerBack = () =>{
        props.setSectiondata(prevData => ({
            ...prevData,
            message: "",
            data: [ ],
            loading: false,
          }));
       // ReloadData();
       props.reloadAwal(1);
    }
    const HandlerProgressBar = () => {
        
      };

    return (
        <div id='submit-score-schema'>
            <div className="mb-5">
                {section.loading ? (
                    <LoadingBtn />
                ) : section.message ? (
                    <AlertNotif message={section.message} color={"danger"} />
                ) : Object.values(section.data).length ? (
                    <TableCurrentClass data={section.data} />
                ) : (
                    "-"
                )}
            </div>

            <div className="my-5">
                {section.loading ? (
                    <LoadingBtn />
                ) : section.message ? (
                    <AlertNotif message={section.message} color={"danger"} />
                ) : Object.values(section.data).length ? (
                    <UpdateComp section_id={section_id} HandlerBack={HandlerBack} HandlerProgressBar={HandlerProgressBar} />
      
                ) : (
                    "-"
                )}
            </div>
        </div>
    )
}