import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import Transcript from "./Transcript";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";

export class PrintTranscript extends Component {
  constructor(props) {
    super(props);
    this.getProfile = this.getProfile.bind(this);
    this.getStudentStatus = this.getStudentStatus.bind(this);
    this.getTranscript = this.getTranscript.bind(this);
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    this.state = {
      AuthDecode: AuthDecode,
      Profile: { loading: false, data: [], message: "" },
      StudentStatus: { loading: false, data: [], message: "" },
      SemesterStatus: [],
      CoursePlan: { loading: false, data: [], message: "" },
      CSemester: {atpid:0, data:[]}
    };
  }

  getProfile() {
    const reqModule = () => {
      this.setState({ Profile: { loading: true, data: [], message: "" } })
      var keys = {
        AppId: this.state.AuthDecode.AppID,
        AppEnv: this.state.AuthDecode.AppEnv,
        Secretkey: this.state.AuthDecode.secretkey,
      };
      var body = {
        parameters: {
          studentid: this.state.AuthDecode.currentData.entityid,
        },
        target_data: "GET_STUDENT_DATA_M",
      };
      const postData = {
        keys: keys,
        body: body,
      };

      AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
        if (response.error) {
          this.setState({ Profile: { loading: false, data: [], message: response.error } })
        } else if ("message" in response.response) {
          this.setState({ Profile: { loading: false, data: [], message: response.response.message.user } })
        } else {
          var results = response.response.data;
          if (results) {
            this.setState({ Profile: { loading: false, data: results, message: "" } })
          } else {
            this.setState({ Profile: { loading: false, data: [], message: "No record found" } })
          }
        }
      });
    };
    reqModule();
  }

  getStudentStatus() {
    const reqModule = () => {
      this.setState({ StudentStatus: { loading: true, data: [], message: "" } })
      var keys = {
        AppId: this.state.AuthDecode.AppID,
        AppEnv: this.state.AuthDecode.AppEnv,
        Secretkey: this.state.AuthDecode.secretkey,
      };
      var body = {
        parameters: {
          studentid: this.state.AuthDecode.currentData.entityid,
        },
        target_data: "GET_STUDENT_STATUS",
      };
      const postData = {
        keys: keys,
        body: body,
      };

      AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
        if (response.error) {
          this.setState({ StudentStatus: { loading: false, data: [], message: response.error } })
        } else if ("message" in response.response) {
          this.setState({ StudentStatus: { loading: false, data: [], message: response.response.message.user } })
        } else {
          var results = response.response.data;
          if (results) {
            var semester = results.semester_status;
            semester.sort((a, b) => (a.semester > b.semester ? 1 : -1));
            this.setState({
              StudentStatus: { loading: false, data: results, message: "" },
              SemesterStatus: semester,
              CSemester:{atpid:semester[0].atp_term.id, data:semester[0]}
            })
            this.getTranscript(semester[0].atp_term.id);
          } else {
            this.setState({ StudentStatus: { loading: false, data: [], message: "No record found" } })
          }
        }
      });
    };
    reqModule();
  }

  getTranscript(value) {
      this.setState({ CoursePlan: { loading: true, data: [], message: "" } })
      const reqModule = () => {
        var keys = {
          AppId: this.state.AuthDecode.AppID,
          AppEnv: this.state.AuthDecode.AppEnv,
          Secretkey: this.state.AuthDecode.secretkey,
        };
        var body = {
          parameters: {
            studentid: this.state.AuthDecode.currentData.entityid,
            atpid: value,
          },
          target_data: "GET_SCR_STUDENT_BY_ATPID",
        };
        const postData = {
          keys: keys,
          body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
          if (response.error) {
            this.setState({ CoursePlan: { loading: false, data: [], message: response.error } })
          } else if ("message" in response.response) {
            this.setState({ CoursePlan: { loading: false, data: [], message: response.response.message.user } })            
          } else {
            var results = response.response.data;
            if (results) {
              this.setState({ CoursePlan: { loading: false, data: results, message: "" } })
            } else {
              this.setState({ CoursePlan: { loading: false, data: [], message: "No result found" } })
            }
          }
        });
      };
      reqModule();
  }

  componentDidMount() {
    this.getStudentStatus();
    this.getProfile();
  }

  render() {

    const chooseSemester = (atpid) => {
      if(atpid > 0){
        const findSemes = this.state.SemesterStatus.find(item => item.atp_term.id === atpid);
        this.getTranscript(atpid);
        this.setState({CSemester:{atpid:atpid, data:findSemes}})        
      }else{
        alert("Please choose semester");
      }
    };



    return (
      <div id="print-transcript">
        {this.state.Profile.loading || this.state.StudentStatus.loading ? <LoadingBtn /> :
          (this.state.Profile.message || this.state.StudentStatus.message) ? <AlertNotif message={this.state.Profile.message || this.state.StudentStatus.message} color={"danger"} title={"Information"} /> :
            (Object.values(this.state.Profile.data).length > 0 && Object.values(this.state.StudentStatus.data).length > 0) ? (
              <div className="row">
                <div className="col-3">
                  <div className="card card-custom gutter-b">
                    <div className="card-body">
                      <p className="font-weigh-bolder">Choose Semester:</p>
                      <div className="input-group">
                        <select
                          name="semester"
                          className="form-control"
                          defaultValue={this.state.CSemester.atpid}
                          onChange={(e) => chooseSemester(parseInt(e.target.value))}
                        >
                          <option value="0">Choose one</option>
                          {Object.values(this.state.SemesterStatus).length > 0
                            ? this.state.SemesterStatus.map((s, index) => (
                              (s.atp_term.id) ? (
                                <option
                                  key={index}
                                  value={s.atp_term.id}
                                >
                                  Semester {s.semester}
                                </option>
                              ) : ''
                            ))
                            : ""}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ width: '790px' }}>
                  <Transcript status={this.state.StudentStatus.data} 
                              profile={this.state.Profile.data} 
                              selected={this.state.CSemester.data.semester} 
                              CurrentSemesStatus={this.state.CSemester.data} 
                              CoursePlan={this.state.CoursePlan} />
                </div>
              </div>
            ) : ""}
      </div>
    );
  }
}

export default PrintTranscript;
