import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { openToast } from "../../../../Library/ToastPopUp";
import { DownloadAllSession } from "./DownloadAllSession";
import { DownloadAtdSummary } from "./DownloadAtdSum";

export function Reporting() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const [rptTL, setRptTL] = useState({
        message: "",
        data: [],
        loading: false,
    });

    const GET_RPT_TEACHINGLOAD = (param) => {
        setRptTL({ ...rptTL, loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: param,
            target_data: "GET_RPT_TEACHINGLOAD",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setRptTL({
                    ...rptTL,
                    message: response.error,
                    loading: false,
                    data: []
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setRptTL({ ...rptTL, message: msg, loading: false, data: [] });
            } else {
                var results = response.response.data;
                if (results) {
                    setRptTL({ ...rptTL, data: results, loading: false, message: "" });
                } else {
                    setRptTL({
                        ...rptTL,
                        message: "No record found",
                        loading: false,
                        data: [],
                    });
                }
            }
        });
    };

    const handlerReport = (term_id) => {
        const param = {
            term_id: term_id,
            emp_id: AuthDecode.currentData.entityid
        };
        GET_RPT_TEACHINGLOAD(param);
    }

    const [acad, setAcad] = useState({
        loading: false,
        message: "",
        data: [],
        iserror: false,
    });

    const GET_ACAD_LOOKUP = () => {
        setAcad({ ...acad, loading: true });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                type: "asc.atp.type.term",
            },
            target_data: "GET_ACAD_LOOKUP",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setAcad({
                    ...acad,
                    iserror: true,
                    message: response.error,
                    loading: false,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setAcad({ ...acad, message: msg, loading: false });
            } else {
                var results = response.response.data;
                if (results) {
                    setAcad({ ...acad, data: results, loading: false });
                } else {
                    setAcad({
                        ...acad,
                        iserror: true,
                        message: "No record found",
                        loading: false,
                        data: [],
                    });
                }
            }
        });
    };

    const [lstAtd, setLstAtd] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const [statusDownload, setStatusDownload] = useState(false);

    const GET_ATD_LECTURER_BY_SECTION = (param) => {
        setLstAtd({ loading: true, data:[], message:"" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                section_id: param.section_id,
                empid: AuthDecode.currentData.entityid,
                status: 5,
                view_other: "FALSE"
            },
            target_data: "GET_ATD_LECTURER_BY_SECTION",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setLstAtd({
                    data:[],
                    message: response.error,
                    loading: false,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setLstAtd({ data:[], message: msg, loading: false });
            } else {
                var results = response.response.data;
                if (Object.values(results).length > 0) {
                    const only_atd_approved = results.filter(prev => prev.status === "APPROVED");
                    if(Object.values(only_atd_approved).length > 0){
                        const current_data = {
                            course:only_atd_approved[0].course,
                            lecturer_name : param.emp.fullname,
                            term : param.atp_term.name,
                            cost_center: param.cost_center.name,
                            section_id : param.section_id
                        };
                        //DownloadAllSession(only_atd_approved, setStatusDownload, current_data);
                        const lectdata = [];
                        lectdata.push({emp:param.emp});
                        const currentData = {section_id:param.section_id, lecturers:lectdata};
                        //console.log(currentData);
                        DownloadAtdSummary(setStatusDownload,currentData);
                        setLstAtd({ message:"", data: results, loading: false });
                    }else{
                        setLstAtd({ message:"No session approve found", data: [], loading: false });
                        openToast({ header: "Information", message: "No session approve found" });
                    }
                } else {
                    openToast({ header: "Information", message: "No record found" });
                    setLstAtd({
                        message: "No record found",
                        loading: false,
                        data: [],
                    });
                }
            }
        });
    };

    useEffect(() => {
        GET_ACAD_LOOKUP();
    }, [])

    const [details, setDetail] = useState({
        open: false,
        section_id: ""
    });

    const HandlerDownloadAtd = (data) => {
        setDetail({ open: true, section_id: data.section_id });
        GET_ATD_LECTURER_BY_SECTION(data);
    }


    return (
        <div id='reporting-lecturer' className='d-flex flex-column flex-grow-1'>
            <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                <div className="alert-icon">
                    <span className="svg-icon svg-icon-primary svg-icon-xl">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero"></path>
                            </g>
                        </svg>
                    </span>
                </div>
                <div className="alert-text"><h3>Report Attendance Class</h3></div>
            </div>



            <div className="card card-custom card-stretch gutter-b mb-8">
                <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="font-weight-bolder text-dark">Browse Attendance</span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">Please choose the term to get the list</span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="form-group mb-0">
                            <label>Term</label>
                            <select
                                name="term_id"
                                className="form-control form-control-sm"
                                onChange={(e) => handlerReport(e.target.value)
                                }>
                                <option value="0">Choose Term</option>
                                {Object.values(acad.data).length > 0
                                    ? acad.data.map((v, index) => (
                                        <option value={v.id} key={index}>
                                            {(v.name) ? v.name : v.id}
                                        </option>
                                    ))
                                    : ""}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-4">
                    <div className="table-responsive">
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                            <thead>
                                <tr className="text-left text-uppercase">
                                    <th>Section ID</th>
                                    <th>Lecturer</th>
                                    <th>Course</th>
                                    <th>Department</th>
                                    <th>Total Assign</th>
                                    <th>Total Submited</th>
                                    <th>Total Approved</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(rptTL.loading || lstAtd.loading || statusDownload) ? (
                                    <tr>
                                        <td colSpan={8}> <LoadingBtn /> </td>
                                    </tr>
                                ) : (
                                    (rptTL.message) ? (
                                        <tr>
                                            <td colSpan={8}> {rptTL.message} </td>
                                        </tr>
                                    ) : (
                                        (Object.values(rptTL.data).length > 0) ? (
                                            rptTL.data.map((v, index) => (
                                                <tr key={index}>
                                                    <td>{v.section_id}</td>
                                                    <td>{v.emp.fullname}</td>
                                                    <td>{(v.course) ? (
                                                        <span>
                                                            {v.course.coursetitle + " / " + v.course.code + " / Credit " + v.course.credit}
                                                        </span>
                                                    ) : ''}</td>
                                                    <td>{(v.cost_center) ? v.cost_center.name : "-"}</td>
                                                    <td>{v.t_assigned}/{v.emp_session}</td>
                                                    <td>{v.t_submitted}</td>
                                                    <td>{v.t_approved}</td>
                                                    <td>
                                                        {/* {v.t_approved > 0 ? ( */}
                                                        <button className="btn btn-icon btn-light btn-sm" type="button"
                                                            onClick={() => HandlerDownloadAtd(v)}>
                                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                                <svg width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                        {/* ) : ""} */}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={8}> No record found </td>
                                            </tr>
                                        )
                                    )
                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}
