import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import jwt_decode from "jwt-decode"
import "./MobileLayout.css"
import RouteMobileLect from './RouteMobileLect';
import ModalPopUp from '../Library/ModalPopUp';

export default function LayoutMobile() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    return (
        <div className='mobile-view'>
            <div className="nav-mobile">
                <div className="nav-item d-flex justify-content-between align-items-center py-5 px-8">
                    <NavLink className="text-white text-center text-capitalize" to={"/dashboard"}>
                        <span className="d-block">
                            <span className="svg-icon svg-icon-white svg-icon-2x">
                                <svg width="24" height="24" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z" />
                                    <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z" />
                                </svg>
                            </span>
                        </span>
                        <span className="font-weight-bolder">Home</span>
                    </NavLink>
                    <NavLink className="text-white text-center text-capitalize" to={"/timetables"}>
                        <span className="d-block">
                            <span className="svg-icon svg-icon-white svg-icon-2x">
                                <svg width="24" height="24" fill="currentColor" className="bi bi-calendar2-check-fill" viewBox="0 0 16 16">
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-2.6 5.854a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                </svg>
                            </span>
                        </span>
                        <span className="font-weight-bolder">
                            Timetables
                        </span>
                    </NavLink>
                    <NavLink className="text-white text-center text-capitalize" to={"/my-class"}>
                        <span className="d-block">
                            <span className="svg-icon svg-icon-white svg-icon-2x">
                            <svg width="24" height="24" fill="currentColor" className="bi bi-calendar-heart-fill" viewBox="0 0 16 16">
                                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5ZM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
                            </svg>
                            </span>
                        </span>
                        <span className="font-weight-bolder">
                            My Class
                        </span>
                    </NavLink>
                    {/* <NavLink className="text-white text-center text-capitalize" to={"/counseling"}>
                        <span className="d-block">
                            <span className="svg-icon svg-icon-white svg-icon-2x">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M2,11.8650466 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.23590829,11 3.04485894,11.3127315 2,11.8650466 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7 L6,7 Z" fill="#000000" />
                                    </g>
                                </svg>
                            </span>
                        </span>
                        <span className="font-weight-bolder">
                            Counseling
                        </span>
                    </NavLink> */}
                    <NavLink className="text-white text-center text-capitalize" to={"/profile"}>
                        <span className="d-block">
                            <span className="svg-icon svg-icon-white svg-icon-2x">
                                <svg width="24" height="24" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                </svg>
                            </span>
                        </span>
                        <span className="font-weight-bolder">
                            Profile
                        </span>
                    </NavLink>
                </div>
            </div>
            
            <div className="sgu-header px-5 py-6" style={{height:"200px", borderBottomRightRadius:"30px", borderBottomLeftRadius:"30px", backgroundColor:"#314a9c"}}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="profile mr-1">
                        <p className="font-weight-bolder font-size-h4 mb-0 text-white">
                            {AuthDecode.currentData.given_name} {AuthDecode.currentData.family_name}
                        </p>
                        <p className="mb-0 text-white">{AuthDecode.currentData.empid} - Lecturer
                        {(AuthDecode.remotedBy) ? (
                            <span className="badge badge-danger ml-2">
                                RemotedBy {AuthDecode.remotedBy.given_name}
                            </span>
                        ) : ""}</p>
                    </div>
                    <div className="action">
                        <Link to={"/sign-out"} className="btn btn-sm btn-icon btn-light-danger" >
                            <svg width="24" height="24" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
                                <path d="M7.5 1v7h1V1h-1z" />
                                <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <section className="container-fluid px-0 mb-20 pb-20">
                <div className="main mb-20 pb-20">
                    {(AuthDecode.currentData.roleid == 1) ?
                        <h1 className="text-center">Please use your desktop browser to access this portal.</h1> : (
                            <div style={{marginTop:"-130px"}}>
                                <RouteMobileLect />
                            </div>
                        )
                    }
                </div>
            </section>

            <ModalPopUp />
        </div>
    )
}
