import React, { useState, useMemo } from 'react'
import Moment from "moment"
import packageJson from "../../../../../package.json";
import { Pagination, Search, TableHeader } from "../../../Library/DataTables"


export function TableData(props) {
    const listData = props.data;
    const student = props.studentData;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total, setTotal] = useState(0);
    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Academic Year", field: "atp", sortable: false },
        { name: "Amount", field: "amount", sortable: false },
        { name: "Due Date", field: "due_date", sortable: false },
        { name: "Paid Date", field: "due_date", sortable: false },
        { name: "Status", field: "status", sortable: false },
        { name: "Detail", field: "id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [listData, currentPage, search, sorting]);
    /*==END DATATABELS CONFIG==*/


    const formatRupiah = (angka, prefix) => {
        var number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        var separator = '';
        if (ribuan) {
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? rupiah : '');
    }

    return (
        <div>
            <table className="table table-head-custom table-head-bg table-vertical-center table-bordered">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody>
                    {(ResultData.length > 0) ? (
                        ResultData.map((listData, index) => {
                            let typeArr = listData.trans_type.split(".");
                            var type = typeArr[typeArr.length - 1];
                            return (
                                <tr key={index}>
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{index + 1}</span></td>
                                    <td>
                                        <span className="d-block text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm text-uppercase">
                                            {(type === "krs") ? "PCF":type}
                                        </span>
                                        <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{listData.atp.name}</span>
                                    </td>
                                    {/* <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{listData.currency} {(listData.amount) ? formatRupiah(listData.amount, '') : 0} </span></td> */}
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">
                                        {listData.currency} {(listData.status === 'OPEN') ? 'Please contact finance dept' : ((listData.amount) ? formatRupiah(listData.amount, '') : 0)}
                                        </span></td>
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{(listData.due_date) ? Moment(listData.due_date).format('D MMMM YYYY') : '-'}</span></td>
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm">{(listData.trans_date) ? Moment(listData.trans_date).format('D MMMM YYYY') : '-'}</span></td>
                                    <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-sm text-uppercase">
                                        <span className={"label label-lg label-inline label-light-" + ((listData.status === 'OPEN') ? 'danger' : 'success')}>
                                            {(listData.status === 'OPEN') ? 'unpaid' : listData.status}
                                        </span> </span>
                                    </td>
                                    <td width="15px" className="text-center">
                                        {(listData.link_payment) ? (
                                            <a className="btn btn-icon btn-light btn-hover-primary btn-sm" data-toggle="tooltip" data-theme="dark" title="View Billing" href={listData.link_payment} target={'_blank'} >
                                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1" />
                                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) " />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </a>
                                        ) : ''}
                                    </td>
                                </tr>
                            )
                        })
                    ) : (
                        <tr><td colSpan="3">Data not found</td></tr>
                    )}
                </tbody>
            </table>
            {(totalItems > 0) ? (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            ) : ''}
        </div>
    )
}

export default TableData