import React, { useState, useRef, useEffect } from "react";
import jwt_decode from "jwt-decode";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import { openToast } from "../../../Library/ToastPopUp";
import AxiosLib from "../../../Library/AxiosLib";
import LoadingBtn from "../../Widgets/LoadingBtn";

export default function Form() {
  const editorRef = useRef(null);
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const now = moment().toDate();

  const objParam = {
    Title: "",
    Message: "",
    StartFrom: now,
    EndFrom: now,
    CreatedBy: AuthDecode.identity.email,
    Menus: [],
    RoleID: 0,
  };
  const [postAnn, setPostAnn] = useState(objParam);
  

  // CONFIG SELECT2
  const [options, setOptions] = useState([{value:0, label:"Dashboard"}]);
  const [selectedValue, setSelectedValue] = useState([]);
  const handleSelected = (selectedValue) => {
    setSelectedValue(selectedValue);
  };
  // END SELECT2

  const [menuRole, setMenuRole] = useState({ loading: false, message: "", data: [] });
  const FetchMenus = (role_id) => {
    setMenuRole({ loading: true, message: "", data: [] })
    var dataParam = {
      Type: 1,
      RoleID: role_id,
      IsActive: 1
    };
    AxiosLib(dataParam, "mc/api/menu-role").then(
      (response) => {
        if (response.error) {
          setMenuRole({ loading: false, message: response.error, data: [] })
        } else {
          if (response.data) {
            setMenuRole({ loading: false, message: "", data: response.data })
            const option = response.data.map((item) => ({
              value: item.menu_id,
              label: item.menu,
            }));
            setOptions([...options, ...option]);
          } else {
            setMenuRole({ loading: false, message: "No record found", data: [] })
          }
        }
      }
    );
  }

  const [roles, setRoles] = useState({ loading: false, data: [], message: "" });
  const FetchRoles = () => {
    setRoles({ loading: true, data: [], message: "" });
    AxiosLib({ IsActive: 1 }, "mc/api/fetch-type-user").then((response) => {
      if (response.error) {
        setRoles({ loading: false, data: [], message: response.error });
      } else {
        if (response.data) {
          setRoles({ loading: false, data: response.data, message: "" });
        } else if (response.message) {
          setRoles({ loading: false, data: [], message: response.message.internal });
        } else {
          setRoles({ loading: false, data: [], message: "No record found" });
        }
      }
    });
  };

  useEffect(() => {
    FetchRoles();
  }, [])

  const HandlerRoles = (value) => {
    value = parseInt(value);
    setPostAnn({ ...postAnn, RoleID: value });
    FetchMenus(value);
  }

  const [anns, setAnns] = useState({ loading: false, message: "", data: [] });
  const SumbitAnnouncement = (e) => {
    e.preventDefault();
    var formid = e.target.id;
    var target = document.getElementById(formid);

    if (
      postAnn.StartFrom &&
      postAnn.EndFrom &&
      postAnn.RoleID &&
      postAnn.Title &&
      postAnn.Message &&
      Object.values(selectedValue).length > 0
    ) {
      var param = { ...postAnn };
      param.StartFrom = moment(param.StartFrom).format("YYYY-MM-DD");
      param.EndFrom = moment(param.EndFrom).format("YYYY-MM-DD");
      param.Menus = selectedValue;

      //console.log(param);

      setAnns({ loading: true, message: "", data: [] });

      AxiosLib(param, "mc/api/notifications/save").then((response) => {
        if (response.result) {
          setAnns({ loading: false, message: "Successfully saved", data: response });
          openToast({
            message: "This announcement have been successfully created.",
            header: "Success",
          });
          window.location.reload();
        } else {
          setAnns({ loading: false, message: "Failed saved", data: [] });
          openToast({
            message: "This announcement have been failed.",
            header: "Error",
          });
        }
      });
    } else {
      setAnns({
        loading: false,
        message: "Please fill in all the mandatory fields",
        data: [],
      });
    }
  };

 

  return (
    <form method="post" autoComplete="off" id="form-submit-announcement" onSubmit={(e)=>SumbitAnnouncement(e)}>
      <div className="row">
        <div className="col-6">
          <div className="mb-3">
            <label className="required">Start Date</label>
            <p>
              <DatePicker 
                className="form-control" 
                popperPlacement='top-start'
                dateFormat={"yyyy-MM-dd"}
                placeholderText='Enter date' selected={postAnn.StartFrom} onChange={(date) => setPostAnn({...postAnn, StartFrom:date})}
                onKeyDown={(e) => {
                    e.preventDefault();
                }} 
              />
            </p>
          </div>
          <div className="mb-3">
            <label className="required">Roles</label>
            <select name="role_id" required className="form-control" defaultValue={postAnn.RoleID} onChange={(e) => HandlerRoles(e.target.value)}>
              <option value="0">Choose One</option>
              {Object.values(roles.data).length > 0 ? (
                roles.data.map((v, index) => (
                  <option value={v.ID} key={index}>{v.Name}</option>
                ))
              ) : ""}
            </select>
          </div>
        </div>
        <div className="col-6" style={{zIndex: 9}}>
          <div className="mb-3">
            <label className="required">End Form</label>
            <p>
              <DatePicker 
                className="form-control" 
                popperPlacement='top-start'
                dateFormat={"yyyy-MM-dd"}
                placeholderText='Enter date' selected={postAnn.EndFrom} onChange={(date) => setPostAnn({...postAnn, EndFrom:date})}
                onKeyDown={(e) => {
                    e.preventDefault();
                }} 
              />
            </p>
          </div>
          <div className="mb-3">
            <label className="required">Menus</label>
            {menuRole.loading ? <LoadingBtn /> : menuRole.message ? (
              <div className="bg-light-danger text-danger p-3 rounded">{menuRole.message}</div>
            ) : (
              (Object.values(menuRole.data).length > 0) ? (
                <Select
                  options={options}
                  onChange={handleSelected}
                  value={selectedValue}
                  isMulti
                />
              ): (
                <select name="menus" className="form-control" disabled>
                  <option value="">Choose one</option>
                </select>
              )
            )}

          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="required">Title</label>
        <input
          type="text"
          name="title"
          className="form-control"
          defaultValue={postAnn.Title}
          onChange={(e) => setPostAnn({ ...postAnn, Title: e.target.value })}
        />
      </div>

      <div className="mb-3">
        <label className="required">Messages</label>
        <Editor
          apiKey="sxd4e2dzs0r4qtfz7zzrh63khnixp6vk68il4znsxxuo1tym"
          className="required"
          value={postAnn.Message}
          onInit={(evt, editor) => (editorRef.current = editor)}
          onEditorChange={(newValue, editor) => {
            setPostAnn({ ...postAnn, Message: newValue });
          }}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor  link | alignleft aligncenter |" +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </div>
      
      {anns.message ? (
        <div className="my-5">
          <div className={"rounded p-3 "+ (Object.values(anns.data).length > 0 ? "bg-light-primary text-primary":"bg-light-danger text-danger")}>{anns.message}</div>
        </div>
      ) : ""}

      <div className="text-right">
        <div className="btn-group">
          <button className="btn btn-lg btn-light" type="button" onClick={()=>window.location.reload}>
            Cancel
          </button>
          <button
            className="btn btn-lg btn-primary"
            type="submit"
            disabled={anns.loading}
          >
            {anns.loading ? "Processing..." : "Submit"}
          </button>
        </div>
      </div>

      
    </form>
  );
}
