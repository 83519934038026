import React from "react";
import noteImage from "../assets/sticky-notes.png";

const AdvisorNote = ({ note, close }) => {
  return (
    <div
      className="modal fade show d-block"
      style={{ backgroundColor: "rgba(63, 66, 84, 0.5)" }}
      tabIndex={-1}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ width: 400 }}>
          <div className="d-flex flex-column position-relative justify-content-center align-items-center pt-15 pb-10 px-5">
            <a
              title="close"
              className="btn btn-xs btn-icon btn-light btn-hover-danger position-absolute"
              style={{ top: 10, right: 10 }}
              onClick={() => close(-1)}
            >
              <i class="ki ki-close icon-sm text-muted"></i>
            </a>
            <img src={noteImage} alt="note" width={70} />
            <h3 className="mt-5">Advisor's Note</h3>
            <p className="mt-8 mb-10 text-center">{note}</p>
            <button
              type="button"
              className="btn btn-primary w-50"
              onClick={() => close(-1)}
            >
              CLose
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisorNote;
