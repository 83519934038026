import React from "react";
import parse from "html-react-parser";

const AlertNotif = (props) => {
  if (props.title) {
    return (
      <MessageBodyWithTitle
        color={props.color}
        message={props.message}
        title={props.title}
      />
    );
  }

  return (
    <MessageBodyWithoutTitle color={props.color} message={props.message} />
  );
};

const MessageBodyWithTitle = ({ color, message, title }) => {
  return (
    <div className={"mb-5 p-5 alert alert-" + color} role="alert">
      <div className="alert-close">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i className="ki ki-close"></i>
          </span>
        </button>
      </div>
      <h4 className="alert-heading">{title}</h4>
      <p>{parse(message)}</p>
    </div>
  );
};

const MessageBodyWithoutTitle = ({ color, message }) => {
  return (
    <div
      className={"alert alert-custom alert-" + color + " fade show"}
      role="alert"
    >
      <div className="alert-icon">
        <i className="flaticon-exclamation-2"></i>
      </div>
      <div className="alert-text">{parse(message)}</div>
      <div className="alert-close">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i className="ki ki-close"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export { AlertNotif };
