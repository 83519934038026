import React from 'react'
import { NavLink, useParams } from 'react-router-dom';
import CardProfile from './CardProfile';

export function DetailStudent() {
    const { stdid } = useParams();
    const objNavStd = [
        {
            id: 1,
            name: "Academic Information",
            link: "/counseling/student/" + stdid+"/academic",
            icon: "flaticon2-open-text-book",
        },
        {
            id: 2,
            name: "Biodata",
            link: "/counseling/student/" + stdid + "/biodata",
            icon: "flaticon-profile",
        },
        {
            id: 3,
            name: "Course Plan",
            link: "/counseling/student/" + stdid + "/course-plan",
            icon: "fas fa-book-reader",
        },
        {
            id: 4,
            name: "Activity History",
            link: "/counseling/student/" + stdid + "/activity",
            icon: "flaticon2-checking",
        }
    ];

    return (
        <div className='detail-std'>
            <div className="card card-custom gutter-b">
                <div className="card-body">
                    <CardProfile
                        studentid={stdid}
                        activeNav={1}
                    />
                    <div className="navigation-student">
                        <ul className="nav nav-light-success nav-bold nav-pills">
                            {objNavStd.map((m, index) => (
                                <li className="nav-item" key={index}>
                                    <NavLink to={m.link} className={"nav-link"}>
                                        <span className="nav-icon">
                                            <i className={m.icon}></i>
                                        </span>
                                        <span className="nav-text">{m.name}</span>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}
