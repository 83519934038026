import React from "react";

const ResendSuccessDialog = ({ status, retry }) => {

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ gap: 10 }}
    >
      {status === "success" ? (
        <>
          <i
            class="far fa-check-circle text-success my-5"
            style={{ fontSize: 60 }}
          ></i>
          <div
            className="d-flex flex-column align-items-center"
            style={{ gap: 5 }}
          >
            <p style={{ fontSize: 24 }}>SUBMITTED</p>
            <p style={{ fontSize: 16, textAlign: "center" }}>
              Your new activity log have been submitted
            </p>
          </div>
        </>
      ) : (
        <>
          <i
            class="far fa-times-circle text-danger"
            style={{ fontSize: 44 }}
          ></i>
          <div
            className="d-flex flex-column align-items-center"
            style={{ gap: 5 }}
          >
            <p style={{ fontSize: 24 }}>FAILED</p>
            <p style={{ fontSize: 16, textAlign: "center" }}>
              Your new activity log failed to submit, check your internet
              connection.
            </p>
            <button
              type="button"
              className="btn btn-primary w-50"
              onClick={() => retry()}
            >
              Retry
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ResendSuccessDialog;
