import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import parse from "html-react-parser";
import {
  CardInvoice,
  ModalCancel,
  TablePCFOri,
  TablePCFRevisi,
} from "./CardAtributes";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../../Library/AlertNotif";
import { openToast } from "../../../../../Library/ToastPopUp";
import { openModal } from "../../../../../Library/ModalPopUp";
import  { Redirect } from 'react-router-dom'

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function PreviewPCFR({
  data,
  totalsks,
  param,
  setSubmitPCFOri,
  mypcf,
  setOpenPrev,
}) {
  const listData = data.length > 0 ? data.courses : mypcf;
  const studentid = AuthDecode.currentData.entityid;
  const [totalSKSOri, setTotalSKSOri] = useState(0);
  const [pcf, setPCF] = useState({ loading: false, data: [], message: "" });
  const GET_REREG_STUDENT_REVISION = () => {
    setPCF({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: studentid.toString(),
      },
      target_data: "GET_REREG_STUDENT_REVISION",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setPCF({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setPCF({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          var arrpcfrev = results.filter((prev) => prev.in_semester === 1);
          var myPcfrev = GetMyPCFRev(arrpcfrev);
          var objSubjectUniq = myPcfrev.reduce((accumulator, currentValue) => {
            if (
              currentValue.detail.course_type !== "optional" &&
              currentValue.action_type === "add"
            ) {
              const {
                course_id,
                detail: { course_credit },
              } = currentValue;
              if (!accumulator.hasOwnProperty(course_id)) {
                accumulator[course_id] = course_credit;
              } else if (accumulator[course_id] !== course_credit) {
                accumulator[course_id] += course_credit;
              }
            }
            return accumulator;
          }, {});
          var tsks = 0;
          if (objSubjectUniq) {
            Object.keys(objSubjectUniq).map((k) => {
              tsks = tsks + objSubjectUniq[k];
            });
            setTotalSKSOri(tsks);
          }
          setPCF({ loading: false, data: myPcfrev, message: "" });
        } else {
          setPCF({ loading: false, data: [], message: "No record found" });
        }
      }
    });
  };

  const GetMyPCFRev = (arrData) => {
    var computedData = arrData.map((v) => {
      var obj = {};
      var detail = {};
      obj.course_id = v.course_id;
      obj.origin = true;
      //obj.action_type = "add";
      detail.coursetitle = v.coursetitle;
      detail.course_id = v.course_id;
      let typeArr = v.course_type.split(".");
      var c_type = typeArr[typeArr.length - 1];
      detail.course_type = c_type;
      detail.course_code = v.code;
      detail.course_credit = v.credit;
      detail.section_id = v.section_id ? v.section_id : "-";
      obj.detail = detail;
      return obj;
    });
    return computedData;
  };

  useEffect(() => {
    GET_REREG_STUDENT_REVISION();
  }, []);

  const HandlerSubmitPCF = () => {
    param.courses = listData.map(v => {
      let obj = {};
      obj.course_id = v.course_id;
      obj.section_id = v.section_id;
      if(v.action_type){
        obj.action_type = v.action_type;
      }else{
        obj.action_type = "add";
      }
      return obj;
    });
    if (
      Object.values(listData).length > 0 &&
      param.cellular &&
      param.studentid
    ) {
      SUBMIT_REREG_PCF(param);
    } else {
      openToast({
        header: "Information",
        message: "Please fill up the form with correctly",
      });
    }
  };

  const [submitPCF, setSubmitPCF] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const SUBMIT_REREG_PCF = (param) => {
    setSubmitPCF({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };

    var body = {
      parameters: param,
      target_data: "SUBMIT_REREG_PCF",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if ("message" in response.response) {
        setSubmitPCF({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
        openToast({
          message: "Error " + response.response.message.user,
          header: "Error",
        });
      } else {
        var result = response.response.result;
        if (result === "success") {
          setSubmitPCF({
            loading: false,
            data: [],
            message:
              "Successfully submit PCF.<br/>Please contact your counselor to approve your PCF.",
          });
          
          openToast({
            message: parse(
              "Successfully submit PCF.<br/>Please contact your counselor to approve your PCF."
            ),
            header: "Info",
          });
          setTimeout(() => {
            //window.location.reload();
            return <Redirect to='/registration/plan-course-form'  />
          }, 2000);
        } else {
          setSubmitPCF({
            loading: false,
            data: [],
            message: "Failed submit PCF Revision",
          });
          openToast({
            message: "Failed submit PCF Revision",
            header: "Error",
          });
        }
      }
    });
  };

  return (
    <div className="prefiew-amount">
      {pcf.loading ? (
        <LoadingBtn />
      ) : pcf.message ? (
        <AlertNotif color={"danger"} message={pcf.message} />
      ) : Object.values(pcf.data).length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 col-xxl-8">
            <div className="card card-custom card-stretch gutter-b">
              <div className="card-header align-items-center border-0 mt-4">
                <h3 className="card-title align-items-start flex-column">
                  <span className="font-weight-bolder text-dark">
                    Preview PCF Revision
                  </span>
                  <span className="text-muted mt-3 font-weight-bold font-size-sm">
                    Compare PCF
                  </span>
                </h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <div className="border p-3 rounded h-100">
                      <h4 className="text-center">PCF Original</h4>
                      <TablePCFOri data={pcf.data} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="border p-3 rounded h-100">
                      <h4 className="text-center">PCF Revision</h4>
                      <TablePCFRevisi
                        data={mypcf}
                        totalsks={totalsks}
                        limit={data.max_sks ? data.max_sks : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-xxl-4">
            {data.totalAmount ? (
              <CardInvoice
                data={data}
                submitPCF={submitPCF}
                HandlerSubmitPCF={HandlerSubmitPCF}
                setSubmitPCFOri={setSubmitPCFOri}
              />
            ) : (
              <div className="card card-custome">
                <div className="card-body">
                  <div>
                    <h3>Terms and Conditions:</h3>
                    <div className="alert alert-light my-5">
                      By agreeing, you confirm that the
                      information provided in the PCF Revision form is accurate and
                      complete. You also acknowledge that the form is ready to be
                      submitted to the counselor for review.
                    </div>

                    <div className="actions">
                      <button
                        className="btn btn-lg btn-primary btn-block font-weight-bold text-uppercase px-7"
                        type="button"
                        disabled={submitPCF.loading}
                        onClick={() => HandlerSubmitPCF()}
                      >
                        {submitPCF.loading
                          ? "Processing..."
                          : "Submit and Finish"}
                      </button>
                      <button
                        className="btn btn-lg btn-light btn-block font-weight-bold text-uppercase px-7 font-weight-bolder"
                        type="button"
                        disabled={submitPCF.loading}
                        onClick={() =>
                          openModal({
                            header: "",
                            message: (
                              <ModalCancel setSubmitPCFOri={setSubmitPCFOri} setOpenPrev={setOpenPrev} />
                            ),
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                  
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
