import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from '../../../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../../Library/AlertNotif';

export default function HistPCF() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const [TSKS, setTSKS] = useState(0);
    const CalculateTotalSKS = (data) => {
        const sumall = ((data) ? (data.map(item => item).reduce((total, curr) => total + ((curr.action_type === 'asc.course.action.add' && curr.course.course_type !== "asc.course.type.optional") ? curr.course.credit : 0), 0)) : 0);
        var tsks = 0;
        if (Object.values(data).length > 0) {
            var objSubjectUniq = data.reduce((accumulator, currentValue) => {
                if (currentValue.action_type === 'asc.course.action.add' && currentValue.course.course_type !== "asc.course.type.optional") {
                    if (accumulator) {
                        const { course_id, course: { credit } } = currentValue;
                        if (!accumulator.hasOwnProperty(course_id)) {
                            accumulator[course_id] = credit;
                        } else if (accumulator[course_id] !== credit) {
                            accumulator[course_id] += credit;
                        }
                        return accumulator;
                    }
                }
            }, {});

            if (objSubjectUniq) {
                Object.keys(objSubjectUniq).map((k) => {
                    tsks = tsks + objSubjectUniq[k];
                });
            } else {
                tsks = sumall;
            }
        }
        setTSKS(tsks);
    }

    const [activity, setActivity] = useState({ loading: false, data: [], message: "" });
    const [selectedActv, setSelectedActv] = useState({ data: [] });
    const GET_STUDENT_ACTIVITY = () => {
        setActivity({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
                activity_type: "asc.student.activity.pcf"
            },
            target_data: "GET_STUDENT_ACTIVITY",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            console.log(response);
            if (response.error) {
                setActivity({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setActivity({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                results.sort(function (a, b) {
                    return b.id - a.id;
                })
                if (Object.keys(results).length > 0) {
                    //const pcfonly = results.filter(item => (item.student_activity.id === "asc.student.activity.pcf" || item.student_activity.id === "asc.student.activity.pcfr") )
                    GET_STUDENT_ACTIVITY_DETAIL(results[0].id);
                    setSelectedActv({ data: results[0] });
                    setActivity({ loading: false, data: results, message: "" });
                } else {
                    setActivity({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    }

    const [activitySelected, setActivitySelected] = useState({ loading: false, data: [], message: "" });
    const GET_STUDENT_ACTIVITY_DETAIL = (id) => {
        setActivitySelected({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                eventid: id,
            },
            target_data: "GET_STUDENT_ACTIVITY_DETAIL",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setActivitySelected({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setActivitySelected({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                results.sort(function (a, b) {
                    return b.id - a.id;
                })
                if (Object.keys(results).length > 0) {
                    console.log(results);
                    CalculateTotalSKS(results);
                    setActivitySelected({ loading: false, data: results, message: "" });
                } else {
                    setActivitySelected({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    }

    const [counselor, setCounselor] = useState({ loading: false, data: [], message: "" });
    const GET_COUNSELOR = () => {
        setCounselor({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
            },
            target_data: "GET_COUNSELOR",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setCounselor({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setCounselor({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    setCounselor({ loading: false, data: results, message: "" });
                } else {
                    setCounselor({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    }

    useEffect(() => {
        GET_STUDENT_ACTIVITY();
        GET_COUNSELOR();
    }, [])

    const HandlerSelectedSemes = (id) => {
        const findByID = activity.data.find(item => item.id === id);
        if (findByID.student_activity.id === "asc.student.activity.pcf" || findByID.student_activity.id === "asc.student.activity.pcfr") {
            setSelectedActv({ data: findByID });
            GET_STUDENT_ACTIVITY_DETAIL(id);
        }
    }

    return (
        <div id='hist-pcf' className='p-5 border bg-white border-top-0'>
            {activity.loading ? <LoadingBtn /> : activity.message ? <AlertNotif color={"danger"} message={activity.message} /> : (
                Object.values(activity.data).length > 0 ? (
                    <>
                        <div className="mb-8 input-group w-500px">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Choose Semester</span>
                            </div>
                            <select name="term" className='form-control' onChange={(e) => HandlerSelectedSemes(parseInt(e.target.value))}>
                                {(Object.values(activity.data).length > 0) ? (
                                    activity.data.map((v, index) => (
                                        <option value={v.id} key={index}>
                                            Semester {v.semester} {v.student_activity ? v.student_activity.name : ""}
                                        </option>
                                    ))
                                ) : ""}
                            </select>
                        </div>
                        {Object.values(activitySelected.data).length > 0 ? (
                            <h4 className='text-center mb-8'>Course List for {selectedActv.data.atp_term ? selectedActv.data.atp_term.name : "-"}</h4>
                        ) : ""}
                        <div className="table-responsive">
                            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                <thead>
                                    <tr className='text-left text-uppercase'>
                                        <th width="8%">No</th>
                                        <th>Section ID</th>
                                        <th>Course title</th>
                                        <th>Code</th>
                                        <th>Credit</th>
                                        <th>Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activitySelected.loading ? (
                                        <tr>
                                            <td colSpan={7}>
                                                <LoadingBtn />
                                            </td>
                                        </tr>
                                    ) : activitySelected.message ? (
                                        <tr>
                                            <td colSpan={7}>
                                                <div className="border border-danger rounded p-5 text-center bg-light-danger">
                                                    <span className="svg-icon svg-icon-danger svg-icon-2x">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <path d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z" fill="#000000" opacity="0.3" />
                                                                <polygon fill="#000000" points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <p className="text-danger mb-0">{activitySelected.message}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : (
                                        Object.values(activitySelected.data).length > 0 ? (
                                            activitySelected.data.map((v, index) => {
                                                let typeArr = v.course.course_type.split(".");
                                                let type = typeArr[typeArr.length - 1];
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{v.section_id}</td>
                                                        <td>{v.course ? v.course.coursetitle : ""}</td>
                                                        <td>{v.course ? v.course.code : ""}</td>
                                                        <td>{v.course ? v.course.credit : ""}</td>
                                                        <td><span className="text-capitalize">{type}</span></td>
                                                        <td>
                                                            {v.action_type !== "" ? (
                                                                <span className={"badge " + (v.action_type === "asc.course.action.remove" ? "badge-danger" : "badge-primary")}>
                                                                    {v.action ? v.action.name : ""}
                                                                </span>
                                                            ) : (
                                                                <span className={"badge badge-danger"}>
                                                                    Remove Course
                                                                </span>
                                                            )}

                                                        </td>
                                                    </tr>)
                                            })
                                        ) : ""
                                    )}
                                </tbody>
                                <tfoot>
                                    {Object.values(activitySelected.data).length > 0 ? (
                                        <tr>
                                            <td colSpan={4}>
                                                <p className="mb-0 font-weight-bolder text-right">Total Credit </p>
                                            </td>
                                            <td colSpan={3}>
                                                <span className="font-weight-bolder text-left">{TSKS} SKS</span> exclude optional
                                            </td>
                                        </tr>
                                    ) : ""}
                                    <tr>
                                        <td colSpan={4}>
                                            <p className="mb-0 font-weight-bolder text-right">Counselor</p>
                                        </td>
                                        <td colSpan={3}>
                                            {selectedActv.data.status_activity === "SUBMITTED" ? (
                                                <p className="mb-0 text-danger">Need Approval by:</p>
                                            ) : ""}
                                            {Object.values(counselor.data).length > 0 ? (
                                                counselor.data.map((item, index) => (
                                                    <span key={index} className="font-weight-bolder text-left text-capitalize d-block">
                                                        {item.emp ? item.emp.fullname : "-"}
                                                    </span>
                                                ))
                                            ) : ""}

                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <p className="mb-0 font-weight-bolder text-right">Status</p>
                                        </td>
                                        <td colSpan={3}>
                                            <span className={"d-block font-weight-bolder text-left text-uppercase w-100px text-center " + ((selectedActv.data.status_activity === "SUBMITTED" ? "badge badge-danger" : "text-success"))}>
                                                {selectedActv.data.status_activity === "APPROVED" && (
                                                    <i className="fas fa-check-circle text-success"></i>
                                                )}
                                                {selectedActv.data.status_activity ? selectedActv.data.status_activity : "-"}
                                            </span>
                                            <span className="font-weight-bolder">
                                                {selectedActv.data.approve_date_formatted ? selectedActv.data.approve_date_formatted : selectedActv.data.submit_date_formatted}
                                            </span>

                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div className="my-10"></div>
                    </>
                ) : (
                    <div className="border rounded p-5 text-center bg-light">
                        <span className="svg-icon svg-icon-primary svg-icon-2x">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z" fill="#000000" opacity="0.3" />
                                    <polygon fill="#000000" points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912" />
                                </g>
                            </svg>
                        </span>
                        <p className="text-primary mb-0">No record found</p>
                    </div>
                )
            )}
        </div>
    )
}
