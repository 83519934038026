import ExcelJs from "exceljs";
import jwt_decode from "jwt-decode";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

const SheetAttd = (wb, data, parentObj) => {
  const ws = wb.addWorksheet("Score " + decodeURIComponent(parentObj.course));
  let rowNumber = 1;

  ws.getRow(rowNumber).getCell("A").value = "Report Score Section ID " + parentObj.section_id;
  ws.getRow(rowNumber).getCell("A").font = {
    bold: true,
    size: 16,
  };

  rowNumber = rowNumber + 1;
  ws.getRow(rowNumber).getCell("A").value = "Course: " + decodeURIComponent(parentObj.course) + "/" + parentObj.course_code + "/ Credit " + parentObj.course_credit + " SKS";
  ws.getRow(rowNumber).getCell("A").font = {
    bold: true,
    size: 16,
  };
  rowNumber = rowNumber + 1;

  ws.getRow(rowNumber).getCell("A").value = "Lecturer: " + parentObj.lecturername;
  ws.getRow(rowNumber).getCell("A").font = {
    bold: true,
    size: 16,
  };
  rowNumber = rowNumber + 2;

  const rowHeader = ws.getRow(rowNumber);
  RowHeader(rowHeader, 1, "No")
  RowHeader(rowHeader, 2, "Student ID")
  RowHeader(rowHeader, 3, "Student Name")
  RowHeader(rowHeader, 4, "Student Email")
  RowHeader(rowHeader, 5, "Department")
  let columnHeader = 6;
  data.component.map(v => {
    RowHeader(rowHeader, columnHeader, decodeURIComponent(v.name) + " (" + ((v.weight * 100).toFixed(0)) + "%)");
    columnHeader = columnHeader + 1;
  })
  let cHeader = columnHeader;
  RowHeader(rowHeader, cHeader, "Score");
  cHeader = cHeader + 1;
  RowHeader(rowHeader, cHeader, "Grade");
  cHeader = cHeader + 1;
  RowHeader(rowHeader, cHeader, "Absent");
  cHeader = cHeader + 1;
  RowHeader(rowHeader, cHeader, "Remark");
  cHeader = cHeader + 1;
  RowHeader(rowHeader, cHeader, "Final Result");
  cHeader = cHeader + 1;

  rowNumber = rowNumber + 1;
  var num = 1;
  data.score.map((v) => {
    const row = ws.getRow(rowNumber);
    row.getCell(1).value = num++;
    row.getCell(2).value = v.studentid;
    row.getCell(3).value = v.studentdata ? (v.studentdata.fullname ? v.studentdata.fullname : "-") : "-";
    row.getCell(4).value = v.studentdata ? (v.studentdata.sgumail ? v.studentdata.sgumail : "-") : "-";
    row.getCell(5).value = v.clu ? (v.clu.name ? v.clu.name : "-") : "-";
    let columnBody = 6;
    data.component.map(c => {
      const compScore = v.student_course_com.find(
        (sc) => sc.wg_com.id === c.wg_com.id
      );
      row.getCell(columnBody).value = compScore ? compScore.score.toString() : "";
      columnBody = columnBody + 1;
    })
    let cBody = columnBody;
    row.getCell(cBody).value = v.score;
    cBody = cBody + 1;
    row.getCell(cBody).value = v.grade_fin;
    cBody = cBody + 1;
    row.getCell(cBody).value = (v.atd ? v.atd+"%" :"");
    cBody = cBody + 1;
    row.getCell(cBody).value = v.remark;
    cBody = cBody + 1;
    row.getCell(cBody).value = v.course_status;
    cBody = cBody + 1;
    rowNumber++;
  });

  ws.columns.forEach((column, index) => {
    if (index === 0) {
      column.width = 15;
    } else {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxColumnLength) {
          maxColumnLength = cellLength;
        }
      });
      column.width = maxColumnLength < 10 ? 10 : maxColumnLength;
    }
  });
  
};

const RowHeader = (rowHeader, i, value) => {
  rowHeader.getCell(i).value = value;
  rowHeader.getCell(i).border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  rowHeader.getCell(i).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "191970" },
  };
  rowHeader.getCell(i).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  rowHeader.getCell(i).font = {
    bold: true,
    size: 11,
    color: { argb: "FFFFFF" },
  };
}

const ScoreDownload = (data, parentObj) => {
  const wb = new ExcelJs.Workbook();
  wb.creator = "MY-SGU";
  wb.lastModifiedBy = "MY-SGU-System";
  wb.created = new Date();
  wb.modified = new Date();
  wb.lastPrinted = new Date();

  //body here
  SheetAttd(wb, data, parentObj);
  //end body here

  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  wb.xlsx.writeBuffer().then((buffer) => {
    writeFile(
      "Report Score-" + parentObj.section_id,
      buffer
    );
  });
};
export default ScoreDownload;