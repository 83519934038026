import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CardCircular = ({ isCoExist, title, meet }) => {
  let count = meet
  let percentage = 0;
  if (meet > -1) {
    if (title.includes("Co-")) {
      if (meet > 5) meet = 3;
      percentage = ((meet / 3) * 100).toFixed();
    } else {
      if (isCoExist) {
        if (meet > 5) meet = 5;
        percentage = ((meet / 5) * 100).toFixed();
      } else {
        if (meet > 8) meet = 8;
        percentage = ((meet / 8) * 100).toFixed();
      }
    }
  } else {
    percentage = meet;
  }
  return (
    <div className="h-md h-100 pb-5">
      <div className="card card-custom h-100 p-5">
        <div className="card-body p-0">
          <div className="card-p d-flex flex-column align-items-center justify-content-between h-100">
            <p
              className="text-center"
              style={{ fontSize: 14, fontWeight: 400, width: "50%" }}
            >
              {title}
            </p>
            <div style={{ width: 150, height: 150 }}>
              <CircularProgressbar
                value={percentage}
                text={`${percentage === -1 ? "N/A" : percentage + "%"}`}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor: "#369AFF",
                  textColor: "#000",
                  textSize: 24,
                })}
              />
            </div>
            <p className="text-muted mt-2" style={{ fontSize: 16 }}>
              {percentage === -1
                ? ""
                : `${count > meet ? meet + "+" : meet} of ${
                    title.includes("Co-") ? "3" : isCoExist ? "5" : "8"
                  } Meetings`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCircular;
