import React, { useEffect, useMemo, useState } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../../../Library/AxiosLibWithHeader2";
import { openToast } from "../../../../../../Library/ToastPopUp";
import { openModal } from "../../../../../../Library/ModalPopUp";
import PreviewPCFFirst from "../WPreviewPCF/PreviewPCFFirst";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function MyPCF({
  student,
  fPCF,
  mypcf,
  setMypcf,
  eventid,
  studentid,
  acad,
  totalsks,
  setTotalSKS,
}) {
  const [totalRemoved, setTotalRemoved] = useState(0);
  const [listData, setListData] = useState(mypcf);

  useEffect(() => {
    setListData(mypcf);
  }, [mypcf]);

  const ResultData = useMemo(() => {
    let computedData = [...listData];

    computedData.sort((a, b) =>
      a.detail.coursetitle > b.detail.coursetitle ? 1 : -1
    );

    const removedItems = computedData.filter(
      (item) => item.action_type === "remove"
    );
    setTotalRemoved(removedItems.length);

    const objSubjectUniq = computedData.reduce((accumulator, currentValue) => {
      if (
        currentValue.detail.course_type !== "optional" &&
        currentValue.action_type === "add"
      ) {
        const {
          course_id,
          detail: { course_credit },
        } = currentValue;
        if (!accumulator.hasOwnProperty(course_id)) {
          accumulator[course_id] = course_credit;
        } else if (accumulator[course_id] !== course_credit) {
          accumulator[course_id] += course_credit;
        }
      }
      return accumulator;
    }, {});

    let tsks = 0;
    if (objSubjectUniq) {
      Object.keys(objSubjectUniq).forEach((k) => {
        tsks += objSubjectUniq[k];
      });
    }
    setTotalSKS(tsks);

    return computedData;
  }, [listData, setTotalSKS]);

  const handlerChangePCF = (data) => {
    setListData((prevListData) => {
      const cloneData = [...prevListData];
      if (data.origin === true) {
        return cloneData.map((item) => {
          if (item.section_id === data.section_id) {
            item.action_type = "remove";
          }
          return item;
        });
      } else {
        //return cloneData.filter((prev) => prev.section_id !== data.section_id);
        const updatedListData = cloneData.filter(
            (prev) => prev.section_id !== data.section_id
        );
        setMypcf(updatedListData);
        return updatedListData;
      }
    });
  };

  const ListRemoveItem = ({ data }) => {
    const listRemoveCourse = data.filter(
      (item) => item.action_type === "remove"
    );
    const handlerRedoPCF = (value) => {
      setListData((prevListData) => {
        const cloneData = [...prevListData];
        return cloneData.map((item) => {
          if (item.section_id === value.section_id) {
            item.action_type = "add";
          }
          return item;
        });
      });
      openModal({ open: false });
    };
    return (
      <div className="hist-pcf">
        {Object.values(listRemoveCourse).length > 0 ? (
          listRemoveCourse.map((v, index) => (
            <div className="border p-3 rounded mb-8 shadow" key={index}>
              <div className="d-flex justify-content-start align-items-start">
                <div className="my-course w-100 ">
                  <span className="font-weight-bolder mb-1">
                    {v.detail.coursetitle}
                  </span>
                  <div>
                    <span className="text-muted font-weight-bold">
                      {v.detail.course_code
                        ? v.detail.course_code
                        : v.detail.code}
                    </span>
                  </div>
                </div>
                <div className="info-course mx-2 mx-xxl-5">
                  <span className="font-weight-bolder d-block">
                    Credit
                    <span className="ml-2 text-dark font-weight-bold">
                      {v.detail.course_credit}
                    </span>
                  </span>
                  <span className="text-muted text-capitalize">
                    {v.detail.course_type}
                  </span>
                </div>
                <button
                  className="btn btn-sm btn-icon btn-warning p-5"
                  type="button"
                  title={
                    student
                      ? "Restore to " + student.firstname + "'s PCF"
                      : "Redo the course"
                  }
                  onClick={() => handlerRedoPCF(v)}
                >
                  <i className="fas fa-redo"></i>
                </button>
              </div>
              <div className="cost_owner mt-5">
                <span className="font-weight-bolder d-block">
                  {v.detail.section_id ? v.detail.section_id : "-"}
                </span>
                <span className="font-weight-bolder text-muted">
                  Section ID
                </span>
              </div>
            </div>
          ))
        ) : (
          <p className="text-danger">No record found</p>
        )}
      </div>
    );
  };

  const PreviewPCF = () => {
    if (totalsks > 24) {
      openToast({
        header: "Information",
        message: "The maximum total SKS is 24",
      });
    } else {
      var cloneCourse = [...listData];
      var courses = cloneCourse.map((item) => {
        var obj = {};
        obj.action_type = item.action_type;
        obj.course_id = item.course_id;
        obj.section_id = item.section_id;
        return obj;
      });
      console.log(courses);
      GET_PCF_AMOUNT(courses);
    }
  };

  const [pcfAmount, setPCFAmount] = useState({ loading: false, data: [], message: "" });
    const GET_PCF_AMOUNT = (courses) => {
        setPCFAmount({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };

        var param = {
            courses: courses,
            studentid: studentid,
            term_id: acad.id
        };

        var body = {
            parameters: param,
            target_data: "GET_PCF_AMOUNT",
        };

        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if ("message" in response.response) {
                setPCFAmount({ loading: false, data: [], message: response.response.message.user });
                openToast({ header: "Error", message: response.response.message.user });
            } else {
                var result = response.response.result;
                if (result === "success") {
                    var dataResult = response.response.data;
                    setPCFAmount({ loading: false, data: dataResult, message: "" });
                    openModal({
                        header:"",
                        message: (
                            <PreviewPCFFirst
                                myprev={dataResult}
                                data={listData}
                                studentid={studentid}
                                fpcf={fPCF}
                                finish={false}
                                eventid={eventid}
                                student={student}
                            />   
                        ),
                        size: "xl",
                    });
                } else {
                    setPCFAmount({ loading: false, data: [], message: "Failed preview PCF." + response.response.msg });
                    openToast({ header: "Error", message: "Failed preview PCF." + response.response.msg });
                }
            }
        });
    };

  return (
    <div className="card card-custom card-stretch gutter-b">
      <HeaderCardStd student={student} acad={acad} totaldata={ResultData.length} />

      <div
        className="card-body pt-0"
        style={{ maxHeight: "600px", overflowY: "auto" }}
      >
        <div className="table-responsive">
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
            <thead>
              <tr>
                <th>
                  <span className="text-dark">COURSE</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {ResultData.length > 0 ? (
                ResultData.map((v, index) =>
                  !v.action_type || v.action_type === "add" ? (
                    <tr key={index}>
                      <td className="px-0">
                        <div className="border p-3 rounded">
                          <div className="d-flex justify-content-start align-items-start">
                            <div className="my-course w-100 ">
                              <span className="font-weight-bolder mb-1">
                                {v.detail.coursetitle}
                              </span>
                              <div>
                                <span className="text-muted font-weight-bold">
                                  {v.detail.course_code
                                    ? v.detail.course_code
                                    : v.detail.code}
                                </span>
                              </div>
                            </div>
                            <div className="info-course mx-2 mx-xxl-5">
                              <span className="font-weight-bolder d-block">
                                Credit
                                <span className="ml-2 text-dark font-weight-bold">
                                  {v.detail.course_credit}
                                </span>
                              </span>
                              <span className="text-muted text-capitalize">
                                {v.detail.course_type}
                              </span>
                            </div>
                            <button
                              className="btn btn-sm btn-icon btn-danger p-5"
                              type="button"
                              title={
                                student
                                  ? "Remove from " +
                                    student.firstname +
                                    "'s PCF"
                                  : "Remove the course"
                              }
                              onClick={() => handlerChangePCF(v)}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </div>
                          <div className="cost_owner mt-5">
                            <span className="font-weight-bolder d-block">
                              {v.detail.section_id ? v.detail.section_id : "-"}
                            </span>
                            <span className="font-weight-bolder text-muted">
                              Section ID
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )
                )
              ) : (
                <tr>
                  <td colSpan={2}>No record found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="card-footer">
        <div className="d-flex justify-content-between align-items-center">
          <span className="font-weight-bolder">
            Total credit: {totalsks} SKS{" "}
          </span>
          <div className="btn-group">
            <button className="btn btn-sm btn-icon btn-warning" type="button">
              <i className="fa fa-trash text-dark"></i>
            </button>
            <button
              className="btn btn-sm btn-light-warning btn-hover-light-warning"
              type="button"
              onClick={() =>
                openModal({
                  header: "Removed Course List",
                  message: <ListRemoveItem data={listData} />,
                })
              }
            >
              <span className="font-weight-bolder text-dark text-uppercase">
                {totalRemoved} course removed
              </span>
            </button>
          </div>
        </div>
        <div className="my-5">
          {AuthDecode.remotedBy ? (
            <div className="alert alert-danger">
              <div className="alert-title text-left">
                <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                You cannot submit this PCF.
                <br />
                This PCF belongs to{" "}
                <b>
                  Mr/Mrs.{" "}
                  {AuthDecode.currentData.given_name +
                    " " +
                    AuthDecode.currentData.family_name}
                </b>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="text-center">
          <button
            className="btn btn-lg btn-primary btn-block font-weight-bolder text-uppercase px-9 py-4"
            title="Preview and Approve"
            onClick={() => PreviewPCF()}
            disabled={pcfAmount.loading}
          >
            {pcfAmount.loading ? "Processing...":"Preview and Approve"}
          </button>
        </div>
      </div>
    </div>
  );
}

const HeaderCardStd = ({ student, acad, totaldata }) => {
  return (
    <div className="card-header align-items-center border-0 mt-4">
      <h3 className="card-title align-items-start flex-column">
        <span className="font-weight-bolder text-dark">
          {student ? student.firstname + "'s PCF" : "UNKNOW STUDENT"}
        </span>
        <span className="text-muted mt-3 font-weight-bold font-size-sm">
          Periode {acad ? (acad.name ? acad.name : "-") : "-"}
        </span>
      </h3>
      <div className="card-toolbar">
        <span className="font-weight-bolder">
            Total item {totaldata}
        </span>
      </div>
    </div>
  );
};
