import html2pdf from "html2pdf.js";
import "./pdf.css";

import Logo from "../../../../../../media/logos/logo-sgu.png";
import Name from "../../../../../../media/logos/logo-sgu-name.png";

const cetakPDF = async ( data, bio ) => {
  // Membuat elemen div sementara untuk memuat konten HTML
  const marginLeft = 90;

  const div = document.createElement("div");
  div.innerHTML = `
    <div
      class="bg-white pt-10 pr-20 pl-5 position-relative d-flex flex-column a4"
      style="height: 1100px;"
    >
      <div>
        <div class="d-flex justify-content-between align-items-end" style="margin-left: ${marginLeft}px;">
          <img src="${Logo}" alt="logo" style="width: 150px; height: 60px; aspect-ratio: 1;" class="flex-shrink-0" />
          <img src="${Name}" alt="logo-name" style="height: 25px;  width: 228px; aspect-ratio: 1;" class="flex-shrink-0" />
        </div>
        <div class="my-5" style="margin-left: ${marginLeft}px; border-bottom: 1px solid black;"></div>
        <div class="mt-5">
          <div style="margin-left: ${marginLeft}px;" class="d-flex align-items-center">
            <h2 style="color: black; font-size: 14px;">Thesis Activity Log Sheet</h2>
            <p class="p-0 m-0 pl-2" style="color: black; font-size: 7px;">
              (should be filled and signed each time the student meets and consults the Advisor)
            </p>
          </div>
          <div>
            <div>
              <table style="width: 100%; table-layout: fixed;">
                <thead>
                  <tr>
                    <td style="padding: 5px; border: 1px solid black; border-right: none; border-bottom: none; width: ${marginLeft}px; white-space: nowrap; color: black; font-size: 10px;">
                      Student's Name
                    </td>
                    <td style="padding: 5px; border: 1px solid black; border-bottom: none; width: 100%; color: black; font-size: 10px;">
                      ${bio.studentdata.fullname}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 5px; border: 1px solid black; border-right: none; border-bottom: none; width: ${marginLeft}px; color: black; font-size: 10px;">
                      Student ID
                    </td>
                    <td style="padding: 5px; border: 1px solid black; border-bottom: none; color: black; font-size: 10px;">
                      ${bio.studentid}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 5px; border: 1px solid black; border-right: none; border-bottom: none; width: ${marginLeft}px; color: black; font-size: 10px;">
                      Faculty
                    </td>
                    <td style="padding: 5px; border: 1px solid black; border-bottom: none; color: black; font-size: 10px;">
                      ${bio.studentdata.clu.name}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 5px; border: 1px solid black; border-right: none; width: ${marginLeft}px; color: black; font-size: 10px;">
                      Thesis Advisor
                    </td>
                    <td style="padding: 5px; border: 1px solid black; color: black; font-size: 10px;">
                      ${bio.advisor.fullname}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative" style="flex: 1 1 auto; margin-left: ${marginLeft}px;">
        <h2 class="mt-5" style="color: black; font-size: 12px;">Thesis Title :</h2>
        <div class="my-5" style="border-bottom: 1px solid black;"></div>
        <div>
          <table style="width: 100%; table-layout: fixed;">
            <thead>
              <tr>
                <th style="padding: 5px; border: 1px solid black; border-right: none; width: 30px; color: black; font-size: 12px; text-align: center;">
                  No
                </th>
                <th style="padding: 5px; border: 1px solid black; border-right: none; width: 90px; color: black; font-size: 12px; text-align: center;">
                  Date/Time
                </th>
                <th style="padding: 5px; border: 1px solid black; border-right: none; color: black; width: 380px; font-size: 12px; text-align: center;">
                  Activity Description
                </th>
                <th style="padding: 5px; border: 1px solid black; color: black; font-size: 12px; text-align: center;">
                  Remark/Advisor's Signature
                </th>
              </tr>
            </thead>
            <tbody>
              ${data.map((x, index) => `
                <tr>
                  <td style="padding: 5px; border: 1px solid black; border-right: none; border-top: none; color: black; font-size: 11px; text-align: center;">
                    ${index + 1}
                  </td>
                  <td style="padding: 5px; border: 1px solid black; border-right: none; border-top: none; color: black; font-size: 11px; text-align: center;">
                    ${x.approved.replace("T", " ")}
                  </td>
                  <td style="padding: 5px; border: 1px solid black; border-right: none; border-top: none; color: black; font-size: 11px;">
                    ${x.descr}
                  </td>
                  <td style="padding: 5px; border: 1px solid black; border-top: none; color: black; font-size: 11px; text-align: center;">
                    ${x.status}
                  </td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
        <div>
          <div class="mt-10">
            <div style="margin-left: ${marginLeft}px;" class="d-flex align-items-center"></div>
          </div>
          <div class="position-absolute bottom-0" style="width: 100%;">
            <div class="my-2" style="border-bottom: 1px solid black;"></div>
            <div class="d-flex justify-content-between">
              <p style="color: black; font-size: 10px;">
                <strong>Swiss German University</strong>
              </p>
              <p style="color: black; font-size: 10px;">
                <strong>Tel.</strong> +62 21 2977 9596/9597
              </p>
            </div>
            <div class="d-flex justify-content-between w-100" style="margin-top: -15px; font-size: 10px;">
              <p style="color: black;">The Prominence Tower</p>
              <p style="color: black;">
                <strong>Fax.</strong> +62 21 2977 9598
              </p>
            </div>
            <div class="d-flex justify-content-between w-100" style="margin-top: -15px; font-size: 10px;">
              <p style="color: black;">Jalan Jalur Sutera Barat No. 15, Alam Sutera</p>
              <p style="color: blue;">marketing@sgu.ac.id</p>
            </div>
            <div class="d-flex justify-content-between w-100" style="margin-top: -15px; font-size: 10px;">
              <p style="color: black;">Tangerang, Banten – 15143, Indonesia</p>
              <p style="color: blue;">www.sgu.ac.id</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;

  // Konfigurasi html2pdf
  const options = {
    margin: 1,
    filename: "example.pdf",
    html2canvas: { scale: 5 }, // Skala tinggi untuk hasil yang lebih baik
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  // Menghasilkan PDF sebagai Blob dan memicu unduhan file
  const pdfBlob = await html2pdf().from(div).set(options).outputPdf("blob");
  const url = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "thesis_activity.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export default cetakPDF;
