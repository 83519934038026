import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import CalendarDeptStd from "./CalendarDeptStd";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function CalendarDeptIndex() {
  const [arrData, setArrData] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [acadActv, setAcadActv] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const GET_ACTIVE_ACAD = () => {
    setArrData({ loading: true, data: [], message: "" });
    setAcadActv({ message: "", loading: true, data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      target_data: "GET_ACTIVE_ACAD",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setAcadActv({ data: [], loading: false, message: response.error });
      } else if ("message" in response.response) {
        setAcadActv({
          data: [],
          loading: false,
          message: response.response.message.internal,
        });
      } else {
        var param = response.response.data;
        if (Object.values(param).length > 0) {
          setAcadActv({ message: "", loading: false, data: param });
          const typeIntake = param[0].atp_term_next.atp_type;
          const typeIntakeArr = typeIntake.split(".");
          const typeName =
            typeIntakeArr[Object.values(typeIntakeArr).length - 1];
          GET_SYS_PARAM(typeName.toLowerCase());
        } else {
          setAcadActv({
            data: [],
            loading: false,
            message: "No record found",
          });
        }
      }
    });
  };

  const CleansingJSON = (data) => {
    let cleansedData = data.replace(/([{,]\s*)(\w+)\s*:/g, '$1"$2":');
    cleansedData = JSON.parse(cleansedData);
    return cleansedData;
  };

  const GET_SYS_PARAM = (name) => {
    const param = { tipe: "std-representative", name: name };
    setArrData({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };

    const postData = {
      keys: keys,
      body: param,
    };

    AxiosLibWithHeader2(postData, "mc/api/sys-param").then((response) => {
      if (response.data) {
        const data = response.data;
        const conv = CleansingJSON(data);
        setArrData({ message: "", loading: false, data: conv });
      } else if (response.error) {
        setArrData({ data: [], loading: false, message: response.error });
      } else {
        setArrData({ data: [], loading: false, message: "No record found" });
      }
    });
  };

  useEffect(() => {
    GET_ACTIVE_ACAD();
  }, []);

  const FetchCallDept = () => {
    return <CalendarDeptStd studentid={selectedDept} />;
  };

  const [selectedSemes, setSelectedSemes] = useState("");
  const [selectedDept, setSelectedDept] = useState("");

  const handleSemesChange = (event) => {
    const value = event.target.value;
    setSelectedSemes(value);
    setSelectedDept("");
  };

  const deptOptions = selectedSemes
    ? arrData.data.find((item) => item.semes.toString() === selectedSemes)?.dept ||
      []
    : [];

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">
            Study Program Timetable (Generalized)
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Please choose department by semester
          </span>
        </h3>
      </div>
      <div className="card-body pt-0 px-0">
        {acadActv.loading || arrData.loading ? (
          <LoadingBtn />
        ) : acadActv.message || arrData.message ? (
          <AlertNotif
            message={acadActv.message || arrData.message}
            color="danger"
          />
        ) : Object.values(acadActv.data).length > 0 &&
          Object.values(arrData.data).length ? (
          <div>
            <div className="bg-light p-5 d-flex justify-content-center align-items-end">
              <div className="mb-3 mr-3 w-50">
                <label className="font-weight-bold">Semester</label>
                <select
                  name="semes"
                  className="form-control"
                  required
                  value={selectedSemes}
                  onChange={handleSemesChange}
                >
                  <option value="">Choose one</option>
                  {arrData.data.map((s, index) => (
                    <option key={index} value={s.semes}>
                      Semester {s.semes}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 mr-3 w-50">
                <label className="font-weight-bold">Department</label>
                <select
                  name="semes"
                  className="form-control"
                  value={selectedDept}
                  required
                  onChange={(e) => setSelectedDept(e.target.value)}
                >
                  <option value="">Choose one</option>
                  {Object.values(deptOptions).length > 0
                    ? deptOptions.map((d, index) => (
                        <option value={d.stdid} key={index}>
                          {d.code}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>

            <div className="selected-calendar">
              {selectedSemes && selectedDept ? <FetchCallDept /> : ""}
            </div>
          </div>
        ) : (
          <p className="mb-0">No record found</p>
        )}
      </div>
    </div>
  );
}
