import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import Form from './Form';
import TableData from './TableData';
import LoadingBtn from '../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../Library/AlertNotif';

export function Leave() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
    var hasSubmit = false;

    const [stdStatus, setStdStatus] = useState({ loading: false, data: [], message: "" });
    const GET_STUDENT_STATUS = () => {
        setStdStatus({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
            },
            target_data: "GET_STUDENT_STATUS",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdStatus({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setStdStatus({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    setStdStatus({ loading: false, data: results, message: "" });
                    GET_ACTIVE_ACAD(results.atp_group_type);
                } else {
                    setStdStatus({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    };

    const [stdAcad, setStdAcad] = useState({ loading: false, data: [], message: "" });
    const GET_ACTIVE_ACAD = (acadType) => {
        setStdAcad({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            target_data: "GET_ACTIVE_ACAD",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdAcad({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setStdAcad({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    var selectIndex = results.findIndex(
                        (v) => v.atp_group_type.id === acadType
                    );
                    setStdAcad({ loading: false, data: results[selectIndex], message: "" });
                } else {
                    setStdAcad({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    }

    const [stdAct, setStdAct] = useState({ loading: false, data: [], message: "" });
    const GET_STUDENT_ACTIVITY = () => {
        setStdAct({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
                activity_type: "asc.student.activity.leave"
            },
            target_data: "GET_STUDENT_ACTIVITY",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setStdAct({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setStdAct({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    setStdAct({ loading: false, data: results, message: "" });
                } else {
                    setStdAct({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    };

    useEffect(() => {
        GET_STUDENT_STATUS();
        GET_STUDENT_ACTIVITY();
    }, [])

    if(Object.values(stdAcad.data).length > 0 && Object.values(stdAct.data).length > 0){
        var exisAcad = stdAct.data.filter(prev => prev.atp_id_term === stdAcad.data.reff_atp_term.id)
        if(Object.values(exisAcad).length > 0){
            hasSubmit = true;
        }
    }

    return (
        <div id='rereg-leave'>
            <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                <div className="alert-icon">
                    <span className="svg-icon svg-icon-primary svg-icon-xl">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero"></path>
                            </g>
                        </svg>
                    </span>
                </div>
                <div className="alert-text"><h3>Leave Semester Periode {(Object.values(stdAcad.data).length > 0) ? ((Object.values(stdAcad.data.reff_atp_term).length > 0) ? stdAcad.data.reff_atp_term.name : "-") : "-"}</h3></div>
            </div>

            <div className="row">
                {!hasSubmit ? (
                <div className="col-md-6 col-lg-6 col-xxl-4">
                    {stdAcad.loading ? <LoadingBtn /> : (
                        stdAcad.message ? <AlertNotif message={stdAcad.message} color={"danger"} /> : (
                            (Object.values(stdAcad.data).length > 0) ? (
                                <Form stdAcad={stdAcad.data} stdStatus={stdStatus} setStdStatus={setStdStatus} GET_STUDENT_ACTIVITY={GET_STUDENT_ACTIVITY} />
                            ) : ""
                        )
                    )}
                </div>
                ) : ""}
                <div className="col-md-6 col-lg-6 col-xxl-8">
                    <TableData data={stdAct.data} />
                </div>
            </div>

        </div>
    )
}
