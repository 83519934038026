export {Dashboard} from "./Dashboard";
export {MADashboard} from "./Attendance/MADashboard";
export {AtdClassList} from "./Attendance/AtdClassList";
export {DetailClassList} from "./Attendance/AtdClassList/DetailClassList";
export {Reporting} from "./Attendance/ReportingNew/Reporting";
export {SGDashboard} from "./ScoreandGrade/SGDashboard";
export {MidScore} from "./ScoreandGrade/Mid";
export {DetailMid} from "./ScoreandGrade/Mid/DetailMid";
export {FinalScore} from "./ScoreandGrade/Final";
export {InternshipScore} from "./ScoreandGrade/Internship"
export {DetailFinal} from "./ScoreandGrade/Final/DetailFinal";
export {StudentProfile} from "./Students/StudentProfile"

export {ThesisScore} from "./Thesis/Defense";
export {ThesisSchema} from "./Thesis/Defense/ThesisSchema"

export {ThesisActivityLCT} from "./Thesis/Activity/ThesisActivityLCT"
export {ThesisActivityLCTDetail} from "./Thesis/Activity/ThesisActivityLCTDetail"