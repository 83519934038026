import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom'
import TableStudentScore from "./TableStudentScore";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";
import TableStudentScoreLetter from "./TableStudentScoreLetter";
import UpdateComp from "./template-score/UpdateComp";
import { GET_SCR_LECTURER_SECTION } from "./Apis";
import { ProgressScoreGenerate } from "./template-score/ScoreActions";
import { openModal } from "../../../../Library/ModalPopUp";
import ScoreDownload from "./ScoreDownload";

export default function FormScoreComp({ section_id, sectionInfo }) {
  const [updateComponent, setUpdateComponent] = useState(false);
  const [dataScore, setDataScore] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [scrStudent, setScrStudent] = useState([]);
  const [scrCom, setScrCom] = useState([]);

  useEffect(() => {
    GET_SCR_LECTURER_SECTION(
      { section_id: section_id },
      setDataScore,
      setScrStudent,
      setScrCom
    );
  }, []);

  const ResultData = useMemo(() => {
    const studentsWithScores = [...scrStudent];
    studentsWithScores.forEach((student) => {
      scrCom.forEach((scItem) => {
        const alreadyExists = student.student_course_com.some(
          (item) => item.wg_com.id === scItem.wg_com.id
        );
        if (!alreadyExists) {
          student.student_course_com.push({
            id: Object.values(student.student_course_com).length + 1,
            score: "",
            submitted: "",
            submittedby: "",
            wg_com: scItem.wg_com,
          });
        }
      });
    });

    return studentsWithScores;
  }, [scrStudent, scrCom]);

  const ReloadData = () => {
    GET_SCR_LECTURER_SECTION(
      { section_id: section_id },
      setDataScore,
      setScrStudent,
      setScrCom
    );
  };

  const HandlerBack = () => {
    setUpdateComponent(false);
    ReloadData();
  };

  const HandlerLeavePage = () =>{
    openModal({header:"Confirmation", message:(
      <div className="text-center">
        <h4>All unsaved information will be lost. Are you sure you want to leave this page?</h4>
        <div className="btn-group mt-5">
          <button className="btn btn-lg btn-light" type="button" onClick={()=>openModal({open:false})}>Cancel</button>
          <Link to={"/class-score-submission"}  onClick={()=>openModal({open:false})} className="btn btn-lg btn-primary">Discard Changes</Link>
        </div>
      </div>
    )})
  }

  const [progressBar, setProgressBar] = useState(false);
  const HandlerProgressBar = () => {
    setUpdateComponent(false); setProgressBar(true);
    ReloadData();
  };

  if (updateComponent) {
    return (
      <UpdateComp section_id={section_id} HandlerBack={HandlerBack} HandlerProgressBar={HandlerProgressBar} />
    );
  }
  if (progressBar) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="text-center">
            <h3>Data has been successfully saved</h3>
            <p>Please Wait! We are updating your data.</p>
            <ProgressScoreGenerate setProgressBar={setProgressBar} start={true} />
          </div>
        </div>
      </div>
    )
  }

  const handlerDownload = () => {
    let lecturername = "";
    if(sectionInfo.lecturer && Object.values(sectionInfo.lecturer).length > 0){
      sectionInfo.lecturer.map(v=>{
        lecturername += (v.emp ? (v.emp.fullname ? v.emp.fullname+", " : "") : "");
      })
    }
    var param = {
      course:(sectionInfo.course) ? (sectionInfo.course.coursetitle ? sectionInfo.course.coursetitle : "") : "",
      course_code:(sectionInfo.course) ? (sectionInfo.course.code ? sectionInfo.course.code : "") : "",
      course_credit:(sectionInfo.course) ? (sectionInfo.course.credit ? sectionInfo.course.credit : "") : "",
      course_id:(sectionInfo.course) ? (sectionInfo.course.id ? sectionInfo.course.id : "") : "",
      section_id:section_id,
      lecturername:lecturername
    };
    var resultdata = {score: ResultData, component: scrCom};
    ScoreDownload(resultdata, param);
  };

  return (
    <div className="card card-custom card-fit card-border">
      <div className="card-header">
        <div className="card-title">
          <div className="d-flex align-items-center">
            <div className="border-right pr-3">
              <span className="cursor-pointer" onClick={()=>HandlerLeavePage()} title="Back to class list">
                <i className="la la-arrow-circle-left icon-3x text-hover-warning"></i>
              </span>
            </div>
            <div className="title d-flex align-items-center ml-5">
              <span className="card-icon">
                <i className="flaticon2-writing"></i>
              </span>
              <h3 className="card-label">Student List</h3>
            </div>
          </div>
        </div>
        <div className="card-toolbar">
          {Object.values(dataScore.data).length > 0 ? 
            <>
            {dataScore.data.score_type === 1 && (
              <div className="btn-group mr-2">
                <button
                  className="btn btn-icon btn-primary btn-lg"
                  type="button"
                  onClick={() => setUpdateComponent(true)}
                >
                  <i className="flaticon-cogwheel-2"></i>
                </button>
                <button
                  className="btn btn-light-primary btn-lg font-weight-bolder"
                  type="button"
                  onClick={() => setUpdateComponent(true)}
                >
                  <span>Grading Scheme Setting</span>
                </button>
              </div>
            )}
            <div className="btn-group mr-2">
                <button
                  className="btn btn-icon btn-primary btn-lg"
                  type="button"
                >
                  <i className="flaticon2-download-2"></i>
                </button>
                <button
                  className="btn btn-light-primary btn-lg font-weight-bolder"
                  type="button"
                  onClick={() => handlerDownload()}
                >
                  <span>Export to excel</span>
                </button>
            </div>
            </>
            : ""}
            
        </div>
      </div>
      <div className="card-body py-0">
        {dataScore.loading ? (
          <LoadingBtn />
        ) : dataScore.message ? (
          <AlertNotif message={dataScore.message} color={"danger"} />
        ) : Object.values(dataScore.data).length > 0 &&
          Object.values(scrStudent).length > 0 ? (
          dataScore.data.score_type !== 1 ? (
            <TableStudentScoreLetter
              data={ResultData}
              section_id={section_id}
              ReloadData={ReloadData}
              HandlerLeavePage={HandlerLeavePage}
            />
          ) : (
            (Object.values(scrCom).length > 0) ?
              <TableStudentScore
                data={ResultData}
                ReloadData={ReloadData}
                components={scrCom}
                scrStudent={scrStudent}
                setScrStudent={setScrStudent}
                sectionInfo={sectionInfo}
                section_id={section_id}
                HandlerProgressBar={HandlerProgressBar}
              />
              : <UpdateComp section_id={section_id} HandlerBack={HandlerBack} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}