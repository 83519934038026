import React, { useEffect, useState } from "react";
import Moment from "moment";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";
import sign from "jwt-encode";
import packageJson from "../../../../../../package.json";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import { AlertNotif } from "../../../../Library/AlertNotif";
import AxiosLib from "../../../../Library/AxiosLib";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { openModal } from "../../../../Library/ModalPopUp";
import { openToast } from "../../../../Library/ToastPopUp";

export default function DetailSchedule(props) {
  const data = props.data ? props.data : null;
  const groupData = JSON.parse(data.groupId);

  const [isFuture, setFuture] = useState(false);

  const today = Moment();
  const selectedDate = Moment(data.start);

  const [currtime, setCurrtime] = useState({
    loading: false,
    data: [],
    message: "",
  });

  //let isOpenQR = false;
  let isOpenQR = true;
  if (Object.values(currtime.data).length > 0) {
    if (currtime.data.today) {
      // var currTime = Moment(currtime.data.today).format('YYYY-MMMM-DD');
      // var startEvent = Moment(data.start).format('YYYY-MMMM-DD');
      //isOpenQR = (currTime >= startEvent);
    }
  }

  const [qrCode, setQRCode] = useState(groupData.qr);

  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [isCheckedTerm, setIscheckTerm] = useState(false);
  const [postAttd, setPostAttd] = useState({
    consecutive: true,
    emp_id: AuthDecode.currentData.entityid,
    remark: "",
    teaching_topic: "",
    tt_schd_id: groupData.id,
  });
  const [submitTT, setSubmitTT] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const handlerAttd = (e) => {
    e.preventDefault();

    if (AuthDecode.remotedBy) {
      openModal({ open: false });
      openToast({
        header: "Failed to save data",
        message:
          "You do not have the necessary permissions. Please contact your administrator if you believe this is an error.",
      });
    } else {
      var time1 = Moment(today).format("YYYY-MM-DD");
      var time2 = Moment(selectedDate).format("YYYY-MM-DD");
      if (time2 > time1) {
        setFuture(true);
      } else if (time2 < time1) {
        SUBMIT_ATD_LECTURER();
      } else {
        SUBMIT_ATD_LECTURER();
      }
    }
  };

  const SUBMIT_ATD_LECTURER = () => {
    setSubmitTT({ ...submitTT, loading: true, message: "", data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: postAttd,
      target_data: "SUBMIT_ATD_LECTURER",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      //console.log(response);
      if ("message" in response.response) {
        var msg =
          "Failed save Teaching Topic. Error " + response.response.message.user;
        setSubmitTT({ ...submitTT, loading: false, message: msg });
      } else if ("responseCode" in response) {
        var result = response.response.data;
        var msg = "";
        if (response.responseCode === 201) {
          msg = "Success update teaching topic.";
        } else {
          msg = "Failed update teaching topic.";
        }
        if (result) {
          setQRCode(result.qr);
        }
        setSubmitTT({
          ...submitTT,
          loading: false,
          message: msg,
          data: result,
        });
        GetCurrentTime();
        props.GET_SCHD_CLASS_LEC_NEXT(props.paramDate);
      } else {
        var msg = "Failed saved data";
        setSubmitTT({ ...submitTT, loading: false, message: msg });
      }
    });
  };

  const openQR = () => {
    var paramQR = qrCode;
    paramQR.section_id = groupData.section_id;
    paramQR.start_dt = groupData.start_dt;
    paramQR.end_dt = groupData.end_dt;
    if (qrCode) {
      var trimmed_payload = JSON.stringify(paramQR).replace(/\s+/g, "");
      var base64 = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(trimmed_payload)
      );
      var linkQR = packageJson.system_param.AttdQR + "showqr/" + base64;
      //window.location.replace(linkQR);

      var strWindowFeatures =
        "location=yes,height=700,width=550,scrollbars=yes,status=yes";
      window.open(linkQR, "_blank", strWindowFeatures);
    }
  };

  const GetCurrentTime = () => {
    setCurrtime({ ...currtime, loading: true, data: [] });
    AxiosLib("", "/app/api/current-time").then((response) => {
      if (response.error) {
        setCurrtime({
          ...currtime,
          loading: false,
          data: [],
          message: response.error,
        });
      } else {
        setCurrtime({
          ...currtime,
          loading: false,
          data: response,
          message: "",
        });
      }
    });
  };

  const [currSection, setCurrSection] = useState({
    data: [],
    message: "",
    loading: false,
  });

  const GET_SECTION = (section_id) => {
    setCurrSection({ ...currSection, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        section_id: section_id,
      },
      target_data: "GET_SECTION",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setCurrSection({
          ...currSection,
          message: response.error,
          loading: false,
          data: [],
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setCurrSection({
          ...currSection,
          message: msg,
          loading: false,
          data: [],
        });
      } else {
        var results = response.response.data;
        if (results) {
          setCurrSection({
            ...currSection,
            message: "",
            loading: false,
            data: results,
          });
        } else {
          setCurrSection({
            ...currSection,
            message: "No record found",
            loading: false,
            data: [],
          });
        }
      }
    });
  };

  useEffect(() => {
    GetCurrentTime();
    if (data.id) GET_SECTION(data.id);
  }, []);

  return (
    <div className="info">
      {isFuture ? (
        <div className="bg-light-danger rounded p-5 text-center">
          <p>
            <span className="font-weight-bolder d-block">Warning!</span>
            You are attempting to submit attendance for a future date (
            <span className="font-weight-bolder">
              {Moment(data.start).format("D MMMM YYYY")}
            </span>
            ). Are you sure you want to continue the submission?
          </p>
          <div className="btn-group">
            <button
              className="btn btn-lg btn-white font-weight-bolder"
              type="button"
              onClick={() => openModal({ open: false })}
            >
              NO
            </button>
            <button
              className="btn btn-lg btn-danger font-weight-bolder p"
              type="button"
              disabled={submitTT.loading}
              onClick={() => SUBMIT_ATD_LECTURER()}
            >
              {submitTT.loading ? "Processing..." : "YES"}
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-start">
              <thead>
                <tr className="text-left text-uppercase">
                  <th colSpan={3}>
                    <span className="text-dark">Detail Schedule</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {groupData.is_cancel ? (
                  <tr>
                    <td
                      colSpan={3}
                      className="text-center bg-danger text-white text-uppercase"
                    >
                      Class is CANCEL
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {data.id ? (
                  <>
                    <tr>
                      <td className="font-weight-bolder" width={"30%"}>
                        Section ID
                      </td>
                      <td width={"2%"}>:</td>
                      <td>{data.id}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bolder">Course</td>
                      <td>:</td>
                      <td>
                        <p className="mb-0">
                          <span className="d-block">{data.title}</span>
                          {groupData.label ? (
                            <span>{groupData.label}</span>
                          ) : (
                            ""
                          )}
                        </p>
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td className="font-weight-bolder">Event</td>
                    <td>:</td>
                    <td>
                      <p className="mb-0">
                        <span className="d-block">{data.title}</span>
                      </p>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="font-weight-bolder">Room</td>
                  <td>:</td>
                  <td>
                    {groupData.event_room
                      ? groupData.event_room.map((v, index) => (
                          <span key={index}>
                            {v.room
                              ? v.room.g_resourceName +
                                "(" +
                                v.room.g_capacity +
                                ")"
                              : "-"}
                          </span>
                        ))
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bolder">Date and Time</td>
                  <td>:</td>
                  <td>
                    {Moment(data.start).format("D MMMM YYYY")},
                    {Moment(data.start).format("hh:mm a")} -{" "}
                    {Moment(data.end).format("hh:mm a")}
                  </td>
                </tr>
                {groupData.section_pre ? (
                  <tr>
                    <td className="font-weight-bolder">Department</td>
                    <td>:</td>
                    <td>
                      {groupData.section_pre.map((d, index) => (
                        <div
                          className="text-dark"
                          key={index}
                          title={d.clu ? d.clu.name : ""}
                        >
                          <span>{d.clu ? d.clu.code : ""}</span>
                          <span>{d.clu_spec ? "-" + d.clu_spec.code : ""}</span>
                          <span className="ml-2">Semester {d.semester}</span>
                        </div>
                      ))}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {!groupData.is_cancel ? (
                  props.actionDetail ? (
                    ""
                  ) : (
                    <>
                      {groupData.atd_lecturer ? (
                        <tr>
                          <td className="font-weight-bolder">Teaching Topic</td>
                          <td>:</td>
                          <td>
                            {groupData.atd_lecturer.teaching_topic
                              ? decodeURIComponent(
                                  groupData.atd_lecturer.teaching_topic
                                )
                              : "-"}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}

                      {groupData.atd_lecturer ? (
                        <tr>
                          <td className="font-weight-bolder">Submit at</td>
                          <td>:</td>
                          <td>
                            {groupData.atd_lecturer.submit_formatted
                              ? groupData.atd_lecturer.submit_formatted
                              : "-"}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  )
                ) : (
                  ""
                )}
              </tbody>
              {!groupData.is_cancel ? (
                props.actionDetail ? (
                  ""
                ) : (
                  <>
                    {qrCode ? (
                      qrCode.qr_code ? (
                        <tfoot>
                          <tr>
                            <td colSpan={3}>
                              <div className="d-flex flex-row justify-content-between align-items-center">
                                {currtime.loading ? (
                                  <LoadingBtn />
                                ) : isOpenQR ? (
                                  <button
                                    className="btn btn-sm btn-success px-5 font-weight-bold"
                                    type="button"
                                    onClick={() => openQR()}
                                  >
                                    <i className="fas fa-qrcode mr-2"></i>
                                    Show Attendance QR Code
                                  </button>
                                ) : (
                                  <p className="border border-info rounded py-1 px-2 text-info mb-0">
                                    QR Code will available at{" "}
                                    <span className="font-weight-bold">
                                      {Moment(data.start).format("D MMM YYYY")}
                                    </span>
                                  </p>
                                )}

                                <a
                                  className="schedule-courses btn btn-sm btn-light-primary"
                                  href={
                                    "/detail/attendance/" +
                                    sign(
                                      { section_id: data.id, status: 5 },
                                      packageJson.system_param.PublicKey
                                    )
                                  }
                                >
                                  Go to attendance{" "}
                                  <i className="far fa-caret-square-right ml-2"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {currSection.loading ? (
                      <tr>
                        <td colSpan={3}>
                          <LoadingBtn />
                        </td>
                      </tr>
                    ) : currSection.message ? (
                      <tr>
                        <td colSpan={3}>
                          <AlertNotif
                            message={currSection.message}
                            color={"danger"}
                            title={"Information"}
                          />
                        </td>
                      </tr>
                    ) : Object.values(currSection.data).length > 0 ? (
                      <tr>
                        <td className="font-weight-bolder">G-Classroom</td>
                        <td>:</td>
                        <td>
                          {currSection.data.map((v, index) =>
                            v.g_calendarId ? (
                              <p className="mb-0" key={index}>
                                <a href={v.g_alternateLink} target="_blank">
                                  Click here to visit Google Class Room
                                </a>
                              </p>
                            ) : (
                              ""
                            )
                          )}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    <tr>
                      <td colSpan={3}>
                        {currSection.loading ? (
                          <LoadingBtn />
                        ) : currSection.message ? (
                          <AlertNotif
                            message={currSection.message}
                            color={"danger"}
                            title={"Information"}
                          />
                        ) : Object.values(currSection.data).length > 0 ? (
                          currSection.data.map((v, index) =>
                            v.g_calendarId ? (
                              <div
                                key={index}
                                className="bg-light-primary p-3 rounded"
                              >
                                <p className="mb-0 font-weight-bolder">
                                  Notes:
                                </p>
                                <p className="mb-0">
                                  To display this course schedule in your Google
                                  Calendar, please subscribe by copying this{" "}
                                  <span className="font-weight-bolder">
                                    Calendar ID{" "}
                                  </span>
                                  (
                                  <span className="font-weight-bolder text-primary mx-2">
                                    {v.g_calendarId}
                                  </span>
                                  ), and then follow the instructions provided{" "}
                                  <a
                                    href="https://support.google.com/calendar/answer/37100?hl=en&co=GENIE.Platform%3DDesktop"
                                    target="_blank"
                                  >
                                    here
                                  </a>
                                  .
                                </p>
                              </div>
                            ) : (
                              ""
                            )
                          )
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </>
                )
              ) : (
                ""
              )}
            </table>
          </div>
          {!groupData.is_cancel ? (
            props.actionDetail ? (
              ""
            ) : qrCode ? (
              qrCode.qr_code ? (
                ""
              ) : (
                <form method="post" onSubmit={(e) => handlerAttd(e)}>
                  <div className="table-responsive">
                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                      <tbody>
                        <tr>
                          <td>
                            <label className="font-weight-bolder required">
                              Teaching Topic
                            </label>
                            <textarea
                              name="teaching_topic"
                              id="teaching_topic"
                              className="form-control"
                              required
                              defaultValue={postAttd.teaching_topic}
                              onChange={(e) =>
                                setPostAttd({
                                  ...postAttd,
                                  teaching_topic: encodeURIComponent(
                                    e.target.value
                                  ),
                                })
                              }
                            ></textarea>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="border p-3 bg-light-primary rounded">
                              <div className="d-flex flex-row justify-content-center align-items-center">
                                <input
                                  type="checkbox"
                                  name="is_checked"
                                  className="mr-3"
                                  required
                                  onChange={() =>
                                    setIscheckTerm(!isCheckedTerm)
                                  }
                                />
                                <div>
                                  <p className="mb-0 text-dark">
                                    I hereby agree to cover all sessions in this
                                    course with the teaching topics as outlined
                                    above.
                                  </p>
                                </div>
                              </div>
                            </div>

                            {AuthDecode.remotedBy ? (
                              <div className="my-3">
                                <div className="alert alert-danger">
                                  <div className="alert-title text-left">
                                    <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                                    You cannot submit this Session.
                                    <br />
                                    This session belongs to{" "}
                                    <b>
                                      Mr/Mrs.{" "}
                                      {AuthDecode.currentData.given_name +
                                        " " +
                                        AuthDecode.currentData.family_name}
                                    </b>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td className="text-right">
                            <button
                              disabled={!isCheckedTerm}
                              className="btn btn-lg btn-primary"
                              type="submit"
                            >
                              {submitTT.loading
                                ? "Processing..."
                                : "Create Attendance QR Code"}
                            </button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </form>
              )
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </>
      )}

      {submitTT.message ? (
        <AlertNotif message={submitTT.message} color={"info"} />
      ) : (
        ""
      )}
    </div>
  );
}
