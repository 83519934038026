import React, { useEffect, useMemo, useState } from "react";
import Moment from "moment";
import jwt_decode from "jwt-decode";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import FullCalendarSchd from "./FullCalendarSchd";
import AxiosLib from "../../../../Library/AxiosLib";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function CalendarSchedule() {
  const [schd, setSchd] = useState({
    loading: false,
    message: "",
    data: [],
    result: false,
  });

  const startOfMonth = Moment().startOf("month").format("YYYY-MM-DD");
  //const endOfMonth = Moment().endOf("month").format("YYYY-MM-DD");
  const endOfMonth = Moment()
    .endOf("month")
    .add(7, "days")
    .format("YYYY-MM-DD");

  // const endOfMonth = Moment()
  //   .endOf("month")
  //   .add(7, "days")
  //   .format("YYYY-MM-DD");

  const paramPost = {
    empid: AuthDecode.currentData.entityid,
    tgl: startOfMonth,
    tglend: endOfMonth,
  };
  const [paramDate, setParamDate] = useState(paramPost);

  const [currtime, setCurrtime] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const GET_SCHD_CLASS_LEC_NEXT = (parameter) => {
    setSchd({ ...schd, loading: true, data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: parameter,
      target_data: "GET_SCHD_CLASS_LEC_NEXT",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setSchd({
          ...schd,
          result: false,
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setSchd({
          ...schd,
          result: false,
          message: msg,
          loading: false,
        });
      } else {
        var results = response.response.data;
        if (results) {
          var currDate =
            Object.values(currtime.data).length > 0
              ? currtime.data.today
              : new Date();
          currDate = Moment(currDate).format("YYYY-MM-DD");
          var eventItem = convertEventItem(currDate, results);
          setSchd({
            ...schd,
            result: true,
            data: eventItem,
            message: "",
            loading: false,
          });
        } else {
          setSchd({
            ...schd,
            result: true,
            data: [],
            message: "No record found",
            loading: false,
          });
        }
      }
    });
  };

  const GetCurrentTime = () => {
    setCurrtime({ ...currtime, loading: true, data: [] });
    AxiosLib("", "/app/api/current-time").then((response) => {
      if (response.error) {
        setCurrtime({
          ...currtime,
          loading: false,
          data: [],
          message: response.error,
        });
      } else {
        setCurrtime({
          ...currtime,
          loading: false,
          data: response,
          message: "",
        });
      }
    });
  };

  useEffect(() => {
    GetCurrentTime();
    GET_SCHD_CLASS_LEC_NEXT(paramDate);
  }, []);

  const handlerPrevNextMonth = (type) => {
    if (type === 1) {
      //prev
      const startOfMonth = Moment(paramDate.tgl)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = Moment(paramDate.tgl)
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        empid: paramDate.empid,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_CLASS_LEC_NEXT(paramSchd);
    } else if (type === 2) {
      //next
      const startOfMonth = Moment(paramDate.tgl)
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = Moment(paramDate.tgl)
        .add(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        empid: paramDate.empid,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_CLASS_LEC_NEXT(paramSchd);
    } else {
      //today
      const startOfMonth = Moment(new Date())
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = Moment(new Date()).endOf("month").format("YYYY-MM-DD");
      setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        empid: paramDate.empid,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_CLASS_LEC_NEXT(paramSchd);
    }
  };

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-body pt-4">
        <div className="pt-6 mb-6 d-flex justify-content-between align-items-center">
          <h3 className="text-capitalize">Your time table</h3>
          <div className="btn-nav">
            <div className="btn-group">
              <button
                className="btn btn-sm btn-light"
                type="button"
                onClick={() => handlerPrevNextMonth(1)}
              >
                <i className="icon-1x flaticon2-left-arrow"></i>
              </button>
              <button
                className="btn btn-sm btn-light text-dark"
                type="button"
                onClick={() => handlerPrevNextMonth(3)}
              >
                <span className="h4">
                  {Moment(paramDate.tgl).format("MMMM YYYY")}
                </span>
              </button>
              <button
                className="btn btn-sm btn-light"
                type="button"
                onClick={() => handlerPrevNextMonth(2)}
              >
                <i className="icon-1x flaticon2-right-arrow"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="mb-2">
          <p className="text-center font-weight-boder mb-0">
            Color information
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex">
              <div className="p-2 bg-success rounded-left">
                <span className="text-white font-weight-bolder font-size-sm px-8">
                  Draft
                </span>
              </div>
            </div>
            <div className="d-flex">
              <div className="p-2 bg-primary">
                <span className="text-white font-weight-bolder font-size-sm px-5">
                  Submited
                </span>
              </div>
            </div>
            <div className="d-flex">
              <div className="p-2 bg-info rounded-right">
                <span className="text-white font-weight-bolder font-size-sm px-8">
                  Today
                </span>
              </div>
            </div>
          </div>
        </div>

        {schd.loading ? <LoadingBtn /> : ""}
        {!schd.loading ? (
          <FullCalendarSchd
            data={Object.values(schd.data).length > 0 ? schd.data : []}
            initialDate={paramDate.tgl}
            GET_SCHD_CLASS_LEC_NEXT={GET_SCHD_CLASS_LEC_NEXT}
            paramDate={paramDate}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const getDate = (dayString) => {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
};

const convertEventItem = (currdate, data) => {
  let events = [];
  if (data) {
    data.map((v) => {
      var blue = "#0359fbba";
      var green = "#03b898fc";
      var purple = "#8505efe8";
      var colorStatus =
        currdate === Moment(v.start_dt).format("YYYY-MM-DD")
          ? purple
          : (v.qr ? (v.qr.qr_code ? blue : green): "#1c325e");
      if (v.is_cancel) {
        colorStatus = "#F64E60";
      }
      var objItem = {};
      objItem.title = v.section_id ? (v.course ? v.course.coursetitle : "-") : v.label;
      objItem.id = v.section_id ? v.section_id : "";
      objItem.start = getDate(v.start_dt);
      objItem.background = colorStatus;
      objItem.border = colorStatus;
      objItem.end = getDate(v.end_dt);
      objItem.groupId = JSON.stringify(v);
      events.push(objItem);
    });
  }

  return events;
};
