import React, { useState } from 'react'
import Moment from "moment";
import jwt_decode from "jwt-decode";
import { StudentRepresentative } from '../../../../Const/StudentRepresentative';
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../../../Page/Widgets/LoadingBtn';
import { AlertNotif } from '../../../../Library/AlertNotif';
import CalendarMonth from './CalendarMonth';

export default function TTDepartment() {
    const arrData = StudentRepresentative;

    const [selectedSemes, setSelectedSemes] = useState('');
    const [selectedDept, setSelectedDept] = useState('');

    const startOfMonth = Moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = Moment()
        .endOf("month")
        .add(7, "days")
        .format("YYYY-MM-DD");

    const paramPost = {
        studentid: selectedDept.toString(),
        tgl: startOfMonth,
        tglend: endOfMonth,
    };
    const [paramDate, setParamDate] = useState(paramPost);

    const handleSemesChange = (event) => {
        const value = event.target.value;
        setSelectedSemes(value);
        setSelectedDept('');
    };

    const deptOptions = selectedSemes
        ? arrData.find(item => item.semes.toString() === selectedSemes)?.dept || []
        : [];

    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const [schd, setSchd] = useState({
        loading: false,
        message: "",
        data: []
    });

    const GET_SCHD_CLASS_STD_NEXT = (param) => {
        setSchd({ message: "", loading: true, data: [] });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: param,
            target_data: "GET_SCHD_CLASS_STD_NEXT",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setSchd({
                    data: [],
                    message: response.error,
                    loading: false,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setSchd({
                    data: [],
                    message: msg,
                    loading: false,
                });
            } else {
                var results = response.response.data;
                if (results) {
                    var eventItem = convertEventItem(results);
                    setSchd({
                        data: eventItem,
                        message: "",
                        loading: false,
                    });
                } else {
                    setSchd({
                        data: [],
                        message: "No record found",
                        loading: false,
                    });
                }
            }
        });
    };

    const handlerPrevNextMonth = (type) => {
        if (type === 1) {
            //prev
            const startOfMonth = Moment(paramDate.tgl)
                .subtract(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
            const endOfMonth = Moment(paramDate.tgl)
                .subtract(1, "months")
                .endOf("month")
                .format("YYYY-MM-DD");
            setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
            var paramSchd = {
                studentid: paramDate.studentid,
                tgl: startOfMonth,
                tglend: endOfMonth,
            };
            GET_SCHD_CLASS_STD_NEXT(paramSchd);
        } else if (type === 2) {
            //next
            const startOfMonth = Moment(paramDate.tgl)
                .add(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
            const endOfMonth = Moment(paramDate.tgl)
                .add(1, "months")
                .endOf("month")
                .format("YYYY-MM-DD");
            setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
            var paramSchd = {
                studentid: paramDate.studentid,
                tgl: startOfMonth,
                tglend: endOfMonth,
            };
            GET_SCHD_CLASS_STD_NEXT(paramSchd);
        } else {
            //today
            const startOfMonth = Moment(new Date())
                .startOf("month")
                .format("YYYY-MM-DD");
            const endOfMonth = Moment(new Date()).endOf("month").format("YYYY-MM-DD");
            setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
            var paramSchd = {
                studentid: paramDate.studentid,
                tgl: startOfMonth,
                tglend: endOfMonth,
            };
            GET_SCHD_CLASS_STD_NEXT(paramSchd);
        }
    };

    const HandlerDepartment = (value) => {
        setSelectedDept(value);
        const paramPost = {
            studentid: value.toString(),
            tgl: startOfMonth,
            tglend: endOfMonth,
        };
        setParamDate(paramPost);
        GET_SCHD_CLASS_STD_NEXT(paramPost);
    }

    return (
        <div className='card card-custom gutter-b'>
            <div className="card-header align-items-center border-0 mt-4">
                <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">Study Program Timetable (Generalized)</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">Please choose department by semester</span>
                </h3>
            </div>
            <div className="card-body pt-0 px-0">
                <div className="bg-light p-5 d-flex justify-content-center align-items-end">
                    <div className="mb-3 mr-3 w-50">
                        <label className="font-weight-bold">Semester</label>
                        <select name="semes" className="form-control" required value={selectedSemes} onChange={handleSemesChange}>
                            <option value="">Choose one</option>
                            {arrData.map((s, index) => (
                                <option key={index} value={s.semes}>Semester {s.semes}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3 mr-3 w-50">
                        <label className="font-weight-bold">Department</label>
                        <select name="semes" className="form-control" value={selectedDept} required onChange={(e) => HandlerDepartment(e.target.value)}>
                            <option value="">Choose one</option>
                            {Object.values(deptOptions).length > 0 ? (
                                deptOptions.map((d, index) => (
                                    <option value={d.stdid} key={index}>{d.code}</option>
                                ))
                            ) : ""}
                        </select>
                    </div>

                </div>

                <div className="selected-calendar">
                    {schd.loading ? <LoadingBtn /> : schd.message ? <AlertNotif color={"danger"} message={schd.message} title={"Information"} /> : (
                        <div className="cal-dept">
                            <div className="pt-6 mb-6 d-flex justify-content-end align-items-center">
                                <div className="btn-group">
                                    <button
                                        className="btn btn-sm btn-light"
                                        type="button"
                                        disabled={schd.loading}
                                        onClick={() => handlerPrevNextMonth(1)}
                                    >
                                        <i className="icon-1x flaticon2-left-arrow"></i>
                                    </button>
                                    <button
                                        className="btn btn-sm btn-light text-dark"
                                        type="button"
                                        disabled={schd.loading}
                                        onClick={() => handlerPrevNextMonth(3)}
                                    >
                                        <span className="h4">
                                            {Moment(paramDate.tgl).format("MMMM YYYY")}
                                        </span>
                                    </button>
                                    <button
                                        className="btn btn-sm btn-light"
                                        type="button"
                                        disabled={schd.loading}
                                        onClick={() => handlerPrevNextMonth(2)}
                                    >
                                        <i className="icon-1x flaticon2-right-arrow"></i>
                                    </button>
                                </div>
                            </div>

                            <CalendarMonth paramDate={paramDate} data={Object.values(schd.data).length > 0 ? schd.data : []} selectedDept={selectedDept} GET_SCHD_CLASS_STD_NEXT={GET_SCHD_CLASS_STD_NEXT} />
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}


const getDate = (dayString) => {
    const today = new Date();
    const year = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();

    if (month.length === 1) {
        month = "0" + month;
    }

    return dayString.replace("YEAR", year).replace("MONTH", month);
};

const convertEventItem = (data) => {
    let events = [];
    if (data) {
        data.map((v) => {
            var objItem = {};
            objItem.title = v.course ? v.course.coursetitle : " - ";
            objItem.id = v.section_id;
            objItem.start = getDate(v.start_dt);
            objItem.end = getDate(v.end_dt);
            objItem.groupId = JSON.stringify(v);
            objItem.background = (v.is_cancel) ? "#f64e60" : "#3699ff";
            objItem.border = "#314a9c";
            events.push(objItem);
        });
    }

    return events;
};

