import React, { useEffect, useState } from "react";
import Moment from "moment";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import CalendarMonthStd from "./CalendarMonthStd";
import CalendarWeekStd from "./CalendarWeekStd";

export default function CalendarDeptStd({ studentid }) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [schd, setSchd] = useState({
    loading: false,
    message: "",
    data: [],
    result: false,
  });

  const GET_SCHD_CLASS_STD_NEXT = (param) => {
    setSchd({ ...schd, loading: true, data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: param,
      target_data: "GET_SCHD_CLASS_STD_NEXT",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setSchd({
          ...schd,
          result: false,
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setSchd({
          ...schd,
          result: false,
          message: msg,
          loading: false,
        });
      } else {
        var results = response.response.data;
        if (results) {
          var eventItem = convertEventItem(results);
          setSchd({
            ...schd,
            result: true,
            data: eventItem,
            message: "",
            loading: false,
          });
        } else {
          setSchd({
            ...schd,
            result: true,
            data: [],
            message: "No record found",
            loading: false,
          });
        }
      }
    });
  };

  const startOfMonth = Moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = Moment()
    .endOf("month")
    .add(7, "days")
    .format("YYYY-MM-DD");

  const paramPost = {
    studentid: studentid.toString(),
    tgl: startOfMonth,
    tglend: endOfMonth,
  };
  const [paramDate, setParamDate] = useState(paramPost);

  useEffect(() => {
    if (studentid) {
      GET_SCHD_CLASS_STD_NEXT(paramDate);
    }
  }, []);

  const handlerPrevNextMonth = (type) => {
    if (type === 1) {
      //prev
      const startOfMonth = Moment(paramDate.tgl)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = Moment(paramDate.tgl)
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        studentid: studentid.toString(),
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_CLASS_STD_NEXT(paramSchd);
    } else if (type === 2) {
      //next
      const startOfMonth = Moment(paramDate.tgl)
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = Moment(paramDate.tgl)
        .add(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        studentid: studentid.toString(),
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_CLASS_STD_NEXT(paramSchd);
    } else {
      //today
      const startOfMonth = Moment(new Date())
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = Moment(new Date()).endOf("month").format("YYYY-MM-DD");
      setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        studentid: studentid.toString(),
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_CLASS_STD_NEXT(paramSchd);
    }
  };
  const [defaultView, setDefaultView] = useState("month");
  return (
    <div className="cal-dept mt-5">
      {/* <div className="d-flex justify-content-center align-items-center ">
        <div className="btn-group">
          <button
            className={
              "btn btn-sm font-weight-bolder cursor-pointer " +
              (defaultView === "month" ? "btn-primary" : "btn-light")
            }
            type="button"
            onClick={() => setDefaultView("month")}
          >
            <i className="far fa-calendar"></i> Month View
          </button>
          <button
            className={
              "btn btn-sm font-weight-bolder cursor-pointer " +
              (defaultView === "week" ? "btn-primary" : "btn-light")
            }
            type="button"
            onClick={() => setDefaultView("week")}
          >
            <i className="far fa-calendar-alt"></i> Week View
          </button>
        </div>
      </div> */}
      {defaultView === "month" ? (
        <div className="cal-dept-month">
          <div className="pt-6 mb-6 d-flex justify-content-end align-items-center">
            <div className="btn-group">
              <button
                className="btn btn-sm btn-light"
                type="button"
                disabled={schd.loading}
                onClick={() => handlerPrevNextMonth(1)}
              >
                <i className="icon-1x flaticon2-left-arrow"></i>
              </button>
              <button
                className="btn btn-sm btn-light text-dark"
                type="button"
                disabled={schd.loading}
                onClick={() => handlerPrevNextMonth(3)}
              >
                <span className="h6">
                  {Moment(paramDate.tgl).format("MMMM YYYY")}
                </span>
              </button>
              <button
                className="btn btn-sm btn-light"
                type="button"
                disabled={schd.loading}
                onClick={() => handlerPrevNextMonth(2)}
              >
                <i className="icon-1x flaticon2-right-arrow"></i>
              </button>
            </div>
          </div>
          {schd.loading ? <LoadingBtn /> : ""}
          {!schd.loading ? (
            <CalendarMonthStd
              data={Object.values(schd.data).length > 0 ? schd.data : []}
              initialDate={paramDate.tgl}
              GET_SCHD_CLASS_STD_NEXT={GET_SCHD_CLASS_STD_NEXT}
              paramDate={paramDate}
              actionDetail={true}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="cal-dept-week">
            <CalendarWeekStd data={schd.data} />
        </div>
      )}
      
    </div>
  );
}

const getDate = (dayString) => {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
};

const convertEventItem = (data) => {
  let events = [];
  if (data) {
    data.map((v) => {
      var objItem = {};
      objItem.title = v.section_id ? (v.course ? v.course.coursetitle : "-") : v.label;
      objItem.id = v.section_id ? v.section_id : "";
      objItem.start = getDate(v.start_dt);
      objItem.end = getDate(v.end_dt);
      objItem.groupId = JSON.stringify(v);
      objItem.background = "#F9F9F9";
      objItem.border = "#F9F9F9";
      events.push(objItem);
    });
  }

  return events;
};
