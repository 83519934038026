import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";
import Transcript from "../../../Student/ScoreGrade/PrintTranscript/Transcript";

export function CCPStd() {
  const { stdid } = useParams();

  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [stdProfile, setStdProfile] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const GET_STUDENT_DATA_M = () => {
    setStdProfile({ ...stdProfile, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: stdid,
      },
      target_data: "GET_STUDENT_DATA_M",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdProfile({
          ...stdProfile,
          loading: false,
          data: [],
          message: response.error,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdProfile({
          ...stdProfile,
          loading: false,
          data: [],
          message: msg,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setStdProfile({
            ...stdProfile,
            loading: false,
            data: results,
            message: "",
          });
        } else {
          setStdProfile({
            ...stdProfile,
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  const [stdStatus, setStdStatus] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const [semesStatus, setSemesStatus] = useState([]);
  const [currSemes, setCurrSemes] = useState({ atpid: 0, data: [] });

  const GET_STUDENT_STATUS = () => {
    setStdStatus({ ...stdStatus, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: stdid,
      },
      target_data: "GET_STUDENT_STATUS",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdStatus({
          ...stdStatus,
          loading: false,
          data: [],
          message: response.error,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdStatus({ ...stdStatus, loading: false, data: [], message: msg });
      } else {
        var results = response.response.data;
        if (results) {
          setStdStatus({
            ...stdStatus,
            loading: false,
            data: results,
            message: "",
          });
          var semester = results.semester_status;
          semester.sort((a, b) => (a.semester > b.semester ? 1 : -1));
          setSemesStatus(semester)
          setCurrSemes({ atpid: semester[0].atp_term.id, data: semester[0] })
          var param = {
            studentid: stdid,
            atpid: semester[0].atp_term.id
          }
          GET_SCR_STUDENT_BY_ATPID(param)
        } else {
          setStdStatus({
            ...stdStatus,
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_STUDENT_DATA_M();
    GET_STUDENT_STATUS();
  }, [])

  const [subject, setSubject] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const GET_SCR_STUDENT_BY_ATPID = (param) => {
    setSubject({ ...subject, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: param,
      target_data: "GET_SCR_STUDENT_BY_ATPID",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setSubject({
          ...subject,
          message: response.error,
          loading: false,
          data: []
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setSubject({
          ...subject,
          message: msg,
          loading: false,
          data: []
        });
      } else {
        var results = response.response.data;
        if (results) {
          setSubject({
            ...subject,
            data: results,
            message: "",
            loading: false,
          });
        } else {
          setSubject({
            ...subject,
            data: [],
            message: "No record found",
            loading: false,
          });
        }
      }
    });
  };

  const HandlerSemester = (atpid) => {
    const findSemes = semesStatus.find(item => item.atp_term.id === atpid);
    setCurrSemes({ atpid: atpid, data: findSemes });
    var param = {
      studentid: stdid,
      atpid: atpid
    }
    GET_SCR_STUDENT_BY_ATPID(param)
  }

  return (
    stdProfile.loading || stdStatus.loading ? <LoadingBtn /> : stdProfile.message || stdStatus.message ? <AlertNotif message={stdProfile.message || stdStatus.message} color={"danger"} title={"Information"} /> : (
      (Object.values(stdProfile.data).length > 0 && Object.values(stdStatus.data).length > 0) ? (
        <div className="row">
          <div className="col-4 col-xxl-3">
            <div className="card card-custom gutter-b">
              <div className="card-body">
                <h3>
                  {stdProfile.data.firstname}'s Course Plan
                </h3>
                <select name="semester" className="form-control" value={currSemes.atpid} onChange={(e) => HandlerSemester(parseInt(e.target.value))}>
                  <option value="0">Choose Semester</option>
                  {Object.values(semesStatus).length > 0 ? (
                    semesStatus.map((s, index) => (
                      <option value={s.atp_term.id} key={index}>Semester {s.semester}</option>
                    ))
                  ) : ""}
                </select>
              </div>
            </div>
          </div>
          <div className="col-8 col-xxl-9">
            <div className="">
            {currSemes.data ? (
              <Transcript status={stdStatus.data}
                profile={stdProfile.data}
                selected={currSemes.data.semester}
                CurrentSemesStatus={currSemes.data}
                CoursePlan={subject} />
            ) : ""}
            </div>
          </div>
        </div>

      ) : ""
    )
  )
}
