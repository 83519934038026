import React from 'react'
import Moment from "moment";
import CryptoJS from "crypto-js"
import packageJSON from "../../../../../../../../package.json"
import { openToast } from '../../../../../../Library/ToastPopUp';

export default function ShowQrCode(props) {
    const data = props.data;

    return (
        <div className='card card-custom card-stretch gutter-b'>
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">How to generate QR</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">Please follow the instruction</span>
                </h3>
            </div>

            <div className="card-body pl-0">
                <ol type='1'>
                    <li>Go to Dashboard menu</li>
                    <li>In Your Time Table Widget, please choose one the subject</li>
                    <li>Please submit the Teaching Topic (if available)</li>
                    <li>Click button "Show Attendance QR Code" (available only if already submit the teaching topic)</li>
                    <li>And it will show a new window for QR Code</li>
                    <li>For more information please see the video by <a href='https://www.youtube.com/watch?v=1---akiLPs0' target='_blank'>click here</a></li>
                </ol>
            </div>
        </div>
    )
}
