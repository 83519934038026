import React from "react";
import { FormatRupiah } from "../../../../../Library/GlobalHelper";
import { openModal } from "../../../../../Library/ModalPopUp";
import { AlertNotif } from "../../../../../Library/AlertNotif";

const CardInvoice = ({
  data,
  submitPCF,
  HandlerSubmitPCF,
  setSubmitPCFOri,
}) => {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">Draft Invoice</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Before Scholarships and Adjustments
          </span>
        </h3>
      </div>
      <div className="card-body pt-3">
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <span className="mr-2">Limit SKS</span>
            <span className="font-weight-bolder text-end">{data.max_sks}</span>
          </div>
        </div>
        {Object.values(data.itemDetail).length > 0 ? (
          <div className="table-responsive">
            {data.itemDetail.map((v, index) => (
              <div key={index} className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="mr-2">{v.itemName} </span>
                  <span className="font-weight-bolder text-end">
                    {v.itemQty} x {FormatRupiah(v.itemPrice)}
                  </span>
                </div>
              </div>
            ))}
            {data.totalAmount ? (
              <div className="border-top mt-3 pt-3 d-flex justify-content-between align-items-center">
                <span className="font-weight-bolder font-size-3">
                  Total Payment
                </span>
                <span className="font-weight-bolder font-size-3">
                  IDR {FormatRupiah(data.totalAmount)}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {submitPCF.message ? (
          <AlertNotif color={"info"} message={submitPCF.message} />
        ) : (
          ""
        )}

        <div className="actions mt-5">
          <button
            className="btn btn-lg btn-primary btn-block font-weight-bold text-uppercase px-7"
            type="button"
            disabled={submitPCF.loading}
            onClick={() => HandlerSubmitPCF()}
          >
            {submitPCF.loading ? "Processing..." : "Submit and Finish"}
          </button>
          <button
            className="btn btn-lg btn-light btn-block font-weight-bold text-uppercase px-7 font-weight-bolder"
            type="button"
            disabled={submitPCF.loading}
            onClick={() =>
              openModal({
                header: "",
                message: <ModalCancel setSubmitPCFOri={setSubmitPCFOri} />,
              })
            }
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const ModalCancel = ({ setSubmitPCFOri }) => {
  return (
    <div className="confirm-close">
      <h3>Are you sure want to cancel the PCF Revision ?</h3>
      <p className="font-weight-bolder">
        You will permanently cancel this payment.
      </p>

      <div className="text-right pt-10">
        <button
          className="btn btn-lg btn-clear px-6 font-weight-bolder"
          type="button"
          onClick={() => openModal({ open: false })}
        >
          Not Now
        </button>
        <button
          className="btn btn-lg btn-danger px-6 font-weight-bolder"
          type="button"
          onClick={() => {
            setSubmitPCFOri({ loading: false, data: [], message: "" });
            openModal({ open: false });
          }}
        >
          Cancel Registration
        </button>
      </div>
    </div>
  );
};

const TablePCFRevisi = ({ data, totalsks, limit }) => {
  data.sort((a, b) => (a.detail.coursetitle > b.detail.coursetitle ? 1 : -1));
  return (
    <div className="table-responsive">
      <div className="d-flex justify-content-between">
        <p className="mb-0 text-center font-weight-bolder">
          Total Credit: {totalsks} SKS
        </p>
        <p className="mb-0 text-center font-weight-bolder">
          Limit Credit: {limit} SKS
        </p>
      </div>
      <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
        <thead>
          <tr className="text-left text-uppercase">
            <th>Courses</th>
            <th>Credit/Type</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(data).length > 0 ? (
            data.map((v, index) => (
              <tr
                key={index}
                className={
                  v.action_type
                    ? v.action_type === "add"
                      ? "bg-light-success"
                      : "bg-light-danger"
                    : ""
                }
              >
                <td>
                  <span className="d-block font-weight-bolder">
                    {v.detail.coursetitle}
                  </span>
                  <span className="d-block text-muted">
                    {v.detail.course_code}
                  </span>
                  <span className="text-muted">{v.section_id}</span>
                </td>
                <td>
                  {v.action_type ? (
                    <span
                      className={
                        "badge text-capitalize badge-" +
                        (v.action_type === "add" ? "success" : "danger")
                      }
                    >
                      {v.action_type}
                    </span>
                  ) : (
                    ""
                  )}
                  <span className="d-block font-weight-bolder">
                    {v.detail.course_credit} SKS
                  </span>
                  <span className="text-muted text-capitalize">
                    {v.detail.course_type}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="4">No record found</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <div className="d-flex justify-content-between">
                <p className="mb-0 text-center font-weight-bolder">
                  Total Credit: {totalsks} SKS
                </p>
                <p className="mb-0 text-center font-weight-bolder">
                  Limit Credit: {limit} SKS
                </p>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const TablePCFOri = ({ data }) => {
  data.sort((a, b) => (a.detail.coursetitle > b.detail.coursetitle ? 1 : -1));
  const totalSKSOri = data.reduce(
    (a, v) =>
      (a =
        a + (v.detail.course_type !== "optional" ? v.detail.course_credit : 0)),
    0
  );
  return (
    <div className="table-responsive">
      <p className="mb-0 text-center font-weight-bolder">
        Total Credit: {totalSKSOri} SKS
      </p>
      <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
        <thead>
          <tr className="text-left text-uppercase">
            <th>Course Title</th>
            <th>Credit/Type</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(data).length > 0 ? (
            data.map((v, index) => (
              <tr key={index}>
                <td>
                  <span className="d-block font-weight-bolder">
                    {v.detail.coursetitle}
                  </span>
                  <span className="d-block text-muted">
                    {v.detail.course_code}
                  </span>
                  <span className="text-muted">{v.detail.section_id}</span>
                </td>
                <td>
                  <span className="d-block font-weight-bolder">
                    {v.detail.course_credit} SKS
                  </span>
                  <span className="text-muted text-capitalize">
                    {v.detail.course_type}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="3">No record found</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={"3"}>
              <p className="mb-0 text-center font-weight-bolder">
                Total Credit: {totalSKSOri} SKS
              </p>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export { CardInvoice, TablePCFRevisi, TablePCFOri };
