import React from 'react'

export default function TableData(props) {
    const data = props.data;
    return (
        <div className="card card-custom gutter-b">
            <div className="card-header align-items-center border-0">
                <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">Request Leave Semester</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">List of leave semester by your request</span>
                </h3>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-head-custom table-head-bg table-vertical-center table-bordered">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Academic Calendar</th>
                                <th>Leave on Semester</th>
                                <th>Reason</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(data).length > 0 ? (
                                data.map((v,index)=>(
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{(v.atp_term) ? v.atp_term.name : "-"}</td>
                                        <td>{v.semester}</td>
                                        <td>{v.note}</td>
                                        <td>{v.status_activity}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No record founded</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
