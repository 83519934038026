import React from 'react'

export function Settings(props) {
    const profile = (props.profile) ? props.profile : {};
    const urlwa = "https://wa.me/message/NNSYT7PGEZM5E1"
    return (
        <div className='settings mb-10'>
            <div className="row mb-7">
                <label className="col-lg-4 font-weight-bold text-muted">Email</label>
                <div className="col-lg-6">
                    <input type="text" disabled className='form-control form-control-lg' value={profile.sgumail} />
                    <a className="btn btn-primary btn-lg mt-5" href={urlwa} target={'_blank'} >Reset your password</a>
                </div>
            </div>

        </div>
    )
}

export default Settings;