import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2"
import LoadingBtn from "../../../Widgets/LoadingBtn"
import Form from "./Form";
import TableData from "./TableData";

export class LeaveSemester extends Component {
    constructor(props){
        super(props);
        this.getStudentStatus = this.getStudentStatus.bind(this);
        this.getActiveAcad = this.getActiveAcad.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode:AuthDecode,
            LoadingPage:false,
            Expired:[],
            CourseList:[],
            StudentStatus:[],
            ActiveAcad:[]
        };
    }

    getStudentStatus(){
        const reqModule = () => {
            this.setState({LoadingPage:true});
            var keys={
                "AppId":this.state.AuthDecode.AppID,
                "AppEnv":this.state.AuthDecode.AppEnv,
                "Secretkey":this.state.AuthDecode.secretkey
            };
            var body={     
                "parameters" :{
                    "studentid":this.state.AuthDecode.currentData.entityid
                },              
                "target_data": "GET_STUDENT_STATUS"
            };
            const postData = {
                keys:keys,
                body:body
            };
            
            AxiosLibWithHeader2(postData,'app/api/req-module')
            .then(
                response => {
                    if("message" in response.response){
                        this.setState({Expired:response.response.message});
                    }else{
                        this.setState({StudentStatus:response.response.data,LoadingPage:false});
                    }   
                }
            );  
        }
        reqModule();
    }

    getActiveAcad(){
        const reqModule = () => {
            this.setState({LoadingPage:true});
            var keys={
                "AppId":this.state.AuthDecode.AppID,
                "AppEnv":this.state.AuthDecode.AppEnv,
                "Secretkey":this.state.AuthDecode.secretkey
            };
            var body={     
                "target_data": "GET_ACTIVE_ACAD"
            };
            const postData = {
                keys:keys,
                body:body
            };
            
            AxiosLibWithHeader2(postData,'app/api/req-module')
            .then(
                response => {
                    if("message" in response.response){
                        this.setState({Expired:response.response.message});
                    }else{
                        this.setState({ActiveAcad:response.response.data,LoadingPage:false});
                    }   
                }
            );  
        }
        reqModule();
    }

    componentDidMount(){
        this.getStudentStatus();
        this.getActiveAcad();   
    }

    render() {
        return (
            <div id="leave-request">
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert"><div className="alert-icon"><span className="svg-icon svg-icon-primary svg-icon-xl"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path><path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fill-rule="nonzero"></path></g></svg></span></div>
                            <div className="alert-text">
                                <span className="font-weight-bold">Submit Leave </span> 
                                <span className="text-mute">is a form that you wants to request for Leave (cuti) on current semester</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4">
                        {(this.state.LoadingPage) ? (
                            <LoadingBtn />
                        ) : ''}
                        <div style={{display:((this.state.LoadingPage) ? "none":"block")}}>
                            <Form acad={this.state.ActiveAcad} studentStatus={this.state.StudentStatus} profile={this.state.AuthDecode} />
                        </div>
                    </div>

                    <div className="col-sm-8">
                        <div className="card card-custom gutter-b">
                            <div className="card-header border-0">
                                <h3 className="card-title">
                                    List of leave request
                                </h3>
                            </div>
                            <div className="card-body pt-0">
                                {(this.state.LoadingPage) ? (
                                    <LoadingBtn />
                                ) : ''}
                                <div style={{display:((this.state.LoadingPage) ? "none":"block")}}>
                                    <TableData />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeaveSemester;