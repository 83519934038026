import React from "react";

const Approved = () => {
  return (
    <span class="label label-xl label-light-success label-inline mr-2 w-100 font-weight-bold">
      Approved
    </span>
  );
};

export default Approved;
