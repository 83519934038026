import React, { Component } from 'react'
import { Redirect } from "react-router-dom"

export class LRDashboard extends Component {
    render() {
        return (
            <Redirect to="/leave-resignation/submit-leave" />
        )
    }
}

export default LRDashboard