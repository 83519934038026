import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css' 
import TableData from "./TableData"
import Form from "./Form";

import AxiosLib from "../../../Library/AxiosLib"
import {openModal} from "../../../Library/ModalPopUp"
import {openToast} from "../../../Library/ToastPopUp"
import LoadingBtn from "../LoadingBtn"

export class ServiceRequest extends Component {
    constructor(props){
        super(props);
        this.getServiceRequestByUser = this.getServiceRequestByUser.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        var param = this.props.match.params.token;
        var decodeCourse = (param) ? jwt_decode(param) : [];
        this.state = {
            AuthDecode:AuthDecode,
            LoadingFetch:false,
            Expired:[],
            ServiceData:[],
        };
    }

    getServiceRequestByUser() {
        let dataParam = {UserID:(this.state.AuthDecode.currentData.empid) ? this.state.AuthDecode.currentData.empid : this.state.AuthDecode.currentData.entityid};
        this.setState({LoadingFetch:true});
        AxiosLib(dataParam,'/mc/api/service-request/fetch')
        .then(
            response => {
                this.setState({LoadingFetch:false,ServiceData:response});
            }
        );
    }

    componentDidMount(){
        this.getServiceRequestByUser();
    }

    render() {
        const submitHandler =  (value) => (e) => {
            e.preventDefault();    
            var formid = e.target.id;
            var target = document.getElementById(formid);
            var myButton = target.getElementsByClassName("btn-submit")[0];
            var classSpinner = ['spinner','spinner-white','spinner-left'];
            

            if(value){
                if(value.Service && value.Title && value.Subject && value.Description){
                    myButton.classList.add(...classSpinner);
                    myButton.textContent = "processing";
                    const submitForm = () => {                    
                        AxiosLib(value,'/mc/api/service-request/save')
                        .then(
                            response => {
                                this.getServiceRequestByUser({});
                                if(response.result){
                                    var targetField = target.querySelectorAll(".required");
                                    targetField.forEach(k => {
                                        k.classList.remove("border-danger");
                                    });
                                    myButton.disabled = true;
                                    myButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                                }else{
                                    var targetField = target.querySelectorAll(".required");
                                    targetField.forEach(k => {
                                        k.classList.add("border-danger");
                                    });
                                    myButton.innerHTML = '<i class="far fa-save"></i> Submit';
                                }
    
                                openToast({
                                    message:response.message,
                                    header:"Info"
                                });
                                
                            }
                        );  
                    }
                    submitForm();
                    
                }else{
                    var targetField = target.querySelectorAll(".required");
                    targetField.forEach(k => {
                        k.classList.add("border-danger");
                    });
                    alert("Please fill up the form");    
                }
                
            }else{
                alert("Please fill up the form");
            }
        }

        const openForm = param =>{
            openModal({
                message: <Form data={param} submitHandler={submitHandler} />,
                header: "Form Service Request",
                size:"lg"
            })
        }

        const deleteData = param =>{
            let token = jwt_decode(param);
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const deleteSelectedData = () => {
                            AxiosLib({table:"portal_list",condition:{ID:token.ID}},'/mc/api/action/delete')
                            .then(
                                response => {
                                    //console.log(response);
                                    this.fetchData({}); 
                                    openToast({
                                        message:response.message,
                                        header:"Info"
                                    });                                   
                                }
                            );  
                        }
                        deleteSelectedData();
                        
                    }
                },
                {
                    label: 'No',
                }]
            });
        }


        const detailInvoice = param =>{
            param = jwt_decode(param);
            openModal({
                message: <Form data={param} submitHandler={submitHandler} />,
                header: "Form Service Request",
                size:"lg"
            })
        }


        return (
            <div id="service-request">
                <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                    <div className="alert-icon">
                        <span className="svg-icon svg-icon-primary svg-icon-xl">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                    <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fillRule="nonzero"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div className="alert-text">Service request</div>
                </div>

                <div className="card card-custom gutter-b">
                    <div className="card-body">
                        {(this.state.LoadingFetch ) ? <LoadingBtn /> :""}
                        <div style={{display:((this.state.LoadingFetch) ? "none":"block")}}>
                            <div className="gutter-b">
                                <button className="btn btn-info float-right" type="button" onClick={(e)=>openForm({})}><i className="fas fa-plus-square"></i> Add new record</button>
                                <p className="text-info">Created by: {(this.state.AuthDecode.currentData.empid) ? this.state.AuthDecode.currentData.empid : this.state.AuthDecode.currentData.entityid}/{this.state.AuthDecode.currentData.given_name}<br />Total data: {this.state.ServiceData.recordsTotal}</p>                                
                            </div>
                            <TableData data={(this.state.ServiceData.data) ? this.state.ServiceData.data : [] } detailInvoice={detailInvoice} />
                        </div>                        
                    </div>
                </div>

            </div>
        )
    }
}

export default ServiceRequest