import React from 'react'
import jwt_decode from "jwt-decode"
import { Link } from 'react-router-dom';

export function UserPanel() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    return (
        <div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 className="font-weight-bold m-0">User Profile</h3>
                <span className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i className="ki ki-close icon-xs text-muted"></i>
                </span>
            </div>

            <div className="offcanvas-content pr-5 mr-n5">
                <div className="d-flex align-items-center mt-5">
                    <div className="d-flex flex-column">
                        <span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">{AuthDecode.currentData.given_name} {AuthDecode.currentData.family_name}</span>
                        <div className="text-muted mt-1">{AuthDecode.currentData.role} </div>
                        <div className="navi mt-2"> 
                            <Link to="/profile" className="btn btn-sm btn-light-success font-weight-bolder py-2 px-5 mr-3">My Profile</Link>
                            <Link to="/sign-out" className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">Sign Out</Link>
                        </div>
                    </div>
                </div>

                <div className="separator separator-dashed mt-8 mb-5"></div>
                <div className="navi navi-spacer-x-0 p-0 d-none">
                    <Link to="" className="navi-item">
                        <div className="navi-link">
                            <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                                    <span className="svg-icon svg-icon-md svg-icon-success">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z" fill="#000000" />
                                                <circle fill="#000000" opacity="0.3" cx="18.5" cy="5.5" r="2.5" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">My Profile</div>
                                <div className="text-muted">Account settings and more
                                <span className="label label-light-danger label-inline font-weight-bold">update</span></div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default UserPanel;