import React from 'react'

export default function DetailCardHeader({ headers }) {

    //console.log(headers);
    const LectuerInfo = ({ data, index }) => {
        let remainingQuota = 0;
        if (data.emp_approved && data.emp_submitted) {
            remainingQuota = data.emp_session - Math.max(data.emp_approved, data.emp_submitted);
        }
        return (
            <span key={index}>
                <span className="text-primary">{data.emp ? (data.emp.fullname ? data.emp.fullname : "-") : "-"}({data.emp_submitted}/{data.emp_session})</span>
                {(index + 1) < (Object.values(headers.lecturer).length) ? <span className='mr-2'>,</span> : ""}
            </span>
        )
    }

    return (
        <div className="section-info pt-3">
            <div className="d-flex align-items-center flex-wrap">
                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                    <span className="mr-4">
                        <i className="flaticon-placeholder-2 icon-2x text-muted font-weight-bold"></i>
                    </span>
                    <div className="d-flex flex-column text-dark-75">
                        <span className="font-weight-bolder font-size-sm text-uppercase">Section ID</span>
                        <span className="font-weight-bolder text-primary font-size-h5">{headers.section_id}</span>
                    </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                    <span className="mr-4">
                        <i className="flaticon-presentation icon-2x text-muted font-weight-bold"></i>
                    </span>
                    <div className="d-flex flex-column text-dark-75">
                        <span className="font-weight-bolder font-size-sm text-uppercase">Student</span>
                        <span className="font-weight-bolder text-primary font-size-h5">
                            {headers.studentdata ? headers.studentdata.fullname : ""} /
                            {headers.studentid ? headers.studentid : ""}
                        </span>
                    </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                    <span className="mr-4">
                        <i className="flaticon-presentation-1 icon-2x text-muted font-weight-bold"></i>
                    </span>
                    <div className="d-flex flex-column text-dark-75">
                        <span className="font-weight-bolder font-size-sm text-uppercase">Advisor</span>
                        <span className="font-weight-bolder text-primary font-size-h5 text-center">
                            {headers.advisor ? headers.advisor.fullname : ""}
                        </span>
                    </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                    <span className="mr-4">
                        <i className="flaticon-presentation-1 icon-2x text-muted font-weight-bold"></i>
                    </span>
                    <div className="d-flex flex-column text-dark-75">
                        <span className="font-weight-bolder font-size-sm text-uppercase">Co Advisor</span>
                        <span className="font-weight-bolder text-primary font-size-h5 text-center">
                            {headers.coadvisor ? headers.coadvisor.fullname : ""}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
