import React from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../../../Library/AxiosLibWithHeader2";
import { openModal } from "../../../../../../Library/ModalPopUp";
import { MessageFailedScore, MessageSuccessScore } from "./ScoringComponents";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

const GET_SECTION = (param, setSection) => {
  setSection({ loading: true, data: [], message: "" });
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };
  var body = {
    parameters: param,
    target_data: "GET_SECTION",
  };
  const postData = {
    keys: keys,
    body: body,
  };
  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    if (response.error) {
      setSection({ loading: false, data: [], message: response.error });
    } else if ("message" in response.response) {
      var msg = response.response.message.user;
      setSection({ loading: false, data: [], message: msg });
    } else {
      var results = response.response.data;
      if (Object.keys(results).length > 0) {
        const result = results[0];
        setSection({ loading: false, data: result, message: "" });
      } else {
        setSection({ loading: false, data: [], message: "No record found" });
      }
    }
  });
};

const GET_SCR_LECTURER_SECTION = (
  param,
  setDataScore,
  setScrStudent,
  setScrCom
) => {
  setDataScore({ loading: true, data: [], message: "" });
  setScrStudent([]);
  setScrCom([]);
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };
  var body = {
    parameters: param,
    target_data: "GET_SCR_LECTURER_SECTION",
  };
  const postData = {
    keys: keys,
    body: body,
  };
  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    if (response.error) {
      setDataScore({ loading: false, data: [], message: response.error });
      setScrStudent([]);
      setScrCom([]);
    } else if ("message" in response.response) {
      var msg = response.response.message.user;
      setDataScore({ loading: false, data: [], message: msg });
      setScrStudent([]);
      setScrCom([]);
    } else {
      var results = response.response.data;
      if (results) {
        //results.score_type = 0;
        setDataScore({ loading: false, data: results, message: "" });
        const ascStudent = results.student.sort((a, b) =>
          a.studentdata.fullname > b.studentdata.fullname ? 1 : -1
        );
        setScrStudent(ascStudent);
        // const ascScrCom = results.section_com.sort((a, b) =>
        //   a.wg_com_id > b.wg_com_id ? 1 : -1
        // );
        // const ascScrComWeight = ascScrCom.sort((a, b) =>
        //   a.weight > b.weight ? 1 : -1
        // );
        setScrCom(results.section_com);
      } else {
        setDataScore({
          loading: false,
          data: [],
          message: "No record found",
        });
        setScrStudent([]);
        setScrCom([]);
      }
    }
  });
};

const SUBMIT_SCR_STD_BY_LEC_COM = (param, setSubmit, ReloadData) => {
  setSubmit({ loading: true, data: [], message: "" });
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };
  var body = {
    parameters: param,
    target_data: "SUBMIT_SCR_STD_BY_LEC_COM",
  };
  const postData = {
    keys: keys,
    body: body,
  };
  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    if (response.error) {
      setSubmit({ loading: false, data: [], message: response.error });
    } else if ("message" in response.response) {
      var msg = response.response.message.user;
      setSubmit({ loading: false, data: [], message: msg });
      openModal({header:"", message:<MessageFailedScore message={msg} />})
    } else {
      var results = response.response.data;
      if (Object.keys(results).length > 0) {
        const result = results[0];
        setSubmit({ loading: false, data: result, message: "" });
        ReloadData();
        openModal({header:"", message:<MessageSuccessScore message={"Data has been successfully saved"} />})
      } else {
        setSubmit({ loading: false, data: [], message: "Failed save, try again later." });
      }
    }
  });
};

const GET_SCR_COM = (param, setScrComp) => {
  setScrComp({ loading: true, data: [], message: "" });
  var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
  };
  var body = {
      parameters: param,
      target_data: "GET_SCR_COM",
  };
  const postData = {
      keys: keys,
      body: body,
  };

  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
          setScrComp({
              data: [],
              message: response.error,
              loading: false,
          });
      } else if ("message" in response.response) {
          var msg = response.response.message.user;
          setScrComp({ message: msg, loading: false, data: [] });
      } else {
          var results = response.response.data;
          if (results) {
              setScrComp({ data: results, loading: false, message: "" });
          } else {
              setScrComp({
                  message: "No record found",
                  loading: false,
                  data: [],
              });
          }
      }
  });
}


const CREATE_APPEND_SECTION_COM = (param, setSubmit, HandlerProgressBar,ReloadData, closeModal=false) => {
  setSubmit({ loading: true, data: [], message: "" });
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };
  var body = {
    parameters: param,
    target_data: "CREATE_APPEND_SECTION_COM",
  };
  const postData = {
    keys: keys,
    body: body,
  };
//console.log(postData);
  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    console.log(response);
    if (response.error) {
      setSubmit({
        data: [],
        message: response.error,
        loading: false,
      });
    } else if ("message" in response.response) {
      var msg = response.response.message.user;
      setSubmit({ message: msg, loading: false, data: [] });
    } else {
      var results = response.response.result;
      if (results === "success") {
        setSubmit({
          data: results,
          loading: false,
          message: "",
        });
        if(closeModal){openModal({open:false})}
        openModal({header:"", message:<MessageSuccessScore message={"Data has been successfully saved"} />})
        ReloadData();
      } else {
        setSubmit({
          message: "Failed saved, try again later",
          loading: false,
          data: [],
        });
      }
    }
  });
};

const DELETE_SECTION = (param, setDelCom, ReloadData) => {
  setDelCom({ loading: true, data: [], message: "" });
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };
  var body = {
    parameters: param,
    target_data: "DELETE_SECTION",
  };
  const postData = {
    keys: keys,
    body: body,
  };

  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    if (response.error) {
      setDelCom({
        data: [],
        message: response.error,
        loading: false,
      });
    } else if ("message" in response.response) {
      var msg = response.response.message.user;
      setDelCom({ message: msg, loading: false, data: [] });
    } else {
      var results = response.response.result;
      if (results === "success") {
        setDelCom({
          data: results,
          loading: false,
          message: "",
        });
        ReloadData();
        openModal({open:false})
      } else {
        setDelCom({
          message: "Failed remove, try again later",
          loading: false,
          data: [],
        });
      }
    }
  });
};

const SCR_SET_INCOMPLETE_BY_LEC = (param, setCom, ReloadData) => {
  setCom({ loading: true, data: [], message: "" });
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };
  var body = {
    parameters: param,
    target_data: "SCR_SET_INCOMPLETE_BY_LEC",
  };
  const postData = {
    keys: keys,
    body: body,
  };

  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    if (response.error) {
      setCom({
        data: [],
        message: response.error,
        loading: false,
      });
    } else if ("message" in response.response) {
      var msg = response.response.message.user;
      setCom({ message: msg, loading: false, data: [] });
      openModal({header:"Error", message:<MessageFailedScore message={msg} />})
    } else {
      var results = response.response.result;
      if (results === "success") {
        setCom({
          data: results,
          loading: false,
          message: "",
        });
        ReloadData();
        openModal({open:false})
      } else {
        setCom({
          message: "Failed updated",
          loading: false,
          data: [],
        });
      }
    }
  });
};



export { GET_SECTION, GET_SCR_LECTURER_SECTION, SUBMIT_SCR_STD_BY_LEC_COM, GET_SCR_COM, CREATE_APPEND_SECTION_COM, DELETE_SECTION, SCR_SET_INCOMPLETE_BY_LEC };
