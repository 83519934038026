import React, { useMemo, useState } from 'react'
import jwt_decode from "jwt-decode"
import PreviewScoreStd from './PreviewScoreStd';
import { Link } from 'react-router-dom';
import { openModal } from '../../../../Library/ModalPopUp';
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import { openToast } from '../../../../Library/ToastPopUp';
import DownloadFinalScore from './DownloadFinalScore';
import CardCurrentSectionHorizontal from '../../Attendance/AtdClassList/widgets/CardCurrentSectionHorizontal';
import { StudentLink } from '../../../../Const/StudentCard';

export default function FormScoreStd(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    const [listData, setListData] = useState((props.data) ? props.data : null);
    const [postStd, setPostSTD] = useState([]);
    const [TotalQuiz, setTotalQuiz] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ResultData = useMemo(() => {
        let computedData;
        if (listData) {
            computedData = listData.map((c) => {
                var obj = {};
                obj.atd = (c.atd) ? c.atd : 0;
                obj.atp_id_term = (c.atp_term) ? c.atp_term.id : 0;
                obj.course_id = (c.course) ? c.course.id : 0;
                obj.quiz_1 = (c.quiz_1) ? c.quiz_1 : 0;
                obj.quiz_2 = (c.quiz_2) ? c.quiz_2 : 0;
                obj.quiz_3 = (c.quiz_3) ? c.quiz_3 : 0;
                obj.midterm = (c.midterm) ? c.midterm : 0;
                obj.quiz_4 = (c.quiz_4) ? c.quiz_4 : 0;
                obj.quiz_5 = (c.quiz_5) ? c.quiz_5 : 0;
                obj.quiz_6 = (c.quiz_6) ? c.quiz_6 : 0;
                obj.finalexam = (c.finalexam) ? c.finalexam : 0;
                obj.score = (c.score) ? c.score : 0;
                obj.grade_fin = (c.grade_fin) ? c.grade_fin : "";
                obj.grade_point_fin = (c.grade_point_fin) ? c.grade_point_fin : "";
                obj.remark = (c.remark) ? c.remark : "";
                obj.studentid = c.studentid;
                obj.studentname = c.studentdata.fullname;
                return obj;
            })
        }

        //computedData.sort((a, b) => (a.section_id > b.section_id) ? 1 : -1);

        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toString().toLowerCase().includes(search)
                        //console.log(key,listData[key])
                    );
                }
            );
        }
        setTotalItems(computedData.length);
        setPostSTD(computedData);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedData;
    }, [listData, search, sorting]);
    /*==END DATATABELS CONFIG==*/

    const [prevScore, setPrevScore] = useState({
        loading: false,
        data: [],
        message: ""
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        if (TotalQuiz > 0) {
            setPrevScore({ ...prevScore, loading: true, message: "", data: [] });
            var std_post = postStd.map((v) => {
                var obj = {};
                //obj.atd = v.atd;
                obj.atp_id_term = v.atp_id_term;
                obj.course_id = v.course_id;
                obj.finalexam = v.finalexam;
                obj.grade_fin = v.grade_fin;
                obj.midterm = v.midterm;
                obj.quiz_1 = v.quiz_1;
                obj.quiz_2 = v.quiz_2;
                obj.quiz_3 = v.quiz_3;
                obj.quiz_4 = v.quiz_4;
                obj.quiz_5 = v.quiz_5;
                obj.quiz_6 = v.quiz_6;
                obj.remark = v.remark;
                obj.score = v.score;
                obj.studentid = v.studentid;
                return obj;
            });

            const paramPostSct = {
                item_type: "fin",
                preview: true,
                section_id: props.section_id,
                total_quiz: TotalQuiz,
                student: std_post
            }

            const paramPostSct2 = {
                item_type: "fin",
                preview: true,
                section_id: props.section_id,
                total_quiz: TotalQuiz,
                student: postStd
            }

            var keys = {
                "AppId": AuthDecode.AppID,
                "AppEnv": AuthDecode.AppEnv,
                "Secretkey": AuthDecode.secretkey
            };
            var body = {
                "parameters": paramPostSct,
                "target_data": "SUBMIT_SCR_STD_BY_LEC"
            };
            const postData = {
                keys: keys,
                body: body
            };
            AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
                if (response.error) {
                    setPrevScore({
                        ...prevScore,
                        message: response.error,
                        loading: false,
                    });
                    openToast({ header: "Error", message: response.error });
                } else if ("message" in response.response) {
                    var msg = response.response.message.internal;
                    setPrevScore({ ...prevScore, message: msg, loading: false });
                    openToast({ header: "Error", message: msg + ". Please check again your form with correctly, minimal score is 0 (not empty)" });
                } else {
                    var results = response.response.data;
                    if (results) {
                        window.scrollTo(0, 0);
                        setPrevScore({
                            ...prevScore,
                            message: "",
                            loading: false,
                            data: results,
                            param: paramPostSct2
                        });
                    } else {
                        setPrevScore({
                            ...prevScore,
                            message: "No record found",
                            loading: false,
                            data: [],
                        });
                        openToast({ header: "Error", message: "Failed execution, try again" });
                    }
                }
            });
        } else {
            openModal({ header: "Information", message: "Please fill up the TOTAL of QUIZ" });
        }
    }

    const handleChangeScore = (e, index, key) => {
        let score = 0;
        const val = e.target.value;
        //if(val){
        if (e.target.validity.valid) {
            score = e.target.value;
        } else if (val === '' || val === '-') {
            score = 0
        }
        //console.log(score);
        setPostSTD((prevState) => {
            const newData = [...prevState];
            newData[index][key] = parseInt(score);
            return newData;
        })
        //}
    }
    const handleChangeRemark = (e, index, key) => {
        const value = e.target.value;
        setPostSTD((prevState) => {
            const newData = [...prevState];
            newData[index][key] = value;
            return newData;
        })
    }
    const removeQuiz = () => {
        if (window.confirm("Are you sure ?")) {
            const cScore = 0;
            setListData((prevState) => {
                const newData = [...prevState];
                let results = newData.map((v) => {
                    return { ...v, quiz_1: cScore, quiz_2: cScore, quiz_3: cScore, quiz_4: cScore, quiz_5: cScore, quiz_6: cScore };
                })
                return results;
            })
        }
    }

    const [download, setDownload] = useState(false);

    const fetchGrade = (studentid) => {
        let data = ResultData.filter(el => el.studentid === studentid);
        if (Object.values(data).length > 0) {
            return data[0].grade_fin;
        }
        return "-";
    }
    const fetchGradePoint = (studentid) => {
        let data = ResultData.filter(el => el.studentid === studentid);
        if (Object.values(data).length > 0) {
            return data[0].grade_point_fin;
        }
        return "0";
    }
    const fetchScore = (studentid) => {
        let data = ResultData.filter(el => el.studentid === studentid);
        if (Object.values(data).length > 0) {
            return data[0].score;
        }
        return "0";
    }

    return (
        <div className="card card-custom gutter-b">
            <div className="card-header bg-light">
                <CardCurrentSectionHorizontal sections={props.CurrentClass.data} />
            </div>
            <div className="card-body px-0">
                <div className="d-flex justify-content-between mb-5 px-5">
                    <h3 className="text-dark">Student List</h3>
                    <div className="toolbar">
                        <div className="btn-group">
                            <span className="btn btn-light-primary btn-sm label-inline font-size-sm">
                                <i className="far fa-user-circle mr-2"></i>
                                Total Student  {Object.keys(props.data).length}
                            </span>
                            {(Object.values(ResultData).length > 0) ? (
                                <button className="btn btn-primary btn-sm" type='button' onClick={() => DownloadFinalScore({ header: props.CurrentClass.data, data: ResultData }, props.section_id)}>Export Score <i className="far fa-file-pdf"></i></button>
                            ) : ""}
                        </div>
                    </div>
                </div>
                {(Object.values(prevScore.data).length > 0) ? <PreviewScoreStd profile={props.profile} prevscore={prevScore} setPrevScore={setPrevScore} /> : (
                    <form onSubmit={(e) => handleSubmit(e)} id="form-scoring" method="post" autoComplete="off">
                        <table className="table table-head-custom table-borderless table-vertical-center">
                            <thead>
                                <tr className='bg-light-primary'>
                                    <th width="5%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">No</span></th>
                                    <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Student</span></th>
                                    <th width="8%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Quiz 1</span></th>
                                    <th width="8%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Quiz 2</span></th>
                                    <th width="8%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Quiz 3</span></th>
                                    <th width="8%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Mid Term</span></th>
                                    <th width="8%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Quiz 4</span></th>
                                    <th width="8%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Quiz 5</span></th>
                                    <th width="8%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Quiz 6</span></th>
                                    <th width="8%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Final Exam</span></th>
                                    <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Score</span></th>
                                    <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Grade</span></th>
                                    <th><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Grade Point</span></th>
                                    <th width="8%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Absent</span></th>
                                    <th width="12%"><span className="text-dark-50 m-0 pt-5 font-weight-bold text-center">Remark</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(Object.values(ResultData).length > 0) ? (
                                    ResultData.map((v, index) => (
                                        <tr key={index} className={((v.atd > 25) ? "bg-light-danger" : "")}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <StudentLink data={v} />
                                            </td>
                                            <td>
                                                <input disabled type="text" name={v.studentid} className="form-control" value={v.quiz_1} />
                                            </td>
                                            <td>
                                                <input disabled type="text" name={v.studentid} className="form-control" value={v.quiz_2} />
                                            </td>
                                            <td>
                                                <input disabled type="text" name={v.studentid} className="form-control" value={v.quiz_3} />
                                            </td>
                                            <td>
                                                <input disabled type="text" name={v.studentid} className="form-control" defaultValue={v.midterm} />
                                            </td>
                                            <td>
                                                <input readOnly={((v.atd > 25) ? true : false)} type="text" min={0} pattern="[0-9]*" name={v.studentid} className="form-control" value={v.quiz_4} onChange={(e) => handleChangeScore(e, index, 'quiz_4')} />
                                            </td>
                                            <td>
                                                <input readOnly={((v.atd > 25) ? true : false)} type="text" min={0} pattern="[0-9]*" name={v.studentid} className="form-control" value={v.quiz_5} onChange={(e) => handleChangeScore(e, index, 'quiz_5')} />
                                            </td>
                                            <td>
                                                <input readOnly={((v.atd > 25) ? true : false)} type="text" min={0} pattern="[0-9]*" name={v.studentid} className="form-control" value={v.quiz_6} onChange={(e) => handleChangeScore(e, index, 'quiz_6')} />
                                            </td>
                                            <td>
                                                <input readOnly={((v.atd > 25) ? true : false)} type="text" min={0} pattern="[0-9]*" name={v.studentid} className="form-control" value={v.finalexam} onChange={(e) => handleChangeScore(e, index, 'finalexam')} />
                                            </td>
                                            <td>
                                                {fetchScore(v.studentid)}
                                            </td>
                                            <td>
                                                {fetchGrade(v.studentid)}
                                            </td>
                                            <td>
                                                {fetchGradePoint(v.studentid)}
                                            </td>
                                            <td>
                                                {v.atd}%
                                            </td>
                                            <td>
                                                <input readOnly={(v.atd > 25) ? true : false} type="text" name={v.studentid} className="form-control" defaultValue={v.remark} onChange={(e) => handleChangeRemark(e, index, 'remark')} />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="16">No record found</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                        <span className="bg-light-danger px-5 py-2"></span>
                                    </td>
                                    <td colSpan={4}>
                                        <small className="d-block">Note:</small><span className="text-danger">Total of Attendance more than 25%</span>
                                    </td>
                                    <td colSpan={6} className='text-center'></td>
                                    <td className='text-right' colspan="2">
                                        <label className="font-weight-bolder">Total Quiz:</label>
                                    </td>
                                    <td colspan="2">
                                        <input type="text" min={1} max={6} name="t_quiz" className='form-control border-info' defaultValue={TotalQuiz} onChange={(e) => setTotalQuiz(parseInt(e.target.value))} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={9} className='text-center'>
                                        <button className="btn btn-light-danger btn-sm" type='button' onClick={() => removeQuiz()}>
                                            <i className="fa fa-trash"></i> Remove All Quiz
                                        </button>
                                    </td>
                                    <td colSpan={6}>
                                        <div className="alert alert-info mb-0" role="alert">
                                            <i className="fas fa-info-circle text-white"></i> Total number of quizzes completed this midterm.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={15} className='text-right'>
                                        <div className="btn-group">
                                            <Link className="btn btn-secondary font-weight-bolder text-uppercase px-9 py-4" type='button' to={"/score-grade/final-score"}>Cancel</Link>
                                            <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit" type='submit' disabled={(prevScore.loading) ? true : false}>{(prevScore.loading) ? "Processing..." : "Next and Preview"}</button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                )}

            </div>
        </div>
    )
}
