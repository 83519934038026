import React, { useRef } from 'react'
import Moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { openModal } from '../../../../Library/ModalPopUp';
import DetailSchedule from './DetailSchedule';

export default function CalendarMonthStd(props) {
    const calendarRef = useRef();
    const now = Moment().format("YYYY-MM-DD");

    const eventRender = (data) => {
        let item = data.event;
        let is_today = now === Moment(item.start).format("YYYY-MM-DD") ? true : false;
        return (
            <div className={"fc-event-container cursor-pointer "+(is_today ? "bg-success":"")} onClick={() => detailEvent(item)} title={item.title}>
                <div className="fc-day-grid-event fc-start fc-end fc-event-solid-danger fc-event-light fc-draggable">
                    <div className={"fc-content "+(is_today ? "text-white":"text-primary")}>
                        <span className="fc-title">{item.title}</span>
                    </div>
                </div>
            </div>
        );
    };

    const detailEvent = (data) => {
        openModal({
            message: <DetailSchedule data={data} GET_SCHD_CLASS_LEC_NEXT={props.GET_SCHD_CLASS_LEC_NEXT} paramDate={props.paramDate} actionDetail={props.actionDetail} />
        });
    };

    return (
        <div>
            <FullCalendar
                ref={calendarRef}
                defaultView="dayGridMonth"
                headerToolbar={{ start: '', center: '', end: '' }}
                plugins={[dayGridPlugin]}
                initialDate={props.paramDate.tgl}
                validRange={{ start: props.paramDate.tgl, end:props.paramDate.tgl_end }}
                events={Object.values(props.data).length > 0 ? props.data : []}
                eventContent={(info) => eventRender(info)}
                eventDidMount={(info) => {
                    if (info.event.extendedProps.background) {
                      info.el.style.background = info.event.extendedProps.background;
                    }
                    if (info.event.extendedProps.border) {
                        info.el.style.borderColor = info.event.extendedProps.border;
                    }
                }}
            />
        </div>
    )
}
