import React from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { openModal } from "../../../../../Library/ModalPopUp";
import FinalPCF from "./FinalPCF";
import SVGAB4 from "../../../../../../media/shapes/abstract-4.svg";
import SVGAB2 from "../../../../../../media/shapes/abstract-2.svg";

const CardFirstPCF = (props) => {
  return (
    <div
      id="card-first-pcf"
      className={
        "card card-custom bgi-no-repeat gutter-b p-6 mb-8 border " +
        (props.pcf.message
          ? " border-warning bg-light-warning"
          : " border-danger bg-light-danger")
      }
      style={{
        backgroundPosition: "right top",
        backgroundSize: "30% auto",
        backgroundImage: "url(" + SVGAB4 + ")",
      }}
    >
      <div className="card-body p-0">
        <div className="row">
          <div className="col-sm-7">
            <h2 className="text-danger font-weight-bolder mb-4">
              PCF Registration
            </h2>
            <p className="text-dark-50 line-height-lg">
              Periode{" "}
              {props.acad
                ? props.acad.reff_atp_term
                  ? props.acad.reff_atp_term.name
                  : "-"
                : "-"}{" "}
              <br />
              {props.pcf.message ? props.pcf.message : "Haven't filled PCF yet"}
            </p>
          </div>
          {props.pcf.message ? (
            <div className="col-sm-5 d-flex align-items-center justify-content-sm-end">
              <span
                className={
                  "btn font-weight-bolder text-uppercase font-size-lg py-3 px-6 btn-warning"
                }
              >
                <i className="far fa-check-circle"></i>
              </span>
            </div>
          ) : (
            <div
              className="col-sm-5 d-flex align-items-center justify-content-sm-end"
              onClick={() => props.OpenFirstPCF()}
            >
              <span
                className={
                  "btn font-weight-bolder text-uppercase font-size-lg py-3 px-6 btn-danger"
                }
              >
                GO
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CardRevPCF = (props) => {
  return (
    <div id="card-first-pcf" className="card card-custom p-6 mb-8">
      <div className="card-body p-0">
        <div className="row">
          <div className="col-sm-7">
            <h2 className="text-dark mb-4">PCF Revision</h2>
            <p className="text-dark-50 line-height-lg">
              Periode{" "}
              {props.acad
                ? props.acad.reff_atp_term
                  ? props.acad.reff_atp_term.name
                  : "-"
                : "-"}{" "}
            </p>
          </div>
          <div className="col-sm-5 d-flex align-items-center justify-content-sm-end">
            <Link
              to={{ pathname: "/registration/revision-plan-course-form" }}
              type="button"
              className="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-3 px-6"
            >
              GO
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const CardFinalPCF = (props) => {
  const handlerDownload = () => {
    openModal({
      header: "Final Plan Course Form",
      message: <FinalPCF acad={props.acad} students={props.student} />,
      size: "lg",
    });
  };

  return (
    <div
      id="card-final-pcf"
      className="card card-custom bgi-no-repeat gutter-b p-6 mb-8 border border-info bg-light-info"
      style={{
        backgroundPosition: "right top",
        backgroundSize: "30% auto",
        backgroundImage: "url(" + SVGAB2 + ")",
      }}
    >
      <div className="card-body p-0">
        <div className="row">
          <div className="col-sm-7">
            <h2 className="text-info font-weight-bolder mb-4">
              Download Final PCF
            </h2>
            <p className="text-dark-50 line-height-lg">
              Periode{" "}
              {props.acad
                ? props.acad.reff_atp_term
                  ? props.acad.reff_atp_term.name
                  : "-"
                : "-"}{" "}
            </p>
          </div>
          <div className="col-sm-5 d-flex align-items-center justify-content-sm-end">
            <button
              type="button"
              className="btn font-weight-bolder text-uppercase font-size-lg btn-info py-3 px-6"
              onClick={() => handlerDownload()}
            >
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CardPCFTerm = ({ OpenTermCondition, program_id }) => {
  return (
    <div id="card-first-pcf" className="card card-custom p-6 mb-8">
      <div className="card-header align-items-center border-0 p-0">
        <h2 className="card-titles">
          <span className="d-block font-weight-bold text-dark">
            Important Notes
          </span>
          <span className="text-dark-50 mt-3 font-size-sm">
            Read properly and correctly
          </span>
        </h2>
        <div className="card-toolbar">
          <button
            className="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-3 px-6"
            type="button"
            onClick={() => OpenTermCondition(program_id)}
          >
            View
          </button>
        </div>
      </div>
    </div>
  );
};

const PCFTerm = ({ program_id }) => {
  let infoArr = [];
  if (program_id === 1) {
    infoArr = [
      {
        id: 1,
        message:
          "Students must enroll to all courses specified in the applicable curriculum and consult to their academic counselor.",
      },
      {
        id: 2,
        message:
          "The number of elective courses selected should match the applicable curriculum.",
      },
      {
        id: 3,
        message:
          "German language courses are only required for the double degree program.",
      },
      {
        id: 4,
        message:
          "The maximum number of credits allowed per semester is 24 (German language courses will be excluded from the total credit calculation).",
      },
      {
        id: 5,
        message:
          "The minimum number of credits that can be taken per semester is 6.",
      },
      {
        id: 6,
        message: parse(
          'Deadline of PCF submission is <span className="text-danger font-weight-bolder">January 15th, 2024, until closed on January 19th 2024</span>, as stated in the <a href="https://sgu.ac.id/academic-services/academic-calendar/#1670206526109-b0a1fc4b-d592" target="_blank">Academic Calendar </a> issued by Swiss German University.'
        ),
      },
      {
        id: 7,
        message: parse(
          'Deadline of tuition fee payment is <span className="text-danger font-weight-bolder">Friday, 09 February 2024</span>. Detailed information regarding tuition fee payment can be accessed <a href="https://drive.google.com/file/d/12qMGyrW_w5_XQU-xFu_LoGRSQuPOT-Lc/view?usp=drive_link" target="_blank">here</a>.'
        ),
      },
      {
        id: 8,
        message: parse(
          'Detailed regulations for Re-Registration and Plan Course Submission (PCF) submission regulations can be accessed <a href="https://drive.google.com/file/d/1qOX7JrLUl9nfr6CKC6Tmj9BeY0LM8I_x/view?usp=drive_link" target="_blank">here</a>.'
        ),
      },
    ];
  } else if (program_id === 2) {
    infoArr = [
      {
        id: 1,
        message:
          "Students must enroll to all courses specified in the applicable curriculum.",
      },
      {
        id: 2,
        message: parse(
          'Deadline of PCF submission is <span className="text-danger font-weight-bolder">January 27th, 2024 until closed on February 05th 2024</span>, as stated in the <a href="https://sgu.ac.id/academic-services/academic-calendar/#1578906612753-94234018-5826" target="_blank">Academic Calendar </a> issued by Swiss German University.'
        ),
      },
      {
        id: 3,
        message: parse(
          'Deadline of tuition fee payment is <span className="text-danger font-weight-bolder">Monday, 19 February 2024</span>. Detailed information regarding tuition fee payment can be accessed <a href="https://drive.google.com/file/d/1bmc4ATrLG7omOQmZQ2jEgzU58L6T3HK7/view?usp=drive_link" target="_blank">here</a>.'
        ),
      },
      {
        id: 4,
        message: parse(
          'Detailed regulations for Re-Registration and Plan Course Submission (PCF) submission regulations can be accessed <a href="https://drive.google.com/file/d/1bmc4ATrLG7omOQmZQ2jEgzU58L6T3HK7/view?usp=drive_link" target="_blank">here</a>.'
        ),
      },
    ];
  }
  return (
    <div id="card-first-pcf">
      <div className="terms">
        <ol typeof="1">
          {infoArr
            ? infoArr.map((v, index) => <li key={index}>{v.message}</li>)
            : ""}
        </ol>
      </div>
    </div>
  );
};

const CardCurrAcad = ({ data }) => {
  const total_semes = Object.values(data.semester_status).length;
  const current_semester_status = data.semester_status[total_semes - 1];
  console.log(current_semester_status);
  return (
    total_semes > 0 ? (
      <div className="row mb-3">
        <div className="col-lg-6">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body text-center p-5">
              <span className="h3">
                {current_semester_status.semester
                  ? current_semester_status.semester
                  : "0"}
              </span>
              <span className="text-uppercase d-block text-gray-700">
                Current Semester
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body text-center p-5">
              <span className="h3">
                {current_semester_status.credit_all
                  ? current_semester_status.credit_all
                  : "0"}{" "}
                SKS
              </span>
              <span className="text-uppercase d-block text-gray-700">
                Current Semester Credit
              </span>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-custom card-stretch">
            <div className="card-body text-center p-5">
              <span className="h3">
                {current_semester_status.cgpa
                  ? current_semester_status.cgpa
                  : "0"}
              </span>
              <span className="text-uppercase d-block text-gray-700">
                Cumulative GPA
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-custom card-stretch">
            <div className="card-body text-center p-5">
              <span className="h3">
                {current_semester_status.ccredit_pass
                  ? current_semester_status.ccredit_pass
                  : "0"}{" "}
                SKS
              </span>
              <span className="text-uppercase d-block text-gray-700">
                Cumulative Earned Credit
              </span>
            </div>
          </div>
        </div>
      </div>
    ): ""
  );
};

export {
  CardFirstPCF,
  CardRevPCF,
  CardFinalPCF,
  CardPCFTerm,
  PCFTerm,
  CardCurrAcad,
};
