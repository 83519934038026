import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import sign from "jwt-encode";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../Page/Widgets/LoadingBtn";
import packageJson from "../../../../../package.json";
import { AlertNotif } from "../../../Library/AlertNotif";

export function MMyClass({ match }) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
  const [listData, setListData] = useState([]);
  const [atdLctAct, setAtdLctAct] = useState({
    loading: false,
    message: "",
    data: [],
    result: false,
  });

  const GET_ATD_LECTURER_BY_ACTIVECAL = () => {
    setAtdLctAct({ ...atdLctAct, loading: true });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        empid: AuthDecode.currentData.entityid,
      },
      target_data: "GET_ATD_LECTURER_BY_ACTIVECAL",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setAtdLctAct({
          ...atdLctAct,
          result: false,
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setAtdLctAct({
          ...atdLctAct,
          result: false,
          message: msg,
          loading: false,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setAtdLctAct({
            ...atdLctAct,
            result: true,
            data: results,
            message: "",
            loading: false,
          });
          setListData(results);
        } else {
          setAtdLctAct({
            ...atdLctAct,
            result: true,
            data: [],
            message: "No record found",
            loading: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_ATD_LECTURER_BY_ACTIVECAL();
  }, []);

  /*==DATATABELS CONFIG==*/
  const [ITEMS_PER_PAGE, setTotalShow] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ResultData = useMemo(() => {
    let computedData = listData;

    if (computedData.length > 0) {
        computedData = computedData.map((v,index)=>{
          var obj = {};
          obj.section_id = v.section_id;
          obj.section_session = v.section_session;
          obj.max_seat = v.max_seat;
          obj.lecturer = v.lecturer ? v.lecturer : "";
          obj.coursetitle = (v.course) ? v.course.coursetitle : "";
          obj.course_code = (v.course) ? v.course.code : "";
          obj.course_credit = (v.course) ? v.course.credit : "";
          obj.corporate = (v.corporate) ? v.corporate.name : "";
          obj.cost_center = (v.cost_center) ? v.cost_center.name : "";
          obj.term_id = (v.atp_term) ? v.atp_term.id : "";
          obj.term_name = (v.atp_term) ? v.atp_term.name : "";
          return obj;
        })

        computedData.sort((a, b) => (a.coursetitle > b.coursetitle ? 1 : -1));
    }

    if (search) {
        computedData = computedData.filter((listData) => {
          return Object.keys(listData).some(
            (key) =>
              listData[key]
                .toString()
                .toLowerCase()
                .includes(search)
            //console.log(key,listData[key])
          );
        });
    }
    setTotalItems(computedData.length);

    return computedData
  }, [listData, search, sorting]);
  /*==END DATATABELS CONFIG==*/

  return (
    <div className="pt-8 px-10">
      <h3 className="text-white text-capitalize">Courses in progress</h3>
      <p className="mb-3 text-white">Total class {totalItems}</p>
      <div className="search-item mb-10">
        <div className="w-100 w-md-200px d-flex align-items-center mb-3 mb-lg-0 bg-white border rounded">
          <input
            type="text"
            className="form-control form-control-sm border-0"
            placeholder="Type here to find it"
            onChange={(e) => setSearch(e.target.value)}
          />
          <span className="svg-icon svg-icon-1 svg-icon-gray-400 px-3">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height="2"
                rx="1"
                transform="rotate(45 17.0365 15.1223)"
                fill="currentColor"
              ></rect>
              <path
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
        </div>
      </div>
        
      <div className="table-responsive">
        {atdLctAct.loading ? (
            <div className="my-5">
                <LoadingBtn />
            </div>
        ) : atdLctAct.message ? <AlertNotif message={atdLctAct.message} color={"danger"} title={"Information"} /> : (
            ResultData.length > 0 ? (
                ResultData.map((v,index)=>(
                    <div className="card mb-5" key={index}>
                        <div className="card-body p-5 pb-0">
                            <div className="d-flex align-item-center">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column flex-grow-1 font-weight-bolder">
                                        <span className="text-dark h3 mb-1 font-size-lg">
                                            {v.coursetitle}
                                        </span>
                                        <span className="text-muted">
                                            {v.course_code}
                                            <span className="mx-2">&middot;</span>
                                            <span>
                                                Credit {v.course_credit}
                                            </span>
                                            <span className="mx-2">&middot;</span>
                                            <span>
                                                Total Session {v.section_session}
                                            </span>
                                        </span>
                                        <span className="text-muted">
                                            {v.section_id}
                                            <span className="mx-2">&middot;</span>
                                            <span>
                                                Max Seat {v.max_seat}
                                            </span>
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="card-footer p-3">
                            <div className="d-flex justify-content-center align-items-center">
                                <Link to={"/my-class/attendance/" + (sign({section_id:v.section_id, status:5}, packageJson.system_param.PublicKey))} className="btn btn-info btn-sm">
                                    <span className="icon">
                                        <svg width="16" height="16" fill="currentColor" className="bi bi-percent" viewBox="0 0 16 16">
                                            <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                                        </svg>
                                    </span>
                                    <span className="name ml-2">
                                        View Attendance
                                    </span>
                                </Link>
                                {/* <Link to={"score-class/" + (sign({section_id:v.section_id, status:5}, packageJson.system_param.PublicKey))} className="btn btn-primary btn-sm">
                                    <span className="icon">
                                        <svg width="16" height="16" fill="currentColor" className="bi bi-speedometer2" viewBox="0 0 16 16">
                                            <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                                            <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"/>
                                        </svg>
                                    </span>
                                    <span className="name ml-2">
                                        Score
                                    </span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                ))
            ) : ""
        )}
      </div>
    </div>
  );
}
