import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import { TableInvoicePCF, TablePCFOri, TableSubmitPCFRevisi } from "./TableSubmitPCF";
import AxiosLibWithHeader2 from "../../../../../../Library/AxiosLibWithHeader2";
import { openModal } from "../../../../../../Library/ModalPopUp";
import { openToast } from "../../../../../../Library/ToastPopUp";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function PreviewPCFRevision({
  myprev,
  data,
  fpcf,
  originPCFRev,
  finish,
  eventid,
  student,
  studentid,
}) {
  var objSubjectUniq = data.reduce((accumulator, currentValue) => {
    if (
      currentValue.detail.course_type !== "optional" &&
      currentValue.action_type === "add"
    ) {
      const {
        course_id,
        detail: { course_credit },
      } = currentValue;
      if (!accumulator.hasOwnProperty(course_id)) {
        accumulator[course_id] = course_credit;
      } else if (accumulator[course_id] !== course_credit) {
        accumulator[course_id] += course_credit;
      }
    }
    return accumulator;
  }, {});
  var tsks = 0;
  if (objSubjectUniq) {
    Object.keys(objSubjectUniq).map((k) => {
      tsks = tsks + objSubjectUniq[k];
    });
  }

  const totalPCFRevisi = originPCFRev.reduce(
    (a, v) =>
    (a =
      a + (v.action_type === "add" && v.detail.course_type !== "optional" ? v.detail.course_credit : 0)),
    0
  );
  
  const totalSKSOffer = tsks;

  const totalFPCF = fpcf.reduce(
    (a, v) =>
    (a =
      a + (v.detail.course_type !== "optional" ? v.detail.course_credit : 0)),
    0
  );

  const HandlerApprove = () => {
    var originpcf = [...fpcf];
    var result = data.filter(
      (a) =>
        originpcf.some(
          (a1) =>
            a1.section_id === a.section_id && a.action_type !== a1.action_type
        ) || originpcf.every((a1) => a1.section_id !== a.section_id)
    );
    var krslines = result.map((v) => {
      var item = {};
      if (v.detail.krsline_id) {
        item.id = v.detail.krsline_id;
      }
      item.action_type = v.action_type;
      item.course_id = v.course_id;
      item.section_id = v.section_id;
      return item;
    });
    var param = {
      eventid: eventid,
      krslines: krslines,
    };

    UPDATE_COUNSELING_PCF(
      param,
      eventid,
      setUpdatePCF,
      setApprovePCF,
      studentid
    );
  };

  const [updatePCF, setUpdatePCF] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [approvePCF, setApprovePCF] = useState({
    loading: false,
    data: [],
    message: "",
  });

  return (
    <div className="preview-pcf">
      <div className="row">
        <div className="col-8">
          <div className="table-pcf">
            <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "450px" }} className="px-3">
              <div className="row">
                <div className="col-6">
                  <div className="card">
                    <div className="card-body p-0">
                      <h3 className="text-center">PCF Original</h3>
                      <TablePCFOri pcffirst={originPCFRev} totalFPCF={totalPCFRevisi} />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card">
                    <div className="card-body p-0">
                      <h3 className="text-center">PCF Revision</h3>
                      <TableSubmitPCFRevisi
                        mypcf={data}
                        totalsks={totalSKSOffer}
                        invoice={myprev}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <TableInvoicePCF
            invoice={myprev}
            approvePCF={approvePCF}
            updatePCF={updatePCF}
            HandlerApprove={HandlerApprove}
          />
        </div>
      </div>
    </div>
  );
}

const UPDATE_COUNSELING_PCF = (
  parameters,
  eventid,
  setUpdatePCF,
  setApprovePCF,
  studentid
) => {
  setUpdatePCF({ loading: true, data: [], message: "" });
  setApprovePCF({ loading: true, data: [], message: "" });
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };
  var body = {
    parameters: parameters,
    target_data: "UPDATE_COUNSELING_PCF",
  };
  const postData = {
    keys: keys,
    body: body,
  };

  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    if (response.error) {
      openModal({ open: false });
      setUpdatePCF({ loading: false, message: response.error, data: [] });
      openToast({ header: "Error", message: response.error });
    } else if ("message" in response.response) {
      openModal({ open: false });
      var msg = response.response.message.internal;
      setUpdatePCF({ loading: false, message: msg, data: [] });
      openToast({ header: "Information", message: msg });
    } else {
      var param = response.response.data;
      setUpdatePCF({
        data: param,
        loading: false,
        message: "Successfully approve ",
      });
      PROCESS_COUNSELING_PCF(eventid, setApprovePCF, studentid);
    }
  });
};
const PROCESS_COUNSELING_PCF = (eventid, setApprovePCF, studentid) => {
  setApprovePCF({ loading: true, data: [], message: "" });
  var keys = {
    AppId: AuthDecode.AppID,
    AppEnv: AuthDecode.AppEnv,
    Secretkey: AuthDecode.secretkey,
  };

  var param = {
    eventid: eventid,
  };

  var body = {
    parameters: param,
    target_data: "PROCESS_COUNSELING_PCF",
  };

  const postData = {
    keys: keys,
    body: body,
  };

  AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
    //console.log("Approve PCF", response);
    if ("message" in response.response) {
      openModal({ open: false });
      setApprovePCF({
        loading: false,
        data: [],
        message: response.response.message.user,
      });
      openToast({
        message: response.response.message.user,
        header: "Info",
      });
    } else {
      var result = response.response.result;
      if (result === "success") {
        openModal({ open: false });
        setApprovePCF({
          loading: false,
          data: response.response.data,
          message: "Succsessfully updated and approved the PCF",
        });
        openToast({
          message:
            "Succsessfully updated and approved the PCF. Wait for a moment...",
          header: "Info",
        });
        setTimeout(() => {
          window.location.href =
            "/counseling/student/" + studentid + "/activity";
        }, 1500);
      } else {
        openModal({ open: false });
        setApprovePCF({
          loading: false,
          data: [],
          message: "Failed approve the PCF." + response.response.msg,
        });
        openToast({
          message: response.response.msg,
          header: "Error",
        });
      }
    }
  });
};
