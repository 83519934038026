import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Moment from "moment";
import AxiosLib from "../../Library/AxiosLib";

export function SubHeader(props) {
  var AuthToken = localStorage.getItem("AuthToken");
  const token = localStorage.getItem("AuthToken");
  const decoded = jwt_decode(token);
  const URLPath = window.location.pathname;
  const URLPathSplit = URLPath.split("/");
  const URLPathName = URLPathSplit[1];
  var breadcrumb = [];

  if (URLPathSplit.length > 2) {
    for (var i = 2; i < URLPathSplit.length; i++) {
      if (i <= 3) {
        breadcrumb.push(
          <span className="text-muted font-weight-bold mr-4  text-capitalize" key={i}>
            {URLPathSplit[i].split("-").join(" ")}
          </span>
        );
      }
    }
  }

  const [time, setTime] = useState();
  const [currtime, setCurrtime] = useState({
    loading: false,
    data: [],
    message: ""
  });
  const GetCurrentTime = () => {
    setCurrtime({ ...currtime, loading: true, data: [] });
    AxiosLib("", "app/api/current-time").then((response) => {
      if (response.error) {
        setCurrtime({ ...currtime, loading: false, data: [], message: response.error });
      } else {
        setCurrtime({ ...currtime, loading: false, data: response, message: "" });
        setTime(Moment(response.today));
      }
    });
  }


  useEffect(() => {
    GetCurrentTime();

    const timer = setInterval(() => {
      setTime(Moment());
    }, 1000); // Update every second

    return () => {
      clearInterval(timer);
    };
  }, [])

  const hours = Moment(time).format("HH");
  const minutes = Moment(time).format("mm");
  const seconds = Moment(time).format("ss");

  return (
    <div className="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-2">
          <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5 text-capitalize">
            {URLPathName.split("-").join(" ")}
          </h5>
          {URLPathName === "detail" || URLPathName === "score-and-grade" || URLPathName === "regulations-form" ? (
            <>
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
              <span className="text-muted font-weight-bold mr-4 text-capitalize">
                {URLPathName == "regulations-form"
                  ? "Form Submit"
                  : URLPathSplit[2].split("-").join(" ")}
              </span>
            </>
          ) : breadcrumb.length > 0 ? (
            <>
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
              {breadcrumb}
            </>
          ) : (
            ""
          )}
        </div>

        {decoded.remotedBy ? (
          <div className="d-flex">
            <div className="topbar-item mr-10">
              <div className="btn-group">
                <div className="btn btn-danger btn-icon btn-lg">
                  <i className="la la-user-cog"></i>
                </div>
                <div className="btn btn-light btn-lg font-size-sm">
                  Remoted By{" "}
                  <span className="r-name">
                    {decoded.remotedBy.given_name +
                      " " +
                      decoded.remotedBy.family_name}
                  </span>{" "}
                  / <span className="r-role">{decoded.remotedBy.role}</span>
                </div>
                <button
                  className="btn btn-danger btn-icon btn-lg"
                  type="button"
                  title="Sign Out"
                  onClick={(e) => props.signOutRemote()}
                >
                  <i className="la la-sign-out"></i>
                </button>
              </div>
            </div>
            <div className="topbar-item mr-10">
              <div className="btn-group">
                <div className="btn btn-info btn-icon btn-lg">
                  <i className="la la-user-cog"></i>
                </div>
                <div className="btn btn-light btn-lg font-size-sm">
                  Current user:
                  <span className="r-name ml-1">
                    {decoded.currentData.given_name +
                      " " +
                      decoded.currentData.family_name}
                  </span>{" "}
                  / <span className="r-role">{decoded.currentData.role}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {decoded.TypeCK === "development" ? (
          <div
            className="topbar-item mr-10"
            title={"Consumer Key for " + decoded.TypeCK}
          >
            <div className="btn-group">
              <div className="btn btn-info btn-icon btn-lg">
                <span className="svg-icon menu-icon">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                </span>
              </div>
              <div className="btn btn-light-info btn-lg font-size-sm">
                <span className="text-uppercase">{decoded.TypeCK}</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="d-flex align-items-center">
          <span
            className="btn btn-sm btn-light font-weight-bold mr-2"
            id="kt_dashboard_daterangepicker"
          >
            <span
              className="text-muted font-size-base font-weight-bold mr-2"
              id="kt_dashboard_daterangepicker_title"
            >
              Today
            </span>
            <span className="text-primary font-size-base font-weight-bolder" id="kt_dashboard_daterangepicker_dates">
              {(Object.values(currtime.data).length > 0) ? ((currtime.data.today) ? (
                <>
                  <span className="date mr-3">{Moment(currtime.data.today).format("MMM DD, YYYY")}</span>
                  <span className="time">
                    {hours}:{minutes}
                  </span>
                </>
              ) : "-") : "-"}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
