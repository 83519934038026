import React from "react";

const Approved = () => {
  return (
    <div className="d-flex flex-column align-items-center" style={{ gap: 5 }}>
      <p style={{ fontSize: 24 }}>SUBMITTED</p>
      <p style={{ fontSize: 16 }}>Your <span className="text-success">APPROVAL</span> have been submitted</p>
    </div>
  );
};

const Rejected = () => {
  return (
    <div className="d-flex flex-column align-items-center" style={{ gap: 5 }}>
      <p style={{ fontSize: 24 }}>SUBMITTED</p>
      <p style={{ fontSize: 16 }}>Your <span className="text-danger">REJECTION</span> have been submitted</p>
    </div>
  );
};

const SuccessFailedDialog = ({ status, res }) => {
  console.log(status);
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ gap: 10 }}
    >
      {res === "success" ? (
        <i
          class="far fa-check-circle text-success my-5"
          style={{ fontSize: 60 }}
        ></i>
      ) : (
        <i class="far fa-times-circle text-danger" style={{ fontSize: 44 }}></i>
      )}
      <div>
        {res === "success" ? (
          status === 1 ? (
            <Approved />
          ) : (
            <Rejected />
          )
        ) : (
          res
        )}
      </div>
    </div>
  );
};

export default SuccessFailedDialog;
