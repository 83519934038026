import React from "react";

const CummulativeCredit = ({data}) =>{
  return (
    <div className="credit-item text-center">
      <span className="text-uppercase d-block text-gray-700">
        Cumulative Earned Credit
      </span>
      <span className="h1">
        {data}
      </span>
    </div>
  )
}

const CreditPass = ({data}) =>{
  return (
    <div className="credit text-center mx-5">
      <span className="text-uppercase d-block text-gray-700">
        Completed Credits
      </span>
      <span className="h1 text-success">
        {data}
      </span>
    </div>
  )
}

const RemainCredit = ({data}) =>{
  return (
    <div className="credit-item text-center">
      <span className="text-uppercase d-block text-gray-700">
        Remaining Credit
      </span>
      <span className="h1 text-danger">{data}</span>
    </div>
  )
}

const CurrentCredit = ({data}) =>{
  return (
    <div className="credit-item text-center ms-5">
      <span className="text-uppercase d-block text-gray-700">
        Current Semester Credits
      </span>
      <span className="h1 text-warning">{data}</span>
    </div>
  )
}

const CardCreditTaken = ({ data }) => {
  //const totalData = Object.values(data).length;
  const results = data;
  const max_credit = 144;
  let remaining_credit = max_credit - results.ccredit_pass;

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-body d-flex flex-column flex-center">
        <h3 className="mb-5 text-left w-100 font-weight-boldest">Total Course Credit</h3>
        <div className="list-credit d-flex align-items-center mb-5">
          <CummulativeCredit data={results.ccredit_pass ? results.ccredit_pass : 0} />
          
        </div>
      </div>
    </div>
  );
};

export { CardCreditTaken, CummulativeCredit, CreditPass, RemainCredit, CurrentCredit };


