import React from 'react'

export default function Form(props) {
    const profile = props.profile;
    const acad = props.acad;
    const studentStatus = props.studentStatus;
    return (
        <div className="form-leave">
            <div className="card card-custom gutter-b">
                <div className="card-header border-0">
                    <h3 className="card-title">
                        Personal Information 
                    </h3>
                </div>
                <div className="card-body pt-0">
                    <form id="form-leave" method="post" autoComplete="off">
                        <div className="form-group">
                            <label>Student ID</label>
                            <input type="text" disabled className="form-control" name="studentid" value={profile.currentData.entityid} />
                        </div>
                        <div className="form-group">
                            <label>Student Name</label>
                            <input type="text" disabled className="form-control" name="studentid" value={profile.currentData.given_name + " " + profile.currentData.family_name} />
                        </div>
                        <div className="form-group">
                            <label>Academic Calendar</label>
                            <input type="text" disabled className="form-control" name="acad" value={0} />
                        </div>
                        <div className="form-group">
                            <label>Start Term</label>
                            <input type="text" disabled className="form-control" name="term" value={(Object.keys(studentStatus).length > 0) ? studentStatus.atp_term.name : '-'} />
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Term Duration</label>
                                    <input type="text" disabled className="form-control" name="duration" value={1} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Current Semester</label>
                                    <input type="text" disabled className="form-control" name="semester" value={(Object.keys(studentStatus).length > 0) ? Object.keys(studentStatus.semester_status).length : '-'} />
                                </div>                                    
                            </div>
                        </div>
                        <div className="btn-group float-right">
                            <button className="btn btn-primary" type="button"><i className="fa fa-plus-square"></i> Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
