import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import CourseOvering from "../FormOvering/CourseOvering";
import CourseCatalog from "../FormOvering/CourseCatalog";
import MyPCF from "../FormOvering/MyPCF";
import { openToast } from "../../../../../Library/ToastPopUp";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import CardProfilePCF from "./CardProfilePCF";
import CardCounselor from "./CardCounselor";
import PreviewPCF from "./PreviewPCF";
import LoadingBtn from "../../../../Widgets/LoadingBtn";
import { CardCreditTaken } from "../../../../Lecturer/Counseling-new/Acad/CardCourseCredit";
import { CardCurrAcad } from "./CardPCF";

export default function FormFirstPCF({ acad }) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
  //const courseplan = props.data ? props.data : [];
  //const counselor = props.counselor ? props.counselor : [];
  //const student = props.student ? props.student : [];
  const studentid = AuthDecode.currentData.entityid;

  const [student, setStdStatus] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const GET_STUDENT_STATUS = () => {
    setStdStatus({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: studentid,
      },
      target_data: "GET_STUDENT_STATUS",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdStatus({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setStdStatus({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setStdStatus({ loading: false, data: results, message: "" });
          GET_ACTIVE_ACAD(results.atp_group_type);
        } else {
          setStdStatus({
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  const [pcf, setPCF] = useState({ loading: false, data: [], message: "" });
  const GET_REREG_STUDENT = () => {
    setPCF({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: studentid,
      },
      target_data: "GET_REREG_STUDENT",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setPCF({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setPCF({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setPCF({ loading: false, data: results, message: "" });
        } else {
          setPCF({ loading: false, data: [], message: "No record found" });
        }
      }
    });
  };

  const [counselor, setCounselor] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const GET_COUNSELOR = () => {
    setCounselor({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: studentid,
      },
      target_data: "GET_COUNSELOR",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setCounselor({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setCounselor({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setCounselor({ loading: false, data: results, message: "" });
        } else {
          setCounselor({
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  const [totalsks, setTotalSKS] = useState(0);
  const [mobile, setMobile] = useState({
    phone: "",
    rephone: "",
    match: "",
    message: "",
  });

  const [acadActv, setAcadActv] = useState([]);
  const [selectedAcad, setSelectedAcad] = useState(0);

  const GET_ACTIVE_ACAD = (atp_group_type) => {
    setAcadActv({ ...acadActv, loading: true, data: [] });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      target_data: "GET_ACTIVE_ACAD",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setAcadActv({ ...acadActv, loading: false, message: response.error });
      } else if ("message" in response.response) {
        setAcadActv({
          ...acadActv,
          loading: false,
          message: response.response.message.internal,
        });
      } else {
        var param = response.response.data;
        if (Object.values(param).length > 0) {
          var std_group = atp_group_type;
          var selected = param.find(
            (prev) => prev.atp_group_type.id === std_group
          );
          if (selected) {
            setSelectedAcad(selected.reff_atp_id_term);
            setAcadActv({ ...acadActv, loading: false, data: param });
          }
        } else {
          setAcadActv({
            ...acadActv,
            loading: false,
            message: "No record found",
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_STUDENT_STATUS();
    GET_COUNSELOR();
    GET_REREG_STUDENT();
  }, []);

  const handlerRepeat = (value) => {
    var message = "";
    if (value) {
      if (value === mobile.phone) {
        message = "match";
      } else {
        message = "not match";
      }
    }
    setMobile({ ...mobile, rephone: value, match: message });
  };

  const [mypcf, setMypcf] = useState([]);

  const handlerActionCourse = (value) => {
    if (value.action_type === "add") {
      //check existing course
      var isSame = mypcf.filter(
        (prev) =>
          prev.section_id === value.section_id ||
          prev.detail.course_code === value.detail.course_code
      );
      if (Object.values(isSame).length > 0) {
        openToast({
          header: "Information",
          message: "This course has already been added to the 'MY PCF'",
        });
      } else {
        setMypcf([...mypcf, value]);
      }
    } else {
      var clone = [...mypcf];
      clone.splice(value.index, 1);
      setMypcf(clone);
    }
  };

  const objparamPCF = {
    cellular: mobile.phone,
    studentid: studentid,
  };

  const handlerSubmitPCF = (e) => {
    e.preventDefault();

    if (totalsks > 24) {
      openToast({
        header: "Information",
        message: "Maximum total Credit is 24 SKS",
      });
    } else {
      // if (AuthDecode.remotedBy) {
      //     openToast({ header: "Error", message: "You cannot access this request" });
      // } else {

      if (Object.values(mypcf).length > 0 && objparamPCF.cellular) {
        var courses = mypcf.map((c) => {
          var obj = {};
          obj.action_type = c.action_type;
          obj.course_id = c.course_id;
          obj.section_id = c.section_id;
          return obj;
        });
        var paramPrev = {};
        paramPrev.courses = courses;
        paramPrev.studentid = studentid.toString();
        paramPrev.term_id = acad.atp_id_term_next;

        GET_PCF_AMOUNT(paramPrev);
      } else {
        openToast({
          header: "Information",
          message: "Please fill up the form with correctly",
        });
      }
      //}
    }
  };

  const [previewPCF, setPreviewPCF] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const GET_PCF_AMOUNT = (param) => {
    setPreviewPCF({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };

    var body = {
      parameters: param,
      target_data: "GET_PCF_AMOUNT",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if ("message" in response.response) {
        setPreviewPCF({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
        openToast({
          message: "Error " + response.response.message.user,
          header: "Error",
        });
      } else {
        var result = response.response.result;
        if (result === "success") {
          setPreviewPCF({
            loading: false,
            data: response.response.data,
            message: "Successfully submit PCF",
          });
          window.scrollTo(0, 0);
        } else {
          setPreviewPCF({
            loading: false,
            data: [],
            message: response.response.msg,
          });
          openToast({
            message: response.response.msg,
            header: "Error",
          });
        }
      }
    });
  };

  if (Object.values(previewPCF.data).length > 0) {
    return (
      <PreviewPCF
        mypcf={mypcf}
        data={previewPCF.data}
        setSubmitPCFOri={setPreviewPCF}
        param={objparamPCF}
        totalsks={totalsks}
      />
    );
  } else {
    return (
      <div id="form-pcf">
        {pcf.loading || student.loading || counselor.loading ? (
          <div className="p-5 bg-white border border-top-0">
            <LoadingBtn />
          </div>
        ) : student.message || counselor.message || pcf.message ? (
          <div className="p-5 bg-white border border-top-0">
            <div className="border rounded p-5 text-center bg-light">
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z"
                      fill="#000000"
                      opacity="0.3"
                    />
                    <polygon
                      fill="#000000"
                      points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"
                    />
                  </g>
                </svg>
              </span>
              <p className="text-primary mb-0">
                {student.message || counselor.message || pcf.message}
              </p>
            </div>

            <div className="mt-8 text-center">
              <Link
                to={{ pathname: "/registration/revision-plan-course-form" }}
                type="button"
                className="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-2 px-6"
              >
                <span>PCF Revision </span>
                <span className="svg-icon svg-icon-2x">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(14.000000, 12.000000) rotate(-90.000000) translate(-14.000000, -12.000000) "
                        x="13"
                        y="5"
                        width="2"
                        height="14"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="3"
                        y="3"
                        width="2"
                        height="18"
                        rx="1"
                      />
                      <path
                        d="M11.7071032,15.7071045 C11.3165789,16.0976288 10.6834139,16.0976288 10.2928896,15.7071045 C9.90236532,15.3165802 9.90236532,14.6834152 10.2928896,14.2928909 L16.2928896,8.29289093 C16.6714686,7.914312 17.281055,7.90106637 17.675721,8.26284357 L23.675721,13.7628436 C24.08284,14.136036 24.1103429,14.7686034 23.7371505,15.1757223 C23.3639581,15.5828413 22.7313908,15.6103443 22.3242718,15.2371519 L17.0300721,10.3841355 L11.7071032,15.7071045 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(16.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-16.999999, -11.999997) "
                      />
                    </g>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        ) : Object.values(student.data).length > 0 ? (
          <>
            <div className="row">
              <div className={"col-8"}>
                <CardProfilePCF studentid={studentid} />
              </div>
              <div className="col-4">
                <CardCurrAcad data={student.data} />
              </div>
            </div>
            {student.data.status.id === "asc.student.status.active" && (
              <>
                <div className="row">
                  <div className="col-4">
                    <CourseOvering
                      data={pcf.data}
                      handlerActionCourse={handlerActionCourse}
                    />
                  </div>
                  <div className="col-4">
                    {selectedAcad ? (
                      <CourseCatalog
                        handlerActionCourse={handlerActionCourse}
                        student={student.data}
                        selectedAcad={selectedAcad}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-4">
                    <MyPCF
                      mypcf={mypcf}
                      setMypcf={setMypcf}
                      acad={acadActv}
                      student={student.data}
                      totalsks={totalsks}
                      setTotalSKS={setTotalSKS}
                      handlerActionCourse={handlerActionCourse}
                    />
                  </div>
                </div>

                <div className="card card-custom gutter-b">
                  <div className="card-body">
                    <form
                      method="post"
                      autoComplete="off"
                      onSubmit={(e) => handlerSubmitPCF(e)}
                    >
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label className="font-weight-bolder">
                              WhatsApp Number{" "}
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              required
                              name="phone"
                              minLength={10}
                              className={
                                "form-control " +
                                (Object.values(mobile.phone).length > 10
                                  ? "is-valid"
                                  : "is-invalid")
                              }
                              placeholder="081xxx"
                              onChange={(e) =>
                                setMobile({
                                  ...mobile,
                                  phone: e.target.value.replace(/\D/g, ""),
                                })
                              }
                              value={mobile.phone}
                            />
                            <span className="text-danger">
                              {!mobile.phone && "Fill up this field"}
                              {mobile.message}
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label className="font-weight-bolder">
                              Repeat Your WhatsApp Number{" "}
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              onPaste={handlePaste}
                              required
                              name="repeat-phone"
                              minLength={10}
                              className={
                                "form-control " +
                                (mobile.match === "match"
                                  ? "is-valid"
                                  : "is-invalid")
                              }
                              placeholder="081xxx"
                              onChange={(e) =>
                                handlerRepeat(e.target.value.replace(/\D/g, ""))
                              }
                              value={mobile.rephone}
                            />
                            <span className="text-danger">
                              {mobile.message}
                            </span>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <label className="font-weight-bolder">
                              Counselor
                            </label>
                            {Object.values(counselor.data).length > 0 ? (
                              counselor.data.map((v, index) => (
                                <div
                                  className="list-counselor py-2"
                                  key={index}
                                >
                                  <span className="font-weight-bold">
                                    {v.emp ? v.emp.fullname : ""}
                                  </span>
                                  {index <
                                  Object.values(counselor.data).length - 1 ? (
                                    <span className="mr-1">, </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))
                            ) : (
                              <div className="alert alert-danger">
                                <div className="alert-title text-left">
                                  <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                                  You do not have a counselor.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {AuthDecode.remotedBy ? (
                        <div className="alert alert-danger">
                          <div className="alert-title text-left">
                            <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                            You cannot submit this PCF.
                            <br />
                            This PCF belongs to{" "}
                            <b>
                              Mr/Mrs.{" "}
                              {AuthDecode.currentData.given_name +
                                " " +
                                AuthDecode.currentData.family_name}
                            </b>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {student.data.program.id === 1 &&
                      Object.values(counselor.data).length === 0 ? (
                        <div className="alert alert-danger">
                          <div className="alert-title text-left">
                            <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                            You do not have a counselor. Please contact your FAO
                            regarding this matter.
                          </div>
                        </div>
                      ) : (
                        <div className="text-center">
                          <button
                            className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit"
                            type="submit"
                            disabled={
                              previewPCF.loading
                                ? true
                                : mobile.rephone
                                ? mobile.phone === mobile.rephone
                                  ? false
                                  : true
                                : true
                            }
                          >
                            {previewPCF.loading
                              ? "Processing"
                              : "Next and Preview"}
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const handlePaste = (e) => {
  e.preventDefault();
  return false;
};
