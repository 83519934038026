import axios from "axios";
import sign from 'jwt-encode';
import CryptoJS from "crypto-js";
import packageJson from '../../../package.json';

async function AxiosLibWithHeader2(value,pathURI) {
    
    var Secretkey = value.keys.Secretkey;           
    var d = new Date();
    var tstamp = d.getTime() / 1000;
    
    
    var body=value.body;
    
    var payloadTmp = body;
    //var trimmed_payload = payloadTmp.toString().replace(/\s+/g, '');
    var trimmed_payload = JSON.stringify(payloadTmp).replace(/\s+/g, '');
    var base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(trimmed_payload));
    //console.log(base64);
    var signature_raw = tstamp + '|<->|' + base64;
    //console.log(signature_raw);
    var signature_bytes = CryptoJS.HmacSHA256(signature_raw,Secretkey);
    //console.log(signature_bytes);
    var signature = CryptoJS.enc.Base64.stringify(signature_bytes);
    var headers={
        "App-Id":value.keys.AppId,
        "App-Env":value.keys.AppEnv,
        "App-Signature":signature,
        "App-Timestamp":tstamp,
        'Content-Type':'application/json'
    }  
    
    var config = {
        method: 'post',
        url: packageJson.system_param.ApiBackendURI+pathURI,
        headers,
        data : body
    };
    
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            var message = "Error fetching data from '"+pathURI+"'.";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}

export default AxiosLibWithHeader2;