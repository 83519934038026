import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode"
import Moment from "moment";
import AxiosLibWithHeader2 from '../../../Library/AxiosLibWithHeader2';
import { LoadingMobile } from '../loading';
import { LoopWeekDay } from '../../../Library/GlobalHelper';

export function MyCalendarWeek() {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

    var now = Moment();
    const paramSchd = {
        empid: AuthDecode.currentData.entityid,
        tgl: Moment(now).format("YYYY-MM-DD"),
        tglend: Moment(now).format("YYYY-MM-DD"),
    };
    const [paramBrowse, setParamBrowse] = useState(paramSchd);
    const [selectedDate, setSelectedDate] = useState();

    const [schd, setSchd] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const GET_SCHD_CLASS_LEC_NEXT = (parameter) => {
        setSchd({ ...schd, loading: true, data: [] });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: parameter,
            target_data: "GET_SCHD_CLASS_LEC_NEXT",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setSchd({
                    ...schd,
                    message: response.error,
                    loading: false,
                });
            } else if ("message" in response.response) {
                var msg = response.response.message.user;
                setSchd({
                    ...schd,
                    message: msg,
                    loading: false,
                });
            } else {
                var results = response.response.data;
                if (results) {
                    setSchd({
                        ...schd,
                        data: results,
                        message: "",
                        loading: false,
                    });
                } else {
                    setSchd({
                        ...schd,
                        data: [],
                        message: "No schedule found",
                        loading: false,
                    });
                }
            }
        });
    };

    useEffect(() => {
        GET_SCHD_CLASS_LEC_NEXT(paramBrowse);
    }, []);

    const handlerRenderEvent = (date) => {
        //setSelectedDate(date);
        setParamBrowse({...paramBrowse, tgl:date, tglend:date});
        const paramSchd = {
            empid: AuthDecode.currentData.entityid,
            tgl: date,
            tglend: date,
        };
        GET_SCHD_CLASS_LEC_NEXT(paramSchd);
    }

    //const myweek = LoopWeekDay(now);
    const cWeek = LoopWeekDay(now);
    const [myweek, setMyWeek] = useState( cWeek );

    const HandlerPrevNext = (type) =>{
        if(type === "prev"){
            var pMonday = Moment(paramBrowse.tgl).subtract(1, 'weeks').weekday(1).format("YYYY-MM-DD");
            //var pSaturday = Moment(paramBrowse.tgl).subtract(1, 'weeks').weekday(6).format("YYYY-MM-DD");
            var pWeek = LoopWeekDay(Moment(pMonday));
            setMyWeek( pWeek );
            setParamBrowse({...paramBrowse, tgl:pMonday, tglend:pMonday});
            const paramSchd = {
                empid: AuthDecode.currentData.entityid,
                tgl: pMonday,
                tglend: pMonday,
            };
            GET_SCHD_CLASS_LEC_NEXT(paramSchd);
        }else if(type === "next"){
            var nMonday = Moment(paramBrowse.tgl).add(1, 'weeks').weekday(1).format("YYYY-MM-DD");
            //var nSaturday = Moment(paramBrowse.tgl).add(1, 'weeks').weekday(6).format("YYYY-MM-DD");
            var nWeek = LoopWeekDay(Moment(nMonday));
            setMyWeek( nWeek );
            setParamBrowse({...paramBrowse, tgl:nMonday, tglend:nMonday});
            const paramSchd = {
                empid: AuthDecode.currentData.entityid,
                tgl: nMonday,
                tglend: nMonday,
            };
            GET_SCHD_CLASS_LEC_NEXT(paramSchd);
        }else{
            setMyWeek( cWeek );
            var cDate = Moment(now).format("YYYY-MM-DD");
            setParamBrowse({...paramBrowse, tgl:cDate, tglend:cDate});
            const paramSchd = {
                empid: AuthDecode.currentData.entityid,
                tgl: cDate,
                tglend: cDate,
            };
            GET_SCHD_CLASS_LEC_NEXT(paramSchd);
        }
    }

    return (
        <div className="list-schd">
            <div className="time-now mt-10">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="head">
                        <h3 className='text-white font-weight-bolder'>Your Timetables</h3>
                        <p className="text-white">
                            <i className="far fa-calendar-alt text-white"></i>
                            <span className="ml-2">{Moment(paramBrowse.tgl).format("dddd, DD MMMM YYYY")}</span>
                        </p>
                    </div>
                    <div className="action">
                        <div className="btn-group">
                            <button className="btn btn-light btn-icon btn-sm" type='button' onClick={()=>HandlerPrevNext("prev")}>
                                <i className="fas fa-chevron-left"></i>
                            </button>
                            <button className="btn btn-light btn-sm" type='button' onClick={()=>HandlerPrevNext("today")}>
                                <span className="font-weight-bolder text-uppercase">Today</span>
                            </button>
                            <button className="btn btn-light btn-icon btn-sm" type='button' onClick={()=>HandlerPrevNext("next")}>
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-white rounded p-5 px-8 shadow'>
                <div className="d-flex justify-content-between align-items-center">
                    {myweek.map((v, index) => (
                        <div key={index} className={"text-center  p-3 rounded-pill font-weight-bolder " + (((Moment(paramBrowse.tgl).format("YYYY-MM-DD")) === v.date) ? "bg-light-primary text-primary font-weight-border" : "")} onClick={() => handlerRenderEvent(v.date)}>
                            <span className="day d-block text-muted">{v.name.charAt(0)}</span>
                            <span className="date">{Moment(v.date).format("DD")}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="mt-8 list-event">
                {schd.loading ? <LoadingMobile /> : schd.message ? (
                    <div className="message bg-white p-5 rounded shadow mb-5">
                        <div className="text-center pt-5">
                            <span className="svg-icon svg-icon-dark svg-icon-3x">
                                <svg width="24" height="24" fill="currentColor" className="bi bi-calendar-x" viewBox="0 0 16 16">
                                    <path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z" />
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                </svg>
                            </span>
                            <p className="text-dark mt-5">{schd.message}</p>
                        </div>
                    </div>
                ) : (
                    Object.values(schd.data).length > 0 ? (
                        <>
                            {schd.data.map((v, index) => (
                                <div className="item bg-white shadow rounded p-5 mb-5" key={index}>
                                    <div className="info">
                                        <p className="mb-0">
                                        <span className="text-primary font-weight-bolder">{Moment(v.start_dt).format("HH:mm")}-{Moment(v.end_dt).format("HH:mm")}</span>
                                        </p>
                                        <p className="text-uppercase font-size-h4">
                                            <span className="font-weight-bolder d-block">{v.course ? v.course.coursetitle : ""}</span>
                                        </p>
                                        <p className="mb-0">
                                            <i className="la la-barcode"></i>
                                            <span className="ml-2">Section {v.section_id}</span>
                                        </p>
                                        {(Object.values(v.event_room).length > 0) ? (
                                            <p className="mb-0">
                                                <i className="la la-map-marker"></i>
                                                <span className='ml-2'>Room </span>
                                                {v.event_room.map((r, index) => (
                                                    <span key={index} className='mr-2'>{r.room.g_resourceName} Floor {r.room.g_floorName} Capacity {r.room.g_capacity},</span>
                                                ))}
                                            </p>
                                        ) : ""}
                                        {(Object.values(v.section_pre).length > 0) ? (
                                            <p className="mb-0 d-flex">
                                                <i className="la la-users"></i>
                                                <span className='ml-2'>
                                                    {v.section_pre.map((r, index) => (
                                                        <span key={index} className='d-block'>
                                                            {r.clu ? r.clu.code : ""}
                                                            {(r.clu_spec ? "-" + r.clu_spec.name : "")}
                                                            <span className="ml-2">Semester {r.semester}</span>
                                                        </span>
                                                    ))}
                                                </span>
                                            </p>
                                        ) : ""}

                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div className="message bg-white p-5 rounded shadow mb-5">
                            <div className="text-center pt-5">
                                <span className="svg-icon svg-icon-dark svg-icon-3x">
                                    <svg width="24" height="24" fill="currentColor" className="bi bi-calendar-x" viewBox="0 0 16 16">
                                        <path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z" />
                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                    </svg>
                                </span>
                                <p className="text-dark mt-5">No schedule found for {paramBrowse.tgl}</p>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    )
}



