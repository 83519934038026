import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import LogoComp from "../../../../media/logos/logo-sgu.png"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../Page/Widgets/LoadingBtn";
import { AlertNotif } from "../../../Library/AlertNotif";

export function MProfile() {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [myProfile, setMyProfile] = useState({
    loading: false,
    message: "",
    data: []
  });

  const GET_EMP_BIO_BY_IDENID = (parameters) => {
    setMyProfile({ loading: true, data: [], message: "" });
    var keys = {
      "AppId": AuthDecode.AppID,
      "AppEnv": AuthDecode.AppEnv,
      "Secretkey": AuthDecode.secretkey
    };
    var body = {
      "parameters": parameters,
      "target_data": "GET_EMP_BIO_BY_IDENID"
    };
    const postData = {
      keys: keys,
      body: body
    };
    AxiosLibWithHeader2(postData, 'app/api/req-module')
      .then(
        response => {
          if (response.error) {
            setMyProfile({
              data: [],
              message: response.error,
              loading: false,
            });
          } else if ("message" in response.response) {
            var msg = response.response.message.user;
            setMyProfile({
              data: [],
              message: msg,
              loading: false,
            });
          } else {
            var results = response.response.data;
            if (results) {
              setMyProfile({
                data: results,
                message: "",
                loading: false,
              });
            } else {
              setMyProfile({
                data: [],
                message: "No record found",
                loading: false,
              });
            }
          }
        }
      );
  }

  useEffect(() => {
    GET_EMP_BIO_BY_IDENID({ empid: AuthDecode.currentData.entityid });
  }, [])

  return (
    <div className='px-10 pt-10'>
      <div className="d-flex justify-content-center" style={{ position: "relative", marginBottom: "-50px", zIndex: 1 }}>
        <div className="w-100px h-100px rounded-circle bg-light border border-2 border-white text-center d-flex justify-content-center align-items-center">
          <img src={LogoComp} alt={AuthDecode.currentData.family_name} className="w-100 p-5" />
        </div>
      </div>
      <div className="card">
        <div className="card-body pt-20">
          <div className="text-center">
            <h3 className="mb-0">
              {AuthDecode.currentData.given_name} {AuthDecode.currentData.family_name}
            </h3>
            <p className="mb-0 text-muted font-weight-bolder h8">
              {AuthDecode.currentData.empid}
            </p>
          </div>

          <div className="my-profile">
            {myProfile.loading ? <LoadingBtn /> : myProfile.message ? <AlertNotif message={myProfile.message} color={"danger"} title={"Information"} /> : (
              Object.values(myProfile.data).length > 0 ? (
                <div className="Personal-info mt-10">
                  <h4 className="text-info">Personal Information</h4>
                  <div className="row">
                    <div className="col-6">
                      <p>
                        <span className="d-block text-muted">Status</span>
                        <span className="text-dark font-weight-bolder">
                          {myProfile.data.is_active ? "Active" : "Not Active"}
                        </span>
                      </p>
                      <p>
                        <span className="d-block text-muted">Type</span>
                        <span className="text-dark font-weight-bolder">
                          {myProfile.data.emp_type ? (myProfile.data.emp_type.name ? myProfile.data.emp_type.name : "-") : "-"}
                        </span>
                      </p>

                    </div>
                    <div className="col-6">                      
                      <p>
                        <span className="d-block text-muted">ID</span>
                        <span className="text-dark font-weight-bolder">
                          {myProfile.data.id ? myProfile.data.id : "-"}
                        </span>
                      </p>
                      <p>
                        <span className="d-block text-muted">Education</span>
                        <span className="text-dark font-weight-bolder">
                          {myProfile.data.rate ? myProfile.data.rate : "-"}
                        </span>
                      </p>
                    </div>
                  </div>

                  <p>
                    <span className="d-block text-muted">Email</span>
                    <span className="text-dark font-weight-bolder">
                      {myProfile.data.sgumail ? myProfile.data.sgumail : "-"}
                    </span>
                  </p>

                  <div className=" mt-8">
                    <Link to="/sign-out" className="btn btn-danger btn-lg btn-block" >
                      SIGN OUT
                    </Link>
                  </div>
                </div>
              ) : ""
            )}
          </div>
        </div>
      </div>

    </div>
  )
}
