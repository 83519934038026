import React, { Component } from 'react'
import jwt_decode from "jwt-decode"
import UploadFile from './UploadFile'
import CategoriesFile from './CategoriesFile'
import AxiosLib from '../../../../Library/AxiosLib'

export class FormRegulations extends Component {
    constructor(props){
        super(props);
        this.fetchFileType = this.fetchFileType.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);        
        this.state = {
            AuthDecode:AuthDecode,
            WizardActive:1,
            WizardNav:[{title:"Upload File", sub:"Submit this form with PDF file"},
                       {title:"Categories of File",sub:"Place to showing this regulation file on portal"}],
            FileTypes:[],
            Loading:false
        };
    }

    fetchFileType(){
        var dataParam = {
            UserID:this.state.AuthDecode.currentData.entityid,
            Type:1,
            ParentID:0,
            RoleID:this.state.AuthDecode.currentData.roleid
        };
        if(this.state.AuthDecode.currentData.empid){
            dataParam.EmpID = this.state.AuthDecode.currentData.empid;
        }
        AxiosLib(dataParam,'/mc/api/regulation-type/fetch')
        .then(
            response => {
                this.setState({ FileTypes: response.data});
            }
        ); 
    }
    
    componentDidMount(){
        this.fetchFileType();
    }

    render() {

        const handlerSubmitFile = (param) =>{
            this.setState({WizardActive:this.state.WizardActive+1})
            console.log(param);
        }

        const handlerBackSubmit = () =>{
            this.setState({WizardActive:this.state.WizardActive-1})
        }

        return (
            <div id='Form-Regulation'>
                <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                    <div className="alert-icon">
                        <span className="svg-icon svg-icon-primary svg-icon-xl">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z" fill="#000000" opacity="0.3"></path>
                                    <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z" fill="#000000" fill-rule="nonzero"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div className="alert-text"><h3>Upload Your File Regulation Here</h3></div>
                </div>

                <div className="card card-custom gutter-b">
                    <div className="card-body">
                        <div className="wizard-nav">
                            <div className="row">
                                {this.state.WizardNav.map((v,index)=>(                            
                                <div className="col pt-5">
                                    <div className="d-flex align-items-center mb-10">
                                        <div className={"symbol symbol-40 mr-5 symbol-light-"+( ((this.state.WizardActive > (index+1)) ? 'success':'primary') )}>
                                            <span className="symbol-label h3">
                                                { ((this.state.WizardActive > (index+1)) ? (
                                                    <span className="svg-icon svg-icon-success svg-icon-2x">
                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24"/>
                                                                <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                ) : (index+1) )  }
                                            </span>
                                        </div>
                                        <div className="d-flex flex-column font-weight-bold">
                                            <span className="text-dark text-hover-primary mb-1 h4">{v.title}</span>
                                            <span className="text-muted">{v.sub}</span>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>

                        <div className="wizard-content pt-5">
                            {(this.state.WizardActive === 1) ? (
                                <UploadFile handlerSubmitFile={handlerSubmitFile}  />
                            ) : (
                            (this.state.WizardActive === 2) ? (
                                <CategoriesFile handlerBackSubmit={handlerBackSubmit}  FileTypes={this.state.FileTypes} />
                            ) :'unknow'
                            )}
                            
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default FormRegulations;

