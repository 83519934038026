import React, { useEffect, useState } from "react";
import Moment from "moment"
import { useParams } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";
import AvatarBoy from "../../../../../media/avatars/011-boy-5.svg";
import AvatarGirl from "../../../../../media/avatars/028-girl-16.svg";

export function CBioStd() {
  const { stdid } = useParams();

  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [stdProfile, setStdProfile] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const GET_STUDENT_DATA_M = () => {
    setStdProfile({ ...stdProfile, loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: stdid,
      },
      target_data: "GET_STUDENT_DATA_M",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdProfile({
          ...stdProfile,
          loading: false,
          data: [],
          message: response.error,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setStdProfile({
          ...stdProfile,
          loading: false,
          data: [],
          message: msg,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setStdProfile({
            ...stdProfile,
            loading: false,
            data: results,
            message: "",
          });
        } else {
          setStdProfile({
            ...stdProfile,
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_STUDENT_DATA_M();
  }, [])
  return (
    stdProfile.loading ? <LoadingBtn /> : stdProfile.message ? <AlertNotif message={stdProfile.message} color={"danger"} title={"Information"} /> : (
      Object.values(stdProfile.data).length > 0 ? (
        <div className="card card-custom gutter-b">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h3 className="mb-8">Personal Biodata</h3>
                <div className="row mb-6">
                  <label className="col-lg-4 font-weight-bold h6">Fullname</label>
                  <div className="col-lg-8">
                    <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdProfile.data.fullname}</span>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 font-weight-bold h6">Birthdate</label>
                  <div className="col-lg-8">
                    <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdProfile.data.birthplace ? stdProfile.data.birthplace + ", " : ""}</span>
                    <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdProfile.data.birthdate ? Moment(stdProfile.data.birthdate).format("DD MMM YYYY") : ""}</span>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 font-weight-bold h6">Gender</label>
                  <div className="col-lg-8">
                    <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdProfile.data.gender ? (stdProfile.data.gender === "P" ? "Male" : "Female") : ""}</span>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 font-weight-bold h6">Religion</label>
                  <div className="col-lg-8">
                    <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdProfile.data.religion ? stdProfile.data.religion.name : "-"}</span>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 font-weight-bold h6">Cellular</label>
                  <div className="col-lg-8">
                    <span className="font-weight-bold text-gray-800 h6 text-capitalize">{stdProfile.data.cellular ? stdProfile.data.cellular : "-"}</span>
                  </div>
                </div>

              </div>
              <div className="col-12 col-lg-6">
                <h3 className="mb-8">Contact Person</h3>
                {Object.values(stdProfile.data.reff_address).length > 0 ? (
                  stdProfile.data.reff_address.map((v, index) => (
                    <div className="p-3 border rounded" key={index}>
                      <p className="mb-0">
                        <i className="fas fa-map-marker-alt mr-2 font-size-lg"></i>
                        <span className="">{v.address} {v.postalcode ? v.postalcode + ", " : ""} {v.city ? v.city.name + ", " : ""} {v.country ? v.country.name + ", " : ""}</span>
                      </p>
                      <p className="mb-0">
                        <span className="mr-2">
                          <i className="socicon-whatsapp mr-2 font-size-lg"></i>
                          <span>{v.cellular1}</span>
                        </span>
                        {v.cellular2 ? (
                          <span className="mr-2">
                            <i className="socicon-whatsapp mr-2 font-size-lg"></i>
                            <span>{v.cellular2}</span>
                          </span>
                        ) : ""}
                        {v.email ? (
                          <span>
                            <i className="flaticon-multimedia-2 mr-2 font-size-lg"></i>
                            <span className="">{v.email}</span>
                          </span>
                        ) : ""}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="p-3 border rounded">
                    <p className="text-center">
                      <i className="flaticon2-open-box font-size-lg"></i>
                      <span>No record found</span>
                    </p>
                  </div>
                )}


                {(stdProfile.data.reff_family && Object.values(stdProfile.data.reff_family).length > 0) ? (
                  <div className="fams">
                    <h3 className="my-5">Family</h3>
                    <div className="d-flex align-items-center">
                      {stdProfile.data.reff_family.map((v, index) => (
                        <div className="d-flex align-items-center border mr-5 p-3 rounded" key={index}>
                          <div className="symbol symbol-40 symbol-light-success flex-shrink-0">
                            {v.gender ? <img src={(v.gender === "p" ? AvatarBoy : AvatarGirl)} alt={v.fullname} className="bg-light" /> : <span className="symbol-label font-size-h4 font-weight-bold">{v.firstname ? v.firstname.charAt(0) : ""}</span>}
                          </div>
                          <div className="ml-4">
                            <div className="text-dark-75 font-weight-bolder h6 mb-0">{v.fullname}</div>
                            <span className="text-muted font-weight-bold">{v.type}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : ""}
              </div>
            </div>
          </div>
        </div>
      ) : ""
    )
  )
}
