import React, { Component } from 'react'
import jwt_decode from "jwt-decode";
import sign from "jwt-encode";
import Filter from './Filter'
import packageJson from "../../../../../package.json";
import AxiosLibWithHeader2 from '../../../Library/AxiosLibWithHeader2';
import LoadingBtn from '../../Widgets/LoadingBtn';
import SVGAB2 from "../../../../media/shapes/abstract-2.svg"
import { AlertNotif } from '../../../Library/AlertNotif';

export default class RemoteUser extends Component {
    constructor(props) {
        super(props);
        this.findUserAccount = this.findUserAccount.bind(this);
        this.AccountStudent = this.AccountStudent.bind(this);
        this.AccountEmp = this.AccountEmp.bind(this);
        this.GET_EMP_BIO_BY_IDENID = this.GET_EMP_BIO_BY_IDENID.bind(this);

        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];
        this.state = {
            AuthDecode: AuthDecode,
            users: { loading: false, message: "", data: [] },
            remoteUser: { loading: false, message: "", data: [] }
        }
    }

    findUserAccount(param) {
        this.setState({
            users: { loading: false, message: "", data: [] },
            remoteUser: { loading: false, message: "", data: [] }
        })
        if (param.Type === 1) {
            this.AccountStudent(param.Type, param.EntityID);
        } else if (param.Type === 2 || param.Type === 3 || param.Type === 4) {
            this.AccountEmp(param.Type, param.EntityName);
        }
    }

    AccountStudent(roleid, studentid) {
        this.setState({ users: { loading: true, message: "", data: [] } });
        var keys = {
            AppId: this.state.AuthDecode.AppID,
            AppEnv: this.state.AuthDecode.AppEnv,
            Secretkey: this.state.AuthDecode.secretkey,
        };
        var body = {
            parameters: { studentid: studentid },
            target_data: "GET_STUDENT_DATA_M",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                this.setState({ users: { loading: false, message: response.error, data: [] } });
            } else if ("message" in response.response) {
                this.setState({ users: { loading: false, message: response.response.message.user, data: [] } });
            } else {
                var value = response.response.data;
                if (value) {
                    var objStd = [];
                    var objProfile = {};
                    objProfile.EntityID = studentid;
                    objProfile.Fullname = value.fullname;
                    objProfile.Role = "Student";
                    objProfile.RoleID = roleid;
                    objStd.push(objProfile);
                    this.setState({ users: { loading: false, message: "", data: objStd } });
                } else {
                    this.setState({ users: { loading: false, message: "No record found", data: [] } });
                }
            }
        });
    }

    AccountEmp(roleid, empname) {
        this.setState({ users: { loading: true, message: "", data: [] } });
        var keys = {
            AppId: this.state.AuthDecode.AppID,
            AppEnv: this.state.AuthDecode.AppEnv,
            Secretkey: this.state.AuthDecode.secretkey,
        };
        var body = {
            parameters: { search: empname },
            target_data: "GET_EMPLOYEE_LOOK_UP",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                this.setState({ users: { loading: false, message: response.error, data: [] } });
            } else if ("message" in response.response) {
                this.setState({ users: { loading: false, message: response.response.message.user, data: [] } });
            } else {
                var param = response.response.data;
                if (param) {
                    var objEmp = [];
                    param.map((v) => {
                        var objProfile = {};
                        objProfile.EntityID = v.id;
                        objProfile.Fullname = v.name_related;
                        objProfile.Empid = v.employee_id;
                        objProfile.Role = "Lecturer";
                        objProfile.RoleID = roleid;
                        objEmp.push(objProfile);
                    });
                    this.setState({ users: { loading: false, message: "", data: objEmp } });
                } else {
                    this.setState({ users: { loading: false, message: "No record found", data: [] } });
                }
            }
        });
    }

    GET_EMP_BIO_BY_IDENID(param) {
        this.setState({ remoteUser: { loading: true, message: "", data: [] } })
        var keys = {
            AppId: this.state.AuthDecode.AppID,
            AppEnv: this.state.AuthDecode.AppEnv,
            Secretkey: this.state.AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                empid: param.EntityID,
            },
            target_data: "GET_EMP_BIO_BY_IDENID",
        };
        const postData = {
            keys: keys,
            body: body,
        };
        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                this.setState({ remoteUser: { loading: false, message: response.error, data: [] } })
            } else if ("message" in response.response) {
                this.setState({ remoteUser: { loading: false, message: response.response.message.user, data: [] } })
            } else if (response.response.data) {
                var value = response.response.data;
                if (value) {
                    let splitName = value.fullname.split(" ");
                    let firstname = splitName[0];
                    let totalSplit = splitName.length;
                    let lastname = "";
                    if (totalSplit > 0) {
                        for (let index = 1; index < totalSplit; index++) {
                            lastname += splitName[index] + " ";
                        }
                    }
                    const newCurrentUser = Object.assign({}, this.state.AuthDecode);
                    newCurrentUser.remotedBy = newCurrentUser.currentData;
                    newCurrentUser.currentData = {
                        entityid: param.EntityID,
                        given_name: firstname,
                        family_name: lastname,
                        empid: value.employee_id,
                        role: param.Role,
                        roleid: param.RoleID,
                    };
                    this.setState({ remoteUser: { loading: false, message: "", data: newCurrentUser } })
                } else {
                    this.setState({ remoteUser: { loading: false, message: "No record found", data: [] } })
                }
            } else {
                this.setState({
                    remoteUser: {
                        loading: false, message: "Failed knowing identity. Code:" +
                            (response.response.responseCode
                                ? response.response.responseCode
                                : "02001"), data: []
                    }
                })
            }
        });
    };

    render() {

        const handlerSwitchUser = (value) => {
            if (value.RoleID === 1) {
                //student
                let splitName = value.Fullname.split(" ");
                let firstname = splitName[0];
                let totalSplit = splitName.length;
                let lastname = "";
                if (totalSplit > 0) {
                    for (let index = 1; index < totalSplit; index++) {
                        lastname += splitName[index] + " ";
                    }
                }
                const newCurrentUser = Object.assign({}, this.state.AuthDecode);
                newCurrentUser.remotedBy = newCurrentUser.currentData;
                newCurrentUser.currentData = {
                    entityid: value.EntityID,
                    given_name: firstname,
                    family_name: lastname,
                    role: value.Role,
                    roleid: value.RoleID,
                };
                this.setState({ remoteUser: { loading: false, message: "", data: newCurrentUser } })
            } else {
                //staff
                this.GET_EMP_BIO_BY_IDENID(value);
            }
        }

        if (Object.values(this.state.remoteUser.data).length > 0) {
            localStorage.setItem(
                "AuthToken",
                sign(this.state.remoteUser.data, packageJson.system_param.PublicKey)
            );
            setTimeout(() => {
                window.location.href = "/dashboard";
            }, 1000);
        }

        return (
            <div id='remote-users'>
                {this.state.AuthDecode.remotedBy ? (
                    <div className="alert alert-custom alert-light-danger alert-shadow border border-danger fade show gutter-b" role="alert">
                        <div className="alert-icon">
                            <span className="svg-icon svg-icon-danger svg-icon-xl">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                            d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                        ></path>
                                        <path
                                            d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                                            fill="#000000"
                                            fillRule="nonzero"
                                        ></path>
                                    </g>
                                </svg>
                            </span>
                        </div>
                        <div className="alert-text">
                            <h3>You are currently using the Remote User feature.</h3>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="alert alert-custom alert-white alert-shadow fade show gutter-b" role="alert">
                            <div className="alert-icon">
                                <span className="svg-icon svg-icon-primary svg-icon-xl">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"></rect>
                                            <path
                                                d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                                                fill="#000000"
                                                opacity="0.3"
                                            ></path>
                                            <path
                                                d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                                                fill="#000000"
                                                fillRule="nonzero"
                                            ></path>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                            <div className="alert-text">
                                <h3>You can remote user by Entity ID</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4 col-xxl-3">
                                <Filter findUserAccount={this.findUserAccount} />
                            </div>
                            <div className="col-12 col-lg-8 col-xxl-9">
                                {this.state.users.loading ? <LoadingBtn /> :
                                    this.state.users.message ? <AlertNotif message={this.state.users.message} color={"danger"} title={"Information"} /> :
                                        Object.values(this.state.users.data).length > 0 ? (
                                            <div className="row">
                                                {this.state.users.data.map((v, index) => (
                                                    <div className="col-12 col-lg-6 col-xxl-4" key={index}>
                                                        <div
                                                            className='card card-custom bgi-no-repeat gutter-b p-3 mb-5 '
                                                            style={{ backgroundPosition: "right top", backgroundSize: "30% auto", backgroundImage: "url(" + SVGAB2 + ")", cursor: "pointer" }}
                                                            onClick={() => handlerSwitchUser(v)}
                                                        >
                                                            <div className="card-body d-flex align-items-center py-0 mt-8">
                                                                <div className="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                                                    <span className="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">
                                                                        {v.Fullname}
                                                                    </span>
                                                                    <span className="font-weight-bold text-muted font-size-lg">
                                                                        {v.Empid ? v.Empid : v.EntityID}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                {Object.values(this.state.remoteUser.data).length > 0 ? (
                                                    <div className="col-12">
                                                        <p className="text-center">
                                                            You will directly switch account. Please wait a moment...
                                                        </p>
                                                    </div>
                                                ) : ""}
                                            </div>
                                        ) : ""}
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}
